import * as AdminActions from './admin.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { AdminEntity } from './admin.models';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { IWorkshopModel } from '@ioh/types';

export const ADMIN_FEATURE_KEY = 'admin';

export interface AdminState extends EntityState<AdminEntity> {
  loaded: boolean;
  error?: any;
  selectedId?: string | number; // which workshop record has been selected
  userId: string;
  programId: string;
  programCode: string;
  workshops: IWorkshopModel[] | any;
  updatedSuccessfully?: boolean | null;
  updatedAdminSuccessfully?: boolean | null;
  saveWorkshopError?: any | null;
  removeAccessSuccessfully?: any | null;
  removeUserAccessError?: any | null;
  saveAdminError?: any | null;
  response?: any | null;
  workshopStatus: any[] | null;
  cancellationReasons: any[] | null;
  selectedHub: string;
  selectedAdminHub: string;
  programList: any[] | null;
  adminProgramList: any[] | null;
  userInfoList: any[] | null;
  userName: any | null;
  userNameSearchResults: any | null;
  adminProgram: any | null;
  accessLevel: any | null;
}

export interface AdminPartialState {
  readonly [ADMIN_FEATURE_KEY]: AdminState;
}

export const adminAdapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (entity) => entity.consultation && entity.consultation.workshop.id,
});

export const initialState: AdminState = adminAdapter.getInitialState({
  error: null,
  loaded: false,
  // set initial required properties
  userId: null,
  programId: null,
  programCode: null,
  workshops: [],
  workshopStatus: null,
  cancellationReasons: null,
  selectedHub: null,
  selectedAdminHub: null,
  programList: [],
  adminProgramList: [],
  userInfoList: [],
  userName: null,
  userNameSearchResults: null,
  adminProgram: null,
  accessLevel: null,
});

const adminReducer = createReducer(
  initialState,
  on(AdminActions.setSelectedHub, (state, { selectedHub }) => ({
    ...state,
    selectedHub,
  })),
  on(AdminActions.setAdminSelectedHub, (state, { selectedAdminHub }) => ({
    ...state,
    selectedAdminHub,
  })),
  on(AdminActions.saveProgramId, (state, { programId }) => ({
    ...state,
    programId,
  })),
  on(AdminActions.saveProgramList, (state, { programList }) => ({
    ...state,
    programList,
  })),
  on(AdminActions.saveAdminProgramList, (state, { adminProgramList }) => ({
    ...state,
    adminProgramList,
  })),
  on(AdminActions.saveUserAccessList, (state, { userInfoList }) => ({
    ...state,
    userInfoList,
  })),
  on(AdminActions.getProgramById, (state) => ({
    ...state,
  })),
  on(AdminActions.saveUserId, (state, { userId }) => ({
    ...state,
    userId,
  })),
  on(AdminActions.saveProgramCode, (state, { programCode }) => ({
    ...state,
    programCode,
  })),
  on(AdminActions.getProgramByIdSuccess, (state, { programCode }) => ({
    ...state,
    programCode,
  })),
  on(AdminActions.getProgramByIdFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
  })),
  on(AdminActions.getCancellationReason, (state) => ({
    ...state,
  })),
  on(
    AdminActions.getCancellationReasonSuccess,
    (state, { cancellationReasons }) => ({
      ...state,
      cancellationReasons,
    })
  ),
  on(AdminActions.getCancellationReasonFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(AdminActions.loadAllWorkshopsByUserIdSuccess, (state, { workshops }) =>
    adminAdapter.addAll(workshops, {
      ...state,
      loaded: true,
      workshops,
    })
  ),
  on(AdminActions.loadAllWorkshopsByUserIdFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
  })),
  on(AdminActions.updateWorkshopById, (state) => ({
    ...state,
    loaded: false,
  })),
  on(AdminActions.updateWorkshopByIdSuccess, (state, { response }) => ({
    ...state,
    response,
    updatedSuccessfully: true,
  })),
  on(AdminActions.updateSuccess, (state, { value }) => ({
    ...state,
    updatedSuccessfully: value,
  })),
  on(AdminActions.updateAdminSuccess, (state, { value }) => ({
    ...state,
    updatedAdminSuccessfully: value,
  })),
  on(AdminActions.updateWorkshopByIdFailure, (state, { error }) => ({
    ...state,
    saveWorkshopError: error,
    loaded: false,
  })),

  on(AdminActions.updateSuccess, (state, { value }) => ({
    ...state,
    updatedSuccessfully: value,
  })),
  on(AdminActions.updateAdminByIdSuccess, (state, { response }) => ({
    ...state,
    response,
    updatedAdminSuccessfully: true,
  })),
  on(AdminActions.updateAdminByIdFailure, (state, { error }) => ({
    ...state,
    saveAdminError: error,
    loaded: false,
  })),
  on(AdminActions.setSelectedWorkshopId, (state, { id }) => ({
    ...state,
    selectedId: id,
  })),
  on(AdminActions.getWorkshopStatus, (state) => ({
    ...state,
  })),
  on(AdminActions.getWorkshopStatusSuccess, (state, { workshopStatus }) => ({
    ...state,
    workshopStatus,
  })),
  on(AdminActions.getWorkshopStatusFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
  })),
  on(AdminActions.removeUserAccess, (state) => ({
    ...state,
    loaded: false,
  })),
  on(AdminActions.removeUserAccessSuccess, (state, { response }) => ({
    ...state,
    response,
    removeAccessSuccessfully: true,
  })),
  on(AdminActions.removeUserAccessFailure, (state, { error }) => ({
    ...state,
    removeUserAccessError: error,
    loaded: false,
  })),
  on(AdminActions.removeAccessSuccess, (state, { value }) => ({
    ...state,
    removeAccessSuccessfully: value,
  })),
  on(AdminActions.loadAccessUserListByUserIdSuccess, (state, { userInfo }) => ({
    ...state,
    loaded: true,
    userInfo,
  })),
  on(AdminActions.loadAccessUserListByUserIdFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
  })),
  on(AdminActions.loadUserNameSearchResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    AdminActions.loadUserNameSearchResultsSuccess,
    (state, { userNameSearchResults }) =>
      adminAdapter.addMany(userNameSearchResults, {
        ...state,
        userNameSearchResults,
        loaded: true,
      })
  ),
  on(AdminActions.loadUserNameSearchResultsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(AdminActions.updateUserName, (state, { userName }) => {
    return Object.assign({}, state, { userName });
  }),
  on(AdminActions.updateAdminProgram, (state, { adminProgram }) => {
    return Object.assign({}, state, { adminProgram });
  }),
  on(AdminActions.updateAccessLevel, (state, { accessLevel }) => {
    return Object.assign({}, state, { accessLevel });
  })
);

export function reducer(state: AdminState | undefined, action: Action) {
  return adminReducer(state, action);
}
