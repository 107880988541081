import { AdditionalNotesComponent } from '../../app/form/accordian-sections/additional-notes/additional-notes.component';
import { AttendeeDetailsComponent } from '../../app/form/accordian-sections/attendee-details/attendee-details.component';
import { ContactDetailsComponent } from '../../app/form/accordian-sections/contact-details/contact-details.component';
import { EngagementDetailsComponent } from '../../app/form/accordian-sections/engagement-details/engagement-details.component';
import { VisitDetailsComponent } from '../../app/form/accordian-sections/visit-details/visit-details.component';

export const sectionAccordian = [
  {
    component: ContactDetailsComponent,
    title: 'Contact Details',
  },
  {
    component: EngagementDetailsComponent,
    title: 'Engagement Details',
  },
  {
    component: VisitDetailsComponent,
    title: 'Visit Details',
  },
  {
    component: AttendeeDetailsComponent,
    title: 'Attendee Details',
  },
  {
    component: AdditionalNotesComponent,
    title: 'Additional Notes',
  },
];
