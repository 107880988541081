<div [formGroup]="visitDetailsGroup" (keydown.enter)="$event.preventDefault()">
  <div class="visit">
    <ioh-duration
      [formGroup]="visitDetailsGroup"
      [options]="options"
      [minDate]="minDate"
      [dateLimit]="dateLimit"
      [waitlist]=false
    >
    </ioh-duration>
    <ioh-wbs-container [wbsDetails]="wbsDetails" (fetchWbsResults)="getWbs($event)"></ioh-wbs-container>
  </div>
</div>
