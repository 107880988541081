import { CustomDateValidators } from '@utils/validators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormControlUtils } from '@utils/formControlUtils';
import { Injectable } from '@angular/core';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Injectable({
  providedIn: 'root',
})
export class DurationEntryService {
  constructor(
    private readonly fb: FormBuilder,
    private readonly customValidator: CustomDateValidators,
    private readonly formControlUtils: FormControlUtils
  ) {}

  public createDate(duration: string, entry?) {
    switch (duration) {
      case 'Half Day or Less': {
        return this.createHalfDayControl(entry);
      }
      case 'Full Day': {
        return this.createFullDayControl(entry);
      }
      case 'Multi-Day': {
        return this.createMultiDayControl(entry);
      }
      default: {
        break;
      }
    }
  }

  public createHalfDayControl(entry?): FormGroup {
    return this.fb.group(
      {
        id: this.fb.control(
          this.formControlUtils.mapProperty({ entry, property: 'id' })
        ),
        date: this.fb.control(
          this.formControlUtils.mapProperty({ entry, property: 'date' }) ||
            this.formControlUtils.mapProperty({ entry, property: 'startDate' }),
          [Validators.required]
        ),
        period: this.fb.control(
          this.formControlUtils.mapProperty({ entry, property: 'period' }) ||
            this.formControlUtils.mapProperty({
              entry,
              property: 'period.value',
            }),
          [Validators.required]
        ),
        waitlistCheckbox: this.fb.control(
          this.formControlUtils.mapProperty({
            entry,
            property: 'waitlistCheckbox',
            defaultValue: false,
          })
        ),
      },
      {
        validators: this.customValidator.checkUniqueGroup.bind(
          this.customValidator
        ),
      }
    );
  }

  public createFullDayControl(entry?): FormGroup {
    return this.fb.group({
      id: this.fb.control(
        this.formControlUtils.mapProperty({ entry, property: 'id' })
      ),
      date: this.fb.control(
        this.formControlUtils.mapProperty({ entry, property: 'date' }) ||
          this.formControlUtils.mapProperty({ entry, property: 'startDate' }),
        [Validators.required, RxwebValidators.unique()]
      ),
      waitlistCheckbox: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'waitlistCheckbox',
          defaultValue: false,
        })
      ),
    });
  }

  public createMultiDayControl(entry?): FormGroup {
    return this.fb.group(
      {
        id: this.fb.control(
          this.formControlUtils.mapProperty({ entry, property: 'id' })
        ),
        endDate: this.fb.control(
          this.formControlUtils.mapProperty({ entry, property: 'endDate' }),
          [Validators.required]
        ),
        startDate: this.fb.control(
          this.formControlUtils.mapProperty({ entry, property: 'startDate' }),
          [Validators.required]
        ),
        waitlistCheckbox: this.fb.control(
          this.formControlUtils.mapProperty({
            entry,
            property: 'waitlistCheckbox',
            defaultValue: false,
          })
        ),
      },
      {
        validators: [
          this.customValidator.checkUniqueGroup.bind(this.customValidator),
          this.customValidator.checkDateTimeline,
        ],
      }
    );
  }
}
