import * as ConsultationActions from './consultation.actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
  ConsultationEntity,
  IOpportunityStateModel,
} from './consultation.models';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

export const CONSULTATION_FEATURE_KEY = 'consultation';

export interface ConsultationState extends EntityState<ConsultationEntity> {
  selectedId?: string | number; // which Consultation record has been selected
  loaded: boolean; // has the Consultation list been loaded
  error?: string | null; // last none error (if any)
  prepopulated: boolean;
  duration: string | null;
  dates: any | null;
  softLockExpirationDate: any | null;
  consultationOwner: any | null;
  consultationOwnerSearchResults: any | null;
  completeConsultationDate: string | null;
  backgroundText: string | null;
  objectivesText: string | null;
  successText: string | null;
  challengesText: string | null;
  purposeOptions: string | null;
  opportunityIdsSearchResults: IOpportunityStateModel[] | [];
  selectedOpportunityIds: any[] | null;
  additionalComments: string | null;
  accentureAttendees: number | null;
  clientCSuiteAttendees: number | null;
  clientOtherAttendees: number | null;
  otherAttendees: number | null;
  totalAttendees: number | null;
  leadershipLevel: string | null;
  audienceType: string | null;
  traveling: string | null;
  internalPurposeResults: any | null;
  selectedInternalPurpose: any;
  attendeeDetails: string | any;
}

export interface ConsultationPartialState {
  readonly [CONSULTATION_FEATURE_KEY]: ConsultationState;
}

export const consultationAdapter: EntityAdapter<any> = createEntityAdapter<any>(
  {
    selectId: (engagementDetails) =>
      engagementDetails.id || engagementDetails.OpportunityId,
  }
);

export const initialState: ConsultationState = consultationAdapter.getInitialState(
  {
    additionalComments: null,
    attendeeDetails: null,
    audienceType: null,
    backgroundText: null,
    challengesText: null,
    completeConsultationDate: null,
    consultationOwner: null,
    consultationOwnerSearchResults: null,
    dates: [],
    duration: null,
    internalPurposeResults: [],
    leadershipLevel: null,
    // set initial required properties
    loaded: false,
    prepopulated: false,
    accentureAttendees: null,
    clientCSuiteAttendees: null,
    clientOtherAttendees: null,
    otherAttendees: null,
    totalAttendees: null,
    objectivesText: null,
    opportunityIdsSearchResults: [],
    purposeOptions: null,
    selectedInternalPurpose: null,
    selectedOpportunityIds: null,
    softLockExpirationDate: null,
    successText: null,
    traveling: null,
  }
);

const consultationReducer = createReducer(
  initialState,
  on(ConsultationActions.markAsPrepopulated, (state, { value }) => {
    return Object.assign({}, state, { prepopulated: value });
  }),
  on(ConsultationActions.loadInternalPurposeResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    ConsultationActions.loadInternalPurposeResultsSuccess,
    (state, { internalPurposeResults }) =>
      consultationAdapter.addMany(internalPurposeResults, {
        ...state,
        internalPurposeResults,
        loaded: true,
      })
  ),
  on(
    ConsultationActions.loadInternalPurposeResultsFailure,
    (state, { error }) => ({ ...state, error })
  ),
  on(
    ConsultationActions.selectInternalPurpose,
    (state, { selectedInternalPurpose }) => {
      return Object.assign({}, state, { selectedInternalPurpose });
    }
  ),
  on(ConsultationActions.loadConsultationOwnerSearchResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    ConsultationActions.loadConsultationOwnerSearchResultsSuccess,
    (state, { consultationOwnerSearchResults }) =>
      consultationAdapter.addMany(consultationOwnerSearchResults, {
        ...state,
        consultationOwnerSearchResults,
        loaded: true,
      })
  ),
  on(
    ConsultationActions.loadConsultationOwnerSearchResultsFailure,
    (state, { error }) => ({
      ...state,
      error,
    })
  ),
  on(
    ConsultationActions.updateConsultationOwner,
    (state, { consultationOwner }) => {
      return Object.assign({}, state, { consultationOwner });
    }
  ),
  on(ConsultationActions.loadOpportunityIdSearchResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    ConsultationActions.loadOpportunityIdsSearchResultsSuccess,
    (state, { opportunityIdsSearchResults }) =>
      consultationAdapter.addMany(opportunityIdsSearchResults, {
        ...state,
        loaded: true,
        opportunityIdsSearchResults,
      })
  ),
  on(
    ConsultationActions.loadOpportunityIdsSearchResultsFailure,
    (state, { error }) => ({ ...state, error })
  ),
  on(
    ConsultationActions.updateSelectedOpportunityIds,
    (state, { selectedOpportunityIds }) => {
      return Object.assign({}, state, { selectedOpportunityIds });
    }
  ),
  on(
    ConsultationActions.updateClientChallenges,
    (state, { challengesText }) => {
      return Object.assign({}, state, { challengesText });
    }
  ),
  on(ConsultationActions.updateDuration, (state, { duration }) => {
    return Object.assign({}, state, { duration });
  }),
  on(ConsultationActions.updateDates, (state, { dates }) => {
    return Object.assign({}, state, { dates });
  }),
  on(
    ConsultationActions.updateSoftlockExpirationDate,
    (state, { softLockExpirationDate }) => {
      return Object.assign({}, state, { softLockExpirationDate });
    }
  ),
  on(
    ConsultationActions.updateCompleteConsultationDate,
    (state, { completeConsultationDate }) => {
      return Object.assign({}, state, { completeConsultationDate });
    }
  ),
  on(ConsultationActions.updateBackgroundText, (state, { backgroundText }) => {
    return Object.assign({}, state, { backgroundText });
  }),
  on(ConsultationActions.updateObjectivesText, (state, { objectivesText }) => {
    return Object.assign({}, state, { objectivesText });
  }),
  on(ConsultationActions.updateSuccessText, (state, { successText }) => {
    return Object.assign({}, state, { successText });
  }),
  on(
    ConsultationActions.updateAdditionalComments,
    (state, { additionalComments }) => {
      return Object.assign({}, state, { additionalComments });
    }
  ),
  on(
    ConsultationActions.updateAccentureAttendees,
    (state, { accentureAttendees }) => {
      return Object.assign({}, state, { accentureAttendees });
    }
  ),
  on(
    ConsultationActions.updateClientCSuiteAttendees,
    (state, { clientCSuiteAttendees }) => {
      return Object.assign({}, state, { clientCSuiteAttendees });
    }
  ),
  on(
    ConsultationActions.updateClientOtherAttendees,
    (state, { clientOtherAttendees }) => {
      return Object.assign({}, state, { clientOtherAttendees });
    }
  ),
  on(ConsultationActions.updateOtherAttendees, (state, { otherAttendees }) => {
    return Object.assign({}, state, { otherAttendees });
  }),
  on(ConsultationActions.updateTotalAttendees, (state, { totalAttendees }) => {
    return Object.assign({}, state, { totalAttendees });
  }),
  on(
    ConsultationActions.updateLeadershipLevel,
    (state, { leadershipLevel }) => {
      return Object.assign({}, state, { leadershipLevel });
    }
  ),
  on(ConsultationActions.updateAudienceType, (state, { audienceType }) => {
    return Object.assign({}, state, { audienceType });
  }),
  on(ConsultationActions.updateTraveling, (state, { traveling }) => {
    return Object.assign({}, state, { traveling });
  }),
  on(
    ConsultationActions.updateAttendeeDetails,
    (state, { attendeeDetails }) => {
      return Object.assign({}, state, { attendeeDetails });
    }
  ),
  on(ConsultationActions.clearStateObject, () => {
    return Object.assign({}, initialState);
  })
);

export function reducer(state: ConsultationState | undefined, action: Action) {
  return consultationReducer(state, action);
}
