import { Injectable } from '@angular/core';

import { Action, Store, select } from '@ngrx/store';

import * as FormActions from './form.actions';
import * as FormSelectors from './form.selectors';
import * as fromForm from './form.reducer';
@Injectable()
export class FormFacade {
  loaded$ = this.store.pipe(select(FormSelectors.getFormLoaded));
  error$ = this.store.pipe(select(FormSelectors.getFormError));
  formSubmitted$ = this.store.pipe(select(FormSelectors.getSubmissionStatus));
  requestSuccessful$ = this.store.pipe(select(FormSelectors.getRequestStatus));
  allForm$ = this.store.pipe(select(FormSelectors.getAllForm));
  selectedForm$ = this.store.pipe(select(FormSelectors.getSelected));
  termsAndConditions$ = this.store.pipe(
    select(FormSelectors.getTermsAndConditions)
  );

  getProgramId$ = this.store.pipe(select(FormSelectors.getProgramId));

  constructor(private readonly store: Store<fromForm.FormPartialState>) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }

  getTermsAndConditions(programId) {
    this.dispatch(FormActions.loadTermsAndConditions({ programId: programId }));
  }

  createRequest(request) {
    this.dispatch(FormActions.createRequest({ request }));
  }

  saveProgramId(programId) {
    this.dispatch(FormActions.saveProgramId({ programId: programId }));
  }

  clearSuccessfulRequest() {
    this.dispatch(FormActions.clearRequestSuccessful());
  }
}
