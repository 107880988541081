import {
  GENERAL_FEATURE_KEY,
  GeneralPartialState,
  GeneralState,
} from './general.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

// Lookup the 'General' feature state managed by NgRx
export const getGeneralState = createFeatureSelector<
  GeneralPartialState,
  GeneralState
>(GENERAL_FEATURE_KEY);

export const getGeneralLoaded = createSelector(
  getGeneralState,
  (state: GeneralState) => state.loaded
);

export const getGeneralPrepopulated = createSelector(
  getGeneralState,
  (state: GeneralState) => state.prepopulated
);

export const getGeneralError = createSelector(
  getGeneralState,
  (state: GeneralState) => state.error
);

export const getAllEngagementSponsorResults = createSelector(
  getGeneralState,
  (state: GeneralState) => state.engagementSponsorSearchResults
);

export const getAllPrimaryContactResults = createSelector(
  getGeneralState,
  (state: GeneralState) => state.primaryContactSearchResults
);

export const getAllAdditionalContactResults = createSelector(
  getGeneralState,
  (state: GeneralState) => state.additionalContactSearchResults
);

export const getEngagementTypeResults = createSelector(
  getGeneralState,
  (state: GeneralState) => state.engagementTypeResults
);

export const getEngagementGroupResults = createSelector(
  getGeneralState,
  (state: GeneralState) => state.engagementGroupResults
);

export const getEngagementGroupEcosystemResults = createSelector(
  getGeneralState,
  (state: GeneralState) => state.engagementGroupEcosystemResults
);

export const getAllEngagementLeadResults = createSelector(
  getGeneralState,
  (state: GeneralState) => state.engagementLeadSearchResults
);

export const getAllEngagementTeamMemberResults = createSelector(
  getGeneralState,
  (state: GeneralState) => state.engagementTeamMemberSearchResults
);

export const getAllExternalOrganizationCategories = createSelector(
  getGeneralState,
  (state: GeneralState) => state.organizationExternalCategoriesResults
);

export const getAllAccentureEntities = createSelector(
  getGeneralState,
  (state: GeneralState) => state.organizationAccentureEntitiesResults
);

export const getAllTagsResults = createSelector(
  getGeneralState,
  (state: GeneralState) => state.tagsSearchResults
);

export const getAllClientOrganizationSearchResults = createSelector(
  getGeneralState,
  (state: GeneralState) => state.clientOrganizationSearchResults
);

export const getAllOrganizationTypes = createSelector(
  getGeneralState,
  (state: GeneralState) => state.organizationTypes
);

export const getEngagementTeamRoles = createSelector(
  getGeneralState,
  (state: GeneralState) => state.engagementTeamRoles
);

export const getEngagementTitle = createSelector(
  getGeneralState,
  (state: GeneralState) => state.engagementTitle
);

export const getDatesAndLocationsRooms = createSelector(
  getGeneralState,
  (state: GeneralState) => state.datesAndLocationsRooms
);

export const getEngagementStartDate = createSelector(
  getGeneralState,
  (state: GeneralState) => state.engagementStartDate
);

export const getEngagementEndDate = createSelector(
  getGeneralState,
  (state: GeneralState) => state.engagementEndDate
);
