<div [formGroup]="parentForm">
  <mat-checkbox
    color="primary"
    formControlName="{{ value }}"
    id="{{checkId}}"
    attr.aria-label={{checkId}}
  >
    <ng-content></ng-content>
  </mat-checkbox>
  <ioh-error
    class="checkbox__error"
    *ngIf="(parentForm.get(value).touched || parentForm.get(value).dirty) &&
      parentForm.get(value).hasError('required')"
    errorMsg="{{ label }} is required">
  </ioh-error>
</div>
