export const tooltipConst = {
  organizaitonTooltipObj: {
    tooltip:
      'Please identify which organizations are the target audience for the engagement. Note: This should not include groups that are co-hosting or co-presenting, which should be identified separately under "Group Particiption".',
  },
  mmsOppTooltipObj: {
    tooltip:
      'If the hub team had a meaningful contribution to the origination or sales enablement of a particular sales pursuit, please identify the MMS Opportunity ID. Please also update the MMS Opportunity record with appropriate Sales Team participation or Sales Campaigns.',
  },
  chargeDateTooltipObj: {
    tooltip:
      'Indicates in which month the recovery chargeback should be processed. For example: A charge date of June 22 will be processed at the end of June.',
  },
  dateLocationTooltipObj: {
    tooltip:
      'Please identify when there are specific event dates planned for this engagement.  For example, if there is a 2-day workshop planned with the client team, please add one row for each event date (two rows total).  If your engagement does not have specific event dates, then do not populate any rows.  Internal or planning meetings should NOT be included.  Please see illustrative timeline:<insert powerpoint visual>',
  },
  engagementStatusTooltipObj: {
    tooltip: `
      Requested: Indicates that a request has been received but the scope, timeline, staffing, and pricing have not yet been confirmed.\n
      Booked: Indicates that the scope, timeline, staffing, and pricing have been confirmed. This includes confirmation of the WBS for the chargeback recovery.  All engagements in a "Booked" status become subject to cancellation fees per policy.\n
      Complete: Indicates that the full scope of work has been delivered.  Note: Before saving an engagement as "Completed", all mandatory fields in the Engage tool must be updated. The "Completed" status is independent of the recovery chargeback timeline and status because chargebacks may be executed during or after an engagement. \n
      Withdrawn: Indicates that a request has been withdrawn before any significant work was completed for the engagement.  There are usually no cancellation fees associated with "Withdrawn" engagements.\n
      Cancelled: Indicates that a request has been canceled after the team has already contributed meaningful work towards the engagement.  There are usually cancellation fees associated with "Canceled" engagements.`,
  },
  engagementPurposeTooltipObj: {
    tooltip: `
      Offering Dev - Asset or Platform Dev: Development of assets/platforms (usually MD&I) with intent to showcase or to sell to clients. \n
      Offering Dev - Partnership Dev : Work with established or prospective ecosystem partners to collaborate on strategy, offerings, or go-to-market efforts. \n
      Client Account Support - Account Advisory (non-client facing): Support for internal ACN account planning, strategy definition, or go-to-market efforts. \n 
      Client Account Support - Relationship Dev: Client-facing engagement to promote collaboration and to showcase Accenture’s capabilities.  \n
      Client Account Support - Origination: Client-facing engagement to explore client business challenges with targeted goal of creating new sales opportunities.  \n
      Client Account Support - Opp Pursuit or Sales Enablement: Client-facing engagement to support existing sales opportunity.  \n
      Client Delivery - Sold Work: Client-facing support for billable (externally chargeable) project-based delivery work.  \n
      Accenture - People Engagement: Internal support for promoting ACN employee engagement (including ERG, I&D, local community events).  \n
      Accenture - Recruitment: Support for HR recruiting events or internship programs (internal or external facing).  \n
      Other - Corporate Citizenship & Social Innovation: Support for charitable or non-profit organizations.  \n
      Other - Marketing: Support to promote ACN''s presence in the market, including conferences, media, or local events.  \n
      Other - None of the Above`,
  },
  engagementTypeTooltipObj: {
    tooltip: `
      Please indicate which engagement type best describes the scope of work.  \n
      Innovation Applied: The work is directly related to client’s innovation agenda and engages multiple components of Accenture’s innovation capabilities. Must result in meaningful co-creation and/or scale delivery of innovation. This is Capital “I” Innovation.\n
      Innovation Methodology: The work employs an innovation mindset or FORM skills to solve a client’s business problem, but the solution itself would not be considered innovative or is not part of a broader innovation agenda. May involve Accenture’s innovation capabilities, but only to a limited degree.  This is Lowercase “i” innovation.  \n
      Other: The work is supported by the hub, but overall contribution is limited.  This work may include some elements of innovation (storytelling, visual design, facilitation), but does not represent a broader innovation mindset or approach. “Other” should also be used to track non-innovation work efforts in Engage for logistical reasons or financial chargebacks. Note: All “Other” engagements are excluded from reporting metrics.`,
  },
  chargeStatusTooltipObj: {
    tooltip: `
      Draft: Indicates that the charge details are pending updates and not yet ready for processing.  \n 
      Ready for Submission: Indicates that the charge details are complete and ready for processing on the charge date.   \n
      Processed: Indicates that the charge has been processed on the charge date.  \n
      Direct Charge: Indicates that the hub team will book time/expense to the WBS directly without the need for processing a separate charge.     `,
  },
};
