<div
  fxLayout="row"
  fxLayout.md="column"
  fxLayout.sm="column"
  fxLayout.xs="column"
  fxLayoutAlign="start start"
  fxLayoutAlign.md="space-between stretch"
  fxLayoutAlign.sm="space-between stretch"
  fxLayoutAlign.xs="space-between stretch"
  [ngClass] = "{'recovery-entry-row':!smallScreen}"
  fxLayoutGap="1rem"
>
    <ioh-checkbox
      class="item__checkbox"
      ngClass.md="item__checkbox--medium"
      ngClass.sm="item__checkbox--small"
      ngClass.xs="item__checkbox--xsmall"
      checkId="passThrough-{{index}}"
      value="passThrough"
      [formGroup]="parentForm"
      [required]=false
    >
     <label fxHide="true">Direct Charge</label>
    </ioh-checkbox>
  <div
    fxLayout="column"
    fxFlex="7%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    fxLayout.md="row"
    fxLayout.sm="row"
    fxLayout.xs="row"
    class='dateRow'
  >
  <label class="chargeDateLabel" *ngIf="smallScreen">Charge Date</label>
    <ioh-date-picker 
      placeholder="mm/dd/yyyy"
      value="chargeDate"
      id="chargeDate-{{index}}"
      ariaLabel="date picker"
      [ngClass] = "{'smDate':smallScreen}"
      [formGroup]="parentForm">
    </ioh-date-picker>
  </div>
    <div fxFlex="7%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%">
    <ioh-dropdown
    dropdownId="status-{{index}}"
    id="status-{{index}}"
    [label]="smallScreen ? 'Charge Status': ''"
    [formGroup]="parentForm"
    [isMultiple] = "statusDropdownSettings.isMultiple"
    [values] = "statusDropdownSettings.values"
    field = "chargeStatus"
    [required] = "statusDropdownSettings.required"
    placeholder = "{{statusDropdownSettings.placeholder}}"
    name = "{{statusDropdownSettings.name}}"
    direction="{{statusDropdownSettings.direction}}"
  ></ioh-dropdown>
  <ioh-error class='statusError'
  *ngIf="chargeStatusFlag"
    errorMsg="Action Needed"
  >
</ioh-error>
</div>
<div fxFlex="28%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%">
  <ioh-wbs 
  (fetchWbsResults)="getWbs($event)"
  [wbsType]="parentForm.get('wbsType').value"
  [wbsDescription]="parentForm.get('wbsDescription').value"
  [expiration]="parentForm.get('expiration').value"
  [isValid]="parentForm.get('isValid').value"
  [responseLoaded]="parentForm.get('responseLoaded').value"
  [index]="index"
  [wbsDetails]="wbsDetails"
  >
  </ioh-wbs>
</div>
  <ioh-dropdown
    fxFlex="7%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    dropdownId="currency-{{index}}"
    id="currency-{{index}}"
    [label]="smallScreen ? 'Currency': ''"
    [formGroup]="parentForm"
    [isDisabled] = "!programCode"
    [isMultiple] = "currencyDropdownSettings.isMultiple"
    [values] = "currencyDropdownSettings.values"
    field = "currency"
    [required] = "currencyDropdownSettings.required"
    name = "{{currencyDropdownSettings.name}}"
    direction="{{currencyDropdownSettings.direction}}"
  >
  </ioh-dropdown>
  <ng-container *ngFor="let input of inputsRecoveryEntry; let inputIndex=index;">
    <ioh-input
      fxFlex="{{input.flex}}"
      fxFlex.md="100%"
      fxFlex.sm="100%"
      fxFlex.xs="100%"
      [label]="smallScreen ? input.label : ''"
      inputId="{{input.id}}-{{index}}"
      id="{{input.id}}-{{index}}"
      name="{{input.name}}"
      placeholder="{{input.placeholder}}"
      [formGroup]="parentForm"
      value="{{input.value}}"
      [maxLength]="input.maxLength"
      [required]="input.required"
      (inputBlurred)="roundToTwoDecimalPoints($event)"
      (input)="setManualEditedFlag(input.id)"
    ></ioh-input>
  </ng-container>
  <div
    class="recovery__item__remove"
    fxFlex="2%"
  >
    <button
      (click)="openModal()"
      id="RemoveButton-{{index}}"
      mat-mini-fab
      class="item__button__delete"
      [class.item__button__delete--first]="index === 0"
      color="accent"
      aria-label="Remove item button"
    >
      <mat-icon class="material-icons" aria-label="Remove item button"
        >clear</mat-icon
      >
    </button>
  </div>
</div>
<ioh-error
  *ngIf="discountGTCostError"
  errorMsg="Discount cannot be greater than the value of the item"
></ioh-error>
<ioh-error
  *ngIf="parentForm
  .get('recovery2P')
  .hasError('invalidRecovery2P')"
  errorMsg="2% Recovery should be less than Recovery Amount"
></ioh-error>
<ng-template #warningTemplate>
    <ioh-warning-modal
      elementName="recovery entry"
      (delete)="removeEntry()"
      (close)="closeModal()"
    ></ioh-warning-modal>
</ng-template>
