import { Action, createReducer, on } from '@ngrx/store';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

import * as FormActions from './form.actions';
import { FormEntity } from './form.models';

export const FORM_FEATURE_KEY = 'form';

export interface FormState extends EntityState<FormEntity> {
  selectedId?: string | number; // which Form record has been selected
  loaded: boolean; // has the Form list been loaded
  error?: string | null; // last none error (if any)
  termsAndConditions: any | null;
  response: any | null;
  programId: string;
  requestSuccessful: boolean | null;
  submitted: boolean | null;
}

export interface FormPartialState {
  readonly [FORM_FEATURE_KEY]: FormState;
}

export const formAdapter: EntityAdapter<FormEntity> = createEntityAdapter<any>({
  selectId: (entity) => entity.id,
});

export const initialState: FormState = formAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  programId: null,
  requestSuccessful: null,
  response: null,
  submitted: false,
  termsAndConditions: null,
});

const formReducer = createReducer(
  initialState,
  on(FormActions.loadTermsAndConditions, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    FormActions.loadTermsAndConditionsSuccess,
    (state, { termsAndConditions }) =>
      formAdapter.addOne(termsAndConditions, {
        ...state,
        loaded: true,
        termsAndConditions,
      })
  ),
  on(FormActions.loadTermsAndConditionsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(FormActions.createRequest, (state) => ({
    ...state,
    error: null,
    loaded: false,
    submitted: true,
  })),
  on(FormActions.createRequestSuccess, (state, { response }) =>
    formAdapter.addOne(response, {
      ...state,
      loaded: true,
      requestSuccessful: true,
      response,
    })
  ),
  on(FormActions.createRequestFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    requestSuccessful: false,
  })),
  on(FormActions.saveProgramId, (state, { programId }) => ({
    ...state,
    programId,
  })),
  on(FormActions.clearRequestSuccessful, (state) => ({
    ...state,
    requestSuccessful: null,
    submitted: null,
  }))
);

export function reducer(state: FormState | undefined, action: Action) {
  return formReducer(state, action);
}
