<div fxLayout="column" [formGroup]="consultationGroup" class="consultation__container">
  <h3 class="consultation__title consultation__title__first">Consultation</h3>
    <ioh-duration
      [formGroup]="consultationGroup"
      [options]="options"
      [dateLimit]="dateLimit"
      [waitlist]=true
    ></ioh-duration>
    <label class="consultation__label">Softlock Expiration Date</label>
    <ioh-date-picker
      fxFlex.md="100%"
      fxFlex.sm="100%"
      fxFlex.xs="100%"
      icon="today"
      value="softlockExpirationDate"
      ariaLabel="date picker"
      [warning]="warning"
      placeholder="Choose a date"
      [formGroup]="consultationGroup">
    </ioh-date-picker>
    <div fxLayout="row" [ngClass]="browser()">
      <ioh-lookup
        fxFlex="40%"
        fxFlex.md="100%"
        fxFlex.sm="100%"
        fxFlex.xs="100%"
        class="consultation__title__mt"
        lookUpId="consultationOwner"
        label="Consultation Owner"
        placeholder="Enterprise ID"
        lookupGroupFieldName="consultationOwner"
        lookupArrayFieldName="consultationOwnerResult"
        lookupInputFieldName="consultationOwnerInput"
        [formGroup]="consultationGroup"
        [limit]="1"
        [required]="false"
        [searchResults$]="allConsultationOwnerResults$"
        [isLoading$]="allConsultationOwnerResultsLoading$"
      >
      </ioh-lookup>
    </div>
    <label class="consultation__label">Consultation Complete Date</label>
    <ioh-date-picker
      icon="today"
      value="completeConsultationDate"
      ariaLabel="date picker"
      placeholder="Choose a date"
      [formGroup]="consultationGroup">
    </ioh-date-picker>
  <h3 class="consultation__title">Consultation Details</h3>
  <ioh-details-engagement
    [textareas]="textareas"
    [formGroup]="consultationGroup"
    lookupGroupFieldName="opportunityInput"
    lookupArrayFieldName="oppResult"
    lookupInputFieldName="input"
    [dropdownOptionsPurpose] = "internalPurposes$ | async"
    [opportunityLimit] = "opportunityLimit"
    [opportunityResults] = "allOpportunityIdSearchResults$"
    [opportunityLoading]="opportunityIdResultsLoading$">
  </ioh-details-engagement>
  <h3 class="consultation__title">Attendee Details</h3>
  <ioh-details-attendee
    [formGroup]="consultationGroup"
    [radioValues]="radioValues"
    [requiredAttendeeNumber]="false"
    (totalAttendee)="updateTotal($event)">
  </ioh-details-attendee>
  <div fxLayout="row">
    <ioh-text-area
    fxFlex="60%"
    fxFlex.md = "100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    [formGroup]="consultationGroup"
    class="consultation__title__mt"
    label="Attendee Details"
    name="attendee details"
    textareaId="attendeeDetails"
    placeholder="Breakdown of expected attendees, roles etc."
    value="attendeeDetails"
    maxCharLimit=1000
    description="Breakdown of expected attendees, roles etc."
    height="4rem"
    [required]=false
  ></ioh-text-area>
  </div>
</div>

