import { AbstractControl, ControlContainer } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ioh-input',
  styleUrls: ['./input-component.component.scss'],
  templateUrl: './input-component.component.html',
})
export class InputComponent implements OnInit {
  @Input() label: string;
  @Input() inputId: any;
  @Input() name: any;
  @Input() placeholder: string;
  @Input() value: any;
  @Input() width: string;
  @Input() required: boolean;
  @Input() regex: any;
  @Input() maxLength: string;
  @Output() inputBlurred = new EventEmitter();
  parentForm: AbstractControl;

  constructor(private readonly controlContainer: ControlContainer) {}

  ngOnInit() {
    this.parentForm = this.controlContainer.control;
  }

  decimalLimit() {
    const formControl = this.parentForm.get(this.value);
    return formControl.hasError('threeDecimalPoints') ? 3 : 2;
  }

  onBlur(value: string): void {
    this.inputBlurred.emit(value);
  }
}
