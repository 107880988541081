import * as EngagementDetailsActions from './engagement-details.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { ReferenceDataService } from '../../../reference-data';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class EngagementDetailsEffects {
  loadEngagementDetailsInternalPurposes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EngagementDetailsActions.loadInternalPurposeResults),
      exhaustMap((action) =>
        this.referenceDataService.getInternalPurpose(action.programId).pipe(
          map((res) =>
            EngagementDetailsActions.loadInternalPurposeResultsSuccess({
              internalPurposeSearchResults: res.data,
            })
          ),
          catchError((error) =>
            of(
              EngagementDetailsActions.loadInternalPurposeResultsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  loadOpportunityIDs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EngagementDetailsActions.loadOpportunityIdsSearchResults),
      exhaustMap((action) =>
        this.referenceDataService.getOpportunityId(action.payload).pipe(
          map((res) =>
            EngagementDetailsActions.loadOpportunityIdsSearchResultsSuccess({
              opportunityIdsSearchResults: res.data,
            })
          ),
          catchError((error) =>
            of(
              EngagementDetailsActions.loadOpportunityIdsSearchResultsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly referenceDataService: ReferenceDataService
  ) {}
}
