<div fxLayout="column">
  <label
    fxFlex
    attr.for="{{ radioId }}"
    class="radio__label"
    [style.font-family]="font"
  >
    <span *ngIf="required" class="radio__asterisk">*</span>
    {{ label }}
  </label>
  <div [formGroup]="parentForm">
    <mat-radio-group
      id="{{ radioId }}"
      [formControlName]="field"
      fxLayout="row"
      fxLayout.md="column"
      fxLayout.sm="column"
      fxLayout.xs="column"
      fxFlex
      fxLayoutGap="2.5rem"
      color="primary"
      attr.aria-labelledby="{{ radioId }}"
      (change)="selectedRadio($event)"
    >
      <mat-radio-button *ngFor="let value of values" [value]="value">
        {{ value }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <ioh-error
    class="radio__error"
    *ngIf="
      (parentForm.get(field).touched || parentForm.get(field).dirty) &&
      parentForm.get(field).hasError('required')
    "
    errorMsg="{{ label }} is required"
  ></ioh-error>
</div>
