import { AppLoadingService } from 'libs/ui-library/src/lib/app-loading/app-loading.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ioh-loading',
  styleUrls: ['./app-loading.component.scss'],
  templateUrl: './app-loading.component.html',
})
export class AppLoadingComponent implements OnInit {
  constructor(public appLoadingService: AppLoadingService) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {}
}
