<div fxLayout="column" class="datepicker" [formGroup]="parentForm">
  <mat-form-field
    fxFlex
    fxLayout="row"
    appearance="outline"
    color="primary"
    class="datepicker__field"
  >
    <input
      matInput
      #input
      formControlName="{{ value }}"
      [matDatepicker]="picker"
      [min]="minDate"
      (click)="picker.open()"
      placeholder="{{placeholder}}"
      attr.aria-label="{{ ariaLabel }}"
    />
    <mat-datepicker-toggle matSuffix [for]="picker" *ngIf="icon">
      <mat-icon
        class="material-icons datepicker__icon"
        [ngClass]="browser()"
        role="img"
        aria-label="open calendar"
        matDatepickerToggleIcon
        >{{ icon }}</mat-icon
      >
    </mat-datepicker-toggle>
    <mat-datepicker #picker (closed)="input.blur()"></mat-datepicker>
  </mat-form-field>
    <ioh-error
      class="datepicker__error"
      *ngIf="(parentForm.get(value).touched || parentForm.get(value).dirty) &&
        (parentForm.get(value).hasError('unique'))"
      errorMsg="This date was already selected!">
    </ioh-error>
    <ioh-error
      class="datepicker__error"
      [class.datepicker__error__pb]="formHasMultipleErrors()"
      *ngIf="(parentForm.touched || parentForm.dirty) &&
        (parentForm.hasError('uniqueGroup'))"
      errorMsg="This option was already selected!">
    </ioh-error>
    <ioh-error
      class="datepicker__error"
      [class.datepicker__error__pb]="formControlHasMultipleErrors(value)"
      *ngIf="( (parentForm.get(value).touched || parentForm.get(value).dirty) &&
        parentForm.get(value).hasError('matDatepickerParse'))"
      errorMsg="This date is invalid!">
    </ioh-error>
    <ioh-error
      class="datepicker__error"
      *ngIf="( (parentForm.get(value).touched || parentForm.get(value).dirty) &&
        parentForm.get(value).hasError('matDatepickerMin'))"
      errorMsg="This date cannot be in the past!">
    </ioh-error>
    <ioh-error
      class="datepicker__error"
      *ngIf="(parentForm.get(value).touched || parentForm.get(value).dirty) &&
        parentForm.get(value).hasError('required')"
      errorMsg="This date is required!">
    </ioh-error>
    <ioh-error
      class="datepicker__error"
      *ngIf="(parentForm.touched || parentForm.dirty) && isEndDate &&
        parentForm.hasError('timeline')"
      errorMsg="EndDate must be strictly after StartDate"
    ></ioh-error>
    <div class="datepicker__warning"
      [class.datepicker__warning__mt]="parentForm.get(value).errors || parentForm.errors"
      *ngIf="((parentForm.get(value).touched || parentForm.get(value).dirty) && warning)"
      > You've selected a past date</div>
</div>
