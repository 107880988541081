import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormControlUtils } from '@utils/formControlUtils';
import { Injectable } from '@angular/core';
import { LookUpValidatorsService } from '@utils/validators';

@Injectable({
  providedIn: 'root',
})
export class OrganizationEntryService {
  constructor(
    private readonly fb: FormBuilder,
    private readonly lookUpValidator: LookUpValidatorsService,
    private readonly formControlUtils: FormControlUtils
  ) {}

  createInternalOrganization(id: number, name: string, entry?: any): FormGroup {
    return this.fb.group({
      id: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'id',
          defaultValue: undefined,
        })
      ),
      accentureEntity: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'accentureEntity',
          defaultValue: '',
        }),
        Validators.required
      ),
      operatingGroup: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'operatingGroup',
          defaultValue: '-',
        }),
        Validators.required
      ),
      type: this.fb.control(id),
      typeString: this.fb.control(name),
    });
  }

  createExternalOrganization(id: number, name: string, entry?: any): FormGroup {
    return this.fb.group({
      id: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'id',
          defaultValue: undefined,
        })
      ),
      category: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'category',
          defaultValue: undefined,
        }),
        Validators.required
      ),
      organizationName: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'organizationName',
          defaultValue: undefined,
        }),
        Validators.required
      ),
      type: this.fb.control(id),
      typeString: this.fb.control(name),
    });
  }

  createClientOrganization(id: number, name: string, entry?: any): FormGroup {
    return this.fb.group({
      id: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'id',
          defaultValue: undefined,
        })
      ),
      accountStatus: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'accountStatus',
          defaultValue: '-',
        })
      ),
      client: this.fb.group({
        clientInput: this.fb.control(''),
        clientResult: this.fb.array(
          [],
          name == 'Other (Including Accenture Federal Clients)'
            ? [this.lookUpValidator.validateLimit(1)]
            : [
                this.lookUpValidator.validateRequired,
                this.lookUpValidator.validateLimit(1),
              ]
        ),
      }),
      country: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'country',
          defaultValue: '-',
        })
      ),
      diamond: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'diamond',
          defaultValue: '-',
        })
      ),
      g2000Rank: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'g2000Rank',
          defaultValue: '-',
        })
      ),
      industrySegment: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'industrySegment',
          defaultValue: '-',
        })
      ),
      operatingGroup: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'operatingGroup',
          defaultValue: '-',
        })
      ),
      operatingUnit: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'operatingUnit',
          defaultValue: '-',
        })
      ),
      organizationName: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'organizationName',
          defaultValue:
            name == 'Other (Including Accenture Federal Clients)' ? '' : '-',
        }),
        Validators.required
      ),
      type: this.fb.control({ id: id, value: name }),
    });
  }
}
