import { AbstractControl, ControlContainer } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ModalService } from 'apps/ioh-app/src/app/services/modal.service';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';

@Component({
  selector: 'ioh-wbs-lookup',
  styleUrls: ['./wbs-lookup.component.scss'],
  templateUrl: './wbs-lookup.component.html',
})
export class WbsLookupComponent implements OnInit, OnDestroy {
  @Output() delete = new EventEmitter();
  @Output() breakdownTypeChanged = new EventEmitter();
  @Output() fetchWbsResults = new EventEmitter();
  @Input() index;
  @Input() responseLoaded;
  @Input() isValid;
  @Input() wbsDetails: boolean;
  @Input() wbsType: string;
  @Input() wbsDescription: string;
  @Input() wbsExpiration: string;
  @Input() breakdownType: string;

  parentForm: AbstractControl;
  emptyPercentage: boolean;
  smallScreen = false;
  widthWarningModal = 'fit-content';
  wbsBreakdown: string;
  wbsResultsLoading$ = new BehaviorSubject(false);
  isExpired: boolean = false;

  private readonly subscriptions: Subscription = new Subscription();
  @ViewChild('warningTemplate') modalTemplate: TemplateRef<any>;

  constructor(
    private readonly controlContainer: ControlContainer,
    public mediaObserver: MediaObserver,
    private readonly modalService: ModalService
  ) {
    this.subscriptions.add(
      mediaObserver.media$.subscribe((change: MediaChange) => {
        this.smallScreen = !!(
          change.mqAlias === 'md' ||
          change.mqAlias === 'sm' ||
          change.mqAlias === 'xs'
        );
      })
    );
  }

  ngOnInit(): void {
    this.parentForm = this.controlContainer.control;
    this.subscriptions.add(
      this.parentForm
        .get('code')
        .valueChanges.pipe(
          tap(() => this.wbsResultsLoading$.next(false)),
          debounceTime(500),
          distinctUntilChanged(),
          map((res) => res)
        )
        .subscribe((change) => {
          /**
           * Tell whoever is listening to dispatch an action to effect > refDataService > getWBS
           * to relevant facade to fetch search results for this dummy component,
           * which will update wbsLookupSearchResults$,
           * which we subscribe to in the parent container component and map the responses to the correct indexed wbs-component
           */
          this.fetchWbsResults.emit({ index: this.index, wbs: change });
          this.wbsResultsLoading$.next(true);
        })
    );
    this.subscriptions.add(
      this.parentForm.get('expiration').valueChanges.subscribe((expiration) => {
        if (expiration) {
          var expirationDate = new Date(expiration);
          this.isExpired = new Date() > expirationDate;
        } else {
          this.isExpired = false;
        }
      })
    );
    this.setLabel();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  setLabel() {
    this.wbsBreakdown = this.wbsDetails ? 'Breakdown' : 'WBS %';
  }

  onRemoveWbs(index: number) {
    if (this.wbsDetails) {
      this.openModal();
    } else {
      this.delete.emit(index);
    }
  }

  openModal(): void {
    const dataModal = { template: this.modalTemplate };
    this.modalService.openDialog(dataModal, this.widthWarningModal);
  }

  removeWbs(index: number): void {
    this.delete.emit(index);
    this.modalService.closeDialog();
  }

  closeModal() {
    this.modalService.closeDialog();
  }

  onChangeBreakdownType(event: string) {
    this.breakdownTypeChanged.emit(event);
  }
}
