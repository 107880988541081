<div class="banner">
  <div class="banner__org">
    <span class="banner__org__title">{{org}}</span>
  </div>
  <div class="banner__heading" fxLayout="row" fxLayoutAlign="space-between center">
    <div [style.margin]="headerMargin()">
      <span class="material-icons banner__heading__icon" (click)="goBack()">{{icon}}</span>
      <span *ngIf="progCode; else elseBlock" class="banner__heading__title">{{progCode}} - {{engId}} | {{title}}</span>
      <ng-template #elseBlock>
        <span class="banner__heading__title">{{title}}</span>
      </ng-template>
    </div>
    <div *ngIf="showButtons" class="banner__heading__buttons" fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-stroked-button color="primary" class="banner__heading__buttons__cancel" (click)="cancel()">Cancel</button>
      <button mat-raised-button color="primary" class="banner__heading__buttons__save" (click)="save()" [disabled]="(saveButtonToggle$ | async) || (initButtonToggle$ | async)">
        <mat-spinner color="primary" diameter="30" *ngIf="saveButtonToggle$ | async; else text"></mat-spinner>
        <ng-template #text>
          Save
        </ng-template>
      </button>
    </div>
  </div>
</div>
