import { AbstractControl, ControlContainer } from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ModalService } from 'apps/ioh-app/src/app/services/modal.service';
import { NumberUtilsService } from 'apps/ioh-app/src/app/utils/validators/number-validators';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  engagementTeamRoleDropdown,
  inputEngagementTeam,
} from '@assets/data/general-details';
@Component({
  selector: 'ioh-team-member',
  styleUrls: ['./team-member.component.scss'],
  templateUrl: './team-member.component.html',
})
export class TeamMemberComponent implements OnInit {
  @Input() index: number;
  @Input() rolesValues$: Observable<any>;
  @Output() deleteTeamMember = new EventEmitter();
  @Output() fetchResults = new EventEmitter();
  inputEngagementTeam = inputEngagementTeam;
  engagementTeamRoleSettings = engagementTeamRoleDropdown;

  parentForm: AbstractControl;
  widthWarningModal = 'fit-content';
  nameLimit = 1;
  showLabel = false;
  first: boolean;
  prepDayCount: string;
  execDayCount: string;
  closeOutDayCount: string;

  @ViewChild('warningTemplate') modalTemplate: TemplateRef<any>;

  constructor(
    private readonly controlContainer: ControlContainer,
    private readonly modalService: ModalService,
    public mediaObserver: MediaObserver,
    private readonly numberValidatorService: NumberUtilsService
  ) {}

  private readonly subscriptions: Subscription = new Subscription();

  ngOnInit(): void {
    this.parentForm = this.controlContainer.control;
    this.first = this.index == 0;
    this.prepDayCount = this.parentForm.get('prepDay').value;
    this.execDayCount = this.parentForm.get('execDay').value;
    this.closeOutDayCount = this.parentForm.get('closeOutDay').value;
    // handles being prepopulated
    this.sumTotalDays();
    this.subscriptions.add(
      this.mediaObserver.media$.subscribe((change: MediaChange) => {
        this.showLabel = !!(
          change.mqAlias === 'md' ||
          change.mqAlias === 'sm' ||
          change.mqAlias === 'xs'
        );
      })
    );
    this.fetchSearchResults();
    this.subscribeToDayCountChanges();
  }

  fetchSearchResults() {
    this.subscriptions.add(
      this.parentForm
        .get('nameInput')
        .get('input')
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((engagementTeamMember) => {
          const search = `${engagementTeamMember}-${this.index}`;
          /**
           * Tell whoever is listening to dispatch an action
           * to relevant facade to fetch search results for this dummy component
           */
          this.fetchResults.emit(search);
          /**
           * set Loading to true so spinner shows
           */
          this.parentForm
            .get('nameInput')
            .get('nameResultsIsLoading')
            .setValue(true);
        })
    );
  }

  roundToThreeDecimalPoints(input: string): void {
    const formControl = this.parentForm.get(input);
    const { value } = formControl;
    const roundedValue = this.numberValidatorService.roundToNDecimalPoints({
      decimalPlace: 3,
      value,
    });
    formControl.setValue(roundedValue);
  }

  subscribeToDayCountChanges(): void {
    this.subscriptions.add(
      this.parentForm.get('prepDay').valueChanges.subscribe((prepDay) => {
        this.prepDayCount = prepDay;
        this.sumTotalDays();
      })
    );
    this.subscriptions.add(
      this.parentForm.get('execDay').valueChanges.subscribe((execDay) => {
        this.execDayCount = execDay;
        this.sumTotalDays();
      })
    );
    this.subscriptions.add(
      this.parentForm
        .get('closeOutDay')
        .valueChanges.subscribe((closeOutDay) => {
          this.closeOutDayCount = closeOutDay;
          this.sumTotalDays();
        })
    );
  }

  sumTotalDays(): void {
    const total =
      +this.numberValidatorService.normalizeToNumbersOnly(this.prepDayCount) +
      +this.numberValidatorService.normalizeToNumbersOnly(this.execDayCount) +
      +this.numberValidatorService.normalizeToNumbersOnly(
        this.closeOutDayCount
      );
    this.parentForm.get('totalDay').setValue(total);
  }

  openModal(): void {
    const dataModal = { template: this.modalTemplate };
    this.modalService.openDialog(dataModal, this.widthWarningModal);
  }

  closeModal(): void {
    this.modalService.closeDialog();
  }

  removeTeamMember(): void {
    this.deleteTeamMember.emit(this.index);
  }
}
