<div fxLayout="column" [formGroup]="recoveryGroup" class="recovery__container">
  <div
    fxLayout="row"
    fxLayout.md="column"
    fxLayout.sm="column"
    fxLayout.xs="column"
    fxLayoutAlign="space-between baseline"
    fxLayoutAlign.md="space-between stretch"
    fxLayoutAlign.sm="space-between stretch"
    fxLayoutAlign.xs="space-between stretch"
    fxLayoutGap="1rem"
    class="recovery__title__first"
  >
    <div fxLayout="column"
      fxFlex="49%"
      fxFlex.sm="100%"
      fxFlex.xs="100%"
    >
      <ng-container *ngFor="let detail of summarySectionDetails;">
        <div
          fxLayout="row"
          class="recovery__title__first"
          *ngIf="detail.name === 'recovery' || detail.name === 'passThrough' || detail.name === 'discounts' || detail.name === 'totalCosts'">
          <div fxFlex="32%" class="recovery__label">{{detail.label}}
              <ioh-tooltip [detail]="detail"></ioh-tooltip>
          </div>
          <span *ngIf="detail.name === 'totalCosts'" fxFlex="68%"class="recovery__title__cost">{{totalCosts.value | currency:mainCurrency:'symbol-narrow'}}</span>
          <span *ngIf="detail.name === 'recovery'" fxFlex="68%" class="recovery__title__cost">{{recoveryTotal.value | currency:mainCurrency:'symbol-narrow'}}</span>
          <span *ngIf="detail.name === 'passThrough'" fxFlex="68%" class="recovery__title__cost">{{passThroughEstimate.value | currency:mainCurrency:'symbol-narrow'}}</span>
          <span *ngIf="detail.name === 'discounts'" fxFlex="68%"class="recovery__title__cost">{{totalDiscounts.value | currency:mainCurrency:'symbol-narrow'}}</span>
        </div>
      </ng-container>
    </div>
    <ioh-text-area
      fxFlex="49%"
      fxFlex.sm="100%"
      fxFlex.xs="100%"
      label="Recovery Comments"
      name="Recovery Comments"
      textareaId="recoveryComments"
      placeholder=""
      [formGroup]="recoveryGroup"
      value="recoveryComments"
      [maxCharLimit]="1000"
      description="text area where you add comments about recovery section"
      height="10rem"
      [required]=false
    ></ioh-text-area>
  </div>
  <h3 class="recovery__title">Recovery entries</h3>
  <div [ngClass] = "{'recoveryWrap':!smallScreen}">
  <div
    fxLayoutAlign="start center"
    fxLayoutGap="1rem"
    class="recovery__mt"
  >
  </div>
  <ng-container formArrayName="recoveryEntries">
    <div
    fxLayout="row"
    fxLayout.md="column"
    fxLayout.sm="column"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
    fxLayoutAlign.md="space-between stretch"
    fxLayoutAlign.sm="space-between stretch"
    fxLayoutAlign.xs="space-between stretch"
    fxLayoutGap="1rem"
    class='recoveryEntriesTitle'
    [ngClass] = "{'titleWidth':!smallScreen}"
    *ngIf= '!smallScreen && recoveryEntries.controls.length > 0'
  >
    <div
    fxLayout="row"
    fxFlex="7%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    fxLayout.md="row"
    fxLayout.sm="row"
    fxLayout.xs="row"
    >
    <div class="padding-top-4">Charge Date</div>
    <ioh-tooltip [detail]="chargeDateTooltipObj"></ioh-tooltip> 
    </div>
    <div
    fxLayout="row"
    fxFlex="7.8%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    fxLayout.md="row"
    fxLayout.sm="row"
    fxLayout.xs="row"
    >
    <div class="padding-top-4">Charge Status</div>
    <ioh-tooltip [detail]="chargeStatusTooltipObj"></ioh-tooltip>
    </div>
    <div
    fxLayout="column"
    fxFlex="7.4%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    fxLayout.md="row"
    fxLayout.sm="row"
    fxLayout.xs="row"
    >
    WBS Code
    </div>
    <div
    fxLayout="column"
    fxFlex="9%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    fxLayout.md="row"
    fxLayout.sm="row"
    fxLayout.xs="row"
    >
    WBS Type
    </div>
    <div
    fxLayout="column"
    fxFlex="8.6%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    fxLayout.md="row"
    fxLayout.sm="row"
    fxLayout.xs="row"
    >
    WBS Description
    </div>
    <div
    fxLayout="column"
    fxFlex="7%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    fxLayout.md="row"
    fxLayout.sm="row"
    fxLayout.xs="row"
    class="currency"
    >
    Currency
    </div>
    <div
    fxLayout="column"
    fxFlex="6%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    fxLayout.md="row"
    fxLayout.sm="row"
    fxLayout.xs="row"
    >
    Cost
    </div>
    <div
    fxLayout="column"
    fxFlex="6%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    fxLayout.md="row"
    fxLayout.sm="row"
    fxLayout.xs="row"
    >
    Discount
    </div>
    <div
    fxLayout="column"
    fxFlex="7%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    fxLayout.md="row"
    fxLayout.sm="row"
    fxLayout.xs="row"
    >
    Recovery Amount
    </div>
    <div
    fxLayout="column"
    fxFlex="7%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    fxLayout.md="row"
    fxLayout.sm="row"
    fxLayout.xs="row"
    >
    2% Recovery
    </div>
    <div
    fxLayout="column"
    fxFlex="14%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    fxLayout.md="row"
    fxLayout.sm="row"
    fxLayout.xs="row"
    >
    Description
    </div>
    </div>
      <ioh-recovery-entry
        *ngFor="let entry of recoveryEntries.controls; let recoveryEntryIndex = index"
        [index]="recoveryEntryIndex"
        [formGroupName]="recoveryEntryIndex"
        [recoveryItemsValues$]="recoveryItems$"
        (deleteRecoveryEntry)="removeRecoveryEntry($event)"></ioh-recovery-entry>
  </ng-container>
</div>
  <div fxLayout="row" class="recovery__mt">
    <mat-divider [inset]="true"></mat-divider>
  </div>
  <div
    fxLayout="row"
    fxLayout.md="column"
    fxLayout.sm="column"
    fxLayout.xs="column"
    fxLayoutAlign="space-between center"
    fxLayoutAlign.md="space-between start"
    fxLayoutAlign.sm="space-between start"
    fxLayoutAlign.xs="space-between start"
    fxLayoutGap="1rem"
    class="recovery__button__container"
  >
    <div class="recovery__add__item__button">
      <button
        mat-flat-button
        color="primary"
        class="square"
        (click)="addRecoveryEntry()"
      >
        <span class="material-icons">
          add
        </span>
      </button>
      <span class="title">Add Line</span>
    </div>
  </div>
</div>
