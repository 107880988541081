import { AbstractControl, ControlContainer, FormArray } from '@angular/forms';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WbsEntryService } from './wbs-entry.service';

@Component({
  selector: 'ioh-wbs-container',
  styleUrls: ['./wbs-container.component.scss'],
  templateUrl: './wbs-container.component.html',
})
export class WbsContainerComponent {
  @Input() wbsDetails: boolean;
  @Output() fetchWbsResults = new EventEmitter();
  parentForm: AbstractControl;

  constructor(
    private readonly controlContainer: ControlContainer,
    public wbsEntryService: WbsEntryService
  ) {}

  ngOnInit(): void {
    this.parentForm = this.controlContainer.control;
  }

  get wbsFormArray(): FormArray {
    return this.parentForm.get('wbsFormArray') as FormArray;
  }

  getWbsResults(event) {
    this.fetchWbsResults.emit(event);
  }
}
