import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateFormatService {
  // Get the date to be prepopulated without timezone browser conversion
  public getDateToPrepopulate(originalDate: string): string {
    if (originalDate) {
      const newDate = new Date(originalDate);
      newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
      const isoDate = newDate.toISOString();
      return isoDate;
    }
  }

  public getDateToSave(originalDate: string): string {
    if (originalDate) {
      const date = new Date(originalDate);
      const dateToSave = this.formatDateToSave(date);
      return dateToSave;
    }
  }

  private formatDateToSave(date: Date): string {
    const monthString = (date.getMonth() + 1).toString();
    const dayString = date.getDate().toString();

    const day = dayString.length < 2 ? `0${dayString}` : dayString;
    const month = monthString.length < 2 ? `0${monthString}` : monthString;
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }
}
