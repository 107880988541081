import {
  AbstractControl,
  ControlContainer,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DatesAndLocationsEntryService } from './dates-locations-entry.service';
import { MatTable } from '@angular/material/table';
import { ModalService } from 'apps/ioh-app/src/app/services/modal.service';
import { NumberUtilsService } from '@utils/validators/number-validators';
import { SelectionModel } from '@angular/cdk/collections';
import { browser } from '@utils/browserUtils';
import { distinctUntilChanged } from 'rxjs/operators';
import _isEqual from 'lodash/isEqual';

@Component({
  selector: 'ioh-dates-locations-table',
  templateUrl: './dates-locations-table.component.html',
  styleUrls: ['./dates-locations-table.component.scss'],
})
export class DatesLocationsTableComponent implements OnInit, OnDestroy {
  @Input() cols: any;
  @Input() roomsValues$: Observable<any[]>;

  parentForm: AbstractControl;
  displayedColumns: string[];
  tableData: any;
  browser = browser;

  dataSource: BehaviorSubject<AbstractControl[]> = new BehaviorSubject<
    AbstractControl[]
  >([]);

  dataSourceArray: any[];
  selectRowPositionArray = [];
  selection: SelectionModel<any>;
  widthWarningModal = 'fit-content';
  totalHours: string;

  private readonly subscriptions: Subscription = new Subscription();
  @ViewChild('table') matTable: MatTable<any>;
  @ViewChild('warningTemplate') modalTemplate: TemplateRef<any>;

  constructor(
    private readonly controlContainer: ControlContainer,
    private readonly modalService: ModalService,
    private readonly datesLocationsService: DatesAndLocationsEntryService,
    private readonly numberUtilsService: NumberUtilsService
  ) {}

  ngOnInit() {
    this.parentForm = this.controlContainer.control;
    this.displayedColumns = this.cols.map((column: any) => column.name);
    this.selection = new SelectionModel<any>(true, []);
    // handle the array being updated by a parent component
    this.updateTableDataSource();
    this.updateDataSourceArray();
    this.updateDataSource();
    this.calculateTotalHours();
    this.calculateTotalHoursOnChange();
  }

  calculateTotalHoursOnChange() {
    this.dates.valueChanges
      .pipe(distinctUntilChanged((prev, current) => _isEqual(prev, current)))
      .subscribe((datesChanges) => {
        this.calculateTotalHours();
      });
  }

  calculateTotalHours() {
    const rawDates = this.dates.getRawValue();
    this.totalHours = rawDates.reduce(
      (accumulator, entry) =>
        +accumulator +
        +this.numberUtilsService.normalizeToNumbersOnly(entry.hours),
      0
    );
    this.totalHoursInput.setValue(this.totalHours);
  }

  updateTableDataSource() {
    this.dataSource.next(this.dates.controls);
  }

  updateDataSourceArray(): void {
    this.subscriptions.add(
      this.dataSource.subscribe((data) => {
        this.dataSourceArray = data;
      })
    );
  }

  updateDataSource(): void {
    this.subscriptions.add(
      this.dates.valueChanges.subscribe((res) => {
        this.addIndexValueToDates();
        this.updateTableDataSource();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  get dates() {
    return this.parentForm.get('dates') as FormArray;
  }

  get totalHoursInput() {
    return this.parentForm.get('totalHours') as FormControl;
  }

  areAllRowsSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceArray.length;
    return numSelected === numRows;
  }

  masterToggleCheckboxes() {
    this.areAllRowsSelected()
      ? this.selection.clear()
      : this.dataSourceArray.forEach((row) => {
          this.selection.select(row);
        });
  }

  addIndexValueToDates() {
    this.dates.controls.map((elem: FormGroup, index: number) => {
      elem.controls.index.setValue(index, {
        onlySelf: true,
        emitEvent: false,
      });
    });
  }

  openModal(): void {
    const dataModal = { template: this.modalTemplate };
    this.modalService.openDialog(dataModal, this.widthWarningModal);
  }

  closeModal() {
    this.modalService.closeDialog();
  }

  deleteSelected() {
    this.selection.selected.map((elem: FormGroup) => {
      this.dates.removeAt(+elem.controls.index.value);
      this.updateTableDataSource();
    });
    this.selection.clear();
    this.closeModal();
  }

  addNewDate() {
    this.dates.push(this.datesLocationsService.createNewDateEntry());
    this.dataSource.next(this.dates.controls);
  }

  addNewLocation(locationFormArray: FormArray) {
    locationFormArray.push(this.datesLocationsService.createNewLocationEntry());
    this.updateTableDataSource();
  }

  removeLocation(locationFormArray: FormArray, index: number) {
    locationFormArray.removeAt(index);
    this.updateTableDataSource();
  }
}
