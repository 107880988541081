export const organizationType = [
  {
    id: '1',
    value: 'Internal',
  },
  {
    id: '2',
    value: 'External (non client)',
  },
  {
    id: '3',
    value: 'Client',
  },
];

export const radioButtonsFields = [
  {
    field: 'leadershipLevel',
    hint: 'What is the highest level attendee you are expecting?',
    label: 'Leadership Level',
    radioId: 'leadership',
    required: true,
    values: [
      'VIP (C-level,SVP)',
      'Mid Level (Executive)',
      'Management (Mgr, Sr.Mgr)',
      'Other',
    ],
  },
];

export const inputConsultationfields = [
  {
    flex: '18%',
    id: 'accentureAttendees',
    label: 'Accenture',
    maxLength: '4',
    name: 'Accenture attendees of detail attendees',
    placeholder: '-',
    required: false,
    value: 'accentureAttendees',
  },
  {
    flex: '18%',
    id: 'clientCSuiteAttendees',
    label: 'Client (C-Suite)',
    maxLength: '4',
    name: 'Client Attendees (C-Suite) of detail attendees',
    placeholder: '-',
    required: false,
    value: 'clientCSuiteAttendees',
  },
  {
    flex: '18%',
    id: 'clientOtherAttendees',
    label: 'Client (Other)',
    maxLength: '4',
    name: 'Client Attendees (Other) of detail attendees',
    placeholder: '-',
    required: false,
    value: 'clientOtherAttendees',
  },
  {
    flex: '18%',
    id: 'otherAttendees',
    label: 'Other',
    maxLength: '4',
    name: 'Other Attendees of detail attendees',
    placeholder: '-',
    required: false,
    value: 'otherAttendees',
  },
  {
    flex: '18%',
    id: 'totalAttendees',
    label: 'Total',
    name: 'Total Attendees of detail attendees',
    placeholder: '-',
    value: 'totalAttendees',
  },
];
