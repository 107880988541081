<div [formGroup]="parentFormGroup"  
fxLayoutGap="1rem"
>
  <ioh-dropdown
    fxFlex="50%"
    label="Client Type"
    dropdownId="og"
    [formGroup]="parentFormGroup"
    isMultiple=false
    field='type'
    [values]='clientTypeOptions'
    placeholder='Please select a client'
    direction="column"
    name='og'
  ></ioh-dropdown>
  <ioh-lookup
  *ngIf="isExisting"
  fxFlex="50%"
  fxFlex.md="100%"
  fxFlex.sm="100%"
  fxFlex.xs="100%"
  class="consultation__title__mt"
  lookUpId="consultationOwner"
  label="Client"
  placeholder="Search by client name or SAP account ID"
  [formGroup]="parentFormGroup"
  lookupGroupFieldName="client"
  lookupArrayFieldName="clientResult"
  lookupInputFieldName="clientInput"
  [searchResults$]="lookUpSearchResults$"
  [isLoading$]="allClientSearchResultsLoading$"
  [limit]="1"
  [required]="false"
>
</ioh-lookup>
<ioh-input
  *ngIf="!isExisting"    
  label="Other Client"
  inputId="org"
  name="Other Client"
  [formGroup]="parentFormGroup"
  value="organizationName"
  placeholder='Please enter the Client Details'
  width="100%"
  fxFlex="50%"
  required=true
  class="org"
  maxLength="100">
</ioh-input>
</div>

<div *ngIf="isExisting" class="detail__wrapper">
  <div  *ngFor="let detail of details" class="detail">
    <div class="detail__title">{{detail.title}}</div>
    <div class="detail__body">{{parentFormGroup.get(detail.formControl).value}}</div>
  </div>
</div>
