import { AdminFacade } from '@ioh/core-data';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Component } from '@angular/core';
import { IEngagement } from '@utils/interfaces';
import { TableService, WorkshopService } from '@services';
import { add, isPast, startOfToday } from 'date-fns';
import { checkStatusIs, transformDates } from '@utils/tablesUtils';
import { requestedColNames } from '@assets/data/tables/table-columns';
import isEmpty from 'lodash/isEmpty';
import isWithinRange from 'date-fns/is_within_range';

@Component({
  selector: 'ioh-requested',
  styleUrls: ['./requested.component.scss'],
  templateUrl: './requested.component.html',
})
export class RequestedComponent {
  data = [];
  colNames = requestedColNames;
  selectHub = '';
  today: Date = startOfToday();
  weekFromToday: Date = add(this.today, { weeks: 1 });
  pendingConsultData$: BehaviorSubject<any> = new BehaviorSubject(null);
  expiredSoftlockData$: BehaviorSubject<any> = new BehaviorSubject(null);
  upcomingRequestsData$: BehaviorSubject<any> = new BehaviorSubject(null);
  allRequestedData$: BehaviorSubject<any> = new BehaviorSubject(null);
  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly tableService: TableService,
    private readonly workshopService: WorkshopService,
    private readonly adminFacade: AdminFacade
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.workshopService.getWorkshops$.subscribe((workshops) => {
        if (!isEmpty(workshops)) {
          this.handleFormatting(workshops);
        }
      })
    );
    this.subscriptions.add(
      this.adminFacade.selectedHub$.subscribe((selectedHub) => {
        this.selectHub = selectedHub;
        this.updateAllData();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleFormatting(workshops: any[]): void {
    if (typeof Worker != 'undefined') {
      const worker: Worker = new Worker(
        '../../../services/data-formatter.worker',
        {
          type: 'module',
        }
      );
      worker.onmessage = ({ data }) => {
        this.data = this.tableService.sortTableDataByDate(data);
        this.updateAllData();
      };
      worker.postMessage(workshops);
    } else {
      const unorderedData = this.tableService.flattenAndFormatWorkshops(
        workshops
      );
      this.data = this.tableService.sortTableDataByDate(unorderedData);
      this.updateAllData();
    }
  }

  updateAllData() {
    if (this.data.length == 0) {
      return true;
    }
    this.pendingConsultData$.next(
      this.data
        .filter(this.pendingConsultDataFilter.bind(this))
        .map(transformDates.bind(this))
    );

    this.expiredSoftlockData$.next(
      this.data
        .filter(this.expiredSoftlockFilter.bind(this))
        .map(transformDates.bind(this))
    );

    this.upcomingRequestsData$.next(
      this.data
        .filter(this.allUpcomingRequestsFilter.bind(this))
        .map(transformDates.bind(this))
    );

    this.allRequestedData$.next(
      this.data
        .filter(this.allRequestedDataFilter.bind(this))
        .map(transformDates.bind(this))
    );
  }

  pendingConsultDataFilter(value: IEngagement): boolean {
    return (
      checkStatusIs(value, 'Requested') &&
      !value.complete &&
      (!this.selectHub || this.selectHub.includes(value.programCode))
    );
  }

  expiredSoftlockFilter(value: IEngagement): boolean {
    const expire = new Date(value.expiration);
    const pastExpire = isPast(expire);
    return (
      checkStatusIs(value, 'Requested') &&
      pastExpire &&
      (!this.selectHub || this.selectHub.includes(value.programCode))
    );
  }

  allUpcomingRequestsFilter(value: IEngagement): boolean {
    const startDate = new Date(value.start);
    const startIsWeekFromToday = isWithinRange(
      startDate,
      this.today,
      this.weekFromToday
    );
    return (
      checkStatusIs(value, 'Requested') &&
      startIsWeekFromToday &&
      (!this.selectHub || this.selectHub.includes(value.programCode))
    );
  }

  allRequestedDataFilter(value: IEngagement): boolean {
    return (
      checkStatusIs(value, 'Requested') &&
      (!this.selectHub || this.selectHub.includes(value.programCode))
    );
  }
}
