import { DateFormatService } from '@services';
import { FormArray, FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { RecoveryEntryService, WbsEntryService } from '@ioh/ui-library';
import { RecoveryState } from '@ioh/core-data';
import isEmpty from 'lodash/isEmpty';

@Injectable({
  providedIn: 'root',
})
export class RecoveryTabService {
  recoveryGroup: FormGroup;
  constructor(
    private readonly wbsEntryService: WbsEntryService,
    private readonly recoveryEntryService: RecoveryEntryService,
    private readonly dateFormatService: DateFormatService
  ) {}

  public prepopulateTabWithDBResponse(workshop, formGroup: FormGroup) {
    this.recoveryGroup = formGroup;
    this.prepopulateRecoveryEntries(workshop.costs);
    this.prepopulateTotalDiscount(workshop.totalDiscounts);
    this.prepopulateRecoveryTotal(workshop.recoveryTotal);
    this.prepopulateTotalCosts(workshop.totalCosts);
    this.prepopulatePassthroughEstimate(workshop.passThroughEstimate);
    this.prepopulateRecoveryComments(workshop.comments);
    this.prepopulateChargeDate(workshop.chargeDate);
  }

  public prepopulateTabWithStateObject(
    stateObject: RecoveryState,
    formGroup: FormGroup
  ) {
    this.recoveryGroup = formGroup;

    this.prepopulateRecoveryEntries(stateObject.recoveryEntries);
    this.prepopulateTotalDiscount(stateObject.totalDiscounts);
    this.prepopulateRecoveryTotal(stateObject.recoveryTotal);
    this.prepopulateTotalCosts(stateObject.totalCosts);
    this.prepopulatePassthroughEstimate(stateObject.passThroughEstimate);
    this.prepopulateRecoveryComments(stateObject.recoveryComments);
    this.prepopulateChargeDate(stateObject.chargeDate);
  }

  get recoveryEntries(): FormArray {
    return this.recoveryGroup.get('recoveryEntries') as FormArray;
  }

  public prepopulateRecoveryEntries(recoveryEntries: any[]) {
    if (!isEmpty(recoveryEntries)) {
      // Show recovery entries by order
      recoveryEntries
        .slice()
        .sort(function (o1, o2) {
          return o1.order - o2.order;
        })
        .forEach((entry) => {
          // TODO for some reason this doesn't update the total estimated cost etc.
          this.recoveryEntries.push(
            this.recoveryEntryService.createRecoveryEntry(entry)
          );
        });
    }
  }

  private prepopulateTotalDiscount(total) {
    this.recoveryGroup.get('totalDiscounts').setValue(total);
  }

  private prepopulateRecoveryTotal(value) {
    this.recoveryGroup.get('recoveryTotal').setValue(value);
  }

  private prepopulateTotalCosts(value) {
    this.recoveryGroup.get('totalCosts').setValue(value);
  }

  private prepopulatePassthroughEstimate(value) {
    this.recoveryGroup.get('passThroughEstimate').setValue(value);
  }

  private prepopulateRecoveryComments(value) {
    this.recoveryGroup.get('recoveryComments').setValue(value);
  }

  private prepopulateChargeDate(value) {
    if (value) {
      const newDate = this.dateFormatService.getDateToPrepopulate(value);
      this.recoveryGroup.get('chargeDate').setValue(newDate);
    }
  }
}
