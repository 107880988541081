import { AbstractControl, ControlContainer } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NumberUtilsService } from '@utils/validators/number-validators';
import { Subscription } from 'rxjs';
import { inputConsultationfields } from '@assets/data/attendee-details';
@Component({
  selector: 'ioh-attendee-number',
  styleUrls: ['./attendee-number.component.scss'],
  templateUrl: './attendee-number.component.html',
})
export class AttendeeNumberComponent implements OnInit {
  parentForm: AbstractControl;
  @Input() requiredAttendeeNumber: boolean;
  @Output() totalAttendee = new EventEmitter();
  inputConsultationfields = inputConsultationfields;
  private readonly subscriptions: Subscription = new Subscription();
  attendeeMessage: boolean = false;

  constructor(
    private readonly controlContainer: ControlContainer,
    private readonly numberUtilsService: NumberUtilsService
  ) {}

  ngOnInit() {
    this.parentForm = this.controlContainer.control;
    this.registerSubscriptions();
  }

  registerSubscriptions() {
    this.handleValueChanges();
  }

  updateTotal(total: number): void {
    this.parentForm
      .get('totalAttendees')
      .setValue(total, { emitEvent: false, onlySelf: true });
    this.totalAttendee.emit(total);
  }

  handleValueChanges(): void {
    this.subscriptions.add(
      this.parentForm.valueChanges.subscribe((changes) => {
        this.updateTotal(this.calculateTotal(changes));
      })
    );
  }

  calculateTotal(formGroupValues): number {
    const {
      accentureAttendees,
      clientCSuiteAttendees,
      clientOtherAttendees,
      otherAttendees,
    } = formGroupValues;
    return (
      +this.numberUtilsService.normalizeToNumbersOnly(accentureAttendees) +
      +this.numberUtilsService.normalizeToNumbersOnly(clientCSuiteAttendees) +
      +this.numberUtilsService.normalizeToNumbersOnly(clientOtherAttendees) +
      +this.numberUtilsService.normalizeToNumbersOnly(otherAttendees)
    );
  }
}
