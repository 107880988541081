import {
  CONSULTATION_FEATURE_KEY,
  ConsultationPartialState,
  ConsultationState,
} from './consultation.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

// Lookup the 'Consultation' feature state managed by NgRx
export const getConsultationState = createFeatureSelector<
  ConsultationPartialState,
  ConsultationState
>(CONSULTATION_FEATURE_KEY);

export const getAllConsultationOwnerSearchResults = createSelector(
  getConsultationState,
  (state: ConsultationState) => state.consultationOwnerSearchResults
);

export const getAllOpportunityIdSearchResults = createSelector(
  getConsultationState,
  (state: ConsultationState) => state.opportunityIdsSearchResults
);

export const getAllInternalPurposes = createSelector(
  getConsultationState,
  (state: ConsultationState) => state.internalPurposeResults
);

export const getPrepopulated = createSelector(
  getConsultationState,
  (state: ConsultationState) => state.prepopulated
);
