import { AngularMaterialModule } from './angular-material.module';
import { AppLoadingComponent } from './app-loading/app-loading.component';
import { AttendeeNumberComponent } from './attendee-number/attendee-number.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CardComponent } from './card/card.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ClientComponent } from './organization/client/client.component';
import { CommonModule } from '@angular/common';
import { ContactsComponent } from './contacts/contacts.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DatesLocationsTableComponent } from './dates-locations-table/dates-locations-table.component';
import { DetailsAttendeeComponent } from './details-attendee/details-attendee.component';
import { DetailsEngagementComponent } from './details-engagement/details-engagement.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { DurationComponent } from './duration/duration.component';
import { ErrorComponent } from './error/error.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ExternalComponent } from './organization/external/external.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from './input-component/input-component.component';
import { InternalComponent } from './organization/internal/internal.component';
import { ListViewComponent } from './list-view/list-view.component';
import { LookUpComponent } from './lookup/lookup.component';
import { LookUpEntryService } from './lookup/look-up-entry.service';
import { OrganizationComponent } from './organization/organization.component';
import { OrganizationEntryService } from './organization/organization-entry.service';
import { PipeModule } from './../../../../apps/ioh-app/src/app/pipes/pipe-module';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { RecoveryEntryComponent } from './recovery-entry/recovery-entry.component';
import { RequestDatesComponent } from './request-dates/request-dates.component';
import { ResponseComponent } from './response/response.component';
import { RouterModule } from '@angular/router';
import { SelectHubComponent } from './select-hub/select-hub.component';
import { ShellComponent } from './shell/shell.component';
import { TeamMemberComponent } from './team-member/team-member.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { TimeOutModalComponent } from './timeOut-modal/timeOut-modal.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { WarningModalComponent } from './warning-modal/warning-modal.component';
import { WbsComponent } from './wbs/wbs.component';
import { WbsContainerComponent } from './wbs-container/wbs-container.component';
import { WbsInputComponent } from './wbs-input/wbs-input.component';
import { WbsLookupComponent } from './wbs-lookup/wbs-lookup.component';

@NgModule({
  declarations: [
    ResponseComponent,
    CheckboxComponent,
    DatePickerComponent,
    DropdownComponent,
    ErrorComponent,
    InputComponent,
    LookUpComponent,
    OrganizationComponent,
    RadioButtonComponent,
    RequestDatesComponent,
    TextAreaComponent,
    CardComponent,
    AppLoadingComponent,
    ListViewComponent,
    SelectHubComponent,
    ErrorPageComponent,
    InternalComponent,
    ExternalComponent,
    ClientComponent,
    DetailsEngagementComponent,
    AttendeeNumberComponent,
    DetailsAttendeeComponent,
    DurationComponent,
    ContactsComponent,
    WarningModalComponent,
    TimeOutModalComponent,
    ShellComponent,
    WbsLookupComponent,
    WbsComponent,
    TooltipComponent,
    WbsContainerComponent,
    WbsInputComponent,
    TeamMemberComponent,
    RecoveryEntryComponent,
    WbsInputComponent,
    DatesLocationsTableComponent,
  ],
  exports: [
    RouterModule,
    AngularMaterialModule,
    ResponseComponent,
    CheckboxComponent,
    DatePickerComponent,
    DropdownComponent,
    ErrorComponent,
    InputComponent,
    LookUpComponent,
    OrganizationComponent,
    RadioButtonComponent,
    RequestDatesComponent,
    TextAreaComponent,
    CardComponent,
    AppLoadingComponent,
    ListViewComponent,
    SelectHubComponent,
    ErrorPageComponent,
    InternalComponent,
    ExternalComponent,
    ClientComponent,
    DetailsEngagementComponent,
    AttendeeNumberComponent,
    DetailsAttendeeComponent,
    DurationComponent,
    ContactsComponent,
    WarningModalComponent,
    TimeOutModalComponent,
    ShellComponent,
    WbsLookupComponent,
    WbsComponent,
    TooltipComponent,
    WbsContainerComponent,
    WbsInputComponent,
    TeamMemberComponent,
    RecoveryEntryComponent,
    WbsInputComponent,
    PipeModule,
    DatesLocationsTableComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    FlexLayoutModule,
    PipeModule,
  ],
  providers: [OrganizationEntryService, LookUpEntryService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UiLibraryModule {}
