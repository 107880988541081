import { AbstractControl, ControlContainer, FormArray } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ioh-request-dates',
  styleUrls: ['./request-dates.component.scss'],
  templateUrl: './request-dates.component.html',
})
export class RequestDatesComponent implements OnInit {
  @Input() field: string;
  @Input() dateType: string;
  @Input() formArray: FormArray;
  @Input() minDate: Date;
  @Input() waitlist: boolean;
  @Output() dateDeleted = new EventEmitter<number>();
  dayPeriod: any;
  parentForm: AbstractControl;
  warning: boolean = false;

  constructor(public controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.parentForm = this.controlContainer.control;

    this.parentForm.valueChanges.subscribe(() => {
      const controlToCheck = this.parentForm.get('date')
        ? this.parentForm.get('date')
        : this.parentForm.get('startDate');
      this.warning = this.waitlist && this.getPastDateCondition(controlToCheck);
    });

    this.dayPeriod = {
      direction: 'column',
      dropdownId: this.field,
      isMultiple: false,
      name: `Organization_Type_${this.field}`,
      parentForm: this.parentForm,
      placeholder: 'Select a part of the day',
      required: true,
      values: [{ value: 'Morning' }, { value: 'Afternoon' }],
    };
  }

  deleteDate(index) {
    this.dateDeleted.emit(index);
  }

  private getPastDateCondition(control: AbstractControl): boolean {
    const controlValue = control.value;
    const now = new Date();
    const today: Date = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate()
    );

    const condition =
      controlValue instanceof Date && controlValue.getTime() < today.getTime();

    return condition;
  }
}
