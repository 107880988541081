import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormControlUtils } from '@utils/formControlUtils';
import { Injectable } from '@angular/core';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
@Injectable({
  providedIn: 'root',
})
export class AttendeeNumberService {
  constructor(
    private readonly fb: FormBuilder,
    private readonly formControlUtils: FormControlUtils
  ) {}

  nonnegativeIntegerRegex = /^[0-9]+$/;
  positiveIntegerRegex = /^[1-9]+$/;

  createAttendeeNumber(entry?): FormGroup {
    return this.fb.group({
      accentureAttendees: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'accentureAttendees',
          defaultValue: null,
        }),
        [
          Validators.min(0),
          RxwebValidators.pattern({
            expression: {
              nonnegativeInteger: this.nonnegativeIntegerRegex,
            },
          }),
        ]
      ),
      clientCSuiteAttendees: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'clientCSuiteAttendees',
          defaultValue: null,
        }),
        [
          Validators.min(0),
          RxwebValidators.pattern({
            expression: {
              nonnegativeInteger: this.nonnegativeIntegerRegex,
            },
          }),
        ]
      ),
      clientOtherAttendees: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'clientOtherAttendees',
          defaultValue: null,
        }),
        [
          Validators.min(0),
          RxwebValidators.pattern({
            expression: {
              nonnegativeInteger: this.nonnegativeIntegerRegex,
            },
          }),
        ]
      ),
      otherAttendees: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'otherAttendees',
          defaultValue: null,
        }),
        [
          Validators.min(0),
          RxwebValidators.pattern({
            expression: {
              nonnegativeInteger: this.nonnegativeIntegerRegex,
            },
          }),
        ]
      ),
      totalAttendees: this.fb.control(
        {
          value: this.formControlUtils.mapProperty({
            entry,
            property: 'totalAttendees',
            defaultValue: 0,
          }),
          disabled: true,
        },
        [
          Validators.min(1),
          RxwebValidators.pattern({
            expression: {
              positiveInteger: this.positiveIntegerRegex,
            },
          }),
        ]
      ),
    });
  }
}
