import { AdminFacade } from '@ioh/core-data';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { SnackbarService } from '../../../services/snackbar.service';
import { filter } from 'rxjs/operators';
import { isNonNull } from '@utils/filters';

@Injectable({
  providedIn: 'root',
})
export class AdminService implements OnDestroy {
  private readonly subscriptions: Subscription = new Subscription();
  removeSuccess$ = this.adminFacade.removeAccessSuccessfully$;
  _saveButtonToggle$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly adminFacade: AdminFacade,
    public snackbarService: SnackbarService
  ) {
    this.handleSubscriptions();
  }

  handleSubscriptions() {
    this.getUserAccessRemoveSuccess();
    this.getUserAccessRemoveError();
  }

  getUserAccessRemoveSuccess(): void {
    this.subscriptions.add(
      this.adminFacade.removeAccessSuccessfully$
        .pipe(filter(isNonNull))
        .subscribe((success) => {
          this.disableSaveButton(false);
          this.openRemoveConfirmationOfSaveModal();
          this.adminFacade.removeAccessSuccess(null);
          this.adminFacade.loadAccessUserListByUserId(
            sessionStorage.getItem('userId')
          );
        })
    );
  }

  getUserAccessRemoveError(): void {
    this.subscriptions.add(
      this.adminFacade.removeUserAccessError$
        .pipe(filter(isNonNull))
        .subscribe(() => {
          this.disableSaveButton(false);
          this.openRemoveFailureToast();
        })
    );
  }

  openRemoveConfirmationOfSaveModal() {
    this.snackbarService.openSnackBar({
      message: 'Remove user access successfully!',
    });
  }

  openRemoveFailureToast(): void {
    this.snackbarService.openSnackBar({
      message: 'Oops! Something went wrong while removing.',
    });
  }

  disableSaveButton(value: boolean): void {
    this._saveButtonToggle$.next(value);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public removeUserAccess(userAccess: any) {
    this.adminFacade.removeUserAccess(userAccess);
  }
}
