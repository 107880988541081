<div
  fxLayoutAlign="center center"
  class="modal__icon__container">
  <mat-icon class="material-icons modal__icon" aria-label="warning icon">report_problem</mat-icon
>
</div>
<div fxLayout="row"
  class="modal__msg__container">Are you sure you want to delete this <span class="modal__msg__bold">{{elementName}}</span>?
</div>
<div
  fxLayoutAlign="space-around center"
  class="modal__btn__container"
  >
  <button mat-button (click)="closeModal()">Cancel </button>
  <button mat-raised-button color="primary" (click)="confirmDeletion()" [disabled]="(saveButtonToggle$ | async)" class='deleteButton'>
  <mat-spinner color="primary" diameter="30" *ngIf="saveButtonToggle$ | async; else text"></mat-spinner>
  <ng-template #text>
    Yes, delete
  </ng-template>
</button>
</div>
