import { AbstractControl, ControlContainer } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ioh-checkbox',
  styleUrls: ['./checkbox.component.scss'],
  templateUrl: './checkbox.component.html',
})
export class CheckboxComponent implements OnInit {
  @Input() checkId: string;
  @Input() value: any;
  @Input() required: boolean;
  @Input() label: string;
  parentForm: AbstractControl;

  constructor(public readonly controlContainer: ControlContainer) {}

  ngOnInit() {
    this.parentForm = this.controlContainer.control;
  }
}
