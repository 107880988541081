<div
  fxLayoutAlign="center center"
  class="modal__icon__container">
  <mat-icon class="material-icons modal__icon" aria-label="warning icon">report_problem</mat-icon
>
</div>
<div fxLayout="row"
  class="modal__msg__container">Your session has timed out.  Please confirm if you wish to continue working, otherwise you will be logged out and any unsaved work will be lost.
</div>
<div
  fxLayoutAlign="space-around center"
  class="modal__btn__container"
  >
  <button mat-flat-button color="primary" (click)="renewToken()">Continue </button>
  <button mat-button (click)="logoutAndClose()">Logout </button>
</div>
