import { AbstractControl, ControlContainer } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Observable, Subscription } from 'rxjs';
import { browser } from '@utils/browserUtils';
@Component({
  selector: 'ioh-wbs-input',
  styleUrls: ['./wbs-input.component.scss'],
  templateUrl: './wbs-input.component.html',
})
export class WbsInputComponent implements OnInit {
  @Input() label: string;
  @Input() inputId: any;
  @Input() name: any;
  @Input() placeholder: string;
  @Input() wbs: string;
  @Input() value: any;
  @Input() wbsDetails: boolean;
  @Input() required: boolean;
  @Input() breakdown: boolean;
  @Input() success: boolean;
  @Input() expired: boolean;
  @Input() response: boolean;
  @Input() valid: boolean;
  @Input() type: string;
  @Input() breakdownSymbol: string;
  @Output() onBreakdownTypeChange = new EventEmitter();
  @Input() width: string;
  @Input() regex: any;
  @Input() maxLength: string;
  @Input() percent: boolean;
  @Input() loading$: Observable<boolean>;
  browser = browser;
  smallScreen: any;

  parentForm: AbstractControl;

  private readonly subscriptions: Subscription = new Subscription();
  constructor(
    private readonly controlContainer: ControlContainer,
    public mediaObserver: MediaObserver
  ) {
    this.subscriptions.add(
      mediaObserver.media$.subscribe((change: MediaChange) => {
        this.smallScreen = !!(
          change.mqAlias === 'md' ||
          change.mqAlias === 'sm' ||
          change.mqAlias === 'xs'
        );
      })
    );
  }

  ngOnInit() {
    this.parentForm = this.controlContainer.control;
  }

  setBreakDownType(event) {
    this.onBreakdownTypeChange.emit(event.value);
  }
}
