import { AbstractControl, ControlContainer, FormArray } from '@angular/forms';
import { Component } from '@angular/core';

import { ICustomer } from '@ioh/types';
import { LookUpValidatorsService } from '@utils/validators';
import { Observable, Subscription } from 'rxjs';
import { OrganizationService } from 'apps/ioh-app/src/app/services/organization.service';
import {
  clientTypeOptions,
  dummyClientDetails,
} from '@assets/data/organisation-details';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'ioh-client',
  styleUrls: ['./client.component.scss'],
  templateUrl: './client.component.html',
})
export class ClientComponent {
  allResults: ICustomer[] = [];
  lookUpSearchResults$: Observable<any>;
  parentFormGroup: AbstractControl;
  details = dummyClientDetails;
  isExisting: boolean;
  allClientSearchResultsLoading$ = this.organizationService
    .clientSearchResultsLoading;

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly controlContainer: ControlContainer,
    private readonly organizationService: OrganizationService,
    private readonly lookUpValidator: LookUpValidatorsService
  ) {}

  clientTypeOptions = clientTypeOptions;

  ngOnInit() {
    this.parentFormGroup = this.controlContainer.control;
    const type = this.parentFormGroup.get('type').value;
    this.isExisting = type.id == 3;
    this.registerSubscriptions();
    /**
     * format the results here,
     * keep a copy of the full results too so that we can populate and change the readonly data on select a new search result
     */
    this.lookUpSearchResults$ = this.organizationService.currentSearchResults.pipe(
      tap((res) => {
        this.allResults = res;
        this.organizationService.clientSearchResultsLoading.next(false);
      }),
      map((res) =>
        res.map(
          (item) =>
            `${item.CustomerNumber}-${item.CustomerName}-${item.SoldToCountryName}`
        )
      )
    );
  }

  registerSubscriptions() {
    this.typeChanges();
    this.selectedClientChanges();
    this.clientSearchQueryChanges();
  }

  typeChanges() {
    this.subscriptions.add(
      this.parentFormGroup.get('type').valueChanges.subscribe((type) => {
        this.checkExisting(type);
      })
    );
  }

  checkExisting(type: any) {
    if (!!type && !!type.value) {
      if (type.value == 'Existing Client') {
        this.isExisting = true;
        const client = this.parentFormGroup.get('client').get('clientResult');
        client.setValidators([
          this.lookUpValidator.validateRequired,
          this.lookUpValidator.validateLimit(1),
        ]);
      } else {
        this.isExisting = false;
        const result = this.parentFormGroup
          .get('client')
          .get('clientResult') as FormArray;
        result.clearValidators();
        result.clear();
        this.parentFormGroup.get('client').get('clientInput').setValue('');
      }
      this.setReadOnlyFormControlValues(undefined);
    }
  }

  selectedClientChanges() {
    this.subscriptions.add(
      this.parentFormGroup
        .get('client')
        .get('clientResult')
        .valueChanges.subscribe((val) => {
          /**
           * Get the selected array
           * forEach => get the ID from the chip lookupservice.getID
           * allClientSearchResults$.find() where CustomerNumber == ID
           */

          /**
           * If selected is empty array then we should set the read only values as '--'
           */
          if (val[0] && val[0].chip) {
            const selectedId = this.getID(val[0].chip);
            const fullClient = this.getFullClient(selectedId);
            this.setReadOnlyFormControlValues(fullClient);
          }
        })
    );
  }

  getFullClient(selectedId: string): ICustomer | undefined {
    return this.allResults.find(
      (result) => result.CustomerNumber == selectedId
    );
  }

  clientSearchQueryChanges() {
    this.subscriptions.add(
      this.parentFormGroup
        .get('client')
        .get('clientInput')
        .valueChanges.pipe(
          distinctUntilChanged(),
          debounceTime(1000),
          filter((res) => res)
        )
        .subscribe((val) => {
          this.organizationService.updateSearchQuery(val);
        })
    );
  }

  public getID(selectedLookupResult: string): string {
    return selectedLookupResult.split('-')[0];
  }

  setReadOnlyFormControlValues(selectedClient: ICustomer | undefined): void {
    this.details.forEach((detail) => {
      this.parentFormGroup
        .get(`${detail.formControl}`)
        .setValue(
          selectedClient
            ? selectedClient[detail.response]
            : this.isExisting
            ? '-'
            : ''
        );
    });
  }
}
