import { HttpService } from '../http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class OrchestratorService {
  constructor(private readonly http: HttpService) {}

  createRequest(request) {
    const url = environment.endpoints.orchestrator.createRequest;
    return this.http.post(url, request);
  }

  getAllWorkshopsByUserId(userId): Observable<any> {
    const url = `${environment.endpoints.orchestrator.getAllWorkshopsByUserId}`;
    return this.http.get(url);
  }

  updateWorkshopById(workshop): Observable<any> {
    const url = environment.endpoints.orchestrator.updateWorkshopById;
    return this.http.post(url, workshop);
  }

  updateAdminById(admin): Observable<any> {
    const url = environment.endpoints.user.access;
    return this.http.post(url, admin);
  }

  getProgramById(id: string): Observable<any> {
    const url = `${environment.endpoints.program.getById}/${id}`;
    return this.http.get(url);
  }
}
