<div
  fxLayout.xl="row"
  fxLayout.lg="row"
  fxLayout.md="column"
  fxLayout.sm="column"
  fxLayout.xs="column"
  fxLayoutAlign="start start"
  fxLayoutAlign.md="space-between stretch"
  fxLayoutAlign.sm="space-between stretch"
  fxLayoutAlign.xs="space-between stretch"
  class="flexMarin"
  [ngClass]='showLabel ? "flexMarinNormal" : "flexMarin"'
  fxLayoutGap="1rem">
  <ioh-dropdown
    fxFlex="14%"
    dropdownId="role-{{index}}"
    [label]="engagementTeamRoleSettings.label"
    [formGroup]="parentForm"
    [isMultiple] = "engagementTeamRoleSettings.isMultiple"
    [values] = "rolesValues$ | async"
    field = "role"
    [required] = "engagementTeamRoleSettings.required"
    placeholder = "{{engagementTeamRoleSettings.placeholder}}"
    name = "{{engagementTeamRoleSettings.name}}"
    direction="{{engagementTeamRoleSettings.direction}}">
  </ioh-dropdown>
  <ioh-lookup
    fxFlex="35%"
    lookUpId="lookUp-{{index}}"
    [label]="'Name'"
    placeholder="Enterprise ID"
    lookupGroupFieldName="nameInput"
    lookupArrayFieldName="nameResult"
    lookupInputFieldName="input"
    [formGroup]="parentForm"
    [limit]="nameLimit"
    [required]="false"
    [teamName]="true"
    [searchResults$]="parentForm.get('nameInput').get('searchResults').valueChanges"
    [isLoading$]="parentForm.get('nameInput').get('nameResultsIsLoading').valueChanges">
  </ioh-lookup>
  <ng-container *ngFor="let input of inputEngagementTeam; let inputIndex='index'">
    <ioh-input
      [fxFlex]="((inputIndex===1) || (inputIndex===2)) ? '14%' : '12%'"
      fxFlex.md="100%"
      fxFlex.sm="100%"
      fxFlex.xs="100%"
      inputId="{{input.id}}-{{index}}"
      [label]="input.label"
      name="{{input.name}}"
      placeholder="{{input.placeholder}}"
      [formGroup]="parentForm"
      value="{{input.value}}"
      [maxLength]="input.maxLength"
      [required]="input.required"
      (inputBlurred)="roundToThreeDecimalPoints($event)"
    ></ioh-input>
  </ng-container>
  <div
    fxFlex="2%"
    class="team__delete"
    [ngClass]="{'team__delete__first': index === 0}"
      [ngClass.md]="'team__delete--medium'"
      [ngClass.sm]="'team__delete--small'"
      [ngClass.xs]="'team__delete--xsmall'"
    >
    <button
      (click)="openModal()"
      mat-mini-fab
      class="team__delete"
      color="accent"
      aria-label="Remove wbs button"
      >
        <mat-icon class="material-icons" aria-label="Remove wbs button"
          >clear</mat-icon
        >
    </button>
  </div>
</div>

<ng-template #warningTemplate>
  <ioh-warning-modal
    elementName="team member"
    [elementIndex]="index"
    (delete)="removeTeamMember($event)"
    (close)="closeModal()"
  ></ioh-warning-modal>
</ng-template>
