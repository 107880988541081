import { BehaviorSubject } from 'rxjs';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ioh-warning-modal',
  styleUrls: ['./warning-modal.component.scss'],
  templateUrl: './warning-modal.component.html',
})
export class WarningModalComponent {
  @Input() elementName: string;
  @Input() elementIndex: number;
  @Input() saveButtonToggle$: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);

  @Output() delete = new EventEmitter();
  @Output() close = new EventEmitter();
  closeModal() {
    this.close.next();
  }

  confirmDeletion() {
    this.delete.emit(this.elementIndex);
  }
}
