<div>
  <ioh-select-hub></ioh-select-hub>
  <ioh-banner title="User List"></ioh-banner>
  <ioh-card>
    <ioh-list-view
      tableName="User List"
      [colNames]="colNames"
      [data$]="data$"
      [pageLength]="10"
      (onDelete)="deleteUser($event)"
      (onAddUser)="openModal()"
    ></ioh-list-view>
  </ioh-card>
</div>

<ng-template #deleteAccessTemplate>
  <ioh-warning-modal
    elementName="User"
    (delete)="deleteSelected()"
    [saveButtonToggle$]="saveButtonToggle$"
    (close)="closeModal()"
  ></ioh-warning-modal>
</ng-template>

<ng-template #UserTemplate>
  <h2 class="modal__title" role="banner" mat-dialog-title>User Details</h2>
  <mat-dialog-content role="contentinfo" class="mat-typography">
    <div [formGroup]="adminGroup">
      <div fxLayout="row">
        <ioh-dropdown
        fxFlex="100%"
        label="{{programSetting.label}}"
        dropdownId="{{programSetting.dropdownId}}"
        [formGroup]="adminGroup"
        [isMultiple] = "programSetting.isMultiple"
        [values] = "programList"
        field = "program"
        [required] = "programSetting.required"
        placeholder = "{{programSetting.placeholder}}"
        name = "{{programSetting.name}}"
        direction="{{programSetting.direction}}">
        </ioh-dropdown>
      </div>
      <div fxLayout="row">
        <ioh-lookup
          fxFlex="100%"
          fxFlex.md="100%"
          fxFlex.sm="100%"
          fxFlex.xs="100%"
          class="userTitle"
          lookUpId="userName"
          label="User Name"
          placeholder="User Enterprise ID"
          lookupGroupFieldName="userName"
          lookupArrayFieldName="userNameResult"
          lookupInputFieldName="userNameInput"
          [formGroup]="adminGroup"
          [limit]="1"
          [required]="true"
          [searchResults$]="allUserNameResults$"
          [isLoading$]="allUserNameResultsLoading$"
        >
        </ioh-lookup>
      </div>
      <div fxLayout="row">
        <ioh-error
          class="look-up__error"
          *ngIf="(getLookUpInput.touched || getLookUpInput.dirty) && isDuplicateUser"
          errorMsg="User already exist!">
        </ioh-error>
      </div>
      <div fxLayout="row">
        <ioh-dropdown
        fxFlex="100%"
        label="{{accessLevelSetting.label}}"
        dropdownId="{{accessLevelSetting.dropdownId}}"
        [formGroup]="adminGroup"
        [isMultiple] = "accessLevelSetting.isMultiple"
        [values] = "accessLevelList"
        field = "accessLevel"
        [required] = "accessLevelSetting.required"
        placeholder = "{{accessLevelSetting.placeholder}}"
        name = "{{accessLevelSetting.name}}"
        direction="{{accessLevelSetting.direction}}">
        </ioh-dropdown>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" role="navigation">
    <button mat-stroked-button color="primary" class="button-cancel" (click)="cancel()">Cancel</button>
    <button class="button-save" mat-flat-button color="primary" [disabled]='!adminGroup.valid || (addUserButtonToggle$ | async) || isDuplicateUser' (click)="addUser()">
      <mat-spinner color="primary" diameter="30" *ngIf="addUserButtonToggle$ | async; else text"></mat-spinner>
        <ng-template #text>
          Add User
        </ng-template>
    </button>
  </mat-dialog-actions>
</ng-template>
