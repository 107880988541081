<!-- Number of Attendees -->
<ioh-attendee-number  
  [formGroup]="parentForm.get('attendeeNumber')" 
  [requiredAttendeeNumber]="requiredAttendeeNumber"
  (totalAttendee)="updateTotal($event)">
</ioh-attendee-number>

<!-- Attendee Radios -->
<div *ngFor="let value of radioValues" class="attendee__radio__container">
  <ioh-radio-button
  radioId="{{ value.radioId }}"
    label="{{ value.label }}"
    field="{{ value.field }}"
    [values]="value.values"
    [formGroup]="parentForm"
    required="{{ value.required }}"
    ></ioh-radio-button>
    <small class="attendee__hint">{{ value.hint }}</small>
  </div>
