import { CanActivate } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private readonly msal: MsalService;
  constructor(private readonly inject: Injector) {
    this.msal = this.inject.get(MsalService);
  }

  canActivate(): boolean {
    if (!this.msal.getAccount()) {
      this.msal.loginRedirect({ scopes: environment.options.consentScopes });
      return false;
    }
    return true;
  }
}
