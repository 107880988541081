export const bookingSections = [
  {
    label: 'General',
    path: 'general',
  },
  {
    label: 'Consultation',
    path: 'consultation',
  },
  {
    label: 'Recovery',
    path: 'recovery',
  },
];

export const statusDropdownSettings = {
  direction: 'row',
  dropdownId: 'statusField',
  isMultiple: false,
  label: 'Engagement Status',
  name: 'status dropdown',
  placeholder: 'Select a state',
  required: true,
};

export const cancellationDropdownSettings = {
  direction: 'row',
  dropdownId: 'cancellationField',
  isMultiple: false,
  label: 'Cancellation reason',
  name: 'cancellation dropdown',
  placeholder: 'Select a cancellation reason',
  required: true,
};

export const programDropdownSettings = {
  direction: 'row',
  dropdownId: 'programField',
  isMultiple: false,
  label: '*Program',
  name: 'program dropdown',
  placeholder: 'Select a program',
  required: true,
};
