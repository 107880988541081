import { AdminFacade } from '../../../../core-data/src/lib/state/back-office/admin/admin.facade';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'ioh-select-hub',
  styleUrls: ['./select-hub.component.scss'],
  templateUrl: './select-hub.component.html',
})
export class SelectHubComponent implements OnInit {
  constructor(
    private readonly adminFacade: AdminFacade,
    readonly router: Router
  ) {}

  public Menu = false;
  selectedHub = '';
  selectedAdminHub = '';
  hubList = [];
  accessedHubs: string = '';
  isAdministration: boolean = this.router.url.includes('/user-access');

  ngOnInit(): void {
    if (this.isAdministration) {
      this.adminFacade.getAdminProgramList$.subscribe((Adminprogram) => {
        this.setOptionList(Adminprogram);
        this.prepopulateCheckboxAnswer(this.selectedAdminHub);
      });
      this.adminFacade.selectedAdminHub$.subscribe((selectedAdminHub) => {
        this.selectedAdminHub =
          typeof selectedAdminHub == 'string'
            ? selectedAdminHub.replace(/^,*|,*$/g, '')
            : '';
        this.selectedAdminHub = this.selectedAdminHub.replace(/,/g, ' | ');
        this.prepopulateCheckboxAnswer(selectedAdminHub);
      });
    } else {
      this.adminFacade.getProgramList$.subscribe((hubList) => {
        this.setOptionList(hubList);
        this.accessedHubs = this.hubList.map((hub) => hub.value).join(',');
        this.setExportProgramList(this.selectedHub);
        this.prepopulateCheckboxAnswer(this.selectedHub);
      });
      this.adminFacade.selectedHub$.subscribe((selectedHub) => {
        this.selectedHub =
          typeof selectedHub == 'string'
            ? selectedHub.replace(/^,*|,*$/g, '')
            : '';
        this.selectedHub = this.selectedHub.replace(/,/g, ' | ');
        this.prepopulateCheckboxAnswer(selectedHub);
        this.setExportProgramList(selectedHub);
      });
    }
  }

  togglePrograms() {
    this.Menu = !this.Menu;
  }

  setOptionList(hub) {
    this.hubList = [];
    hub.forEach((element) => {
      this.hubList.push({
        value: element.code,
        checked: false,
      });
    });
    this.hubList = this.hubList.sort(this.sortName);
  }

  sortName(a, b) {
    var max = a.value.toUpperCase();
    var min = b.value.toUpperCase();
    if (max < min) {
      return -1;
    }
    if (max > min) {
      return 1;
    }
    return 0;
  }

  updateAnswer(checked, value): void {
    this.hubList
      .filter((v) => v.value == value)
      .map((element) => {
        element.checked = checked;
        return element;
      });
  }

  setExportProgramList(selectedHub: string) {
    if (selectedHub) {
      const selectedHubIds = this.hubList
        .filter((option) => selectedHub.includes(option.value))
        .map((option) => option.value)
        .join(',');
      sessionStorage.setItem('exportProgramList', selectedHubIds);
    } else {
      sessionStorage.setItem('exportProgramList', this.accessedHubs);
    }
  }

  setSelectedHub(): void {
    this.togglePrograms();
    const selectHub = this.hubList
      .filter((element) => element.checked)
      .map((hub) => hub.value)
      .join(',');
    if (this.isAdministration) {
      this.adminFacade.setAdminSelectedHub(selectHub);
    } else {
      this.adminFacade.setSelectedHub(selectHub);
    }
  }

  batchCheck(value) {
    this.hubList.forEach((element) => {
      element.checked = value;
    });
  }

  cancel() {
    this.togglePrograms();
    if (this.isAdministration) {
      this.cancelLastOperation(this.selectedAdminHub);
    } else {
      this.cancelLastOperation(this.selectedHub);
    }
  }

  cancelLastOperation(selectedHubList: string) {
    if (!selectedHubList) {
      this.hubList.forEach((element) => {
        element.checked = false;
      });
      return true;
    }
    this.hubList.forEach((element) => {
      element.checked = !!selectedHubList.includes(element.value);
    });
  }

  prepopulateCheckboxAnswer(selectedHub) {
    if (!selectedHub) {
      return true;
    }

    this.hubList.map((option) => {
      if (selectedHub.includes(option.value)) {
        option.checked = true;
      }
      return option;
    });
  }
}
