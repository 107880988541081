import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sortOperation' })
export class SortOperationGroupPipe implements PipeTransform {
  transform(dropdownArray: any[]): any[] {
    if (Array.isArray(dropdownArray) && dropdownArray.length > 0) {
      const copy: any[] = [...dropdownArray];
      const indexOfCrossGroup: number = copy.findIndex(
        (element) => element.value == 'Cross Operating Group'
      );
      const indexOfNa: number = copy.findIndex(
        (element) => element.value == 'N/A'
      );
      const crossGroup: any[] = copy.splice(indexOfCrossGroup, 1)[0];
      const naValue: any[] = copy.splice(indexOfNa, 1)[0];

      copy.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      copy.push(crossGroup);
      copy.push(naValue);
      return copy;
    }
  }
}
