import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import * as RecoveryActions from './recovery.actions';
import { ReferenceDataService } from '../../../../reference-data';
import { catchError, exhaustMap, map } from 'rxjs/operators';

@Injectable()
export class RecoveryEffects {
  loadWBSResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecoveryActions.loadWBSLookupResults),
      exhaustMap((action) =>
        this.referenceDataService.getWBS(action.wbs).pipe(
          map((res) => {
            const response = {
              ...res.data,
              WbsComponentIndex: action.index,
            };
            return RecoveryActions.loadWBSLookupResultsSuccess({
              wbsCodeResults: response,
            });
          }),
          catchError((error) =>
            of(RecoveryActions.loadWBSLookupResultsFailure({ error: error }))
          )
        )
      )
    )
  );

  getExchangeRate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RecoveryActions.getExchangeRate),
      exhaustMap((action) =>
        this.referenceDataService.getExchangeRate(action.searchQuery).pipe(
          map((res) =>
            RecoveryActions.getExchangeRateSuccess({
              exchangeRate: res.data,
            })
          ),
          catchError((error) =>
            of(
              RecoveryActions.getExchangeRateFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly referenceDataService: ReferenceDataService
  ) {}
}
