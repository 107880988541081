<ioh-details-engagement
  [textareas]="textareas"
  [formGroup]="engagementDetailsGroup"
  lookupGroupFieldName="opportunityInput"
  lookupArrayFieldName="oppResult"
  lookupInputFieldName="input"
  [dropdownOptionsPurpose]="internalPurposes$ | async"
  [opportunityLimit]="opportunityLimit"
  [opportunityResults]="allOpporunityIdsSearchResults$"
  [opportunityLoading]="opportunityIdResultsLoading$"
  >
</ioh-details-engagement>
