import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import _get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  private readonly DATE_FORMAT: string = 'MM/dd/yyyy';
  public formatDate = (date: string): string => {
    const newDate = new Date(date);
    const formatedISODate = this.getUTCDateISOFormat(newDate.toISOString());
    return format(new Date(formatedISODate), this.DATE_FORMAT);
  };

  public minDate = (array: string[], fieldName: string): string => {
    const arrayOfDates = array
      .map((date) => date[fieldName] && new Date(date[fieldName]))
      .filter((elem) => elem);

    return arrayOfDates.length > 0
      ? this.formatDate(Math.min.apply(null, arrayOfDates))
      : '';
  };

  public getUTCDateISOFormat(originalDate: string): string {
    if (originalDate) {
      const newDate = new Date(originalDate);
      newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());

      const isoDate = newDate.toISOString();
      return isoDate;
    }
  }

  public maxDate = (array: string[], fieldName: string): string => {
    const arrayOfDates = array
      .map((date) => date[fieldName] && new Date(date[fieldName]))
      .filter((elem) => elem);
    return arrayOfDates.length > 0
      ? this.formatDate(Math.max.apply(null, arrayOfDates))
      : '';
  };

  flattenAndFormatWorkshops(array: any[]) {
    return array
      .map((workshop) => {
        var newWorkshop = {
          ...workshop.consultation,
          ...workshop.general,
          ...workshop.recovery,
          ...workshop.consultation.workshop.program,
        };
        newWorkshop.updatedAt = workshop.consultation.updatedAt;
        return newWorkshop;
      })
      .map((workshop) => {
        return {
          modifyBy: _get(workshop, 'modifyBy', ''),
          updatedAt: _get(workshop, 'updatedAt', ''),
          attendees: _get(workshop, 'totalAttendees', ''),
          chargedate: workshop.chargeDate
            ? this.formatDate(workshop.chargeDate)
            : '',
          comments: _get(workshop, 'comments', 'N/A'),
          complete: workshop.completeDate
            ? this.formatDate(workshop.completeDate)
            : '',
          costs: _get(workshop, 'costs', []),
          end: this.mapRequestedDates(workshop).endDate,
          expiration: workshop.softLockExpirationDate
            ? this.formatDate(workshop.softLockExpirationDate)
            : '',
          id: _get(workshop, 'workshop.id'),
          listViewId:
            `${_get(workshop, 'code')} - ` + `${_get(workshop, 'workshop.id')}`,
          lead: _get(workshop, 'engagement.lead', 'N/A'),
          org: this.flattenOrganizations(workshop.organizations),
          owner: _get(workshop, 'consultationOwner', 'N/A'),
          poc: _get(workshop, 'contactDetails.primaryPointOfContact', ''),
          start: this.mapRequestedDates(workshop).startDate,
          status: _get(workshop, 'workshop.status.value'),
          team: this.mapEngagementTeam(_get(workshop, 'engagement.team', [])),
          title: _get(workshop, 'engagement.title', 'N/A'),
          engagementStartDate: _get(
            workshop,
            'engagement.engagementStartDate',
            ''
          ),
          engagementEndDate: _get(workshop, 'engagement.engagementEndDate', ''),
          total: _get(workshop, 'recoveryTotal', 'N/A'),
          totalCosts: _get(workshop, 'totalCosts', 'N/A'),
          type: _get(workshop, 'engagement.type.value', 'N/A'),
          waitlist: this.mapWaitlistDates(workshop),
          wbs: this.flattenChargeCodes(workshop.costs),
          programId: _get(workshop, 'id', ''),
          programCode: _get(workshop, 'code', ''),
          groupOwner: _get(workshop, 'engagement.group.value', ''),
          tags: this.flattenTags(_get(workshop, 'engagement.tags', [])),
        };
      });
  }

  formatRecoveryEntries(data) {
    const recoveryEntriesData = [];
    data.map((item) => {
      if (item.costs && item.costs.length > 0) {
        item.costs.map((entries) => {
          entries.id = item.id;
          entries.listViewId = item.listViewId;
          entries.chargedate = entries.chargeDate
            ? this.formatDate(entries.chargeDate)
            : '';
          entries.title = item.title;
          entries.org = item.org;
          entries.totalRecoveryOrg = item.org.split(',').join(';');
          entries.start = item.start;
          entries.end = item.end;
          entries.status = item.status;
          entries.lead = item.lead;
          entries.team = item.team;
          entries.costElement = 900101;
          entries.isValid = entries.isValid ? 'Valid' : 'Invalid';
          entries.total = entries.subTotal
            ? entries.subTotal
            : this.calculateSubTotal(entries);
          entries.amount = entries.total
            ? this.formatCurrency(entries.total)
            : '';
          entries.recovery2P = entries.recovery2P
            ? this.formatCurrency(entries.recovery2P)
            : '';
          entries.programCode = item.programCode;
          entries.programId = item.programId;
          entries.updatedAt = item.updatedAt;
          entries.modifyBy = item.modifyBy;
          entries.groupOwner = item.groupOwner;
          entries.engagementTitle =
            `${entries.programCode} - ` +
            `${entries.id} | ` +
            `${entries.title}`;
          entries.bookingId = `${entries.programCode} - ` + `${entries.id}`;
          recoveryEntriesData.push(entries);
        });
      }
    });
    return recoveryEntriesData;
  }

  calculateSubTotal(entry) {
    if (entry.cost || entry.discount) {
      return (entry.cost - entry.discount).toFixed(2);
    } else {
      return null;
    }
  }

  mapWaitlistDates(workshop) {
    const waitlistDates = _get(workshop, 'waitlistDates', []);
    const duration =
      _get(workshop, 'requestedDates[0].duration') ||
      _get(workshop, 'waitlistDates[0].duration');
    if (duration == 'Multi-Day') {
      return waitlistDates
        .map((date: any) => {
          return `${this.formatDate(date.startDate)}-${this.formatDate(
            date.endDate
          )}`;
        })
        .join();
    } else {
      return waitlistDates
        .map((date: any) => this.formatDate(date.startDate))
        .join();
    }
  }

  mapRequestedDates(flattenWorkshop: any) {
    const result: any = {};
    const scheduleDates: any[] = _get(flattenWorkshop, 'schedule.dates', []);

    if (
      scheduleDates &&
      scheduleDates.length > 0 &&
      scheduleDates.filter((dates) => {
        return dates.date != null;
      }).length > 0
    ) {
      result.startDate = this.minDate(scheduleDates, 'date');
      result.endDate = this.maxDate(scheduleDates, 'date');
    } else {
      const requestedDates: any[] = _get(flattenWorkshop, 'requestedDates', []);
      const waitlistDates: any[] = _get(flattenWorkshop, 'waitlistDates', []);
      const mergedDates = [...requestedDates, ...waitlistDates];

      const earliestStartDate = this.minDate(mergedDates, 'startDate');
      const rowObject = mergedDates.find(
        (elem) => this.formatDate(elem.startDate) === earliestStartDate
      );

      const startDate = _get(rowObject, 'startDate', '');
      const endDate = _get(rowObject, 'endDate', '');

      result.startDate = startDate && this.formatDate(startDate);
      const endD = endDate || startDate;
      result.endDate = endD && this.formatDate(endD);
    }
    return result;
  }

  sortTableDataByDate(array) {
    const dataWithEmptyDate = [...array].filter((data) => isEmpty(data.start));
    const dataWithDate: any[] = [...array].filter(
      (data) => !isEmpty(data.start)
    );
    dataWithDate.sort((a, b) => +new Date(a.start) - +new Date(b.start));
    return dataWithEmptyDate.concat(dataWithDate);
  }

  sortTableDataByAlphabeticalOrder(
    data: any[],
    column: string,
    sensitiveCase = true
  ) {
    data.sort((a, b) => {
      var max = sensitiveCase ? a[column] : a[column].toUpperCase();
      var min = sensitiveCase ? b[column] : b[column].toUpperCase();
      if (max < min) {
        return -1;
      }
      if (max > min) {
        return 1;
      }
      return 0;
    });
    return data;
  }

  flattenChargeCodes(array: any[]): string {
    return this.flattenArray({ array, key: 'code' });
  }

  flattenTags(array: any[]): string {
    return this.flattenArray({ array, key: 'name' });
  }

  mapEngagementTeam(engagementTeam: any[]): string {
    return engagementTeam.map((member) => member.name).join();
  }

  flattenOrganizations(array: any[]): string {
    return this.flattenArray({
      array,
      key: 'organizationName',
      secondaryKey: 'accentureEntity.value',
    });
  }

  flattenArray({
    array,
    key,
    secondaryKey,
  }: {
    array: any[];
    key?: string;
    secondaryKey?: string;
  }): string {
    if (array) {
      const { length } = array;
      if (length == 1) {
        return this.formattedElement(array[0], key, secondaryKey);
      } else {
        return array
          .map((element) => {
            const subString =
              `${this.formattedElement(element, key, secondaryKey)}` == ''
                ? ''
                : `${this.formattedElement(element, key, secondaryKey)},`;
            return subString;
          })
          .join('\n')
          .slice(0, -1);
      }
    } else return '';
  }

  formattedElement(element, key?: string, secondaryKey?: string): string | any {
    return _get(element, key) || _get(element, secondaryKey)
      ? _get(element, key) || _get(element, secondaryKey)
      : '';
  }

  public formatCurrency(currency) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(currency);
  }
}
