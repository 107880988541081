import {
  AdditionalNotesState,
  AttendeeDetailsState,
  ContactDetailsState,
  EngagementDetailsState,
  VisitDetailsState,
} from '@ioh/core-data';
import { DateFormatService } from './date-format.service';
import { Injectable } from '@angular/core';
import _get from 'lodash/get';
import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';
@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private readonly formatDateService: DateFormatService) {}
  mandatories = [
    ['selectedEngagementSponsor', 'Engagement sponsor'],
    ['selectedPrimaryContact', 'Primary contact'],
    ['backgroundText', 'Background info'],
    ['objectivesAndExpectations', 'Objective and expectations'],
    ['successCriteria', 'Success criteria'],
    ['challenges', 'Client Challenges'],
    ['selectedInternalPurpose', 'Engagement Purpose'],
    ['organizations', 'Organizations'],
    ['totalAttendees', 'Total attendees'],
    ['leadershipLevel', 'Leadership level'],
    ['workshop.program', 'Program Id'],
  ];

  removeSearchResults(
    searchResults: string[],
    state:
      | ContactDetailsState
      | EngagementDetailsState
      | VisitDetailsState
      | AttendeeDetailsState
      | AdditionalNotesState
  ) {
    const stateClone = clone(state);
    searchResults.forEach((searchResult) => {
      stateClone[searchResult] = null;
    });
    return stateClone;
  }

  formatContactDetails(state: ContactDetailsState) {
    const formattedClone = this.removeSearchResults(
      [
        'engagementSponsorSearchResults',
        'primaryContactSearchResults',
        'additionalContactSearchResults',
      ],
      state
    );
    return formattedClone;
  }

  formatEngagementDetails(state: EngagementDetailsState) {
    const formattedClone = this.removeSearchResults(
      ['opportunityIdsSearchResults', 'internalPurposeSearchResults'],
      state
    );
    return formattedClone;
  }

  formatVisitDetails(state: VisitDetailsState) {
    const formattedClone = this.removeSearchResults(['wbsCodeResults'], state);
    return formattedClone;
  }

  formatAttendeeDetails(state: AttendeeDetailsState) {
    const formattedClone = this.removeSearchResults(
      [
        'categoriesResults',
        'operatingGroupResults',
        'accentureEntitiesResults',
        'clientOrganizationSearchResults',
        'organizationTypesResults',
      ],
      state
    );
    return formattedClone;
  }

  formatAdditionalNotes(state: AdditionalNotesState) {
    return state;
  }

  allFormGroupsAreValid(body): boolean {
    return this.mandatories.every((mandatory) => {
      const prop = _get(body, mandatory[0]);
      // isEmpty cannot handle numbers for some reason. but handles arrays, objects well
      // so it's worth using it and handling the number case separately
      if (typeof prop !== 'number') {
        return !isEmpty(prop);
      } else if (typeof prop === 'number' && prop) {
        return true;
      }
    });
  }

  async formatRequestBody(request): Promise<any> {
    return new Promise((resolve, reject) => {
      const formattedRequest = this.createFormattedBody(request);
      if (this.allFormGroupsAreValid(formattedRequest)) {
        resolve(formattedRequest);
      } else {
        const error = this.generateErrorMessage(formattedRequest);
        reject(new Error(error));
      }
    });
  }

  generateErrorMessage(body) {
    const missingFields = [];
    this.mandatories.forEach((mandatory) => {
      const prop = _get(body, mandatory[0]);
      if (typeof prop !== 'number' && isEmpty(prop)) {
        missingFields.push(mandatory[1]);
      } else if (typeof prop === 'number' && !prop) {
        missingFields.push(mandatory[1]);
      }
    });
    return `
        <p class="modal__text"> Oops! Your request is missing the following mandatory fields:
        \n 
        ${missingFields.join('\n')}. 
        \n Please update these fields and try again.</p>`;
  }

  // TODO CREATE A TYPE FOR WORKSHOP REQUEST FROM FE
  createFormattedBody({
    programId,
    contactDetails,
    engagementDetails,
    visitDetails,
    attendeeDetails,
    additionalNotes,
  }): any {
    return {
      workshop: this.addWorkshop(programId),
      ...this.formatContactDetails(contactDetails),
      ...this.formatEngagementDetails(engagementDetails),
      ...this.formatVisitDetails(visitDetails),
      ...this.formatAttendeeDetails(attendeeDetails),
      ...this.formatAdditionalNotes(additionalNotes),
    };
  }

  addWorkshop(programId: string) {
    return {
      program: programId,
      status: 1,
    };
  }
}
