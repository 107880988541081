import { AdminFacade } from '@ioh/core-data';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Component } from '@angular/core';
import { TableService } from '../../../services/table.service';
import { WorkshopService } from '../../../services/workshop.service';
import { closedColNames } from '@assets/data/tables/table-columns';
import { transformDates } from '@utils/tablesUtils';
import isEmpty from 'lodash/isEmpty';

@Component({
  selector: 'ioh-closed',
  styleUrls: ['./closed.component.scss'],
  templateUrl: './closed.component.html',
})
export class ClosedComponent {
  data = [];
  data$ = new BehaviorSubject(null);
  colNames = closedColNames;
  selectHub = '';
  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly tableService: TableService,
    private readonly workshopService: WorkshopService,
    private readonly adminFacade: AdminFacade
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.workshopService.getWorkshops$.subscribe((workshops) => {
        if (!isEmpty(workshops)) {
          this.handleFormatting(workshops);
        }
      })
    );
    this.subscriptions.add(
      this.adminFacade.selectedHub$.subscribe((selectedHub) => {
        this.selectHub = selectedHub;
        this.updateAllData();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleFormatting(workshops: any[]): void {
    if (typeof Worker != 'undefined') {
      const worker: Worker = new Worker(
        '../../../services/data-formatter.worker',
        {
          type: 'module',
        }
      );
      worker.onmessage = ({ data }) => {
        this.data = this.tableService.sortTableDataByDate(data);
        this.updateAllData();
      };
      worker.postMessage(workshops);
    } else {
      const unorderedData = this.tableService.flattenAndFormatWorkshops(
        workshops
      );
      this.data = this.tableService.sortTableDataByDate(unorderedData);
      this.updateAllData();
    }
  }

  updateAllData() {
    if (this.data.length == 0) {
      return true;
    }
    this.data$.next(this.data.map(transformDates.bind(this)));
    this.filterDataOnHub(this.selectHub);
  }

  filterDataOnHub(selectedHub: string) {
    if (!selectedHub) {
      return true;
    }
    this.data$.next(
      this.data.filter((data) => selectedHub.includes(data.programCode))
    );
  }
}
