import * as VisitDetailsActions from './visit-details.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

export const VISITDETAILS_FEATURE_KEY = 'visitDetails';

/** TODO fix types */
export interface VisitDetailsState extends EntityState<any> {
  selectedId?: string | number; // which VisitDetails record has been selected
  loaded: boolean; // has the VisitDetails list been loaded
  error?: string | null; // last none error (if any)
  duration: string | null;
  dates: any | null; // is any array of date objects
  wbsCodes: any | null;
  wbsCodeResults: any | null;
}

export interface VisitDetailsPartialState {
  readonly [VISITDETAILS_FEATURE_KEY]: VisitDetailsState;
}

export const visitDetailsAdapter: EntityAdapter<any> = createEntityAdapter<any>(
  {
    selectId: (visitDetails: any) => visitDetails.ChargeCode,
  }
);

export const initialState: VisitDetailsState = visitDetailsAdapter.getInitialState(
  {
    dates: [],

    duration: '',
    // set initial required properties
    loaded: false,
    wbsCodeResults: null,
    wbsCodes: [],
  }
);

const visitDetailsReducer = createReducer(
  initialState,
  on(VisitDetailsActions.loadWBSLookupResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    VisitDetailsActions.loadWBSLookupResultsSuccess,
    (state, { wbsCodeResults }) =>
      visitDetailsAdapter.addOne(wbsCodeResults, {
        ...state,
        loaded: true,
        wbsCodeResults,
      })
  ),
  on(VisitDetailsActions.loadWBSLookupResultsFailure, (state, { error }) => ({
    ...state,
    error,
    wbsCodeResults: null,
  })),
  on(VisitDetailsActions.updateSelectedDuration, (state, { duration }) => {
    return Object.assign({}, state, { duration });
  }),
  on(VisitDetailsActions.updateSelectedDates, (state, { dates }) => {
    return Object.assign({}, state, { dates });
  }),
  on(VisitDetailsActions.updateSelectedWBSCodes, (state, { wbsCodes }) => {
    return Object.assign({}, state, { wbsCodes });
  }),
  on(VisitDetailsActions.clearStateObject, () => {
    return Object.assign({});
  })
);

export function reducer(state: VisitDetailsState | undefined, action: Action) {
  return visitDetailsReducer(state, action);
}
