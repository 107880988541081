import { AdminFacade } from '@ioh/core-data';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Component } from '@angular/core';
// import { ICostModel } from '@ioh/types';
import { TableService } from '../../../services/table.service';
import { WorkshopService } from '../../../services/workshop.service';
import { checkStatusIs, transformDates } from '@utils/tablesUtils';
import {
  directChargeAndSubmissioncolNames,
  pendingColNames,
} from '@assets/data/tables/table-columns';
import isEmpty from 'lodash/isEmpty';

@Component({
  selector: 'ioh-pending',
  styleUrls: ['./pending.component.scss'],
  templateUrl: './pending.component.html',
})
export class PendingComponent {
  data = [];
  sortData = [];
  workshop = [];
  totalRecoveryReport = [];
  selectHub = '';
  private readonly DATE_FORMAT: string = 'MM/dd/yyyy';
  colNames = pendingColNames;
  directChargeAndSubmissioncolNames = directChargeAndSubmissioncolNames;
  pendingMissingRecoveryData$: BehaviorSubject<any> = new BehaviorSubject(null);
  readyForSubmissionData$: BehaviorSubject<any> = new BehaviorSubject(null);
  directChargeData$: BehaviorSubject<any> = new BehaviorSubject(null);
  pendingTotalRecoveryData$: BehaviorSubject<any> = new BehaviorSubject(null);
  totalRecoveryReport$: BehaviorSubject<any> = new BehaviorSubject(null);
  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly tableService: TableService,
    private readonly workshopService: WorkshopService,
    private readonly adminFacade: AdminFacade
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.workshopService.getWorkshops$.subscribe((workshops) => {
        if (!isEmpty(workshops)) {
          this.workshop = workshops;
          this.handleFormatting(workshops);
        }
      })
    );
    this.subscriptions.add(
      this.adminFacade.selectedHub$.subscribe((selectedHub) => {
        this.selectHub = selectedHub;
        this.updateAllData();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleFormatting(workshops: any[]): void {
    if (typeof Worker != 'undefined') {
      const worker: Worker = new Worker(
        '../../../services/data-formatter.worker',
        {
          type: 'module',
        }
      );
      worker.onmessage = ({ data }) => {
        this.sortData = this.tableService.sortTableDataByDate(data);
        this.data = this.tableService.formatRecoveryEntries(this.sortData);
        this.updateAllData();
      };
      worker.postMessage(workshops);
    } else {
      const unorderedData = this.tableService.flattenAndFormatWorkshops(
        workshops
      );
      this.sortData = this.tableService.sortTableDataByDate(unorderedData);
      this.data = this.tableService.formatRecoveryEntries(this.sortData);
    }
  }

  updateAllData() {
    if (this.data.length == 0) {
      return true;
    }
    this.pendingMissingRecoveryData$.next(
      this.data
        .filter(this.missingRecoveryFilter.bind(this))
        .map(transformDates.bind(this))
    );
    this.readyForSubmissionData$.next(
      this.data
        .filter(this.readyForSubmissionFilter.bind(this))
        .map(transformDates.bind(this))
    );
    this.directChargeData$.next(
      this.data
        .filter(this.directChargeDataFilter.bind(this))
        .map(transformDates.bind(this))
    );
    this.pendingTotalRecoveryData$.next(
      this.data
        .filter(this.totalRecoveryFilter.bind(this))
        .map(transformDates.bind(this))
    );
    this.totalRecoveryReport$.next(
      this.data
        .filter(this.totalRecoveryFilter.bind(this))
        .map(transformDates.bind(this))
    );
  }

  directChargeDataFilter(value): boolean {
    return (
      value.chargeStatus == 'Direct Charge' &&
      value.code !== null &&
      value.isValid == 'Valid' &&
      value.total != null &&
      (checkStatusIs(value, 'Booked') ||
        checkStatusIs(value, 'Completed') ||
        (checkStatusIs(value, 'Cancelled') && value.total != 0)) &&
      (!this.selectHub || this.selectHub.includes(value.programCode))
    );
  }

  readyForSubmissionFilter(value): boolean {
    return (
      value.chargeStatus == 'Ready for Submission' &&
      value.code !== null &&
      value.isValid == 'Valid' &&
      value.total != null &&
      (checkStatusIs(value, 'Booked') ||
        checkStatusIs(value, 'Completed') ||
        (checkStatusIs(value, 'Cancelled') && value.total != 0)) &&
      (!this.selectHub || this.selectHub.includes(value.programCode))
    );
  }

  missingRecoveryFilter(value): boolean {
    var code = typeof value.code == 'string' ? value.code.trim() : value.code;
    return (
      checkStatusIs(value, 'Booked') &&
      (!code || value.isValid == false || value.total == null) &&
      (!this.selectHub || this.selectHub.includes(value.programCode))
    );
  }

  totalRecoveryFilter(value): boolean {
    return (
      !value.chargeDate &&
      (checkStatusIs(value, 'Completed') ||
        (checkStatusIs(value, 'Cancelled') && !!+value.total)) &&
      (!this.selectHub || this.selectHub.includes(value.programCode))
    );
  }
}
