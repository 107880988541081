import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormControlUtils } from '@utils/formControlUtils';
import { Injectable } from '@angular/core';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
@Injectable({
  providedIn: 'root',
})
export class WbsEntryService {
  constructor(
    private readonly fb: FormBuilder,
    private readonly formControlUtils: FormControlUtils
  ) {}

  public createCurrencyWbs(entry?): FormGroup {
    return this.fb.group({
      id: this.fb.control(
        this.formControlUtils.mapProperty({ entry, property: 'id' })
      ),
      code: this.fb.control(this.mapWbsCode(entry), [RxwebValidators.unique()]),
      description: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'description',
        }),
        Validators.required
      ),
      expiration: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'expiration',
        }),
        Validators.required
      ),
      isValid: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'isValid',
          defaultValue: false,
        })
      ),
      responseLoaded: this.fb.control(false),
      type: this.fb.control(this.mapType(entry), Validators.required),
    });
  }

  public createPercentageWbs(entry?): FormGroup {
    return this.fb.group({
      id: this.fb.control(
        this.formControlUtils.mapProperty({ entry, property: 'id' })
      ),
      code: this.fb.control(this.mapWbsCode(entry), [RxwebValidators.unique()]),
      description: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'description',
        }),
        Validators.required
      ),
      expiration: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'expiration',
        }),
        Validators.required
      ),
      isValid: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'isValid',
          defaultValue: false,
        })
      ),
      responseLoaded: this.fb.control(false),
      type: this.fb.control(this.mapType(entry), Validators.required),
    });
  }

  private mapWbsCode(entry: any): string {
    return (
      this.formControlUtils.mapProperty({ entry, property: 'wbsCode' }) ||
      this.formControlUtils.mapProperty({ entry, property: 'code' })
    );
  }

  private mapType(entry: any): string {
    return this.formControlUtils.mapProperty({
      entry,
      property: 'type',
      defaultValue: '-',
    });
  }
}
