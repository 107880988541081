import { DATES_LOCATIONS, ENGAGEMENT_GROUP } from '@utils/enums';
import { DateFormatService } from '@services';
import {
  DatesAndLocationsEntryService,
  LookUpEntryService,
  OrganizationEntryService,
  TeamMemberService,
} from '@ioh/ui-library';
import { FormArray, FormGroup } from '@angular/forms';
import { GeneralState, IGeneralModelDb } from '@ioh/core-data';
import { Injectable } from '@angular/core';
import { clientTypeOptions } from '@assets/data/organisation-details';
import _get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

@Injectable({
  providedIn: 'root',
})
export class GeneralTabService {
  generalGroup: FormGroup;

  constructor(
    public lookupEntryService: LookUpEntryService,
    public organizationEntryService: OrganizationEntryService,
    public teamMemberService: TeamMemberService,
    public datesAndLocationsEntryService: DatesAndLocationsEntryService,
    private readonly dateFormatService: DateFormatService
  ) {}

  get organizationsArray(): FormArray {
    return this.generalGroup.get('organisations') as FormArray;
  }

  get engagementTeams(): FormArray {
    return this.generalGroup.get('engagementTeams') as FormArray;
  }

  get datesAndLocation(): FormGroup {
    return this.generalGroup.get(DATES_LOCATIONS) as FormGroup;
  }

  get dates(): FormArray {
    return this.datesAndLocation.controls.dates as FormArray;
  }

  get engagementLead(): FormArray {
    return this.generalGroup
      .get('engagementLeadInput')
      .get('engagementLeadResult') as FormArray;
  }

  get engagementSponsors(): FormArray {
    return this.generalGroup
      .get('engagementSponsorInput')
      .get('engagementSponsorResult') as FormArray;
  }

  get primaryContacts(): FormArray {
    return this.generalGroup
      .get('primaryContactInput')
      .get('primaryContactResult') as FormArray;
  }

  get additionalContacts(): FormArray {
    return this.generalGroup
      .get('additionalContactsInput')
      .get('additionalContactsResult') as FormArray;
  }

  get tagsArray(): FormArray {
    return this.generalGroup.get('tags').get('tagsResult') as FormArray;
  }

  public prepopulateGeneralTabWithDBResponse(
    workshop: IGeneralModelDb,
    formGroup: FormGroup
  ) {
    this.generalGroup = formGroup;
    const { engagement, contactDetails, organizations, schedule } = workshop;
    if (!isEmpty(engagement)) {
      this.prepopulateEngagement({
        engagementTitle: engagement.title,
        engagementType: engagement.type,
        tags: engagement.tags,
        engagementTeam: engagement.team,
        engagementSummary: engagement.summary,
        adminComments: engagement.adminComments,
        engagementLead: engagement.lead,
        engagementGroup: engagement.group,
        groupParticipation: engagement.groupParticipation,
        groupEcosystem: engagement.groupEcosystem,
        engagementStartDate: engagement.engagementStartDate,
        engagementEndDate: engagement.engagementEndDate,
      });
    }
    this.prepopulateOrganizations(organizations);
    this.prepopulateDatesAndLocations(schedule?.dates);
    this.prepopulateContactDetails({
      additionalContacts: contactDetails.additionalContacts,
      engagementSponsor: contactDetails.engagementSponsor,
      primaryPointOfContact: contactDetails.primaryPointOfContact,
    });
  }

  public prepopulateGeneralTabWithStateObject(
    generalStateObject: GeneralState,
    formGroup: FormGroup
  ) {
    this.generalGroup = formGroup;
    this.prepopulateOrganizations(generalStateObject.organizations);
    this.prepopulateEngagement({
      engagementTitle: generalStateObject.engagementTitle,
      engagementType: generalStateObject.engagementType,
      tags: generalStateObject.tags,
      engagementTeam: generalStateObject.engagementTeam,
      engagementSummary: generalStateObject.engagementSummary,
      adminComments: generalStateObject.adminComments,
      engagementLead: generalStateObject.engagementLead,
      engagementGroup: generalStateObject.engagementGroup,
      groupParticipation: generalStateObject.groupParticipation,
      groupEcosystem: generalStateObject.groupEcosystem,
      engagementStartDate: generalStateObject.engagementStartDate,
      engagementEndDate: generalStateObject.engagementEndDate,
    });
    this.prepopulateDatesAndLocations(
      generalStateObject.datesAndLocations.dates
    );
    this.prepopulateContactDetails({
      engagementSponsor: generalStateObject.engagementSponsors,
      primaryPointOfContact: generalStateObject.primaryContacts,
      additionalContacts: generalStateObject.additionalContacts,
    });
  }

  private prepopulateTags(value: any[]) {
    if (Array.isArray(value) && !isEmpty(value)) {
      value.forEach((tag) => {
        this.tagsArray.push(this.lookupEntryService.createLookUpEntry(tag));
      });
    }
  }

  private prepopulateTeam(value: any[]): void {
    if (!isEmpty(value)) {
      value.forEach((member, index) => {
        this.engagementTeams.push(
          this.teamMemberService.createTeamMember(member)
        );
        this.setTeamMemberName(member, index);
      });
    }
  }

  private setTeamMemberName(member, index: number): void {
    const memberName =
      _get(member, 'name') || _get(member, 'nameInput.nameResult[0]');
    if (memberName) {
      (this.engagementTeams
        .at(index)
        .get('nameInput')
        .get('nameResult') as FormArray).push(
        this.lookupEntryService.createLookUpEntry(memberName)
      );
    }
  }

  private prepopulateEngagementTitle(value: string): void {
    if (!isEmpty(value)) {
      this.generalGroup.get('engagementTitle').setValue(value);
    }
  }

  private prepopulateEngagementType(value): void {
    if (!isEmpty(value)) {
      this.generalGroup.get('engagementType').setValue({
        id: value.id,
        value: value.value,
      });
    }
  }

  private prepopulateAdminComments(value): void {
    if (!isEmpty(value)) {
      this.generalGroup.get('adminComments').setValue(value);
    }
  }

  private prepopulateEngagementSummary(value): void {
    if (!isEmpty(value)) {
      this.generalGroup.get('summary').setValue(value);
    }
  }

  private prepopulateEngagementLead(value: any[] | {}): void {
    if (Array.isArray(value) && !isEmpty(value)) {
      value.forEach((lead) => {
        this.engagementLead.push(
          this.lookupEntryService.createLookUpEntry(lead)
        );
      });
    } else if (!isEmpty(value)) {
      this.engagementLead.push(
        this.lookupEntryService.createLookUpEntry(value)
      );
    }
  }

  private prepopulateEngagementGroup(value): void {
    if (!isEmpty(value)) {
      this.generalGroup.get(ENGAGEMENT_GROUP).setValue({
        id: value.id,
        value: value.value,
      });
    }
  }

  private prepopulateGroupParticipation(value: string): void {
    if (!isEmpty(value)) {
      this.generalGroup.get('groupParticipation').setValue(value);
    }
  }

  private prepopulateGroupEcosystem(value: string): void {
    if (!isEmpty(value)) {
      this.generalGroup.get('groupEcosystem').setValue(value);
    }
  }

  private prepopulateEngagementStartDate(value: string): void {
    if (!isEmpty(value)) {
      const newValue = this.dateFormatService.getDateToPrepopulate(value);
      this.generalGroup.get('engagementStartDate').setValue(newValue);
    }
  }

  private prepopulateEngagementEndDate(value: string): void {
    if (!isEmpty(value)) {
      const newValue = this.dateFormatService.getDateToPrepopulate(value);
      this.generalGroup.get('engagementEndDate').setValue(newValue);
    }
  }

  private prepopulateEngagement({
    engagementTitle,
    engagementType,
    tags,
    engagementTeam,
    engagementSummary,
    adminComments,
    engagementLead,
    engagementGroup,
    groupParticipation,
    groupEcosystem,
    engagementStartDate,
    engagementEndDate,
  }): void {
    this.prepopulateEngagementTitle(engagementTitle);
    this.prepopulateEngagementType(engagementType);
    this.prepopulateTags(tags);
    this.prepopulateTeam(engagementTeam);
    this.prepopulateEngagementSummary(engagementSummary);
    this.prepopulateAdminComments(adminComments);
    this.prepopulateEngagementLead(engagementLead);
    this.prepopulateEngagementGroup(engagementGroup);
    this.prepopulateGroupParticipation(groupParticipation);
    this.prepopulateGroupEcosystem(groupEcosystem);
    this.prepopulateEngagementStartDate(engagementStartDate);
    this.prepopulateEngagementEndDate(engagementEndDate);
  }

  private prepopulateContactDetails({
    engagementSponsor,
    primaryPointOfContact,
    additionalContacts,
  }) {
    if (Array.isArray(engagementSponsor)) {
      engagementSponsor.forEach((sponsor) => {
        this.engagementSponsors.push(
          this.lookupEntryService.createLookUpEntry(sponsor)
        );
      });
    } else {
      if (!isEmpty(engagementSponsor)) {
        this.engagementSponsors.push(
          this.lookupEntryService.createLookUpEntry(engagementSponsor)
        );
      }
    }

    if (Array.isArray(primaryPointOfContact)) {
      primaryPointOfContact.forEach((contact) => {
        this.primaryContacts.push(
          this.lookupEntryService.createLookUpEntry(contact)
        );
      });
    } else {
      if (!isEmpty(primaryPointOfContact)) {
        this.primaryContacts.push(
          this.lookupEntryService.createLookUpEntry(primaryPointOfContact)
        );
      }
    }

    if (Array.isArray(additionalContacts)) {
      additionalContacts.forEach((contact) => {
        this.additionalContacts.push(
          this.lookupEntryService.createLookUpEntry(contact)
        );
      });
    } else {
      if (!isEmpty(additionalContacts)) {
        this.additionalContacts.push(
          this.lookupEntryService.createLookUpEntry(additionalContacts)
        );
      }
    }
  }

  prepopulateOrganizations(organizations: any[]): void {
    // callstack needs to be clear for organizations ui to update, hence the setTimeout
    setTimeout(() => {
      if (Array.isArray(organizations) && !isEmpty(organizations)) {
        organizations.forEach((org, index) => {
          // handle FE state response and BE db response
          const organizationTypeId = _get(org, 'type.id') || _get(org, 'type');
          const organizationTypeValue =
            _get(org, 'type.value') || _get(org, 'typeString');
          if (organizationTypeId == 1) {
            this.organizationsArray.push(
              this.organizationEntryService.createInternalOrganization(
                organizationTypeId,
                organizationTypeValue,
                org
              )
            );
          } else if (organizationTypeId == 2) {
            this.organizationsArray.push(
              this.organizationEntryService.createExternalOrganization(
                organizationTypeId,
                organizationTypeValue,
                org
              )
            );
          } else if (organizationTypeId == 3 || organizationTypeId == 4) {
            this.organizationsArray.push(
              this.organizationEntryService.createClientOrganization(
                organizationTypeId,
                clientTypeOptions
                  .filter((t) => t.id == organizationTypeId)
                  .map((type) => type.value)[0],
                org
              )
            );
            this.setClientName(this.organizationsArray, org, index);
          }
        });
      }
    }, 0);
  }

  setClientName(orgForm, org, index) {
    const clientName =
      org.clientName ||
      (org.client &&
        org.client.clientResult.length > 0 &&
        org.client.clientResult[0].chip);
    if (clientName) {
      (orgForm.at(index).get('client').get('clientResult') as FormArray).push(
        this.lookupEntryService.createLookUpEntry(clientName)
      );
    }
  }

  prepopulateDatesAndLocations(dates: any[]): void {
    if (!isEmpty(dates)) {
      dates.forEach((date) => {
        const newDate = this.dateFormatService.getDateToPrepopulate(
          date.date || date.dates
        );
        const newEntry = { ...date };
        if (date.date) {
          newEntry.date = newDate;
        } else if (date.dates) {
          newEntry.dates = newDate;
        }
        this.dates.push(
          this.datesAndLocationsEntryService.createNewDateEntry(newEntry)
        );
      });
    }
  }
}
