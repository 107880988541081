import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AddBookingService } from './add-booking.service';
import { AdminFacade, GeneralFacade, RecoveryFacade } from '@ioh/core-data';
import { AppLoadingService } from 'libs/ui-library/src/lib/app-loading/app-loading.service';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  of,
} from 'rxjs';
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DateFormatService, ModalService, WorkshopService } from '@services';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  bookingSections,
  cancellationDropdownSettings,
  programDropdownSettings,
  statusDropdownSettings,
} from '@assets/data/booking-details';
import { delay, filter, map, retryWhen, switchMap, take } from 'rxjs/operators';
import { isNonNull } from '@utils/filters';
import { recoveryCurrency } from '@assets/data/currency-details';
import { tooltipConst } from '@assets/data/tooltip-details';
import _get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
@Component({
  selector: 'ioh-add-booking',
  styleUrls: ['./add-booking.component.scss'],
  templateUrl: './add-booking.component.html',
})
export class AddBookingComponent implements OnInit, OnDestroy {
  bookingGroup: FormGroup;
  workshopId: string;
  previousList: string;
  workshopInfo: any;
  engStartDate: string;
  engEndDate: string;
  engagementStatusTooltipObj: any = {};
  refreshPage: any;
  navLinks = bookingSections;
  programDropdown = programDropdownSettings;
  statusDropdown = statusDropdownSettings;
  cancellationDropdownSettings = cancellationDropdownSettings;

  workshopStatusValues$ = this.workshopService.workshopStatus$;
  cancellationReasonValues$ = this.workshopService.cancellationReasons$;
  programList = [];
  toCy: string = Object.values(recoveryCurrency)
    .map((hub) => hub.currency)
    .filter((cys, index, list) => list.indexOf(cys) == index)
    .join(',');

  engagementSponsorLimit = 1;
  primaryContactLimit = 1;
  additionalContactsLimit = 25;
  engagementLeadLimit = 1;
  nameLimit = 1;
  teamMembersLimit = 20;
  tagsLimit = 50;
  private readonly subscriptions: Subscription = new Subscription();
  showCancellationDropDown$ = new BehaviorSubject<boolean>(false);
  title: string;
  icon: string;
  org: string;
  progCode: string;
  engId: string;
  errorMessage: string =
    'To save with "Completed" Engagement Status, update all mandatory fields, and try again.';

  width = '35%';
  @ViewChild('FailedSaveTemplate')
  failedSaveTemplate: TemplateRef<any>;

  mandatories = [
    ['engagementTitle', '- Engagement Title'],
    ['engagementType', '- Engagement Type'],
    ['engagementSponsor', '- Engagement Sponsor'],
    ['primaryContacts', '- Primary Point of Contact'],
    ['organizations', '- Organizations'],
    ['program', '- Program'],
    ['engagementPurpose', '- Engagement Purpose'],
    ['leadershipLevel', '- Leadership Level'],
  ];

  saveButtonToggle$ = this.addBookingService._saveButtonToggle$;
  initButtonToggle$ = new BehaviorSubject<boolean>(false);

  datesAndLocationsRoomsValues$: Observable<any> = this.generalTabFacade
    .datesAndLocationsRooms$;

  engagementTeamsRoles$: Observable<any> = this.generalTabFacade
    .engagementTeamsRoles$;

  modifyBy: any;
  updatedAt: any;

  @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
    event.returnValue = false;
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly workshopService: WorkshopService,
    private readonly addBookingService: AddBookingService,
    public readonly appLoadingService: AppLoadingService,
    private readonly router: Router,
    private readonly dateFormatService: DateFormatService,
    private readonly generalTabFacade: GeneralFacade,
    private readonly recoveryFacade: RecoveryFacade,
    private readonly modalService: ModalService,
    private readonly adminFacade: AdminFacade
  ) {
    this.workshopInfo = this.router.getCurrentNavigation()?.extras?.state?.workshop;
    this.refreshPage = this.router.getCurrentNavigation()?.id == 1;
    this.previousList = sessionStorage.getItem('listView');
    this.getProgramCode();
  }

  ngOnInit() {
    this.engagementStatusTooltipObj = tooltipConst.engagementStatusTooltipObj;
    this.getGeneralStartDate();
    this.getGeneralEndDate();
    if (this.refreshPage) {
      this.router.navigateByUrl('/admin/home');
    }
    this.bookingGroup = this.fb.group({
      status: [''],
      cancellationReason: [''],
      program: [''],
    });
    this.programList = this.addBookingService.programList;
    if (this.programList.length === 1) {
      this.bookingGroup.get('program').setValue({
        id: this.programList[0].id,
        value: this.programList[0].value,
      });
      const parity = this.bookingGroup.get('program').value.value;
      this.recoveryFacade.getExchangeRate({
        fromCurr:
          this.bookingGroup.get('program').value.value &&
          recoveryCurrency[String(parity)]
            ? recoveryCurrency[String(parity)]['currency']
            : 'USD',
        toCurr: this.toCy,
      });
      this.adminFacade.saveProgramId(this.programList[0].id);
      this.generalTabFacade.getEngagementTeamRoles(this.programList[0].id);
      this.generalTabFacade.loadDatesAndLocationsRooms(this.programList[0].id);
      sessionStorage.setItem('programId', this.programList[0].id);
      this.progCode = this.programList[0].value;
      this.adminFacade.saveProgramCode(this.progCode);
    } else {
      if (this.workshopInfo && this.workshopInfo.programId) {
        this.adminFacade.saveProgramId(this.workshopInfo.programId);
      } else {
        this.adminFacade.saveProgramId(null);
      }
      if (this.workshopInfo && this.workshopInfo.programCode) {
        this.adminFacade.saveProgramCode(this.workshopInfo.programCode);
      } else {
        this.progCode = null;
        this.adminFacade.saveProgramCode(null);
      }
    }
    this.handleProgramChanges();
    this.subscriptions.add(
      this.workshopService.getWorkshops$
        .pipe(
          map((response) => {
            if (response.length === 0) {
              throw new Error();
            }
            return response;
          }),
          retryWhen((errors) => errors.pipe(take(5), delay(1000)))
        )
        .subscribe(() => {
          this.workshopService.loadWorkshopStatus();
          this.workshopService.loadCancellationReasons();
          this.registerSubscriptions();
          this.getWorkshopHeaderInfo();
        })
    );
    this.prepopulateProgramFields();
  }

  registerSubscriptions(): void {
    this.getWorkshopById();
    this.handleStatusChanges();
    this.handleCancellationReasonChanges();
    this.prepopulateStatusFields();
    this.setLoading();
  }

  setLoading() {
    this.subscriptions.add(
      combineLatest([
        this.datesAndLocationsRoomsValues$,
        this.engagementTeamsRoles$,
      ]).subscribe({
        next: () => this.appLoadingService.loadingEnd(),
      })
    );
  }

  loadingEnd() {
    setTimeout(() => {
      this.appLoadingService.loadingEnd();
    }, 12000);
  }

  prepopulateStatusFields(): void {
    this.subscriptions.add(
      this.workshopService.selectedWorkshopGeneral$
        .pipe(filter(isNonNull))
        .subscribe((generalModel) => {
          this.addBookingService.prepopulateCancellationReason(
            this.bookingGroup,
            generalModel
          );
          this.addBookingService.prepopulateWorkshopStatus(
            this.bookingGroup,
            generalModel
          );
        })
    );
  }

  prepopulateProgramFields(): void {
    if (this.bookingGroup.get('program').value === '') {
      this.initButtonToggle$.next(true);
    } else {
      this.initButtonToggle$.next(false);
    }
    if (this.workshopInfo !== undefined) {
      this.bookingGroup.get('program').setValue({
        id: this.workshopInfo.programId,
        value: this.workshopInfo.programCode,
      });
      this.adminFacade.saveProgramId(this.workshopInfo.programId);
    }
  }

  getWorkshopById(): void {
    this.subscriptions.add(
      this.route.paramMap
        .pipe(switchMap((params: ParamMap) => of(params.get('id'))))
        .subscribe((id) => {
          this.workshopId = id;
          this.workshopService.setSelectedWorkshopID(id);
        })
    );
  }

  handleStatusChanges(): void {
    this.subscriptions.add(
      this.bookingGroup.get('status').valueChanges.subscribe((status) => {
        if (this.statusIsCancelledOrWithdrawn(status)) {
          this.showCancellationDropDown$.next(true);
          const cancellationDate = new Date().toISOString();
          const formattedCancellationDate = this.dateFormatService.getDateToSave(
            cancellationDate
          );
          this.addBookingService.cancellationDate$.next(
            formattedCancellationDate
          );
        } else {
          this.showCancellationDropDown$.next(false);
          this.addBookingService.cancellationDate$.next(null);
        }
        this.addBookingService.workshopStatus$.next(status);
      })
    );
  }

  handleCancellationReasonChanges(): void {
    this.subscriptions.add(
      this.bookingGroup
        .get('cancellationReason')
        .valueChanges.subscribe((cancellationReason) => {
          this.addBookingService.cancellationReason$.next(cancellationReason);
        })
    );
  }

  handleProgramChanges(): void {
    this.subscriptions.add(
      this.bookingGroup.get('program').valueChanges.subscribe((program) => {
        this.appLoadingService.loadingStart();
        this.initButtonToggle$.next(false);
        this.adminFacade.saveProgramId(program.id);
        this.adminFacade.saveProgramCode(program.value);
        this.generalTabFacade.getEngagementTeamRoles(program.id);
        this.generalTabFacade.loadDatesAndLocationsRooms(program.id);
        this.recoveryFacade.getExchangeRate({
          fromCurr:
            program && recoveryCurrency[program.value]
              ? recoveryCurrency[program.value]['currency']
              : 'USD',
          toCurr: this.toCy,
        });
        this.progCode = program.value;
        sessionStorage.setItem('programId', program.id);
        this.loadingEnd();
      })
    );
  }

  statusIsCancelledOrWithdrawn(status): boolean {
    return status.id === '4' || status.id === '5';
  }

  ngOnDestroy() {
    this.addBookingService.resetAllTabs();
    this.addBookingService.clearAllStateObjects();
    this.subscriptions.unsubscribe();
    this.addBookingService.disableSaveButton(false);
  }

  getGeneralStartDate() {
    this.subscriptions.add(
      this.generalTabFacade.engagementStartDateResults$.subscribe(
        (startDate) => {
          this.engStartDate = startDate;
        }
      )
    );
  }

  getGeneralEndDate() {
    this.subscriptions.add(
      this.generalTabFacade.engagementEndDateResults$.subscribe((endDate) => {
        this.engEndDate = endDate;
      })
    );
  }

  getProgramCode(): void {
    this.subscriptions.add(
      this.workshopService.programCode$.pipe(take(1)).subscribe((code) => {
        this.progCode = code;
      })
    );
  }

  createOrSaveWorkshop(): void {
    this.addBookingService.disableSaveButton(true);
    if (this.workshopId) {
      this.updateExisitingWorkshop();
    } else {
      this.createWorkshop();
    }
  }

  private createWorkshop(): void {
    const today = new Date().toISOString();
    if (
      (this.bookingGroup.get('status').value.value == 'Booked' ||
        this.bookingGroup.get('status').value.value == 'Completed' ||
        this.bookingGroup.get('status').value.value == 'Cancelled') &&
      !this.engStartDate
    ) {
      const formattedEngagementStartDate = this.dateFormatService.getDateToSave(
        today
      );
      this.addBookingService.engagementStartDate$.next(
        formattedEngagementStartDate
      );
    }
    if (
      (this.bookingGroup.get('status').value.value == 'Completed' ||
        this.bookingGroup.get('status').value.value == 'Cancelled') &&
      !this.engEndDate
    ) {
      const formattedEngagementEndDate = this.dateFormatService.getDateToSave(
        today
      );
      this.addBookingService.engagementEndDate$.next(
        formattedEngagementEndDate
      );
    }

    const workshop = this.addBookingService.createNewWorkshopRequest();
    const mandatoryFields = this.getMandatoryFields(workshop);
    if (
      this.bookingGroup.get('status').value.value != 'Completed' ||
      this.filledMandatoryFields(mandatoryFields)
    ) {
      this.workshopService.updateWorkshopById(workshop);
    } else {
      this.addBookingService.disableSaveButton(false);
      this.errorMessage = this.generateErrorMessage(mandatoryFields);
      this.openFailedRequestDialog();
    }
  }

  private updateExisitingWorkshop(): void {
    const today = new Date().toISOString();
    if (
      (this.bookingGroup.get('status').value.value == 'Booked' ||
        this.bookingGroup.get('status').value.value == 'Completed' ||
        this.bookingGroup.get('status').value.value == 'Cancelled') &&
      !this.engStartDate
    ) {
      const formattedEngagementStartDate = this.dateFormatService.getDateToSave(
        today
      );
      this.addBookingService.engagementStartDate$.next(
        formattedEngagementStartDate
      );
    }
    if (
      (this.bookingGroup.get('status').value.value == 'Completed' ||
        this.bookingGroup.get('status').value.value == 'Cancelled') &&
      !this.engEndDate
    ) {
      const formattedEngagementEndDate = this.dateFormatService.getDateToSave(
        today
      );
      this.addBookingService.engagementEndDate$.next(
        formattedEngagementEndDate
      );
    }

    const workshop = this.addBookingService.createRequest();
    const mandatoryFields = this.getMandatoryFields(workshop);
    if (
      this.bookingGroup.get('status').value.value != 'Completed' ||
      this.filledMandatoryFields(mandatoryFields)
    ) {
      this.workshopService.updateWorkshopById(workshop);
    } else {
      this.addBookingService.disableSaveButton(false);
      this.errorMessage = this.generateErrorMessage(mandatoryFields);
      this.openFailedRequestDialog();
    }
  }

  cancelUpdate(): void {
    this.router.navigateByUrl(this.previousList);
  }

  getWorkshopHeaderInfo(): void {
    var dbDate = _get(this.workshopInfo, 'updatedAt', '')
      ? _get(this.workshopInfo, 'updatedAt', '')
      : '';
    if (dbDate) {
      this.updatedAt = this.headerTimeFormat(dbDate);
    }
    this.modifyBy = _get(this.workshopInfo, 'modifyBy', '')
      ? _get(this.workshopInfo, 'modifyBy', '')
      : 'Unknown User';
    this.title = _get(this.workshopInfo, 'title', '');
    this.icon = 'arrow_back';
    this.org = this.setOrganizationsValues(this.workshopInfo?.org);
    this.engId = _get(this.workshopInfo, 'id', '');
  }

  headerTimeFormat(time) {
    var timeDate = new Date(time);
    var utcReduce6 = timeDate.setHours(timeDate.getHours() - 6);
    var ISOString = '';
    ISOString = new Date(utcReduce6)
      .toISOString()
      .substr(0, 16)
      .replace('T', ' ');
    return ISOString;
  }

  getWorkshopStatus() {
    const status = _get(this.workshopInfo, 'status', '');
    if (status === 'Cancelled' || status === 'Withdrawn') {
      this.showCancellationDropDown$.next(true);
    }
  }

  setOrganizationsValues(orgValue: string): string {
    if (orgValue && this.hasMultipleOrganizations(orgValue)) {
      return 'Multiple';
    } else {
      // this.showCancellationDropDown$.next(false);
      return orgValue;
    }
  }

  hasMultipleOrganizations(orgValue: string): boolean {
    return orgValue.includes('\n');
  }

  filledMandatoryFields(body): boolean {
    return this.mandatories.every((mandatory) => {
      const prop = _get(body, mandatory[0]);
      if (typeof prop !== 'number') {
        return !isEmpty(prop);
      } else if (typeof prop === 'number' && prop) {
        return true;
      }
    });
  }

  openFailedRequestDialog() {
    this.modalService.openDialog(
      { template: this.failedSaveTemplate },
      this.width
    );
  }

  closeFailedSubmissionDialog() {
    this.modalService.closeDialog();
  }

  generateErrorMessage(body) {
    const missingFields = [];
    this.mandatories.forEach((mandatory) => {
      const prop = _get(body, mandatory[0]);
      if (typeof prop !== 'number' && isEmpty(prop)) {
        missingFields.push(mandatory[1]);
      } else if (typeof prop === 'number' && !prop) {
        missingFields.push(mandatory[1]);
      }
    });
    return `
        <p class="modal__text mb-0"> To save with "Completed" Engagement Status, update all mandatory fields, and try again.</p>
        <p class='missingField mb-0'>${missingFields.join('\n')}</p>
        <p>To avoid losing your updates, set the Engagement Status to "Booked" and click "Save".</p>`;
  }

  getMandatoryFields(workshop) {
    const mandatoryFields = {
      program: workshop.workshop.program,
      engagementTitle: workshop.general.engagementTitle,
      engagementType: workshop.general.engagementType
        ? workshop.general.engagementType.value
        : '',
      engagementSponsor: workshop.general.engagementSponsors,
      organizations: workshop.general.organizations,
      primaryContacts: workshop.general.primaryContacts,
      leadershipLevel: workshop.consultation.leadershipLevel,
      engagementPurpose:
        workshop.consultation.selectedInternalPurpose ||
        workshop.consultation.internalPurpose,
    };
    return mandatoryFields;
  }
}
