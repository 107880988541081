<div class="error">
    <h1 class="error__title error__title__dark">{{errorDetails.mainTitle}}</h1>
    <h1 class="error__title">{{errorDetails.firstSubtitle}}</h1>
    <h1 class="error__title"  *ngIf="!isLogoutPage">{{errorDetails.secondSubtitle}}</h1>
    <div class="error__body">
      <p class="error__body__text">{{errorDetails.mainBodyText}}</p>
      <ng-container *ngIf="isLogoutPage">
        <p class="error__body__text">{{errorDetails.mainSecondaryBodyText}}</p>
        <p class="error__body__text">{{errorDetails.mainTertiaryBodyText}}
          <a class="error__body__text__link--btn" (click)="login()">{{errorDetails.link}}</a>.
        </p>
      </ng-container>
      <p class="error__body__text">
          {{errorDetails.secondaryBodyText}}
          <a
            class="error__body__text__link"
            href="https://circles.accenture.com/06a81558-73bc-4bd6-a0af-d4a188b758b0?tab=discussions"
            target="_blank">Engage Innovation Feedback Circle</a>.
      </p>
    </div>
</div>
