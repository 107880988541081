import { AbstractControl, ControlContainer } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ioh-dropdown',
  styleUrls: ['./dropdown.component.scss'],
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent implements OnInit {
  @Input() label: string;
  @Input() value: string;
  @Input() dropdownId: string;
  @Input() isMultiple: boolean;
  @Input() isDisabled: boolean;
  @Input() field: any;
  @Input() values: any[];
  @Input() required: boolean;
  @Input() placeholder: string;
  @Input() name: string;
  @Input() direction: string;
  @Input() tooltipObj: any;
  parentForm: AbstractControl;
  
  constructor(public controlContainer: ControlContainer) {}
  

  ngOnInit() {
    this.parentForm = this.controlContainer.control;
  }


  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

  public compareByValue(f1: any, f2: any) {
    if(f1 && f2 && f1.value && f2.value) {
      return f1 && f2 && (f1.value === f2.value);
    } else if(f1 && f2 && f1.value && !f2.value) {
      // if db response is just a string value or just an id
      return f1 && f2 && (f1.value === f2 || f1.id === f2);
    } 
  }
}
