import { AdminFacade, ReportService } from '@ioh/core-data';
import { Component } from '@angular/core';
import { ExcelExportService } from 'apps/ioh-app/src/app/services';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { menuItems } from '../../../assets/data/menu-details';

@Component({
  selector: 'ioh-menu',
  styleUrls: ['./menu.component.scss'],
  templateUrl: './menu.component.html',
})
export class MenuComponent {
  menuItems = menuItems;
  isShowHelpBox = false;
  showAdminMenu: any = [];
  private readonly subscriptions: Subscription = new Subscription();
  adminMenuRoute = '/admin/user-access';
  constructor(
    readonly router: Router,
    readonly adminFacade: AdminFacade,
    readonly reportService: ReportService,
    readonly exportService: ExcelExportService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.adminFacade.getAdminProgramList$.subscribe((adminProgramList) => {
        this.showAdminMenu = adminProgramList.length > 0;
      })
    );
  }

  navigateTo(routeValue) {
    if (
      !this.router.url.includes('/general') &&
      !this.router.url.includes('/consultation') &&
      !this.router.url.includes('/recovery')
    ) {
      sessionStorage.setItem('listView', this.router.url);
    }
    this.router.navigateByUrl(routeValue);
  }

  // TODO: review if it needs to go through store instead
  downloadReport() {
    // Get userId
    this.exportService.isDownloadReport = true;
    // Construct URL and get report buffer
    this.reportService
      .getExcelReport(sessionStorage.getItem('exportProgramList'))
      .subscribe((response: any) => {
        // Load and download report
        const loadBuffer = response.data.data;
        this.exportService.loadDownloadReport(loadBuffer, 'report.xlsx');
      });
  }

  toggleHellBox(): void {
    this.isShowHelpBox = !this.isShowHelpBox;
  }
}
