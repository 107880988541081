import { ConsultationState, IConsultationModelDb } from '@ioh/core-data';
import { DURATION } from '@utils/enums';
import { DateFormatService } from '@services';
import { DurationEntryService, LookUpEntryService } from '@ioh/ui-library';
import { FormArray, FormGroup } from '@angular/forms';
import { IPeriodModel } from '@ioh/types';
import { Injectable } from '@angular/core';
import _get from 'lodash/get';
import add from 'date-fns/add';
import isEmpty from 'lodash/isEmpty';

@Injectable({
  providedIn: 'root',
})
export class ConsultationTabService {
  constructor(
    private readonly lookupEntryService: LookUpEntryService,
    private readonly durationEntryService: DurationEntryService,
    private readonly dateFormatService: DateFormatService
  ) {}

  consultationGroup: FormGroup;
  workshop: any;

  get consultationOwnerArray(): FormArray {
    return this.consultationGroup
      .get('consultationOwner')
      .get('consultationOwnerResult') as FormArray;
  }

  get mmsOpportunityIds(): FormArray {
    return this.consultationGroup
      .get('opportunityInput')
      .get('oppResult') as FormArray;
  }

  get dates(): FormArray {
    return this.consultationGroup.get('dates') as FormArray;
  }

  public prepopulateConsultationTabWithDBResponse(
    workshop: IConsultationModelDb,
    formGroup: FormGroup
  ): void {
    this.consultationGroup = formGroup;
    this.prepopulateConsultationSummary({
      duration: workshop,
      dates: workshop.requestedDates,
      waitlistDates: workshop.waitlistDates,
      softlock: workshop.softLockExpirationDate
        ? workshop.softLockExpirationDate
        : this.formatSoftlockDate(workshop.createdAt),
      consultationOwner: workshop.consultationOwner,
      completeConsulationDate: workshop.completeDate,
    });
    this.prepopulateConsultationDetails({
      backgroundText: workshop.backgroundText,
      objectivesText: workshop.objectivesText,
      successCriteria: workshop.successCriteria,
      challengesText: workshop.challengesText,
      additionalComments: workshop.additionalComments,
      internalPurpose: workshop.internalPurpose,
      opportunities: workshop.opportunities,
    });
    this.prepopulateAttendeeDetails({
      accentureAttendees: workshop.accentureAttendees,
      clientCSuiteAttendees: workshop.clientCSuiteAttendees,
      clientOtherAttendees: workshop.clientOtherAttendees,
      otherAttendees: workshop.otherAttendees,
      totalAttendees: workshop.totalAttendees,
      leadershipLevel: workshop.leadershipLevel,
      audienceType: workshop.audienceType,
      traveling: workshop.traveling,
      attendeeDetails: workshop.attendeeDetails,
    });
  }

  public prepopulateConsultationTabWithStateObject(
    stateObject: ConsultationState,
    formGroup: FormGroup
  ): void {
    this.consultationGroup = formGroup;
    this.prepopulateConsultationSummary({
      dates: stateObject.dates,
      duration: stateObject.duration,
      softlock: stateObject.softLockExpirationDate,
      consultationOwner: stateObject.consultationOwner,
      completeConsulationDate: stateObject.completeConsultationDate,
    });
    this.prepopulateConsultationDetails({
      backgroundText: stateObject.backgroundText,
      objectivesText: stateObject.objectivesText,
      successCriteria: stateObject.successText,
      challengesText: stateObject.challengesText,
      additionalComments: stateObject.additionalComments,
      internalPurpose: stateObject.selectedInternalPurpose,
      opportunities: stateObject.selectedOpportunityIds,
    });
    this.prepopulateAttendeeDetails({
      accentureAttendees: stateObject.accentureAttendees,
      clientCSuiteAttendees: stateObject.clientCSuiteAttendees,
      clientOtherAttendees: stateObject.clientOtherAttendees,
      otherAttendees: stateObject.otherAttendees,
      totalAttendees: stateObject.totalAttendees,
      leadershipLevel: stateObject.leadershipLevel,
      audienceType: stateObject.audienceType,
      traveling: stateObject.traveling,
      attendeeDetails: stateObject.attendeeDetails,
    });
  }

  private formatSoftlockDate(value) {
    const workshopCreationDate = new Date(value);
    const weekFromTheDate: Date = add(workshopCreationDate, { weeks: 1 });
    return weekFromTheDate.toISOString();
  }

  private prepopulateConsultationSummary({
    duration,
    dates,
    waitlistDates,
    softlock,
    consultationOwner,
    completeConsulationDate,
  }: {
    duration: IConsultationModelDb | string;
    dates: any[];
    waitlistDates?: any[];
    softlock: string;
    consultationOwner: any;
    completeConsulationDate: string;
  }): void {
    this.prepopulateDuration(duration);
    this.prepopulateDates(dates);
    this.prepopulateWaitlistDates(waitlistDates);
    this.prepopulateSoftlockExpirationDate(softlock);
    this.prepopulateConsultationOwner(consultationOwner);
    this.prepopulateCompleteConsultationDate(completeConsulationDate);
  }

  private prepopulateDates(dates: IPeriodModel[]) {
    if (!isEmpty(dates)) {
      if (!this.dates.valid) {
        this.clearDatesFormArray();
      }
      dates.forEach((element) => {
        let clone = { ...element };
        // handle both the db resp and the state object
        const duration = _get(
          element,
          'duration',
          this.consultationGroup.get(DURATION).value
        );

        clone = this.normalizeDates(element, clone);

        this.dates.push(this.durationEntryService.createDate(duration, clone));
      });
    }
  }

  /**
   * Returns a date object with UTC normalized date, startDate and endDate
   * @param element
   * @param clone
   */
  normalizeDates(element, clone) {
    if (element.date) {
      const newDate = this.dateFormatService.getDateToPrepopulate(element.date);
      clone.date = newDate;
    }
    if (element.startDate) {
      const newStartDate = this.dateFormatService.getDateToPrepopulate(
        element.startDate
      );
      clone.startDate = newStartDate;
    }
    if (element.endDate) {
      const newEndDate = this.dateFormatService.getDateToPrepopulate(
        element.endDate
      );
      clone.endDate = newEndDate;
    }
    return clone;
  }

  private prepopulateWaitlistDates(waitlistDates) {
    if (!isEmpty(waitlistDates)) {
      /* istanbul ignore next */
      if (!this.dates.valid) {
        this.clearDatesFormArray();
      }
      waitlistDates.forEach((element) => {
        let clone = { ...element, waitlistCheckbox: true };
        // handle both the db resp and the state object
        const duration = _get(
          element,
          'duration',
          this.consultationGroup.get(DURATION).value
        );

        clone = this.normalizeDates(element, clone);
        this.dates.push(this.durationEntryService.createDate(duration, clone));
      });
    }
  }

  clearDatesFormArray(): void {
    // this is a hack so that the duration component can handle both SRF and back office - prepopulation and not
    // duration component listens for changes on 'duration' form control rather than the radio selected event
    // so it will create an empty element for the duration type
    // we want to remove it here so we won't have an unnecessary empty array element
    this.dates.removeAt(0);
  }

  /**
   * Should handle prepopulation from both the DBModel and the FE state object.
   * @param duration
   */
  private prepopulateDuration(duration: IConsultationModelDb | string): void {
    const mappedDuration =
      _get(duration, 'requestedDates[0].duration') ||
      _get(duration, 'waitlistDates[0].duration') ||
      duration;
    this.prepopulateStringDuration(mappedDuration);
  }

  private prepopulateStringDuration(duration: string) {
    if (!isEmpty(duration)) {
      this.consultationGroup.get('duration').setValue(duration);
    }
  }

  private prepopulateSoftlockExpirationDate(value): void {
    if (value) {
      const newValue = this.dateFormatService.getDateToPrepopulate(value);
      this.consultationGroup.get('softlockExpirationDate').setValue(newValue);
    }
  }

  private prepopulateConsultationOwner(value): void {
    if (!isEmpty(value)) {
      if (Array.isArray(value)) {
        value.forEach((element) => {
          this.consultationOwnerArray.push(
            this.lookupEntryService.createLookUpEntry(element)
          );
        });
      } else {
        this.consultationOwnerArray.push(
          this.lookupEntryService.createLookUpEntry(value)
        );
      }
    }
  }

  private prepopulateCompleteConsultationDate(value): void {
    if (value) {
      const newValue = this.dateFormatService.getDateToPrepopulate(value);
      this.consultationGroup.get('completeConsultationDate').setValue(newValue);
    }
  }

  private prepopulateConsultationDetails({
    backgroundText,
    objectivesText,
    successCriteria,
    challengesText,
    additionalComments,
    internalPurpose,
    opportunities,
  }): void {
    this.prepopulateBackgroundText(backgroundText);
    this.prepopulateObjectivesText(objectivesText);
    this.prepopulateSuccessCriteria(successCriteria);
    this.prepopulateChallengesText(challengesText);
    this.prepopulateAdditionalComments(additionalComments);
    this.prepopulateInternalPurpose(internalPurpose);
    this.prepopulateMMSOpportunityIds(opportunities);
  }

  private prepopulateBackgroundText(value: string): void {
    if (!isEmpty(value)) {
      this.consultationGroup.get('backgroundText').setValue(value);
    }
  }

  private prepopulateObjectivesText(value: string): void {
    if (!isEmpty(value)) {
      this.consultationGroup.get('objectivesText').setValue(value);
    }
  }

  private prepopulateSuccessCriteria(value: string): void {
    if (!isEmpty(value)) {
      this.consultationGroup.get('successCriteria').setValue(value);
    }
  }

  private prepopulateChallengesText(value: string): void {
    if (!isEmpty(value)) {
      this.consultationGroup.get('challengesText').setValue(value);
    }
  }

  private prepopulateAdditionalComments(value: string): void {
    if (!isEmpty(value)) {
      this.consultationGroup.get('additionalComments').setValue(value);
    }
  }

  private prepopulateInternalPurpose(value): void {
    if (!isEmpty(value)) {
      this.consultationGroup.get('purposeOptions').setValue({
        id: value.id,
        value: value.value,
      });
    }
  }

  private prepopulateMMSOpportunityIds(value): void {
    if (!isEmpty(value)) {
      value.forEach((opportunity) => {
        if (!opportunity.chip) {
          opportunity = {
            ...opportunity,
            chip: `${opportunity.id}-${opportunity.description}`,
          };
        }
        this.mmsOpportunityIds.push(
          this.lookupEntryService.createOpportunityLookupEntry(opportunity)
        );
      });
    }
  }

  private prepopulateAttendeeDetails({
    accentureAttendees,
    clientCSuiteAttendees,
    clientOtherAttendees,
    otherAttendees,
    totalAttendees,
    leadershipLevel,
    audienceType,
    traveling,
    attendeeDetails,
  }): void {
    this.prepopulateAccentureAttendees(accentureAttendees);
    this.prepopulateClientCSuiteAttendees(clientCSuiteAttendees);
    this.prepopulateClientOtherAttendees(clientOtherAttendees);
    this.prepopulateOtherAttendees(otherAttendees);
    this.prepopulateTotalAttendees(totalAttendees);
    this.prepopulateLeadershipLevel(leadershipLevel);
    this.prepopulateAudienceType(audienceType);
    this.prepopulateTraveling(traveling);
    this.prepopulateAttendeeDetailsComments(attendeeDetails);
  }

  private prepopulateAccentureAttendees(value): void {
    if (value != undefined) {
      this.consultationGroup
        .get('attendeeNumber')
        .get('accentureAttendees')
        .setValue(value);
    }
  }

  private prepopulateClientCSuiteAttendees(value): void {
    if (value != undefined) {
      this.consultationGroup
        .get('attendeeNumber')
        .get('clientCSuiteAttendees')
        .setValue(value);
    }
  }

  private prepopulateClientOtherAttendees(value): void {
    if (value != undefined) {
      this.consultationGroup
        .get('attendeeNumber')
        .get('clientOtherAttendees')
        .setValue(value);
    }
  }

  private prepopulateOtherAttendees(value): void {
    if (value != undefined) {
      this.consultationGroup
        .get('attendeeNumber')
        .get('otherAttendees')
        .setValue(value);
    }
  }

  private prepopulateTotalAttendees(value): void {
    if (value != undefined) {
      this.consultationGroup
        .get('attendeeNumber')
        .get('totalAttendees')
        .setValue(value);
    }
  }

  private prepopulateLeadershipLevel(value): void {
    if (!isEmpty(value)) {
      this.consultationGroup.get('leadershipLevel').setValue(value);
    }
  }

  private prepopulateAudienceType(value): void {
    if (!isEmpty(value)) {
      this.consultationGroup.get('audienceType').setValue(value);
    }
  }

  private prepopulateTraveling(value: boolean | string): void {
    if (value != undefined && typeof value == 'boolean') {
      this.consultationGroup.get('traveling').setValue(value ? 'Yes' : 'No');
    } else if (value !== undefined && typeof value == 'string') {
      this.consultationGroup.get('traveling').setValue(value);
    }
  }

  private prepopulateAttendeeDetailsComments(value: string): void {
    if (!isEmpty(value)) {
      this.consultationGroup.get('attendeeDetails').setValue(value);
    }
  }
}
