<div>
  <ioh-select-hub></ioh-select-hub>
  <ioh-banner title="In Progress"></ioh-banner>
  <ioh-card>
    <ioh-list-view
      tableName="Past Due"
      urgentTableName=true
      [colNames]="colNames"
      [data$]="expiredSoftlockData$"
    ></ioh-list-view>
    <ioh-list-view
      tableName="Ongoing"
      [colNames]="colNames"
      [data$]="ongoingRequestsData$"
    ></ioh-list-view>
    <ioh-list-view
      tableName="Next 7 days"
      [colNames]="colNames"
      [data$]="upcomingRequestsData$"
    ></ioh-list-view>
    <ioh-list-view
      tableName="More than 7 days"
      [colNames]="colNames"
      [data$]="futureRequestsData$"
    ></ioh-list-view>
  </ioh-card>
</div>
