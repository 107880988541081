import { ICustomer, IOrgType, IOrganizationCategory } from '@ioh/types';
import { createAction, props } from '@ngrx/store';

export const getOrganizationCategoriesResults = createAction(
  '[AttendeeDetails] Load OrganizationCategoriesResults'
);

export const getOrganizationCategoriesResultsSuccess = createAction(
  '[AttendeeDetails] Load OrganizationCategoriesResults Success',
  props<{ categoriesResults: IOrganizationCategory[] }>()
);

export const getOrganizationCategoriesResultsFailure = createAction(
  '[AttendeeDetails] Load OrganizationCategoriesResults Failure',
  props<{ error: any }>()
);

export const getOperatingGroupResults = createAction(
  '[AttendeeDetails] Load OperatingGroupResults'
);

export const getOperatingGroupResultsSuccess = createAction(
  '[AttendeeDetails] Load OperatingGroupResults Success',
  props<{ operatingGroupResults: any }>()
);

export const getOperatingGroupResultsFailure = createAction(
  '[AttendeeDetails] Load OperatingGroupResults Failure',
  props<{ error: any }>()
);

export const getAccentureEntities = createAction(
  '[AttendeeDetails] Load AccentureEntities'
);

export const getAccentureEntitiesSuccess = createAction(
  '[AttendeeDetails] Load AccentureEntities Success',
  props<{ accentureEntitiesResults: any }>()
);

export const getAccentureEntitiesFailure = createAction(
  '[AttendeeDetails] Load AccentureEntities Failure',
  props<{ error: any }>()
);

export const getOrganizationTypes = createAction(
  '[AttendeeDetails] Load OrganizationTypes'
);

export const getOrganizationTypesSuccess = createAction(
  '[AttendeeDetails] Load OrganizationTypes Success',
  props<{ organizationTypesResults: IOrgType[] }>()
);

export const getOrganizationTypesFailure = createAction(
  '[AttendeeDetails] Load OrganizationTypes Failure',
  props<{ error: any }>()
);

export const updateOrganizations = createAction(
  '[AttendeeDetails] Update Organizations',
  props<{ organizations: any }>()
);

export const getClientOrganizationSearchResults = createAction(
  '[AttendeeDetails] Load ClientOrganizationSearchResults',
  props<{ searchQuery: string }>()
);

export const getClientOrganizationSearchResultsSuccess = createAction(
  '[AttendeeDetails] Load ClientOrganizationSearchResults Success',
  props<{ clientOrganizationSearchResults: ICustomer[] }>()
);

export const getClientOrganizationSearchResultsFailure = createAction(
  '[AttendeeDetails] Load ClientOrganizationSearchResults Failure',
  props<{ error: any }>()
);

export const updateAttendeeNumber = createAction(
  '[AttendeeDetails] Update AttendeeNumber',
  props<{ attendeeNumer: any }>()
);

export const updateAccentureAttendees = createAction(
  '[AttendeeDetails] Update AccentureAttendees',
  props<{ accentureAttendees: number }>()
);

export const updateClientCSuiteAttendees = createAction(
  '[AttendeeDetails] Update ClinetCSuiteAttendees',
  props<{ clientCSuiteAttendees: number }>()
);

export const updateClientOtherAttendees = createAction(
  '[AttendeeDetails] Update ClientOtherAttendees',
  props<{ clientOtherAttendees: number }>()
);

export const updateOtherAttendees = createAction(
  '[AttendeeDetails] Update OtherAttendees',
  props<{ otherAttendees: number }>()
);

export const updateTotalAttendees = createAction(
  '[AttendeeDetails] Update TotalAttendees',
  props<{ totalAttendees: number }>()
);

export const updateSelectedLeadershipLevel = createAction(
  '[AttendeeDetails] Update SelectedLeadershipLevel',
  props<{ leadershipLevel: string }>()
);

export const updateSelectedAudienceType = createAction(
  '[AttendeeDetails] Update SelectedAudienceType',
  props<{ audienceType: string }>()
);

export const updateSelectedTraveling = createAction(
  '[AttendeeDetails] Update SelectedTraveling',
  props<{ traveling: string }>()
);

export const clearStateObject = createAction(
  '[AttendeeDetails] Clear state object'
);
