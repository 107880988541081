import * as AdminActions from './admin.actions';
import * as AdminSelectors from './admin.selectors';
import * as fromAdmin from './admin.reducer';
import { Action, Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { filter, first } from 'rxjs/operators';

@Injectable()
export class AdminFacade {
  allAdmin$ = this.store.pipe(select(AdminSelectors.getAdminState));
  getUserId$ = this.store.pipe(select(AdminSelectors.getUserId));
  getProgramList$ = this.store.pipe(select(AdminSelectors.getProgramList));
  getAdminProgramList$ = this.store.pipe(
    select(AdminSelectors.getAdminProgramList)
  );

  getUserInfoList$ = this.store.pipe(select(AdminSelectors.getUserInfoList));
  getProgramId$ = this.store.pipe(select(AdminSelectors.getProgramId));
  allUserNameSearchResults$ = this.store.pipe(
    select(AdminSelectors.getUserNameSearchResults),
    filter((result) => Boolean(result))
  );

  getProgramCode$ = this.store.pipe(select(AdminSelectors.getProgramCode));
  response$ = this.store.pipe(select(AdminSelectors.getResponse));
  allWorkshopEntities$ = this.store.pipe(
    select(AdminSelectors.getWorkshopEntities)
  );

  getWorkshops$ = this.store.pipe(select(AdminSelectors.getWorkshops));
  selectedWorkshop$ = this.store.pipe(select(AdminSelectors.getSelected));
  selectedHub$ = this.store.pipe(select(AdminSelectors.getSelectedHub));
  selectedAdminHub$ = this.store.pipe(
    select(AdminSelectors.getSelectedAdminHub)
  );

  selectedWorkshopGeneral$ = this.store.pipe(
    select(AdminSelectors.getSelectedGeneral)
  );

  selectedWorkshopConsultation$ = this.store.pipe(
    select(AdminSelectors.getSelectedConsultation)
  );

  selectedWorkshopRecovery$ = this.store.pipe(
    select(AdminSelectors.getSelectedRecovery)
  );

  selectedWorkshopId$ = this.store.pipe(select(AdminSelectors.getSelectedId));

  selectedWorkshopUpdatedSuccess$ = this.store.pipe(
    select(AdminSelectors.updatedSuccessfully)
  );

  selectedAdminUpdatedSuccess$ = this.store.pipe(
    select(AdminSelectors.updatedAdminSuccessfully)
  );

  saveWorkshopError$ = this.store.pipe(
    select(AdminSelectors.saveWorkshopError)
  );

  removeAccessSuccessfully$ = this.store.pipe(
    select(AdminSelectors.removeAccessSuccessfully)
  );

  removeUserAccessError$ = this.store.pipe(
    select(AdminSelectors.removeUserAccessError)
  );

  saveAdminError$ = this.store.pipe(select(AdminSelectors.saveAdminError));

  workshopStatus$ = this.store.pipe(select(AdminSelectors.getWorkshopStatus));

  cancellationReasons$ = this.store.pipe(
    select(AdminSelectors.getCancellationReasons)
  );

  constructor(private readonly store: Store<fromAdmin.AdminPartialState>) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }

  updateSuccess(value) {
    this.dispatch(AdminActions.updateSuccess({ value }));
  }

  updateAdminSuccess(value) {
    this.dispatch(AdminActions.updateAdminSuccess({ value }));
  }

  setSelectedHub(selectedHub: string) {
    this.dispatch(AdminActions.setSelectedHub({ selectedHub }));
  }

  setAdminSelectedHub(selectedAdminHub: string) {
    this.dispatch(AdminActions.setAdminSelectedHub({ selectedAdminHub }));
  }

  saveProgramId(programId: string) {
    this.dispatch(AdminActions.saveProgramId({ programId }));
  }

  saveProgramList(programList: any[]) {
    this.dispatch(AdminActions.saveProgramList({ programList }));
  }

  saveAdminProgramList(adminProgramList: any[]) {
    this.dispatch(AdminActions.saveAdminProgramList({ adminProgramList }));
  }

  saveUserAccessList(userInfoList: any[]) {
    this.dispatch(AdminActions.saveUserAccessList({ userInfoList }));
  }

  saveUserId(userId: string) {
    this.dispatch(AdminActions.saveUserId({ userId }));
  }

  saveProgramCode(programCode: string) {
    this.dispatch(AdminActions.saveProgramCode({ programCode }));
  }

  loadAllWorkshopsByUserId(userId?: string) {
    if (userId) {
      this.dispatch(AdminActions.loadAllWorkshopsByUserId({ userId }));
    } else {
      this.getUserId$.pipe(first()).subscribe((val) => {
        this.dispatch(AdminActions.loadAllWorkshopsByUserId({ userId: val }));
      });
    }
  }

  setSelectedWorkshopId(workshopId: string) {
    this.dispatch(AdminActions.setSelectedWorkshopId({ id: workshopId }));
  }

  updateWorkshopById(workshop) {
    this.dispatch(AdminActions.updateWorkshopById({ workshop }));
  }

  getWorkshopStatus() {
    this.dispatch(AdminActions.getWorkshopStatus());
  }

  getProgramById(programId: string) {
    this.dispatch(AdminActions.getProgramById({ programId }));
  }

  getCancellationReasons() {
    this.dispatch(AdminActions.getCancellationReason());
  }

  removeUserAccess(userAccess) {
    this.dispatch(AdminActions.removeUserAccess({ userAccess }));
  }

  removeAccessSuccess(value) {
    this.dispatch(AdminActions.removeAccessSuccess({ value }));
  }

  loadAccessUserListByUserId(userId: string) {
    this.dispatch(AdminActions.loadAccessUserListByUserId({ userId }));
  }

  loadUserNameSearchResults(searchQuery) {
    this.dispatch(AdminActions.loadUserNameSearchResults({ searchQuery }));
  }

  updateUserName(value) {
    this.dispatch(AdminActions.updateUserName({ userName: value }));
  }

  updateAdminProgram(adminProgram) {
    this.dispatch(AdminActions.updateAdminProgram({ adminProgram }));
  }

  updateAccessLevel(accessLevel) {
    this.dispatch(AdminActions.updateAccessLevel({ accessLevel }));
  }

  updateAdminById(admin) {
    this.dispatch(AdminActions.updateAdminById({ admin }));
  }
}
