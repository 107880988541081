import { AddBookingComponent } from './add-booking/add-booking.component';
import { AdminComponent } from './admin/admin.component';
import { BackOfficeModule } from '../back-office.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ClosedComponent } from './closed/closed.component';
import { CommonModule } from '@angular/common';
import { ConsultationTabComponent } from './add-booking/consultation-tab/consultation-tab.component';
import { GeneralTabComponent } from './add-booking/general-tab/general-tab.component';
import { HomeComponent } from './home/home.component';
import { InProgressComponent } from './in-progress/in-progress.component';
import { PendingComponent } from './pending/pending.component';
import { RecoveryTabComponent } from './add-booking/recovery-tab/recovery-tab.component';
import { RequestedComponent } from './requested/requested.component';
import { RouterModule } from '@angular/router';
import { SettingsComponent } from './settings/settings.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [
    AddBookingComponent,
    ClosedComponent,
    AdminComponent,
    HomeComponent,
    InProgressComponent,
    PendingComponent,
    RequestedComponent,
    SettingsComponent,
    ConsultationTabComponent,
    GeneralTabComponent,
    RecoveryTabComponent,
  ],
  exports: [
    AddBookingComponent,
    ClosedComponent,
    AdminComponent,
    HomeComponent,
    InProgressComponent,
    PendingComponent,
    RequestedComponent,
    SettingsComponent,
  ],
  imports: [CommonModule, RouterModule, BackOfficeModule, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SectionsModule {}
