<div>
  <ioh-banner [title]="title" [progCode]="progCode" [previousList]="previousList" [engId]="engId" [icon]="icon"
    [org]="org" [showButtons]="true" (onSave)="createOrSaveWorkshop()" (onCancel)="cancelUpdate()"
    [saveButtonToggle$]="saveButtonToggle$" [initButtonToggle$]="initButtonToggle$"></ioh-banner>
  <div *ngIf="this.engId" class="lastModifier">Last Modified by {{modifyBy}} on {{updatedAt}} (UTC-6)</div>
  <ioh-card>
    <div fxLayoutAlign="space-between center" fxLayout.md="column" fxLayout.sm="column" fxLayout.xs="column">
      <nav mat-tab-nav-bar>
        <a mat-tab-link *ngFor="let link of navLinks; let i = index" [routerLink]="link.path" routerLinkActive
          #rla="routerLinkActive" [active]="rla.isActive">
          {{link.label}}
        </a>
      </nav>
      <div fxLayout="row" fxLayoutGap="1rem" fxLayout.sm="column" fxLayout.xs="column">
        <ioh-dropdown fxFlex.sm="100%" fxFlex.xs="100%" *ngIf="programList.length !== 1"
          class="booking__dropdown booking__dropdownProgram" label="{{programDropdown.label}}"
          dropdownId="{{programDropdown.dropdownId}}" [formGroup]="bookingGroup"
          isMultiple="{{programDropdown.isMultiple}}" [values]="programList" field="program"
          required="{{programDropdown.required}}" placeholder="{{programDropdown.placeholder}}"
          name="{{programDropdown.name}}" direction="column"></ioh-dropdown>
        <ioh-dropdown fxFlex.sm="100%" fxFlex.xs="100%" class="booking__dropdown booking__dropdownStatus"
          label="{{statusDropdown.label}}" dropdownId="{{statusDropdown.dropdownId}}" [formGroup]="bookingGroup"
          isMultiple="{{statusDropdown.isMultiple}}" [values]="workshopStatusValues$| async" field="status"
          required="{{statusDropdown.required}}" placeholder="{{statusDropdown.placeholder}}"
          name="{{statusDropdown.name}}" direction="column"  [tooltipObj] = "engagementStatusTooltipObj">
        </ioh-dropdown>
      </div>
    </div>
    <div fxLayoutAlign="space-between" fxLayout.md="column" fxLayout.sm="column" fxLayoutAlign="end stretch"
      class='lineHeight' fxLayout.xs="column">
      <div fxLayout="row" fxLayoutGap="1rem" fxLayout.sm="column" fxLayout.xs="column">
        <ioh-dropdown fxFlex="100%" fxFlex.sm="100%" fxFlex.xs="100%" *ngIf="showCancellationDropDown$ | async"
          class="booking__dropdown booking__dropdown__cancelled" ngClass.sm="booking__dropdown__small__device"
          ngClass.xs="booking__dropdown__small__device" label="{{cancellationDropdownSettings.label}}"
          dropdownId="{{cancellationDropdownSettings.dropdownId}}" [formGroup]="bookingGroup"
          isMultiple="{{cancellationDropdownSettings.isMultiple}}" [values]="cancellationReasonValues$ | async"
          field="cancellationReason" required="{{cancellationDropdownSettings.required}}"
          placeholder="{{cancellationDropdownSettings.placeholder}}" name="{{cancellationDropdownSettings.name}}"
          direction="column"></ioh-dropdown>
      </div>
    </div>
    <router-outlet style="margin-top: -50px;"></router-outlet>
  </ioh-card>
</div>

<ng-template #FailedSaveTemplate class="print__area">
  <div fxLayoutAlign="center center" class="modal__icon__container">
    <mat-icon class="material-icons modal__icon" aria-label="warning icon">report_problem</mat-icon>
  </div>
  <h2 class="modal__title" role="banner" mat-dialog-title>Oops! Your changes cannot be saved. </h2>
  <mat-dialog-content role="contentinfo" class="mat-typography" [innerHTML]="errorMessage"
    style="white-space: pre-line;">
    {{errorMessage}}
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" role="navigation">
    <button class="modal__accept__button" mat-flat-button (click)="closeFailedSubmissionDialog()" color="primary">Got
      it!</button>
  </mat-dialog-actions>
</ng-template>