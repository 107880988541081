import { AdminFacade } from '@ioh/core-data';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IEngagement } from '@utils/interfaces';
import { TableService } from '../../../services/table.service';
import { WorkshopService } from '../../../services/workshop.service';
import { checkStatusIs, transformDates } from '@utils/tablesUtils';
import { inProgressColNames } from '@assets/data/tables/table-columns';
import add from 'date-fns/add';
import isAfter from 'date-fns/isAfter';
import isEmpty from 'lodash/isEmpty';
import isFuture from 'date-fns/isFuture';
import isPast from 'date-fns/isPast';
import isWithinRange from 'date-fns/is_within_range';
import startOfToday from 'date-fns/startOfToday';

@Component({
  selector: 'ioh-in-progress',
  styleUrls: ['./in-progress.component.scss'],
  templateUrl: './in-progress.component.html',
})
export class InProgressComponent implements OnInit, OnDestroy {
  data = [];
  colNames = inProgressColNames;
  selectHub = '';
  expiredSoftlockData$: BehaviorSubject<any> = new BehaviorSubject(null);
  upcomingRequestsData$: BehaviorSubject<any> = new BehaviorSubject(null);
  futureRequestsData$: BehaviorSubject<any> = new BehaviorSubject(null);
  ongoingRequestsData$: BehaviorSubject<any> = new BehaviorSubject(null);
  today: Date = startOfToday();
  weekFromToday: Date = add(this.today, { weeks: 1 });
  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly tableService: TableService,
    private readonly workshopService: WorkshopService,
    private readonly adminFacade: AdminFacade
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.workshopService.getWorkshops$.subscribe((workshops) => {
        if (!isEmpty(workshops)) {
          this.handleFormatting(workshops);
        }
      })
    );
    this.subscriptions.add(
      this.adminFacade.selectedHub$.subscribe((selectedHub) => {
        this.selectHub = selectedHub;
        this.updateAllData();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleFormatting(workshops: any[]): void {
    if (typeof Worker != 'undefined') {
      const worker: Worker = new Worker(
        '../../../services/data-formatter.worker',
        {
          type: 'module',
        }
      );
      worker.onmessage = ({ data }) => {
        this.data = this.tableService.sortTableDataByDate(data);
        this.updateAllData();
      };
      worker.postMessage(workshops);
    } else {
      const unorderedData = this.tableService.flattenAndFormatWorkshops(
        workshops
      );
      this.data = this.tableService.sortTableDataByDate(unorderedData);
      this.updateAllData();
    }
  }

  updateAllData() {
    if (this.data.length == 0) {
      return true;
    }
    this.expiredSoftlockData$.next(
      this.data
        .filter(this.expiredSoftlockDataFilter.bind(this))
        .map(transformDates.bind(this))
    );

    this.upcomingRequestsData$.next(
      this.data
        .filter(this.upcomingRequestsDataFilter.bind(this))
        .map(transformDates.bind(this))
    );

    this.futureRequestsData$.next(
      this.data
        .filter(this.futureRequestsDataFilter.bind(this))
        .map(transformDates.bind(this))
    );

    this.ongoingRequestsData$.next(
      this.data
        .filter(this.ongoingRequestsDataFilter.bind(this))
        .map(transformDates.bind(this))
    );
  }

  expiredSoftlockDataFilter(value: IEngagement): boolean {
    const endDate = new Date(value.end);
    const pastExpire = isPast(endDate);
    return (
      checkStatusIs(value, 'Booked') &&
      pastExpire &&
      (!this.selectHub || this.selectHub.includes(value.programCode))
    );
  }

  upcomingRequestsDataFilter(value: IEngagement): boolean {
    const startDate = new Date(value.start);

    const startIsWeekFromToday = isWithinRange(
      startDate,
      this.today,
      this.weekFromToday
    );
    return (
      checkStatusIs(value, 'Booked') &&
      startIsWeekFromToday &&
      (!this.selectHub || this.selectHub.includes(value.programCode))
    );
  }

  futureRequestsDataFilter(value: IEngagement): boolean {
    const startDate = new Date(value.start);
    const afterWeekFromToday = isAfter(startDate, this.weekFromToday);
    return (
      checkStatusIs(value, 'Booked') &&
      afterWeekFromToday &&
      (!this.selectHub || this.selectHub.includes(value.programCode))
    );
  }

  ongoingRequestsDataFilter(value: IEngagement): boolean {
    const startInPast = isPast(new Date(value.start));
    const endInFuture = isFuture(new Date(value.end));
    return (
      checkStatusIs(value, 'Booked') &&
      startInPast &&
      endInFuture &&
      (!this.selectHub || this.selectHub.includes(value.programCode))
    );
  }
}
