import {
  ADMIN_FEATURE_KEY,
  AdminPartialState,
  AdminState,
  adminAdapter,
} from './admin.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

// Lookup the 'Admin' feature state managed by NgRx
export const getAdminState = createFeatureSelector<
  AdminPartialState,
  AdminState
>(ADMIN_FEATURE_KEY);

const { selectAll, selectEntities } = adminAdapter.getSelectors();

export const getUserId = createSelector(
  getAdminState,
  (state: AdminState) => state.userId
);

export const getProgramId = createSelector(
  getAdminState,
  (state: AdminState) => state.programId
);

export const getProgramList = createSelector(
  getAdminState,
  (state: AdminState) => state.programList
);

export const getAdminProgramList = createSelector(
  getAdminState,
  (state: AdminState) => state.adminProgramList
);

export const getUserInfoList = createSelector(
  getAdminState,
  (state: AdminState) => state.userInfoList
);
export const getProgramCode = createSelector(
  getAdminState,
  (state: AdminState) => state.programCode
);

export const getResponse = createSelector(
  getAdminState,
  (state: AdminState) => state.response
);

export const getWorkshopEntities = createSelector(
  getAdminState,
  (state: AdminState) => selectEntities(state)
);

export const getWorkshops = createSelector(getAdminState, (state: AdminState) =>
  selectAll(state)
);

export const getSelectedId = createSelector(
  getAdminState,
  (state: AdminState) => state.selectedId
);

export const updatedSuccessfully = createSelector(
  getAdminState,
  (state: AdminState) => state.updatedSuccessfully
);

export const updatedAdminSuccessfully = createSelector(
  getAdminState,
  (state: AdminState) => state.updatedAdminSuccessfully
);

export const saveWorkshopError = createSelector(
  getAdminState,
  (state: AdminState) => state.saveWorkshopError
);

export const saveAdminError = createSelector(
  getAdminState,
  (state: AdminState) => state.saveAdminError
);

export const getWorkshopStatus = createSelector(
  getAdminState,
  (state: AdminState) => state.workshopStatus
);

export const getCancellationReasons = createSelector(
  getAdminState,
  (state: AdminState) => state.cancellationReasons
);

export const getSelectedHub = createSelector(
  getAdminState,
  (state: AdminState) => state.selectedHub
);

export const getSelectedAdminHub = createSelector(
  getAdminState,
  (state: AdminState) => state.selectedAdminHub
);

export const getSelected = createSelector(
  getWorkshopEntities,
  getSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

export const getSelectedGeneral = createSelector(
  getWorkshopEntities,
  getSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId].general
);

export const getSelectedConsultation = createSelector(
  getWorkshopEntities,
  getSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId].consultation
);

export const getSelectedRecovery = createSelector(
  getWorkshopEntities,
  getSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId].recovery
);

export const removeAccessSuccessfully = createSelector(
  getAdminState,
  (state: AdminState) => state.removeAccessSuccessfully
);

export const removeUserAccessError = createSelector(
  getAdminState,
  (state: AdminState) => state.removeUserAccessError
);

export const getUserNameSearchResults = createSelector(
  getAdminState,
  (state: AdminState) => state.userNameSearchResults
);
