export const menuItems = [
  {
    icon: 'home',
    name: 'Home',
    route: '/admin/home',
  },
  {
    icon: 'announcement',
    name: 'Requested',
    route: '/admin/requested',
  },
  {
    icon: 'update',
    name: 'In Progress',
    route: '/admin/in-progress',
  },
  {
    icon: 'credit_card',
    name: 'Pending Recovery',
    route: '/admin/pending',
  },
  {
    icon: 'list',
    name: 'All Engagements',
    route: '/admin/closed',
  },
];
