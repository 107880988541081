import * as RecoveryActions from './recovery.actions';
import * as RecoverySelectors from './recovery.selectors';
import * as fromRecovery from './recovery.reducer';
import { Action, Store, select } from '@ngrx/store';
import { ExchangeSearchQuery } from '@utils/interfaces';
import { Injectable } from '@angular/core';

@Injectable()
export class RecoveryFacade {
  loaded$ = this.store.pipe(select(RecoverySelectors.getRecoveryLoaded));
  prepopulated$ = this.store.pipe(select(RecoverySelectors.getPrepopulated));
  allRecoveryTab$ = this.store.pipe(select(RecoverySelectors.getRecoveryState));
  exchangeRate$ = this.store.pipe(select(RecoverySelectors.getExchangeRate));
  wbsLookupSearchResults$ = this.store.pipe(
    select(RecoverySelectors.getAllWBSSearchResults)
  );

  constructor(
    private readonly store: Store<fromRecovery.RecoveryPartialState>
  ) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }

  updatePrepopulated(value: boolean) {
    this.dispatch(RecoveryActions.updatePrepopulated({ value }));
  }

  updateRecoveryComments(recoveryComments: string) {
    this.dispatch(RecoveryActions.updateRecoveryComments({ recoveryComments }));
  }

  updateChargeDate(chargeDate: string) {
    this.dispatch(RecoveryActions.updateChargeDate({ chargeDate }));
  }

  updateSelectedWbsCodes(wbsCodes: any) {
    this.dispatch(RecoveryActions.updateWbsCodes({ wbsCodes }));
  }

  updateRecoveryTotal(recoveryTotal: any) {
    this.dispatch(RecoveryActions.updateRecoveryTotal({ recoveryTotal }));
  }

  updateTotalCosts(totalCosts: any) {
    this.dispatch(RecoveryActions.updateTotalCosts({ totalCosts }));
  }

  updateTotalDiscounts(totalDiscounts: any) {
    this.dispatch(RecoveryActions.updateTotalDiscounts({ totalDiscounts }));
  }

  updatePassThroughEstimate(passThroughEstimate: any) {
    this.dispatch(RecoveryActions.updatePassThrough({ passThroughEstimate }));
  }

  updateRecoveryEntries(recoveryEntries: any) {
    this.dispatch(RecoveryActions.updateRecoveryEntries({ recoveryEntries }));
  }

  getWBS(wbs: string, index: number) {
    this.dispatch(RecoveryActions.loadWBSLookupResults({ wbs, index }));
  }

  getExchangeRate(searchQuery: ExchangeSearchQuery): void {
    this.dispatch(RecoveryActions.getExchangeRate({ searchQuery }));
  }

  clearStateObject(): void {
    this.dispatch(RecoveryActions.clearStateObject());
  }
}
