import * as VisitDetailsActions from './visit-details.actions';
import * as VisitDetailsSelectors from './visit-details.selectors';
import * as fromVisitDetails from './visit-details.reducer';
import { Action, Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';

@Injectable()
export class VisitDetailsFacade {
  loaded$ = this.store.pipe(
    select(VisitDetailsSelectors.getVisitDetailsLoaded)
  );

  allVisitDetails$ = this.store.pipe(
    select(VisitDetailsSelectors.getVisitDetailsState)
  );

  wbsLookupSearchResults$ = this.store.pipe(
    select(VisitDetailsSelectors.getAllWBSCodes)
  );

  constructor(
    private readonly store: Store<fromVisitDetails.VisitDetailsPartialState>
  ) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }

  updateDuration(value) {
    this.dispatch(
      VisitDetailsActions.updateSelectedDuration({ duration: value })
    );
  }

  updateDates(value) {
    this.dispatch(VisitDetailsActions.updateSelectedDates({ dates: value }));
  }

  getWBS(wbs: string, index: number) {
    this.dispatch(VisitDetailsActions.loadWBSLookupResults({ index, wbs }));
  }

  updateSelectedWBSCodes(value) {
    this.dispatch(
      VisitDetailsActions.updateSelectedWBSCodes({ wbsCodes: value })
    );
  }

  clearStateObject() {
    this.dispatch(VisitDetailsActions.clearStateObject());
  }
}
