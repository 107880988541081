import * as ConsultationActions from './consultation.actions';
import * as ConsultationSelectors from './consultation.selectors';
import * as fromConsultation from './consultation.reducer';
import { Action, Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

@Injectable()
export class ConsultationFacade {
  allConsultationTab$ = this.store.pipe(
    select(ConsultationSelectors.getConsultationState)
  );

  prepopulated$ = this.store.pipe(
    select(ConsultationSelectors.getPrepopulated)
  );

  allConsultationOwnerSearchResults$ = this.store.pipe(
    select(ConsultationSelectors.getAllConsultationOwnerSearchResults),
    filter((result) => Boolean(result))
  );

  allOpportunityIdSearchResults$ = this.store.pipe(
    select(ConsultationSelectors.getAllOpportunityIdSearchResults)
  );

  allInternalPurposes$ = this.store.pipe(
    select(ConsultationSelectors.getAllInternalPurposes)
  );

  constructor(
    private readonly store: Store<fromConsultation.ConsultationPartialState>
  ) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }

  updatePrepopulated(value: boolean) {
    this.dispatch(ConsultationActions.markAsPrepopulated({ value }));
  }

  loadInternalPurposeResults(programId: string) {
    this.dispatch(
      ConsultationActions.loadInternalPurposeResults({ programId })
    );
  }

  updateInternalPurpose(internalPurpose: any) {
    this.dispatch(
      ConsultationActions.selectInternalPurpose({
        selectedInternalPurpose: internalPurpose,
      })
    );
  }

  loadConsultationOwnerSearchResults(searchQuery) {
    this.dispatch(
      ConsultationActions.loadConsultationOwnerSearchResults({ searchQuery })
    );
  }

  loadOpportunityIdSearchResults(searchQuery) {
    this.dispatch(
      ConsultationActions.loadOpportunityIdSearchResults({ searchQuery })
    );
  }

  updateDuration(value) {
    this.dispatch(ConsultationActions.updateDuration({ duration: value }));
  }

  updateDates(value) {
    this.dispatch(ConsultationActions.updateDates({ dates: value }));
  }

  updateSoftlockExpirationDate(value) {
    this.dispatch(
      ConsultationActions.updateSoftlockExpirationDate({
        softLockExpirationDate: value,
      })
    );
  }

  updateClientChallenges(res) {
    this.dispatch(
      ConsultationActions.updateClientChallenges({ challengesText: res })
    );
  }

  updateConsultationOwner(value) {
    this.dispatch(
      ConsultationActions.updateConsultationOwner({ consultationOwner: value })
    );
  }

  updateCompleteConsultationDate(value) {
    this.dispatch(
      ConsultationActions.updateCompleteConsultationDate({
        completeConsultationDate: value,
      })
    );
  }

  updateBackgroundText(value: string) {
    this.dispatch(
      ConsultationActions.updateBackgroundText({ backgroundText: value })
    );
  }

  updateObjectivesText(value: string) {
    this.dispatch(
      ConsultationActions.updateObjectivesText({ objectivesText: value })
    );
  }

  updateSuccessText(value: string) {
    this.dispatch(
      ConsultationActions.updateSuccessText({ successText: value })
    );
  }

  updateAdditionalComments(value: string) {
    this.dispatch(
      ConsultationActions.updateAdditionalComments({
        additionalComments: value,
      })
    );
  }

  updateNumberOfAttendees(attendees: any) {
    this.updateAccentureAttendees(attendees.accentureAttendees);
    this.updateClientCSuiteAttendees(attendees.clientCSuiteAttendees);
    this.updateClientOtherAttendees(attendees.clientOtherAttendees);
    this.updateOtherAttendees(attendees.otherAttendees);
    this.updateTotalAttendees(attendees.totalAttendees);
  }

  updateAccentureAttendees(value: number) {
    this.dispatch(
      ConsultationActions.updateAccentureAttendees({
        accentureAttendees: value,
      })
    );
  }

  updateClientCSuiteAttendees(value: number) {
    this.dispatch(
      ConsultationActions.updateClientCSuiteAttendees({
        clientCSuiteAttendees: value,
      })
    );
  }

  updateClientOtherAttendees(value: number) {
    this.dispatch(
      ConsultationActions.updateClientOtherAttendees({
        clientOtherAttendees: value,
      })
    );
  }

  updateOtherAttendees(value: number) {
    this.dispatch(
      ConsultationActions.updateOtherAttendees({
        otherAttendees: value,
      })
    );
  }

  updateTotalAttendees(value: number) {
    this.dispatch(
      ConsultationActions.updateTotalAttendees({
        totalAttendees: value,
      })
    );
  }

  updateLeadershipLevel(value: string) {
    this.dispatch(
      ConsultationActions.updateLeadershipLevel({ leadershipLevel: value })
    );
  }

  updateAudienceType(value: string) {
    this.dispatch(
      ConsultationActions.updateAudienceType({ audienceType: value })
    );
  }

  updateTraveling(value: string) {
    this.dispatch(ConsultationActions.updateTraveling({ traveling: value }));
  }

  updateAttendeeDetails(value: string) {
    this.dispatch(
      ConsultationActions.updateAttendeeDetails({ attendeeDetails: value })
    );
  }

  updateSelectedOpportunityIds(value) {
    this.dispatch(
      ConsultationActions.updateSelectedOpportunityIds({
        selectedOpportunityIds: value,
      })
    );
  }

  clearStateObject(): void {
    this.dispatch(ConsultationActions.clearStateObject());
  }
}
