import {
  EngagementTeamMember,
  ICustomer,
  ITag,
  PeopleRequest,
  PeopleResponse,
} from '@ioh/types';
import { createAction, props } from '@ngrx/store';

/**
 * Prepopulated
 */

export const markAsPrepopulated = createAction(
  '[General] Update prepopulated value',
  props<{ value: boolean }>()
);

/**
 * Engagement Status
 */
export const getEngagementStatusResults = createAction(
  '[General] Load EngagementStatus Results'
);
export const getEngagementStatusResultsSuccess = createAction(
  '[General] Load EngagementStatus Results Success',
  props<{ engagementStatusResults: any }>()
);
export const getEngagementStatusResultsFailure = createAction(
  '[General] Load EngagementStatus Results Failure',
  props<{ error: any }>()
);

export const updateEngagementStatus = createAction(
  '[General] Update EngagementStatus',
  props<{ engagementStatus: string }>()
);

export const updateTitle = createAction(
  '[General] Update Title',
  props<{ engagementTitle: string }>()
);

/**
 * Engagement Type
 */
export const getEngagementTypeResults = createAction(
  '[General] Load EngagementTypeResults'
);

export const getEngagementTypeResultsSuccess = createAction(
  '[General] Load EngagementTypeResults Success',
  props<{ engagementTypeResults: any }>()
);

export const getEngagementTypeResultsFailure = createAction(
  '[General] Load EngagementTypeResults Failure',
  props<{ error: any }>()
);

export const updateEngagementType = createAction(
  '[General] Update EngagementType',
  props<{ engagementType: string }>()
);

/**
 * Tags
 */

export const updateTags = createAction(
  '[General] Update Tags',
  props<{ tags: ITag[] }>()
);

export const loadTagsResults = createAction(
  '[General] Load TagSearchResults',
  props<{ searchQuery: string }>()
);

export const loadTagsResultsSuccess = createAction(
  '[General] Load TagsResults Success',
  props<{ tagsSearchResults: ITag[] }>()
);

export const loadTagsResultsFailure = createAction(
  '[General] Load TagsResults Failure',
  props<{ error: any }>()
);

export const updateSummary = createAction(
  '[General] Update Summary',
  props<{ engagementSummary: string }>()
);

export const updateAdminComments = createAction(
  '[General] Update AdminComments',
  props<{ adminComments: string }>()
);

/**
 * Engagement Sponsor
 */
export const getEngagementSponsorSearchResults = createAction(
  '[General] Load EngagementSponsorSearchResults',
  props<{ searchQuery: PeopleRequest }>()
);
export const getEngagementSponsorSearchResultsSuccess = createAction(
  '[General] Load EngagementSponsorSearchResults Success',
  props<{ engagementSponsorSearchResults: PeopleResponse[] }>()
);
export const getEngagementSponsorSearchResultsFailure = createAction(
  '[General] Load EngagementSponsorSearchResults Failure',
  props<{ error: any }>()
);

export const updateEngagementSponsors = createAction(
  '[General] Update EngagementSponsors',
  props<{ engagementSponsors: PeopleResponse[] }>()
);

/**
 * Primary Contacts
 */
export const getPrimaryContactSearchResults = createAction(
  '[General] Load PrimaryContactSearchResults',
  props<{ searchQuery: PeopleRequest }>()
);

export const getPrimaryContactSearchResultsSuccess = createAction(
  '[General] Load PrimaryContactSearchResults Success',
  props<{ primaryContactSearchResults: PeopleResponse[] }>()
);

export const getPrimaryContactSearchResultsFailure = createAction(
  '[General] Load PrimaryContactSearchResults Failure',
  props<{ error: any }>()
);

export const updatePrimaryContacts = createAction(
  '[General] Update PrimaryContacts',
  props<{ primaryContacts: PeopleResponse[] }>()
);

/**
 * Additional Contacts
 */
export const getAdditionalContactsSearchResults = createAction(
  '[General] Load AdditionalContactsSearchResults',
  props<{ searchQuery: PeopleRequest }>()
);
export const getAdditionalContactsSearchResultsSuccess = createAction(
  '[General] Load AdditionalContactsSearchResults Success',
  props<{ additionalContactSearchResults: PeopleResponse[] }>()
);
export const getAdditionalContactsSearchResultsFailure = createAction(
  '[General] Load AdditionalContactsSearchResults Failure',
  props<{ error: any }>()
);

export const updateAdditionalContacts = createAction(
  '[General] Update AdditionalContacts',
  props<{ additionalContacts: PeopleResponse[] }>()
);

export const updateOrganizations = createAction(
  '[General] Update Organizations',
  props<{ organizations: any[] }>()
);

export const updateDatesAndlocations = createAction(
  '[General] Update DatesAndLocations',
  props<{ datesAndLocations: any[] }>()
);

/**
 * External Organization Categories
 */
export const getExternalOrganizationCategoriesResults = createAction(
  '[General] Load ExternalOrganizationCategoriesResults'
);
export const getExternalOrganizationCategoriesResultsSuccess = createAction(
  '[General] Load ExternalOrganizationCategoriesResults Success',
  props<{ organizationExternalCategoriesResults: any[] }>()
);

export const getExternalOrganizationCategoriesResultsFailure = createAction(
  '[General] Load ExternalOrganizationCategoriesResults Failure',
  props<{ error: any }>()
);
/**
 * Accenture Entities
 */

export const getAccentureEntities = createAction(
  '[General] Load AccentureEntities'
);

export const getAccentureEntitiesSuccess = createAction(
  '[General] Load AccentureEntities Success',
  props<{ organizationAccentureEntitiesResults: any[] }>()
);

export const getAccentureEntitiesFailure = createAction(
  '[General] Load AccentureEntities Failure',
  props<{ error: any }>()
);

export const updateEngagementTeam = createAction(
  '[General] Update EngagementTeam',
  props<{ engagementTeam: EngagementTeamMember[] }>()
);

export const updateEngagementGroup = createAction(
  '[General] Update EngagementGroup',
  props<{ engagementGroup: any }>()
);

export const loadEngagementGroupResults = createAction(
  '[General] Load EngagementGroupResults',
  props<{ programId: string }>()
);

export const loadEngagementGroupResultsSuccess = createAction(
  '[General] Load EngagementGroupResults Success',
  props<{ engagementGroupResults: any }>()
);

export const loadEngagementGroupResultsFailure = createAction(
  '[General] Load EngagementGroupResults Failure',
  props<{ error: any }>()
);

export const loadEngagementGroupEcosystemResults = createAction(
  '[General] Load EngagementGroupEcosystemResults',
  props<{ programId: string }>()
);

export const loadEngagementGroupEcosystemResultsSuccess = createAction(
  '[General] Load EngagementGroupEcosystemResults Success',
  props<{ engagementGroupEcosystemResults: any }>()
);

export const loadEngagementGroupEcosystemResultsFailure = createAction(
  '[General] Load EngagementGroupEcosystemResults Failure',
  props<{ error: any }>()
);

export const loadEngagementLeadResults = createAction(
  '[General] Load EngagementLeadResults',
  props<{ searchQuery: PeopleRequest }>()
);

export const loadEngagementLeadResultsSuccess = createAction(
  '[General] Load EngagementLeadResults Success',
  props<{ engagementLeadSearchResults: PeopleResponse[] }>()
);

export const loadEngagementLeadResultsFailure = createAction(
  '[General] Load EngagementLeadResults Failure',
  props<{ error: any }>()
);

export const updateEngagementLead = createAction(
  '[General] Update EngagementLead',
  props<{ engagementLead }>()
);

/**
 * Engagement Team Members
 */

export const loadEngagementTeamMemberResults = createAction(
  '[General] Load EngagementTeamMemberResults',
  props<{ searchQuery: PeopleRequest; index: number }>()
);

export const loadEngagementTeamMemberResultsSuccess = createAction(
  '[General] Load EngagementTeamMemberResults Success',
  props<{ engagementTeamMemberSearchResults: PeopleResponse[] }>()
);

export const loadEngagementTeamMemberResultsFailure = createAction(
  '[General] Load EngagementTeamMemberResults Failure',
  props<{ error: any }>()
);

export const updateGroupParticipation = createAction(
  '[General] Update GroupParticipation',
  props<{ groupParticipation: string[] }>()
);

export const updateGroupEcosystem = createAction(
  '[General] Update GroupEcosystem',
  props<{ groupEcosystem: string[] }>()
);

export const updateCancellationReason = createAction(
  '[General] Update Cancellation Reason',
  props<{ cancellationReason: any }>()
);

export const updateCancellationDate = createAction(
  '[General] Update Cancellation Date',
  props<{ cancellationDate: string }>()
);
/**
 * Engagement Start/End Date
 */
export const updateEngagementStartDate = createAction(
  '[General] Update updateEngagementStartDate',
  props<{ engagementStartDate: any }>()
);

export const updateEngagementEndDate = createAction(
  '[General] Update updateEngagementEndDate',
  props<{ engagementEndDate: any }>()
);
/**
 * Client Organization Search Results
 */
export const getClientOrganizationSearchResults = createAction(
  '[General] Load ClientOrganizationSearchResults',
  props<{ searchQuery: string }>()
);

export const getClientOrganizationSearchResultsSuccess = createAction(
  '[General] Load ClientOrganizationSearchResults Success',
  props<{ clientOrganizationSearchResults: ICustomer[] }>()
);

export const getClientOrganizationSearchResultsFailure = createAction(
  '[General] Load ClientOrganizationSearchResults Failure',
  props<{ error: any }>()
);

export const getOrganizationTypes = createAction(
  '[General] Get Organization Types'
);

export const getOrganizationTypesSuccess = createAction(
  '[General] Get Organization Types Success',
  props<{ organizationTypes: any }>()
);

export const getOrganizationTypesFailure = createAction(
  '[General] Get Organization Types Failure',
  props<{ error: any }>()
);

export const getEngagementTeamRoles = createAction(
  '[General] Get Engagement Team Roles',
  props<{ programId: string }>()
);

export const getEngagementTeamRolesSuccess = createAction(
  '[General] Get Engagement Team Roles Success',
  props<{ engagementTeamRoles: any }>()
);

export const getEngagementTeamRolesFailure = createAction(
  '[General] Get Engagement Team Roles Failure',
  props<{ error: any }>()
);

export const loadDatesAndLocationsRooms = createAction(
  '[General] Load Dates And Locations Rooms Values',
  props<{ programId: string }>()
);

export const loadDatesAndLocationsRoomsSuccess = createAction(
  '[General] Load Dates And Locations Rooms Values Success',
  props<{ datesAndLocationsRooms: any[] }>()
);

export const loadDatesAndLocationsRoomsFailure = createAction(
  '[General] Load Dates And Locations Rooms Values Failure',
  props<{ error: any }>()
);

export const clearStateObject = createAction('[General] Clear State Object');
