import * as GeneralActions from './general.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { ReferenceDataService } from '../../../../reference-data';
import { catchError, delay, exhaustMap, map, retry } from 'rxjs/operators';

import { of } from 'rxjs';
@Injectable()
export class GeneralEffects {
  getEngagementTypeResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.getEngagementTypeResults),
      exhaustMap((action) =>
        this.referenceDataService.getEngagementTypes().pipe(
          map((res: any) =>
            GeneralActions.getEngagementTypeResultsSuccess({
              engagementTypeResults: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              GeneralActions.getEngagementTypeResultsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  getEngagementSponsorSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.getEngagementSponsorSearchResults),
      exhaustMap((action) =>
        this.referenceDataService
          .getPeopleProfile({
            exactMatch: action.searchQuery.exactMatch,
            requestId: action.searchQuery.requestId,
            searchText: action.searchQuery.searchText,
          })
          .pipe(
            map((res: any) =>
              GeneralActions.getEngagementSponsorSearchResultsSuccess({
                engagementSponsorSearchResults: res.data,
              })
            ),
            catchError((error) =>
              of(
                GeneralActions.getEngagementSponsorSearchResultsFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  loadPrimaryContactSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.getPrimaryContactSearchResults),
      exhaustMap((action) =>
        this.referenceDataService
          .getPeopleProfile({
            exactMatch: action.searchQuery.exactMatch,
            requestId: action.searchQuery.requestId,
            searchText: action.searchQuery.searchText,
          })
          .pipe(
            map((res: any) =>
              GeneralActions.getPrimaryContactSearchResultsSuccess({
                primaryContactSearchResults: res.data,
              })
            ),
            catchError((error) =>
              of(
                GeneralActions.getPrimaryContactSearchResultsFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  loadAdditionalContactSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.getAdditionalContactsSearchResults),
      exhaustMap((action) =>
        this.referenceDataService
          .getPeopleProfile({
            exactMatch: action.searchQuery.exactMatch,
            requestId: action.searchQuery.requestId,
            searchText: action.searchQuery.searchText,
          })
          .pipe(
            map((res: any) =>
              GeneralActions.getAdditionalContactsSearchResultsSuccess({
                additionalContactSearchResults: res.data,
              })
            ),
            catchError((error) =>
              of(
                GeneralActions.getAdditionalContactsSearchResultsFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  loadEngagementGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.loadEngagementGroupResults),
      exhaustMap((action) =>
        this.referenceDataService.getEngagementGroup().pipe(
          map((res: any) =>
            GeneralActions.loadEngagementGroupResultsSuccess({
              engagementGroupResults: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              GeneralActions.loadEngagementGroupResultsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  loadEngagementGroupEcosystem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.loadEngagementGroupEcosystemResults),
      exhaustMap((action) =>
        this.referenceDataService.getEngagementGroupEcosystem().pipe(
          map((res: any) =>
            GeneralActions.loadEngagementGroupEcosystemResultsSuccess({
              engagementGroupEcosystemResults: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              GeneralActions.loadEngagementGroupEcosystemResultsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  loadEngagementLeadResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.loadEngagementLeadResults),
      exhaustMap((action) =>
        this.referenceDataService
          .getPeopleProfile({
            exactMatch: action.searchQuery.exactMatch,
            requestId: action.searchQuery.requestId,
            searchText: action.searchQuery.searchText,
          })
          .pipe(
            map((res: any) =>
              GeneralActions.loadEngagementLeadResultsSuccess({
                engagementLeadSearchResults: res.data,
              })
            ),
            catchError((error) =>
              of(
                GeneralActions.loadEngagementLeadResultsFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  loadEngagementTeamMemberResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.loadEngagementTeamMemberResults),
      exhaustMap((action) =>
        this.referenceDataService
          .getPeopleProfile({
            exactMatch: action.searchQuery.exactMatch,
            requestId: action.searchQuery.requestId,
            searchText: action.searchQuery.searchText,
          })
          .pipe(
            map((res: any) => {
              res.data.forEach((e) => {
                e.EngagementTeamMemberIndex = action.index;
              });

              return GeneralActions.loadEngagementTeamMemberResultsSuccess({
                engagementTeamMemberSearchResults: res.data,
              });
            }),
            catchError((error) =>
              of(
                GeneralActions.loadEngagementTeamMemberResultsFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  getExternalOrganizationCategoriesResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.getExternalOrganizationCategoriesResults),
      exhaustMap((action) =>
        this.referenceDataService.getOrganizationCategories().pipe(
          map((res: any) =>
            GeneralActions.getExternalOrganizationCategoriesResultsSuccess({
              organizationExternalCategoriesResults: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              GeneralActions.getExternalOrganizationCategoriesResultsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  getAccentureEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.getAccentureEntities),
      exhaustMap((action) =>
        this.referenceDataService.getAccentureEntities().pipe(
          map((res: any) =>
            GeneralActions.getAccentureEntitiesSuccess({
              organizationAccentureEntitiesResults: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              GeneralActions.getAccentureEntitiesFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  loadOrganizationTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.getOrganizationTypes),
      exhaustMap((action) =>
        this.referenceDataService.getOrganizationsTypes().pipe(
          map((res) =>
            GeneralActions.getOrganizationTypesSuccess({
              organizationTypes: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              GeneralActions.getOrganizationTypesFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  getTagsSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.loadTagsResults),
      exhaustMap((action) =>
        this.referenceDataService.getTags(action.searchQuery).pipe(
          map((res: any) =>
            GeneralActions.loadTagsResultsSuccess({
              tagsSearchResults: res.data,
            })
          ),
          catchError((error) =>
            of(
              GeneralActions.loadTagsResultsFailure({
                error,
              })
            )
          )
        )
      )
    )
  );

  loadClientOrganizationSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.getClientOrganizationSearchResults),
      exhaustMap((action) =>
        this.referenceDataService.getClients(action.searchQuery).pipe(
          map((res) =>
            GeneralActions.getClientOrganizationSearchResultsSuccess({
              clientOrganizationSearchResults: res.data,
            })
          ),
          catchError((error) =>
            of(
              GeneralActions.getClientOrganizationSearchResultsFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  loadDatesAndLocationsRooms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.loadDatesAndLocationsRooms),
      exhaustMap((action) =>
        this.referenceDataService
          .getDatesAndLocationsRooms(action.programId)
          .pipe(
            map((res) =>
              GeneralActions.loadDatesAndLocationsRoomsSuccess({
                datesAndLocationsRooms: res.data,
              })
            ),
            retry(4),
            delay(1000),
            catchError((error) =>
              of(
                GeneralActions.loadDatesAndLocationsRoomsFailure({
                  error: error,
                })
              )
            )
          )
      )
    )
  );

  getEngagementTeamRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GeneralActions.getEngagementTeamRoles),
      exhaustMap((action) =>
        this.referenceDataService.getEngagementTeamRoles(action.programId).pipe(
          map((res) =>
            GeneralActions.getEngagementTeamRolesSuccess({
              engagementTeamRoles: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              GeneralActions.getEngagementTeamRolesFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly referenceDataService: ReferenceDataService
  ) {}
}
