import { IEngagement } from './interfaces';
import { format } from 'date-fns';

export const transformDates = (value: IEngagement): IEngagement => {
  value.start = value.start && format(new Date(value.start), 'MM/dd/yyyy');
  value.end = value.end && format(new Date(value.end), 'MM/dd/yyyy');
  value.chargedate =
    value.chargedate && format(new Date(value.chargedate), 'MM/dd/yyyy');
  value.expiration =
    value.expiration && format(new Date(value.expiration), 'MM/dd/yyyy');
  value.complete =
    value.complete && format(new Date(value.complete), 'MM/dd/yyyy');
  return value;
};

export const checkStatusIs = (value: any, status: string): boolean => {
  return value.status.toLowerCase() == status.toLowerCase();
};
