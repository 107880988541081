import {
  AbstractControl,
  ControlContainer,
  FormArray,
  FormControl,
} from '@angular/forms';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DURATION, REQUESTED_DATES } from '@utils/enums';
import { DurationEntryService } from './duration-entry.service';

@Component({
  selector: 'ioh-duration',
  styleUrls: ['./duration.component.scss'],
  templateUrl: './duration.component.html',
})
export class DurationComponent implements OnInit, AfterViewInit {
  @Input() options: any;
  @Input() minDate: Date;
  @Input() dateLimit: number;
  @Input() waitlist: boolean;
  parentForm: AbstractControl;

  selectedRadio;

  constructor(
    public controlContainer: ControlContainer,
    private readonly durationEntryService: DurationEntryService
  ) {}

  ngOnInit(): void {
    this.parentForm = this.controlContainer.control;
    // handle prepopulation
    this.selectedRadio = this.duration.value;
  }

  ngAfterViewInit(): void {
    this.duration.valueChanges.subscribe((duration) => {
      this.onDurationSelected(duration);
    });
  }

  get dates(): FormArray {
    return this.parentForm.get(REQUESTED_DATES) as FormArray;
  }

  get duration(): FormControl {
    return this.parentForm.get(DURATION) as FormControl;
  }

  addDates(selectedRadio: string): void {
    switch (selectedRadio) {
      case 'Half Day or Less': {
        this.dates.push(this.durationEntryService.createHalfDayControl());
        break;
      }
      case 'Full Day': {
        this.dates.push(this.durationEntryService.createFullDayControl());
        break;
      }
      case 'Multi-Day': {
        this.dates.push(this.durationEntryService.createMultiDayControl());
        break;
      }
      default: {
        break;
      }
    }
  }

  onDurationSelected(selectedValue: string): void {
    this.selectedRadio = selectedValue;
    this.dates.clear();
    this.addDates(selectedValue);
  }

  deleteDate(index: number): void {
    this.dates.removeAt(index);
  }
}
