<main class="container"
  ngClass.md="container__md"
  ngClass.sm="container__sm"
  ngClass.xs="container__xs"
>
  <h1 class="container__title">Service Request</h1>
  <div class="container__form mat-elevation-z2"
    ngClass.md="container__form__md"
    ngClass.sm="container__form__sm"
  >
    <form>
      <ioh-accordian></ioh-accordian>
    </form>
    <div
      class="container__footer"
      fxLayout.xl="row"
      fxLayout.lg="row"
      fxLayout.md="row"
      fxLayout.sm="row"
      fxLayout.xs="column"
      fxLayoutAlign.xl="space-between center"
      fxLayoutAlign.lg="space-between center"
      fxLayoutAlign.md="space-around center"
      fxLayoutAlign.sm="space-around center"
      fxLayoutAlign.xs="space-around center"
    >
    <ioh-checkbox
      checkId="terms"
      value="checkbox"
      [formGroup]="baseForm"
      required=true
      label="Terms and Conditions selection"
    >
      <div>
        I agree to the
        <a
          class="container__link"
          routerLink="."
          (click)="openDialog($event)">Terms and Conditions</a>
      </div>
    </ioh-checkbox>
    <button
      mat-raised-button color="accent"
      [ngClass]="!termsAndConditionsAccepted || isSavingWorkshop ? 'container__footer__submit--disabled' : 'container__footer__submit'" 
      [disabled]="!termsAndConditionsAccepted || isSavingWorkshop" 
      (click)="createRequest()"
    >
      <div *ngIf="isSavingWorkshop; else text" fxLayout="row" fxLayoutAlign="start center">
        <mat-spinner diameter="20"></mat-spinner>
        <p class="container__form__submit__text">Saving ...</p>
      </div>
      <ng-template #text>
        Submit
      </ng-template>
  </button>
  </div>
  </div>
</main>
<ng-template #TermAndConditionTemplate>
  <h2 class="modal__title" role="banner" mat-dialog-title>Terms and Conditions</h2>
  <mat-dialog-content role="contentinfo" class="mat-typography">
    <ng-container *ngFor="let content of contents">
      <h3 class="modal__title">{{content.title}}</h3>
      <p class="modal__text">{{content.text}}</p>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" role="navigation">
    <button mat-stroked-button color="primary" (click)="onDownload()">Download</button>
    <button class="modal__accept__button" mat-flat-button (click)="onAccept()" color="primary">Accept</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #FailedSubmissionTemplate class="print__area">
  <h2 class="modal__title" role="banner" mat-dialog-title>Something went wrong!</h2>
  <mat-dialog-content role="contentinfo" class="mat-typography" [innerHTML]="errorMessage" style="white-space: pre-line;">
        {{errorMessage}}
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" role="navigation">
    <button class="modal__accept__button" mat-flat-button (click)="closeFailedSubmissionDialog()" color="primary">Got it!</button>
  </mat-dialog-actions>
</ng-template>


