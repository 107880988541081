<div fxLayout="column" [formGroup]="generalGroup" class="general__container">
  <h3 class="general__title general__title__first" id='engagementDetails'>Engagement Details</h3>
  <div
    fxLayout="row"
    fxLayout.md="column"
    fxLayout.sm="column"
    fxLayout.xs="column"
    fxLayoutAlign="space-between start"
    fxLayoutAlign.md="space-between stretch"
    fxLayoutAlign.sm="space-between stretch"
    fxLayoutAlign.xs="space-between stretch"
    fxLayoutGap="1rem"
    class="general__mt"
  >
    <ioh-input
      fxFlex="31%"
      label="Engagement Title"
      inputId="engagementTitle"
      name="Engagement Title"
      placeholder="Descriptive name for engagement"
      [formGroup]="generalGroup"
      value="engagementTitle"
      maxLength="100"
      [required]="true"
    ></ioh-input>
    <ioh-dropdown
      fxFlex="38%"
      label="{{engagementTypeDropdownSettings.label}}"
      dropdownId="{{engagementTypeDropdownSettings.dropdownId}}"
      [formGroup]="generalGroup"
      [isMultiple] = "engagementTypeDropdownSettings.isMultiple"
      [values] = "engagementTypeResults$ | async"
      field = "engagementType"
      [tooltipObj] = "engagementTypeTooltipObj"
      [required] = "engagementTypeDropdownSettings.required"
      placeholder = "{{engagementTypeDropdownSettings.placeholder}}"
      name = "{{engagementTypeDropdownSettings.name}}"
      direction="{{engagementTypeDropdownSettings.direction}}">
    </ioh-dropdown>
    <ioh-lookup
      fxFlex="31%"
      [formGroup]="generalGroup"
      lookUpId="tags"
      label="Tags"
      placeholder="Identifiers/tags for reporting purposes"
      lookupGroupFieldName="tags"
      lookupArrayFieldName="tagsResult"
      lookupInputFieldName="tagsInput"
      [addEnable]="true"
      [limit]="tagsLimit"
      [required]="false"
      [searchResults$]="allTagsResults$"
      [isLoading$]="allTagsLoading$"
      ></ioh-lookup>
  </div>
  <div
    fxLayout="row"
    fxLayout.md="column"
    fxLayout.sm="column"
    fxLayout.xs="column"
    fxLayoutAlign="space-between start"
    fxLayoutAlign.md="space-between stretch"
    fxLayoutAlign.sm="space-between stretch"
    fxLayoutAlign.xs="space-between stretch"
    fxLayoutGap="1rem"
    class="general__mt">
    <div fxFlex="31%" class="generalDate">
      <div class="tooltipWrap">
        <label class="general__label"><span class="asterisk">*</span>  Engagement Start Date</label>
        <mat-icon class="material-icons" id='icon-startDate' (mouseover)="dateIn('icon-startDate','startDateBox')" (mouseout)="dateOut()">help</mat-icon>
        <div [hidden]=!dateFlag class="tooltipBox" id="startDateBox">
          <p>
            {{dateTooltip}}
          </p>
          <img src="../../../../../assets/images/timeline.PNG" alt="">
        </div>
        <ioh-date-picker
          icon="today"
          value="engagementStartDate"
          ariaLabel="date picker"
          placeholder="Choose Start date"
          [isEndDate]="false"
          [formGroup]="generalGroup">
        </ioh-date-picker>
      </div>
    </div>
    <div fxFlex="38%" class="generalDate">
      <div class="tooltipWrap">
        <label class="general__label"><span class="asterisk">*</span>  Engagement End Date</label>
        <mat-icon class="material-icons" id='icon-endDate' (mouseover)="dateIn('icon-endDate','startDateBox')" (mouseout)="dateOut()">help</mat-icon>
        <ioh-date-picker
          icon="today"
          value="engagementEndDate"
          ariaLabel="date picker"
          placeholder="Choose End date"
          [isEndDate]="true"
          [formGroup]="generalGroup">
        </ioh-date-picker>
      </div>
     </div>
     <div fxFlex="31%">

     </div>
  </div>
  <div
    fxLayout="row"
    fxLayout.md="column"
    fxLayout.sm="column"
    fxLayout.xs="column"
    fxLayoutAlign="space-between center"
    fxLayoutAlign.md="space-between stretch"
    fxLayoutAlign.sm="space-between stretch"
    fxLayoutAlign.xs="space-between stretch"
    class="general__mt">
    <ng-container *ngFor="let textarea of generalTextareas">
      <ioh-text-area
        fxFlex="49%"
        fxFlex.md="100%"
        fxFlex.sm="100%"
        fxFlex.xs="100%"
        label={{textarea.label}}
        name={{textarea.name}}
        textareaId={{textarea.id}}
        placeholder={{textarea.placeholder}}
        [formGroup]="generalGroup"
        value={{textarea.value}}
        maxCharLimit={{textarea.limit}}
        description={{textarea.description}}
        height="{{textarea.height}}"
        [required]=textarea.required
      ></ioh-text-area>
    </ng-container>
  </div>
  <h3 class="general__title">Organizations
  </h3>
  <ioh-organization 
  [formGroup]="generalGroup" 
  [externalOrganizationsCategories$]="externalOrganizationCategories$ | async"   
  [accentureEntitiesResults$]="accentureEntitiesResults$ | async"
  [organizationTypes$]="organizationTypesResults$ | async">
</ioh-organization>
  <div class="tooltipWrap">
    <h3 class="general__title">Dates and Location  <mat-icon class="material-icons" id='icon-dateLocation' (mouseover)="dateLocationIn('icon-dateLocation','dateLocationBox')" (mouseout)="dateLocationOut()">help</mat-icon></h3>
    <div [hidden]=!daleLocationFlag class="tooltipBox" id="dateLocationBox">
      <p>
        {{imageTooltipTxt.dateLoction}}
      </p>
      <img src="../../../../../assets/images/timeline.PNG" alt="">
    </div>
  </div>
  <ioh-dates-locations-table
    [formGroup]="datesAndLocation"
    [cols]="columns"
    [roomsValues$]="datesAndLocationsRoomsValues$"
  ></ioh-dates-locations-table>
  <h3 class="general__title">Contact Details</h3>
  <ioh-contacts [lookUps]="lookUps" [formGroup]="generalGroup"></ioh-contacts>
  <h3 class="general__title">Resourcing</h3>
  <div
    fxLayout="row"
    fxLayout.md="column"
    fxLayout.sm="column"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
    fxLayoutAlign.md="space-between stretch"
    fxLayoutAlign.sm="space-between stretch"
    fxLayoutAlign.xs="space-between stretch"
    fxLayoutGap="1rem"
    class="general__mt"
  >
    <ioh-dropdown
      fxFlex="40%"
      label="{{engagementGroupDropdownSettings.label}}"
      dropdownId="{{engagementGroupDropdownSettings.dropdownId}}"
      [formGroup]="generalGroup"
      [isMultiple] = "engagementGroupDropdownSettings.isMultiple"
      [values] = "allEngagementGroupResults$ | async | sortDropdown"
      field = "engagementGroup"
      [required] = "engagementGroupDropdownSettings.required"
      placeholder = "{{engagementGroupDropdownSettings.placeholder}}"
      name = "{{engagementGroupDropdownSettings.name}}"
      direction="{{engagementGroupDropdownSettings.direction}}">
    </ioh-dropdown>
    <ioh-lookup
      fxFlex="40%"
      class="general__title__mt"
      lookUpId="engagmentLead"
      label="Engagement Lead"
      placeholder="Enterprise ID"
      lookupGroupFieldName="engagementLeadInput"
      lookupArrayFieldName="engagementLeadResult"
      lookupInputFieldName="input"
      [formGroup]="generalGroup"
      [limit]="engagementLeadLimit"
      [required]="false"
      [searchResults$]="allEngagementLeadResults$"
      [isLoading$]="allEngagementLeadResultsLoading$"
      [teamName]="true"
    >
    </ioh-lookup>
  </div>
  <h4 class="general__title__secondary">Engagement Team</h4>
    <ng-container formArrayName="engagementTeams">  
      <div
    fxLayout="row"
    fxLayout.md="column"
    fxLayout.sm="column"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
    fxLayoutAlign.md="space-between stretch"
    fxLayoutAlign.sm="space-between stretch"
    fxLayoutAlign.xs="space-between stretch"
    fxLayoutGap="1rem"
    class='engagementTeamTitle'
    *ngIf= '!smallScreen && engagementTeams.controls.length > 0 '>
    <div
    fxLayout="column"
    fxFlex="14%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    fxLayout.md="row"
    fxLayout.sm="row"
    fxLayout.xs="row"
    >
    Role
    </div>
    <div
    fxLayout="column"
    fxFlex="35%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    fxLayout.md="row"
    fxLayout.sm="row"
    fxLayout.xs="row"
    >
    Name
    </div>
    <div
    fxLayout="column"
    fxFlex="12%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    fxLayout.md="row"
    fxLayout.sm="row"
    fxLayout.xs="row"
    >
    Prep Days
    </div>
    <div
    fxLayout="column"
    fxFlex="14%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    fxLayout.md="row"
    fxLayout.sm="row"
    fxLayout.xs="row"
    >
    Execution Days
    </div>
    <div
    fxLayout="column"
    fxFlex="14%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    fxLayout.md="row"
    fxLayout.sm="row"
    fxLayout.xs="row"
    >
    Close Out Days
    </div>
    <div
    fxLayout="column"
    fxFlex="12%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    fxLayout.md="row"
    fxLayout.sm="row"
    fxLayout.xs="row"
    >
    Total Days
    </div>
    <div
    fxLayout="column"
    fxFlex="2%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    fxLayout.md="row"
    fxLayout.sm="row"
    fxLayout.xs="row"
    >
    </div>
    </div>

      <div *ngFor="let team of engagementTeams.controls; let indexTeams = index;">
        <ioh-team-member 
        [index]="indexTeams"
        [formGroupName]="indexTeams" 
        (deleteTeamMember)="removeTeamMember($event)" 
        (fetchResults)="fetchSearchResults($event)"
        [rolesValues$]="engagementTeamsRoles$">
      </ioh-team-member>
      </div>
    </ng-container>
  <div class="general__add__team__button"
    ngClass.md="general__add__team__button--medium"
    ngClass.sm="general__add__team__button--small"
    ngClass.xs="general__add__team__button--xsmall">
    <button
      mat-flat-button
      color="primary"
      class="square"
      (click)="addTeamMember()"
      [disabled]="engagementTeams.length === teamMembersLimit"
    >
      <span class="material-icons">
        add
      </span>
    </button>
    <span class="title">Add Team Member</span>
  </div>
  <small class="duration__hint"
    >Please select up to {{teamMembersLimit}} team members.</small
  >
  <h4 class="general__title__secondary">Group Participation</h4>
  <div
    fxLayout="row"
    fxLayout.md="column"
    fxLayout.sm="column"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
    fxLayoutAlign.md="space-between stretch"
    fxLayoutAlign.sm="space-between stretch"
    fxLayoutAlign.xs="space-between stretch"
    fxLayoutGap="1rem">
    <ioh-dropdown
      fxFlex="40%"
      fxFlex.md="100%"
      fxFlex.sm="100%"
      fxFlex.xs="100%"
      label="{{groupParticipationDropdownSettings.label}}"
      dropdownId="{{groupParticipationDropdownSettings.dropdownId}}"
      [formGroup]="generalGroup"
      [isMultiple] = "groupParticipationDropdownSettings.isMultiple"
      [values] = "allGroupParticipations$ | async | sortDropdown"
      field = "groupParticipation"
      [required] = "groupParticipationDropdownSettings.required"
      placeholder = "{{groupParticipationDropdownSettings.placeholder}}"
      name = "{{groupParticipationDropdownSettings.name}}"
      direction="{{groupParticipationDropdownSettings.direction}}">
    </ioh-dropdown>
    <ioh-dropdown
      fxFlex="40%"
      fxFlex.md="100%"
      fxFlex.sm="100%"
      fxFlex.xs="100%"
      label="{{groupEcosystemDropdownSettings.label}}"
      dropdownId="{{groupEcosystemDropdownSettings.dropdownId}}"
      [formGroup]="generalGroup"
      [isMultiple] = "groupEcosystemDropdownSettings.isMultiple"
      [values] = "allGroupEcosystem$ | async | sortDropdown"
      field = "groupEcosystem"
      [required] = "groupEcosystemDropdownSettings.required"
      placeholder = "{{groupEcosystemDropdownSettings.placeholder}}"
      name = "{{groupEcosystemDropdownSettings.name}}"
      direction="{{groupEcosystemDropdownSettings.direction}}">
    </ioh-dropdown>
  </div>
</div>