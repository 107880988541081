import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@env/environment';

export interface IHttpOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private readonly http: HttpClient) {}

  /**
   * @returns An observable of the response
   * @param url the url you wish to get from
   * @param options other configuration options
   */
  get<T>(url: string, options?: IHttpOptions): Observable<T> {
    url = this.constructURL(environment.endpoints.base, url);
    return this.http.get<T>(url, options);
  }

  /**
   * @returns An observable of the response
   * @param url the url you wish to get from
   * @param body the info to be posted
   * @param options other configuration options
   */
  patch(url, body, options?: IHttpOptions): Observable<any> {
    url = this.constructURL(environment.endpoints.base, url);
    return this.http.patch(url, body, options);
  }

  /**
   * @returns An observable of the response
   * @param url the url you wish to get from
   * @param body the info to be posted
   * @param options other configuration options
   */
  post<T>(url, body, options?: IHttpOptions): Observable<T> {
    url = this.constructURL(environment.endpoints.base, url);
    return this.http.post<T>(url, body, options);
  }

  /**
   * @returns An observable of the result
   * @param url the url you wish to get from
   * @param options other configuration options
   */
  delete(url, options?: IHttpOptions): Observable<any> {
    url = this.constructURL(environment.endpoints.base, url);
    return this.http.delete(url, options);
  }

  private constructURL(base: string, endpoint: string): string {
    if (endpoint.startsWith('http') || endpoint.startsWith('https')) {
      return endpoint;
    } else {
      return base + endpoint;
    }
  }
}
