export const textAreasConsultation = [
  {
    description: 'Background description',
    height: '4rem',
    id: 'background',
    label: 'Background',
    limit: 1000,
    name: 'Background',
    placeholder:
      'i.e. Business Context, Relationship, Specific interest areas (Technology, value chain, industry outlook etc.), long term plan (i.e. digital maturity, part of a project), project in flight, outstanding proposals, recent wins etc.',
    required: false,
    value: 'backgroundText',
  },
  {
    description: 'Objectives and Expectations',
    height: '4rem',
    id: 'objectives-expectations',
    label: 'Objectives and Expectations',
    limit: 1000,
    name: 'Objectives and Expectations',
    placeholder:
      'What are the clients expectations and objectives for this engagement',
    required: false,
    value: 'objectivesText',
  },
  {
    description: 'Success criteria description',
    height: '4rem',
    id: 'success',
    label: 'Success Criteria',
    limit: 1000,
    name: 'Success Criteria',
    placeholder:
      'How will you quantitatively or qualitatively measure the success of this engagement.',
    required: false,
    value: 'successCriteria',
  },
  {
    description: 'Challenges description',
    height: '4rem',
    id: 'challenges',
    label: 'Client Challenges',
    limit: 1000,
    name: 'Client Challenges',
    placeholder:
      'What challenges or issues is the client currently facing. Is there a specific challenge area that we want to focus on discussing or addressing as part of the engagement?',
    required: false,
    value: 'challengesText',
  },
  {
    description: 'Additional Comments',
    height: '4rem',
    id: 'additionalComments',
    label: 'Additional Comments',
    limit: 1000,
    name: 'Additional Comments',
    placeholder:
      'Please provide any blockers, internal politics, social styles, privacy concerns, branding requirements, preferred dress code, etc. that we should know about',
    required: false,
    value: 'additionalComments',
  },
];

export const radioBtnConsultationFields = [
  {
    field: 'leadershipLevel',
    hint: 'What is the highest level attendee you are expecting?',
    label: 'Leadership Level',
    radioId: 'leadership',
    required: false,
    values: [
      'VIP (C-level,SVP)',
      'Mid Level (Executive)',
      'Management (Mgr, Sr.Mgr)',
      'Other',
    ],
  },
];
