<div>
  <ioh-select-hub></ioh-select-hub>
  <ioh-banner title="Pending Recovery"></ioh-banner>
    <ioh-card>
    <ioh-list-view
      tableName="Missing Recovery Data"
      urgentTableName=true
      [colNames]="colNames"
      [data$]="pendingMissingRecoveryData$"
      class = 'pendListTable'
    ></ioh-list-view>
    <ioh-list-view
    tableName="Direct Charge"
    [colNames]="directChargeAndSubmissioncolNames"
    [data$]="directChargeData$"
  ></ioh-list-view>
  <ioh-list-view
  tableName="Ready for Submission"
  [colNames]="directChargeAndSubmissioncolNames"
  [data$]="readyForSubmissionData$"
></ioh-list-view>
    <ioh-list-view
      tableName="Total Recovery"
      [colNames]="colNames"
      [data$]="pendingTotalRecoveryData$"
      [totalRecovery$]="totalRecoveryReport$"
      class = 'pendListTable totalRecoveryTable'
    ></ioh-list-view>
  </ioh-card>
</div>
