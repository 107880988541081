import { AccessService } from './services/access.service';
import {
  AfterViewChecked,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AppStateService } from './services/appstate.service';
import { ModalService } from './services/modal.service';
import { MsalService } from '@azure/msal-angular';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';

@Component({
  selector: 'ioh-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {
  title = 'ioh-app';
  widthTimeOutModal = '26rem';
  notConsentRadioBtn: HTMLInputElement;
  private readonly subscription: Subscription = new Subscription();
  private interval: any;
  private radioClickListener: () => void;
  @ViewChild('timeOutTemplate') modalTemplate: TemplateRef<any>;

  constructor(
    private readonly router: Router,
    public appStateService: AppStateService,
    public readonly modalService: ModalService,
    public accessService: AccessService,
    private readonly renderer: Renderer2,
    public readonly authService: MsalService
  ) {}

  ngOnInit() {
    this.interval = setInterval(() => {
      this.openModal();
    }, environment.config.timeOut);
    this.subscription.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          sessionStorage.setItem(
            'path',
            decodeURIComponent(event.url.replace(/^\/|\/$/g, ''))
          );
          sessionStorage.setItem(
            'overrideCode',
            decodeURIComponent(
              event.url.replace(/^\/|\/$/g, '').split('overrideCode=')[1]
            )
          );

          if (this.authService.getAccount()) {
            this.subscription.add(this.appStateService.getState().subscribe());
            this.subscription.add(this.accessService.checkAccess().subscribe());
          }
        }
      })
    );

    if (sessionStorage.getItem('path')) {
      const path = sessionStorage.getItem('path');
      this.router.navigateByUrl(path);
    }

    if (this.authService.getAccount()) {
      window['acnprivacy'].getUserSelection().then(
        function (consent) {
          if (consent == 0) {
            this.authService.logout();
          }
        }.bind(this)
      );
    }
  }

  ngAfterViewChecked() {
    this.notConsentRadioBtn = document.querySelector('.acn-consent-radio-2');
    if (this.notConsentRadioBtn && this.authService.getAccount()) {
      this.radioClickListener = this.renderer.listen(
        this.notConsentRadioBtn,
        'click',
        () => {
          this.authService.logout();
        }
      );
    }
  }

  openModal(): void {
    const dataModal = { template: this.modalTemplate };
    this.modalService.openDialog(dataModal, this.widthTimeOutModal);
    window.clearInterval(this.interval);
  }

  closeModal() {
    this.modalService.closeDialog();
    this.logout();
  }

  renewToken() {
    const requestObj = {
      scopes: environment.options.consentScopes,
    };
    this.authService
      .acquireTokenSilent(requestObj)
      .then(function (tokenResponse) {
        console.log('Token will expire On: ', tokenResponse.expiresOn);
      })
      .catch((error) => {
        this.errorHandler(error);
      });
    this.modalService.closeDialog();
    this.interval = setInterval(() => {
      this.openModal();
    }, environment.config.timeOut);
  }

  public errorHandler(error: any): void {
    if (error.name == 'InteractionRequiredAuthError') {
      console.error(error);
      this.authService.loginRedirect();
    } else {
      console.error(error);
      this.authService
        .acquireTokenPopup({ scopes: environment.options.consentScopes })
        .catch((error) => {
          console.error(error);
          this.logout();
        });
    }
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    window.clearInterval(this.interval);
    if (this.radioClickListener) {
      this.radioClickListener();
    }
  }
}
