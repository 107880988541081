<div>
  <ioh-select-hub></ioh-select-hub>
  <ioh-banner title="Home"></ioh-banner>
  <ioh-card>
    <ioh-list-view
      tableName="Past Due"
      urgentTableName=true
      [colNames]="colNames"
      [data$]="pastDueHomeData$"
    ></ioh-list-view>
    <ioh-list-view
      tableName="Upcoming and in Progress"
      [colNames]="colNames"
      [data$]="upcomingHomeData$"
    ></ioh-list-view>
    <ioh-list-view
      tableName="Completed last 30 days"
      [colNames]="colNames"
      [data$]="completedHomeData$"
    ></ioh-list-view>
    <ioh-list-view
      tableName="My Consultations"
      [colNames]="colNames"
      [data$]="myConsultationsHomeData$"
    ></ioh-list-view>
  </ioh-card>
</div>
