<div class="menu"
  ngClass.md="menu__medium__screen"
  ngClass.sm="menu__small__screen"
  fxLayout="column">
  <div
    class="menu__heading"
    fxLayoutAlign="center center"
  >
    ENGAGE
  </div>
  <div
    class="menu__add"
    fxLayoutAlign="center center"
    (click)="navigateTo('/admin/add-booking')"
    (keydown.enter)="navigateTo('/admin/add-booking')"
    tabIndex="0"
  >
    <span class="material-icons menu__add__icon">
      add_circle
    </span>
    <span class="menu__add__label">
      Add Booking
    </span>
  </div>
  <div
    class="menu__item"
    fxLayout="row"
    *ngFor="let menuItem of menuItems"
    routerLink="{{menuItem.route}}"
    routerLinkActive="active"
    (keydown.enter)="navigateTo(menuItem.route)"
  >
    <span class="material-icons menu__item__icon">
      {{menuItem.icon}}
    </span>
    <span class="menu__item__label">
      {{menuItem.name}}
    </span>
  </div>
  <div 
  class="menu__item help-row" 
  fxLayout="row" (click)="toggleHellBox()"
  >
    <span class="material-icons menu-Help">
      help_outline
    </span>
    <span class="menu-Help-text">
      Help
    </span>
  </div>
  <div id="menu-admin" *ngIf="showAdminMenu"
  class="menu__item admin-row" routerLink="{{adminMenuRoute}}"
  routerLinkActive="active" (keydown.enter)="navigateTo(adminMenuRoute)"
  fxLayout="row" (click)="navigateTo(adminMenuRoute)"
  >
    <span id="menu-admin-icon" class="material-icons menu-Help">
      settings
    </span>
    <span id="menu-admin-text" class="menu-Help-text">
      Administration
    </span>
  </div>
  <div fxLayoutAlign="center center" class="menu__download">
      <button color="primary" mat-raised-button tabIndex="0"
      (click)="downloadReport()" 
      (keydown.enter)="downloadReport()" 
      [disabled]="this.exportService.isDownloadReport"
      [ngClass]="this.exportService.isDownloadReport ? 'menu__download-disabled' : 'menu__download'">
        <div class="download-report" fxLayout="row" fxLayoutAlign="center center" *ngIf="this.exportService.isDownloadReport;else text">
          <mat-spinner diameter="20"></mat-spinner>
          <span>Download Report</span>
        </div>
        <ng-template #text class="menu__download__label">
          <span class="material-icons menu__download__icon">
            save_alt
          </span>
          Download Report
        </ng-template>
      </button>
  </div>
</div>
<div class="help-overlay" *ngIf="isShowHelpBox"></div>
<div class="help-box" *ngIf="isShowHelpBox">
  <h2>
    Help
  </h2>
  <p>
    1. For general questions or new/existing user access issues, please email <a href="mailto:EI.Support@accenture.com">EI.Support@accenture.com</a>
  </p>
  <p>
    2. For applications errors or enhancement requests, please visit <a href="https://urldefense.proofpoint.com/v2/url?u=https-3A__nam11.safelinks.protection.outlook.com_-3Furl-3Dhttps-253A-252F-252Fsupport.accenture.com-252Ftechnologysupport-26data-3D04-257C01-257Candrew.fiester-2540avanade.com-257C3aebfafcbda2459746ea08d92f4ee45b-257Ccf36141cddd745a7b073111f66d0b30c-257C0-257C1-257C637592838173318285-257CUnknown-257CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0-253D-257C1000-26sdata-3DXeCuIymX9kgaVYwomWLpXiWzKdmOsVenDEuBpY296bs-253D-26reserved-3D0&d=DwMFAw&c=eIGjsITfXP_y-DLLX0uEHXJvU8nOHrUK8IrwNKOtkVU&r=3KJ6V6BqfMN54iUTQReWqnA-2QL-CGg_PoZvMTvHg0U&m=uRIoE7aUcSeyVbybrGzqCSkEvhKqfmVLpc8g9HKQyVo&s=qT_P0PbeZyRBMKcpuF8sbMHe_RlSDEU6OlIcSpk3cis&e=" target="_blank">Accenture Technology Support</a> to open a ticket:
  </p>
  <ul>
    <li>1. Click “Create an Incident” </li>
    <li>2. Select Classification “Application Software”</li>
    <li>3. Type “EngageInnovation” in the Application field.</li>
    <li>4. Select the issue from the drop down menu you are experiencing from the Type field.</li>
    <li>5. Enter “Your Summary Comments” in the Summary field describing the issue.</li>
    <li>6. Fill in  “Details” describing the issue in the Description field.</li>
    <li>7. Upload “Any screenshots and/or other evidence” to Add Attachments.</li>
    <li>8. Remember to click “Submit” to send the request.</li>
  </ul>
  <div class="button-row">
    <button (click)="toggleHellBox()">OK</button>
  </div>
</div>
