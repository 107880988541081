<div class="input-container" *ngFor="let lookUp of lookUps">
  <ioh-lookup
    fxFlex="60%"
    fxFlex.md="100%"
    fxFlex.sm="100%"
    fxFlex.xs="100%"
    [formGroup]="parentForm"
    lookUpId="{{ lookUp.lookUpId }}"
    label="{{ lookUp.label }}"
    placeholder="{{ lookUp.placeholder }}"
    [lookupGroupFieldName]="lookUp.lookupGroupFieldName"
    [lookupArrayFieldName]="lookUp.lookupArrayFieldName"
    [lookupInputFieldName]="lookUp.lookupInputFieldName"
    [limit]="lookUp.limit"
    [required]="lookUp.required"
    [searchResults$]="lookUp.searchResults"
    [isLoading$]="lookUp.isLoading"
  >
  </ioh-lookup>
</div>
