import { Component } from '@angular/core';
import { CustomDateValidators, WBSValidators } from '@utils/validators';
import { DateFormatService } from '@services';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { VisitDetailsFacade } from '@ioh/core-data';
import { WbsEntryService } from '@ioh/ui-library';
import { filter } from 'rxjs/operators';
import { radioOptions } from '@assets/data/visit-details';
@Component({
  selector: 'ioh-visit-details',
  styleUrls: ['./visit-details.component.scss'],
  templateUrl: './visit-details.component.html',
})
export class VisitDetailsComponent {
  private readonly subscriptions: Subscription = new Subscription();
  constructor(
    private readonly fb: FormBuilder,
    private readonly wbsValidators: WBSValidators,
    public wbsEntryService: WbsEntryService,
    private readonly dateValidator: CustomDateValidators,
    private readonly visitDetailsFacade: VisitDetailsFacade,
    private readonly dateFormatService: DateFormatService
  ) {}

  visitDetailsGroup: FormGroup;
  minDate: Date = new Date();
  dateLimit = 2;
  options = radioOptions;
  maxPercentage: number = 100;
  totalRemainingPercentageAllocation: number = 100;
  wbsDetails = false;

  ngOnInit(): void {
    this.visitDetailsGroup = this.fb.group({
      dates: this.fb.array([], this.dateValidator.trigger),
      duration: this.fb.control(''),
      wbsFormArray: this.fb.array(
        [this.wbsEntryService.createPercentageWbs()],
        this.wbsValidators.percentageControlsValidator
      ),
    });

    this.mapWBSLookupSearchResults();
    this.updateDuration();
    this.updateSelectedDates();
    this.updateSelectedWBSCodes();
  }

  get dates(): FormArray {
    return this.visitDetailsGroup.get('dates') as FormArray;
  }

  get wbsFormArray(): FormArray {
    return this.visitDetailsGroup.get('wbsFormArray') as FormArray;
  }

  mapWBSLookupSearchResults() {
    this.subscriptions.add(
      this.visitDetailsFacade.wbsLookupSearchResults$
        .pipe(filter((x) => !!x))
        .subscribe((searchResults) => {
          this.wbsFormArray
            .at(searchResults.WbsComponentIndex)
            .get('isValid')
            .setValue(searchResults.IsValid);
          this.wbsFormArray
            .at(searchResults.WbsComponentIndex)
            .get('responseLoaded')
            .setValue(true);
          this.wbsFormArray
            .at(searchResults.WbsComponentIndex)
            .get('type')
            .setValue(searchResults.BusinessActivityLevel1Description);
          this.wbsFormArray
            .at(searchResults.WbsComponentIndex)
            .get('description')
            .setValue(searchResults.WbsDescription);
          this.wbsFormArray
            .at(searchResults.WbsComponentIndex)
            .get('expiration')
            .setValue(searchResults.BasicFinishDate);
        })
    );
  }

  updateDuration() {
    this.subscriptions.add(
      this.visitDetailsGroup
        .get('duration')
        .valueChanges.subscribe((changes) => {
          this.visitDetailsFacade.updateDuration(changes);
        })
    );
  }

  updateSelectedDates() {
    this.subscriptions.add(
      this.visitDetailsGroup.get('dates').valueChanges.subscribe((res) => {
        const transRes = res.map((elem) => {
          const transformed = { ...elem };
          if (elem.date) {
            const newDate = this.dateFormatService.getDateToSave(elem.date);
            transformed.date = newDate;
          }
          if (elem.startDate) {
            const newStartDate = this.dateFormatService.getDateToSave(
              elem.startDate
            );
            transformed.startDate = newStartDate;
          }
          if (elem.endDate) {
            const newEndDate = this.dateFormatService.getDateToSave(
              elem.endDate
            );
            transformed.endDate = newEndDate;
          }
          return transformed;
        });
        this.visitDetailsFacade.updateDates(transRes);
      })
    );
  }

  updateSelectedWBSCodes() {
    this.subscriptions.add(
      this.visitDetailsGroup
        .get('wbsFormArray')
        .valueChanges.subscribe((changes) => {
          this.visitDetailsFacade.updateSelectedWBSCodes(changes);
        })
    );
  }

  getWbs(event) {
    this.visitDetailsFacade.getWBS(event.wbs, event.index);
  }
}
