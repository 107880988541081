export const wildcardErrorDetails = {
  mainTitle: 'Oops!',
  firstSubtitle: 'In design, thinking',
  secondSubtitle: 'wrong is right.',
  mainBodyText: `Sorry! It seems you can't have access to this page.`,
  secondaryBodyText: 'Please contact:',
};

export const logoutErrorDetails = {
  mainTitle: 'Oops!',
  firstSubtitle: 'You have been logged out.',
  mainBodyText: `Sorry! You have not accepted our GDPR policies.`,
  mainSecondaryBodyText: `Please adjust your privacy settings in the site footer,`,
  mainTertiaryBodyText: `before re-accessing our application`,
  link: 'here',
  secondaryBodyText: 'If you still cannot log in, please contact:',
};

export const unauthorisedDetails = {
  mainTitle: 'Oops!',
  firstSubtitle: 'Unauthorised to view this page',
  mainBodyText: `Sorry! You don't have access to this Program.`,
  mainSecondaryBodyText: `Please check with the Program leaders,`,
  mainTertiaryBodyText: `to see if you should have access`,
  secondaryBodyText:
    'If you are an admin & should have access, please contact:',
};
