<main class="container">
    <section class="response__wrapper">
        <ioh-response [response]="response"></ioh-response>
    </section>
    <section>
        <button mat-button (click)="goBack()"><span class="material-icons">
            keyboard_backspace
            </span>Go back</button>
    </section>
</main>
