export const entityOptions = [
  { id: 1, value: 'Accenture Federal' },
  { id: 2, value: 'Avanade' },
  { id: 3, value: 'Others' },
];
export const OGOptions = [
  { id: 1, value: 'Communications, Media, & Technology' },
  { id: 2, value: 'Financial Services' },
  { id: 3, value: 'Health & Public Service' },
  { id: 4, value: 'Products' },
  { id: 5, value: 'Resources' },
  { id: 6, value: 'N/A' },
  { id: 7, value: 'Cross Operating Group' },
];
export const categoryOptions = [
  { id: 1, value: 'Academia' },
  { id: 2, value: 'Alliance' },
  { id: 3, value: 'Analyst' },
  { id: 4, value: 'Media' },
  { id: 5, value: 'Vendor' },
  { id: 6, value: 'Other' },
];
export const clientTypeOptions = [
  { id: 3, value: 'Existing Client' },
  { id: 4, value: 'Other (Including Accenture Federal Clients)' },
];
export const dummyClientDetails = [
  {
    body: 'Dupont',
    formControl: 'organizationName',
    response: 'CustomerName',
    title: 'Organization Name',
  },
  {
    body: 'Financial Services',
    formControl: 'operatingGroup',
    response: 'PrimaryOperatingGroup',
    title: 'Market',
  },
  {
    body: 'United States',
    formControl: 'country',
    response: 'SoldToCountryName',
    title: 'Country',
  },
  {
    body: 'Dummy',
    formControl: 'operatingUnit',
    response: 'PrimaryOperatingUnit',
    title: 'Market Unit',
  },
  {
    body: 'Dummy',
    formControl: 'industrySegment',
    response: 'IndustrySegment',
    title: 'Industry',
  },
  {
    body: 'Dummy',
    formControl: 'g2000Rank',
    response: 'G2000PositionCode',
    title: 'G2000 Rank',
  },
  {
    body: 'Dummy',
    formControl: 'accountStatus',
    response: 'CustomerStatus',
    title: 'Account Status',
  },
  {
    body: 'Dummy',
    formControl: 'diamond',
    response: 'GlobalClientClassificationName',
    title: 'MC Classification',
  },
];
