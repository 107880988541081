import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as FileSaver from 'file-saver';
import { Injectable } from '@angular/core';
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
@Injectable({
  providedIn: 'root',
})
export class ExcelExportService {
  public isDownloadReport = false;
  exportTable(data, tableName, colNames) {
    const workbook = new Excel.Workbook();
    workbook.creator = 'ioh.admin';
    workbook.created = new Date();
    const sheet = workbook.addWorksheet(tableName);
    const columnsItems = [];
    for (var item in colNames) {
      columnsItems.push({ header: colNames[item], key: item });
    }
    sheet.columns = columnsItems;
    sheet.addRows(data);
    this.setWidth(sheet);
    workbook.xlsx.writeBuffer().then((buffer) => {
      this.saveAsExcelFile(buffer, tableName);
    });
  }

  loadDownloadReport(loadBuffer, exportName) {
    const workbook = new Excel.Workbook();
    workbook.xlsx.load(loadBuffer).then((wb) => {
      wb.xlsx.writeBuffer().then((writeBuffer) => {
        this.saveAsExcelFile(writeBuffer, exportName);
        this.isDownloadReport = false;
      });
    });
  }

  setWidth(sheet) {
    sheet.columns.forEach((column) => {
      let dataMax = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? cell.value.length : 0;
        if (columnLength > dataMax) {
          dataMax = columnLength;
        }
      });
      column.width = 12;
    });
  }

  saveAsExcelFile(buffer, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName);
  }
}
