import { BehaviorSubject } from 'rxjs';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ioh-banner',
  styleUrls: ['./banner.component.scss'],
  templateUrl: './banner.component.html',
})
export class BannerComponent {
  @Input() title: string;
  @Input() progCode: string;
  @Input() engId: string;
  @Input() org: string;
  @Input() icon: string;
  @Input() previousList: string;
  @Input() showButtons: boolean = false;
  @Input() saveButtonToggle$: BehaviorSubject<boolean>;
  @Input() initButtonToggle$: BehaviorSubject<boolean>;
  @Output() onSave = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  constructor(private readonly router: Router) {}

  headerMargin() {
    return this.showButtons ? '0' : '0.5rem 0';
  }

  save() {
    this.onSave.emit();
  }

  cancel() {
    this.onCancel.emit();
  }

  goBack() {
    this.router.navigateByUrl(this.previousList);
  }
}
