import { FormBuilder, FormGroup } from '@angular/forms';
import { FormControlUtils } from '@utils/formControlUtils';
import { Injectable } from '@angular/core';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DatesAndLocationsEntryService {
  constructor(
    private readonly fb: FormBuilder,
    private readonly formControlUtils: FormControlUtils
  ) {}

  public createNewDateEntry(entry?) {
    const form = this.fb.group(
      {
        id: this.fb.control(
          this.formControlUtils.mapProperty({ entry, property: 'id' })
        ),
        index: this.fb.control(
          this.formControlUtils.mapProperty({
            entry,
            property: 'index',
          })
        ),
        dates: this.fb.control(
          this.formControlUtils.mapProperty({
            entry,
            property: 'dates',
          }) ||
            this.formControlUtils.mapProperty({
              entry,
              property: 'date',
            })
        ),
        type: this.fb.control({
          value:
            // eslint-disable-next-line prettier/prettier
            (entry && typeof entry.type === 'string')
              ? this.formControlUtils.mapProperty({
                  entry,
                  property: 'type',
                })
              : this.formControlUtils.mapProperty({
                  entry,
                  property: 'type.value',
                }),
        }),
        clientArrival: this.fb.control(
          this.formControlUtils.mapPropertyForTime({
            entry,
            property: 'clientArrival',
          }) ||
            this.formControlUtils.mapPropertyForTime({
              entry,
              property: 'arrivalTime',
            }),
          [this.valid24HourTime()]
        ),
        startTime: this.fb.control(
          this.formControlUtils.mapPropertyForTime({
            entry,
            property: 'startTime',
          }),
          [this.valid24HourTime()]
        ),
        endTime: this.fb.control(
          this.formControlUtils.mapPropertyForTime({
            entry,
            property: 'endTime',
          }),
          [this.valid24HourTime()]
        ),
        hours: this.fb.control({
          value: this.formControlUtils.mapProperty({
            entry,
            property: 'hours',
            defaultValue: '0',
          }),
          disabled: true,
        }),
        locations: this.fb.array(this.prepopulateLocations(entry?.locations)),
      },
      {
        validators: this.crossValidateAllDateTimes,
      }
    );

    form.controls.startTime.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((startTime) => {
        const hours = this.calculateHours(
          startTime,
          form.controls.endTime.value
        );
        form.controls.hours.enable();
        form.controls.hours.setValue(hours);
        form.controls.hours.disable();
      });

    form.controls.endTime.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((endTime) => {
        const hours = this.calculateHours(
          form.controls.startTime.value,
          endTime
        );
        form.controls.hours.enable();
        form.controls.hours.setValue(hours);
        form.controls.hours.disable();
      });

    return form;
  }

  private prepopulateLocations(locations?) {
    const array = [];
    if (locations) {
      locations.forEach((element) => {
        array.push(this.createNewLocationEntry(element));
      });
    } else {
      array.push(this.createNewLocationEntry());
    }
    return array;
  }

  createNewLocationEntry(entry?) {
    return this.fb.group(
      {
        id: this.fb.control(
          this.formControlUtils.mapProperty({ entry, property: 'id' })
        ),
        room: this.fb.control({
          id:
            this.formControlUtils.mapProperty({
              entry,
              property: 'room.id',
            }) ||
            this.formControlUtils.mapProperty({
              entry,
              property: 'location.id',
            }),
          value:
            this.formControlUtils.mapProperty({
              entry,
              property: 'room.value',
            }) ||
            this.formControlUtils.mapProperty({
              entry,
              property: 'location.value',
            }),
        }),
        locationStartTime: this.fb.control(
          this.formControlUtils.mapPropertyForTime({
            entry,
            property: 'locationStartTime',
          }) ||
            this.formControlUtils.mapPropertyForTime({
              entry,
              property: 'startTime',
            }),
          [this.valid24HourTime()]
        ),
        locationEndTime: this.fb.control(
          this.formControlUtils.mapPropertyForTime({
            entry,
            property: 'locationEndTime',
          }) ||
            this.formControlUtils.mapPropertyForTime({
              entry,
              property: 'endTime',
            }),
          [this.valid24HourTime()]
        ),
        ems: this.fb.control(
          this.formControlUtils.mapProperty({ entry, property: 'ems' }),
          RxwebValidators.pattern({
            expression: { eightDigitsOnly: /^[0-9]{8}$/ },
            message: 'Enter a valid EMS with exactly 8 digits only please',
          })
        ),
      },
      {
        validators: this.validateLocationDates,
      }
    );
  }

  private valid24HourTime() {
    return RxwebValidators.pattern({
      expression: {
        valid12HourTime: /^([01][0-9]|2[0-3]):([0-5][0-9])$/,
      },
      message: 'Enter a valid 24 hour time please, e.g: 13:00',
    });
  }

  private calculateHours(startTime: string, endTime: string) {
    const checkStartTime: string = startTime || '';
    const startTimeHours: number = Number(checkStartTime.split(':')[0]);
    const startTimeMinutes: number = Number(checkStartTime.split(':')[1]);
    const startTInMinutes: number = startTimeHours * 60 + startTimeMinutes;

    const checkEndTime: string = endTime || '';
    const endTimeHours: number = Number(checkEndTime.split(':')[0]);
    const endTimeMinutes: number = Number(checkEndTime.split(':')[1]);
    const endTInMinutes: number = endTimeHours * 60 + endTimeMinutes;

    const resultInHours: number = (endTInMinutes - startTInMinutes) / 60;
    return resultInHours.toFixed(2) === 'NaN'
      ? '0'
      : resultInHours.toFixed(2).toString();
  }

  private validateLocationDates(formGroup: FormGroup) {
    const startTime = formGroup.get('locationStartTime');
    const endTime = formGroup.get('locationEndTime');

    if (
      (!startTime.errors || !startTime.getError('valid12HourTime')) &&
      startTime.value != '' &&
      endTime.value != '' &&
      startTime.value > endTime.value &&
      !endTime.getError('valid12HourTime')
    ) {
      endTime.setErrors({
        lessThan: {
          value: true,
          message: 'End Time cannot be before Start Time',
        },
      });
    } else {
      endTime.setErrors({ lessThan: null });
      endTime.updateValueAndValidity({ onlySelf: true });
    }

    return null;
  }

  private crossValidateAllDateTimes(formGroup: FormGroup) {
    const clientArrival = formGroup.get('clientArrival');
    const startTime = formGroup.get('startTime');
    const endTime = formGroup.get('endTime');

    if (
      (startTime.errors && startTime.getError('valid12HourTime')) ||
      (!startTime.errors && endTime.errors && clientArrival.errors)
    ) {
      return null;
    }

    const clearStartTimeErrors = () => {
      startTime.setErrors({ invalidStartTime: null });
      startTime.updateValueAndValidity({ onlySelf: true });
    };

    const clearArrivalTimeErrors = () => {
      clientArrival.setErrors({ invalidClientArrival: null });
      clientArrival.updateValueAndValidity({ onlySelf: true });
    };

    const clearEndTimeErrors = () => {
      endTime.setErrors({ crossValidation: null });
      endTime.updateValueAndValidity({ onlySelf: true });
    };

    clearStartTimeErrors();
    if (!clientArrival.errors) {
      clearArrivalTimeErrors();
    }
    if (
      startTime.value != '' &&
      clientArrival.value != '' &&
      startTime.value < clientArrival.value &&
      !clientArrival.getError('valid12HourTime')
    ) {
      clientArrival.setErrors({
        invalidClientArrival: true,
      });
    } else {
      clearArrivalTimeErrors();
    }
    if (!endTime.errors) {
      clearEndTimeErrors();
    }
    if (
      startTime.value != '' &&
      endTime.value != '' &&
      startTime.value > endTime.value &&
      !endTime.getError('valid12HourTime')
    ) {
      startTime.setErrors({ invalidStartTime: true });
    } else {
      clearStartTimeErrors();
    }

    return null;
  }
}
