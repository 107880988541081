<div [formGroup]="parentForm">
  <div class="duration__radios">
    <div class="duration__option">
      <ioh-radio-button
        *ngFor="let option of options"
        radioId="{{ option.id }}"
        field="duration"
        label="{{ option.label }}"
        font="{{ option.font }}"
        [formGroup]="parentForm"
        [values]="option.value"
      ></ioh-radio-button>
    </div>
    <small class="duration__hint"
      >How long of a visit are you looking for?</small
    >
  </div>
  <div class="duration__dates" *ngIf="selectedRadio">
    <div class="duration__dates__title mb">Requested Date(s)</div>
    <ng-container formArrayName="dates">
      <div *ngFor="let date of dates.controls; let indexOrg = index">
        <ioh-request-dates
          [formGroupName]="indexOrg"
          [field]="indexOrg"
          [dateType]="selectedRadio"
          [minDate]="minDate"
          (dateDeleted)="deleteDate($event)"
          [waitlist]="waitlist"
        ></ioh-request-dates>
      </div>
      <div class="duration__dates__details__button">
        <button
          mat-flat-button
          color="primary"
          class="square"
          (click)="addDates(selectedRadio)"
          [disabled]="dates.length === dateLimit"
        >
          <span class="material-icons">
            add
          </span>
        </button>
        <span class="title">Add Dates</span>
      </div>
      <small class="duration__hint"
        >Please select up to {{dateLimit}} preferred dates for your visit.</small
      >
    </ng-container>
  </div>
</div>
