import * as AdminActions from './admin.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { OrchestratorService } from '../../../orchestrator-service';
import { ReferenceDataService } from '../../../reference-data';
import { UserAccessService } from '../../../user-access';
import { catchError, delay, exhaustMap, map, retry } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class AdminEffects {
  loadAllWorkshopsByUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.loadAllWorkshopsByUserId),
      exhaustMap((action) =>
        this.orchestratorService.getAllWorkshopsByUserId(action.userId).pipe(
          map((res) =>
            AdminActions.loadAllWorkshopsByUserIdSuccess({
              workshops: res,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              AdminActions.loadAllWorkshopsByUserIdFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  updateWorkshopById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.updateWorkshopById),
      exhaustMap((action) =>
        this.orchestratorService.updateWorkshopById(action.workshop).pipe(
          map((res) =>
            AdminActions.updateWorkshopByIdSuccess({
              response: res,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              AdminActions.updateWorkshopByIdFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  updateAdminById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.updateAdminById),
      exhaustMap((action) =>
        this.orchestratorService.updateAdminById(action.admin).pipe(
          map((res) =>
            AdminActions.updateAdminByIdSuccess({
              response: res,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              AdminActions.updateAdminByIdFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  getProgramById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.getProgramById),
      exhaustMap((action) =>
        this.orchestratorService.getProgramById(action.programId).pipe(
          map((res) =>
            AdminActions.getProgramByIdSuccess({
              programCode: res.data.code,
            })
          ),
          catchError((error) =>
            of(
              AdminActions.getProgramByIdFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  loadWorkshopStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.getWorkshopStatus),
      exhaustMap((action) =>
        this.referenceDataService.getWorkshopStatus().pipe(
          map((res) =>
            AdminActions.getWorkshopStatusSuccess({
              workshopStatus: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              AdminActions.getWorkshopStatusFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  loadCancellationReason$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.getCancellationReason),
      exhaustMap((action) =>
        this.referenceDataService.getCancellationReason().pipe(
          map((res) =>
            AdminActions.getCancellationReasonSuccess({
              cancellationReasons: res.data,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              AdminActions.getCancellationReasonFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  removeUserAccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.removeUserAccess),
      exhaustMap((action) =>
        this.adminUserService.removeAccess(action.userAccess).pipe(
          map((res) =>
            AdminActions.removeUserAccessSuccess({
              response: res,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              AdminActions.removeUserAccessFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  loadUserNameSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.loadUserNameSearchResults),
      exhaustMap((action) =>
        this.referenceDataService
          .getPeopleProfile({
            exactMatch: action.searchQuery.exactmatch,
            requestId: action.searchQuery.requestid,
            searchText: action.searchQuery.searchtxt,
          })
          .pipe(
            map((res: any) =>
              AdminActions.loadUserNameSearchResultsSuccess({
                userNameSearchResults: res.data,
              })
            ),
            catchError((error) =>
              of(
                AdminActions.loadUserNameSearchResultsFailure({
                  error,
                })
              )
            )
          )
      )
    )
  );

  loadAccessUserListByUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.loadAccessUserListByUserId),
      exhaustMap((action) =>
        this.adminUserService.getAccessUserListByUserId(action.userId).pipe(
          map((res) =>
            AdminActions.loadAccessUserListByUserIdSuccess({
              userInfo: res,
            })
          ),
          retry(4),
          delay(1000),
          catchError((error) =>
            of(
              AdminActions.loadAccessUserListByUserIdFailure({
                error: error,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly orchestratorService: OrchestratorService,
    private readonly referenceDataService: ReferenceDataService,
    private readonly adminUserService: UserAccessService
  ) {}
}
