import { AttendeeNumberService } from '@ioh/ui-library';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  of,
} from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ConsultationFacade,
  ConsultationState,
  IConsultationModelDb,
  IOpportunityStateModel,
} from '@ioh/core-data';
import { ConsultationTabService } from './consultation-tab.service';
import {
  CustomDateValidators,
  LookUpValidatorsService,
} from '@utils/validators';
import { DateFormatService, WorkshopService } from '@services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { browser } from '@utils/browserUtils';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  tap,
} from 'rxjs/operators';
import {
  radioBtnConsultationFields,
  textAreasConsultation,
} from '@assets/data/consultation-details';
import { radioOptions } from '@assets/data/visit-details';
import add from 'date-fns/add';
import startOfToday from 'date-fns/startOfToday';

@Component({
  selector: 'ioh-consultation-tab',
  styleUrls: ['./consultation-tab.component.scss'],
  templateUrl: './consultation-tab.component.html',
})
export class ConsultationTabComponent implements OnInit, OnDestroy {
  consultationGroup: FormGroup;
  options = radioOptions;
  textareas = textAreasConsultation;
  opportunityLimit = 10;
  consultationOwnerLimit = 1;
  radioValues = radioBtnConsultationFields;
  today: Date = startOfToday();
  weekFromToday: Date = add(this.today, { weeks: 1 });
  dateLimit = 10;
  allConsultationOwnerResultsLoading$ = new BehaviorSubject(false);
  warning: boolean = false;
  opportunityIdResultsLoading$ = new BehaviorSubject(false);
  consultationStateObject$: ConsultationState;
  prepopulated: boolean | null;
  browser = browser;

  allConsultationOwnerResults$ = this.consultationFacade.allConsultationOwnerSearchResults$.pipe(
    tap(() => this.allConsultationOwnerResultsLoading$.next(false)),
    filter((x) => !!x),
    map((res) => res.map((item) => item.enterpriseID))
  );

  internalPurposes$: Observable<any> = this.consultationFacade
    .allInternalPurposes$;

  allOpportunityIdSearchResults$ = this.consultationFacade.allOpportunityIdSearchResults$.pipe(
    tap(() => this.opportunityIdResultsLoading$.next(false)),
    filter((x) => !!x),
    map((res: IOpportunityStateModel[]) =>
      res.map(
        (item: IOpportunityStateModel) =>
          `${item.OpportunityId}-${item.OpportunityDescription}`
      )
    )
  );

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly lookUpValidator: LookUpValidatorsService,
    private readonly consultationFacade: ConsultationFacade,
    private readonly dateValidator: CustomDateValidators,
    private readonly workshopService: WorkshopService,
    public consultationTabService: ConsultationTabService,
    private readonly dateFormatService: DateFormatService,
    public attendeeNumberService: AttendeeNumberService
  ) {}

  ngOnInit() {
    this.consultationGroup = this.fb.group({
      additionalComments: this.fb.control([]),
      attendeeDetails: this.fb.control(''),
      audienceType: this.fb.control(''),
      backgroundText: this.fb.control(''),
      challengesText: this.fb.control(''),
      completeConsultationDate: this.fb.control(''),
      consultationOwner: this.fb.group({
        consultationOwnerInput: this.fb.control(''),
        consultationOwnerResult: this.fb.array(
          [],
          [
            this.lookUpValidator.validateRequired,
            this.lookUpValidator.validateLimit(this.consultationOwnerLimit),
          ]
        ),
      }),
      dates: this.fb.array([], this.dateValidator.trigger),
      duration: this.fb.control(''),
      leadershipLevel: this.fb.control(''),
      attendeeNumber: this.attendeeNumberService.createAttendeeNumber(),
      objectivesText: this.fb.control(''),
      opportunityInput: this.fb.group({
        input: this.fb.control(''),
        oppResult: this.fb.array(
          [],
          [
            this.lookUpValidator.validateRequired,
            this.lookUpValidator.validateLimit(this.opportunityLimit),
          ]
        ),
      }),
      purposeOptions: this.fb.control('', Validators.required),
      softlockExpirationDate: this.fb.control(this.weekFromToday),
      successCriteria: this.fb.control(''),
      traveling: this.fb.control(''),
    });

    // this.setInitialsoftlockExpirationDate();
    this.registerSubscriptions();
  }

  private registerSubscriptions(): void {
    this.updateDuration();
    this.updateDates();
    this.updateSoftlockExpirationDate();
    this.fetchOpportunityIdSearchResults();
    this.updateOpportuniyId();
    this.updateCompleteConsultationDate();
    this.updateBackgroundText();
    this.updateObjectivesText();
    this.updateSuccessText();
    this.updateClientChallenges();
    this.loadInternalPurpose();
    this.updateSelectedInternalPurpose();
    this.fetchConsultationOwnerSearchResults();
    this.updateConsultationOwner();
    this.updateAdditionalComments();
    this.updateNumberOfAttendees();
    this.updateLeadershipLevel();
    this.updateAudienceType();
    this.updateTraveling();
    this.updateAttendeeDetails();
    this.getConsultationState();
    this.checkIfPrepopulated();
    this.updateSelectedWorkshopConsultation();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadInternalPurpose(): void {
    this.workshopService.programId$.subscribe((programId) => {
      this.consultationFacade.loadInternalPurposeResults(programId);
    });
  }

  getConsultationState(): void {
    this.subscriptions.add(
      this.consultationFacade.allConsultationTab$.subscribe(
        (consultationState) => {
          this.consultationStateObject$ = consultationState;
        }
      )
    );
  }

  isANewWorkshop$: Observable<boolean> = of(
    this.router.url.includes('/add-booking')
  );

  checkIfPrepopulated(): void {
    this.subscriptions.add(
      this.consultationFacade.prepopulated$.subscribe(
        (prepopulated) => (this.prepopulated = prepopulated)
      )
    );
  }

  /**

    If the consultation tab state object is empty
    then prepopulate from the database
    if the consultation tab state object is NOT empty
    this is because the user has made changes
    and as such, the store is the most up to date
    source of truth and so the form should be prepopulated
    with the store object
    */
  updateSelectedWorkshopConsultation(): void {
    this.subscriptions.add(
      combineLatest(
        this.isANewWorkshop$,
        this.workshopService.selectedWorkshopConsultation$
      ).subscribe(([isANewWorkshop, workshop]) => {
        if (isANewWorkshop) {
          this.handleNewWorkshopMapping();
        } else if (!isANewWorkshop && workshop) {
          this.handleExistingWorkshopMapping(workshop);
        }
      })
    );
  }

  handleNewWorkshopMapping(): void {
    if (this.prepopulated) {
      this.consultationTabService.prepopulateConsultationTabWithStateObject(
        this.consultationStateObject$,
        this.consultationGroup
      );
    } else {
      this.consultationFacade.updatePrepopulated(true);
    }
  }

  private handleExistingWorkshopMapping(workshop: IConsultationModelDb): void {
    if (!this.prepopulated && workshop) {
      this.consultationTabService.prepopulateConsultationTabWithDBResponse(
        workshop,
        this.consultationGroup
      );
      this.consultationFacade.updatePrepopulated(true);
    } else {
      this.consultationTabService.prepopulateConsultationTabWithStateObject(
        this.consultationStateObject$,
        this.consultationGroup
      );
    }
  }

  private getPastDateCondition(value: Date): boolean {
    const now = new Date();
    const today: Date = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate()
    );

    const condition =
      value instanceof Date && value.getTime() < today.getTime();

    return condition;
  }

  updateDuration(): void {
    this.subscriptions.add(
      this.consultationGroup
        .get('duration')
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((res) => {
          this.consultationFacade.updateDuration(res);
        })
    );
  }

  updateDates(): void {
    this.subscriptions.add(
      this.consultationGroup
        .get('dates')
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((res) => {
          const transRes = res.map((elem) => {
            const transformed = { ...elem };
            if (elem.date) {
              const newDate = this.dateFormatService.getDateToSave(elem.date);
              transformed.date = newDate;
            }
            if (elem.startDate) {
              const newStartDate = this.dateFormatService.getDateToSave(
                elem.startDate
              );
              transformed.startDate = newStartDate;
            }
            if (elem.endDate) {
              const newEndDate = this.dateFormatService.getDateToSave(
                elem.endDate
              );
              transformed.endDate = newEndDate;
            }
            return transformed;
          });
          this.consultationFacade.updateDates(transRes);
        })
    );
  }

  updateSoftlockExpirationDate(): void {
    this.subscriptions.add(
      this.consultationGroup
        .get('softlockExpirationDate')
        .valueChanges.pipe(distinctUntilChanged())
        .subscribe((res) => {
          this.warning = this.getPastDateCondition(res);
          const newRes = this.dateFormatService.getDateToSave(res);
          this.consultationFacade.updateSoftlockExpirationDate(newRes);
        })
    );
  }

  updateCompleteConsultationDate(): void {
    this.subscriptions.add(
      this.consultationGroup
        .get('completeConsultationDate')
        .valueChanges.subscribe((res) => {
          const newRes = this.dateFormatService.getDateToSave(res);
          this.consultationFacade.updateCompleteConsultationDate(newRes);
        })
    );
  }

  updateBackgroundText(): void {
    this.subscriptions.add(
      this.consultationGroup
        .get('backgroundText')
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((res) => {
          this.consultationFacade.updateBackgroundText(res);
        })
    );
  }

  updateObjectivesText(): void {
    this.subscriptions.add(
      this.consultationGroup
        .get('objectivesText')
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((res) => {
          this.consultationFacade.updateObjectivesText(res);
        })
    );
  }

  updateSuccessText(): void {
    this.subscriptions.add(
      this.consultationGroup
        .get('successCriteria')
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((res) => {
          this.consultationFacade.updateSuccessText(res);
        })
    );
  }

  updateClientChallenges(): void {
    this.subscriptions.add(
      this.consultationGroup
        .get('challengesText')
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((res) => {
          this.consultationFacade.updateClientChallenges(res);
        })
    );
  }

  updateSelectedInternalPurpose(): void {
    this.subscriptions.add(
      this.consultationGroup
        .get('purposeOptions')
        .valueChanges.subscribe((change) => {
          this.consultationFacade.updateInternalPurpose(change);
        })
    );
  }

  updateOpportuniyId(): void {
    this.subscriptions.add(
      this.consultationGroup
        .get('opportunityInput')
        .get('oppResult')
        .valueChanges.subscribe((res) => {
          this.opportunityIdResultsLoading$.next(true);
          this.consultationFacade.updateSelectedOpportunityIds(res);
        })
    );
  }

  fetchOpportunityIdSearchResults(): void {
    this.subscriptions.add(
      this.consultationGroup
        .get('opportunityInput')
        .get('input')
        .valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          filter((res) => res)
        )
        .subscribe((res) => {
          this.opportunityIdResultsLoading$.next(true);
          this.consultationFacade.loadOpportunityIdSearchResults(res);
        })
    );
  }

  updateAdditionalComments(): void {
    this.subscriptions.add(
      this.consultationGroup
        .get('additionalComments')
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((res) => {
          this.consultationFacade.updateAdditionalComments(res);
        })
    );
  }

  get numberOfAttendees(): FormGroup {
    return this.consultationGroup.get('attendeeNumber') as FormGroup;
  }

  updateNumberOfAttendees(): void {
    this.subscriptions.add(
      this.consultationGroup
        .get('attendeeNumber')
        .valueChanges.subscribe(() => {
          this.consultationFacade.updateNumberOfAttendees(
            this.numberOfAttendees.getRawValue()
          );
        })
    );
  }

  updateTotal(totalAttendee: number) {
    this.consultationFacade.updateTotalAttendees(totalAttendee);
  }

  updateLeadershipLevel(): void {
    this.subscriptions.add(
      this.consultationGroup
        .get('leadershipLevel')
        .valueChanges.subscribe((res) => {
          this.consultationFacade.updateLeadershipLevel(res);
        })
    );
  }

  updateAudienceType(): void {
    this.subscriptions.add(
      this.consultationGroup
        .get('audienceType')
        .valueChanges.subscribe((changes) => {
          this.consultationFacade.updateAudienceType(changes);
        })
    );
  }

  updateTraveling(): void {
    this.subscriptions.add(
      this.consultationGroup
        .get('traveling')
        .valueChanges.subscribe((changes) => {
          this.consultationFacade.updateTraveling(changes);
        })
    );
  }

  updateAttendeeDetails(): void {
    this.subscriptions.add(
      this.consultationGroup
        .get('attendeeDetails')
        .valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          map((res) => res)
        )
        .subscribe((changes) => {
          this.consultationFacade.updateAttendeeDetails(changes);
        })
    );
  }

  updateConsultationOwner(): void {
    this.subscriptions.add(
      this.consultationGroup
        .get('consultationOwner')
        .get('consultationOwnerResult')
        .valueChanges.subscribe((res) => {
          this.allConsultationOwnerResultsLoading$.next(true);
          this.consultationFacade.updateConsultationOwner(res);
        })
    );
  }

  fetchConsultationOwnerSearchResults(): void {
    this.subscriptions.add(
      this.consultationGroup
        .get('consultationOwner')
        .get('consultationOwnerInput')
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((res) => {
          this.allConsultationOwnerResultsLoading$.next(true);
          this.consultationFacade.loadConsultationOwnerSearchResults({
            searchtxt: res,
          });
        })
    );
  }
}
