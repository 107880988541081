import { FormArray, FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CustomDateValidators {
  checkDateTimeline(groupControl: FormGroup): { [s: string]: boolean } {
    if (groupControl) {
      const controlStartDate =
        groupControl.get('startDate') ||
        groupControl.get('engagementStartDate');
      const controlEndDate =
        groupControl.get('endDate') || groupControl.get('engagementEndDate');
      const startDateValue = controlStartDate.value;
      const endDateValue = controlEndDate.value;

      const condition =
        startDateValue instanceof Date &&
        endDateValue instanceof Date &&
        startDateValue.getTime() >= endDateValue.getTime();

      if (condition) {
        return { timeline: true };
      }
    }
    return null;
  }

  trigger(formArray: FormArray): { [s: string]: boolean } {
    formArray.controls.forEach((elem: FormGroup) => {
      setTimeout(() => {
        elem.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      }, 0);
    });
    return null;
  }

  // 1) Substract FormGroup to FormArray
  // 2) Find From Result of 1) Form Group
  // 3) if found is duplicated then return { uniqueGroup: true } else null
  checkUniqueGroup(groupControl?: FormGroup): { [s: string]: boolean } {
    const groupValue = groupControl.value;
    const arrayValue = (<FormArray>groupControl.parent)?.getRawValue();

    // Step 1)
    const findIndex = arrayValue?.findIndex((elem) =>
      this.getEqualityCondition(elem, groupValue)
    );

    arrayValue?.splice(findIndex, 1);

    // Step 2)
    const findDup = arrayValue?.find((elem) =>
      this.getEqualityCondition(elem, groupValue)
    );

    // Step 3)
    const result = findDup ? { uniqueGroup: true } : null;

    return result;
  }

  // Get date & period for halfDay Or
  // Get startDate & endDate for multidate
  private getEqualityCondition(elem, elemToCompare): boolean {
    const firstElem = elem.date ? elem.date : elem.startDate;
    const firstElemToCompare = elemToCompare.date
      ? elemToCompare.date
      : elemToCompare.startDate;

    const secondElem = elem.period ? elem.period.value : elem.endDate;
    const secondElemToCompare = elemToCompare.period
      ? elemToCompare.period.value
      : elemToCompare.endDate;

    const conditionDateFn = (date1, date2) =>
      date1 instanceof Date &&
      date2 instanceof Date &&
      date1.getTime() === date2.getTime();

    const conditionFirstElem = conditionDateFn(firstElem, firstElemToCompare);
    const conditionSecondElem = elem.period
      ? secondElem === secondElemToCompare
      : conditionDateFn(secondElem, secondElemToCompare);

    return conditionFirstElem && conditionSecondElem;
  }
}
