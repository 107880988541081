<div class="backoffice">
  <!--
    PHASE 2
    <header class="backoffice__search mat-elevation-z3">
      <ioh-search></ioh-search>
    </header>
  -->
  <main class="backoffice__main">
    <div class="backoffice__main__container">
      <router-outlet></router-outlet>
    </div>
  </main>
  <aside class="backoffice__menu">
    <ioh-menu class="backoffice__menu__content"></ioh-menu>
  </aside>
</div>
<ioh-loading *ngIf="this.appLoadingService.loading"></ioh-loading>