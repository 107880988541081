import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ExcelExportService } from 'apps/ioh-app/src/app/services';
import { IEngagement } from '@utils/interfaces';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import {
  allEngagementColNames,
  totalRecoveryReportColNames,
} from '@assets/data/tables/table-columns';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'ioh-list-view',
  styleUrls: ['./list-view.component.scss'],
  templateUrl: './list-view.component.html',
})
export class ListViewComponent implements OnInit {
  @Input() data$: Observable<any>;
  @Input() totalRecovery$: Observable<any>;
  @Input() colNames;
  @Input() tableName: string;
  @Input() colour;
  @Input() urgentTableName: boolean = false;
  /**
   * Default to true because all uses of the table, EXCEPT in the ioh-org component
   * should allow rerouting
   */
  @Input() allowReroute: boolean = true;
  @Output() onEdit = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onAddUser = new EventEmitter();
  colNamesKeys: string[];
  totalRecoverycolNamesKeys: string[];
  totalRecoveryReportColNames = totalRecoveryReportColNames;
  allEngagementColNames = allEngagementColNames;
  dataSource: MatTableDataSource<IEngagement>;
  allData: object[];
  totalRecoveryReport: object[];
  currentUser = sessionStorage.getItem('userId');
  @Input() pageLength = 10;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable) table;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private readonly router: Router,
    private readonly exportService: ExcelExportService
  ) {}

  ngOnInit(): void {
    this.colNamesKeys = Object.keys(this.colNames);
    this.totalRecoverycolNamesKeys = Object.keys(
      this.totalRecoveryReportColNames
    );
    this.data$.pipe(filter((v) => v)).subscribe((val) => {
      this.allData = val;
      this.dataSource = new MatTableDataSource<IEngagement>(val);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'listViewId':
            return +item.id;
          case 'total':
            return +item.total;
          case 'totalCosts':
            return +item.totalCosts;
          case 'attendees':
            return +item.attendees;
          case 'start':
            return item.start ? new Date(item.start) : null;
          case 'chargedate':
            return item.chargedate ? new Date(item.chargedate) : null;
          case 'end':
            return item.end ? new Date(item.end) : null;
          case 'expiration':
            return item.expiration ? new Date(item.expiration) : null;
          case 'complete':
            return item.complete ? new Date(item.complete) : null;
          case 'waitlist':
            return item.waitlist ? new Date(item.waitlist) : null;
          case 'createdAt':
            return item.createdAt ? new Date(item.createdAt) : null;
          default:
            return item[property];
        }
      };
      this.dataSource.filterPredicate = (data, filter) => {
        return (
          this.colNamesKeys.filter(
            (col) =>
              col != 'action' &&
              !!data[col] &&
              data[col].toString().trim().toLowerCase().includes(filter)
          ).length > 0
        );
      };
    });

    this.totalRecovery$ &&
      this.totalRecovery$.subscribe((val) => {
        this.totalRecoveryReport = val;
      });
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editRow(element): void {
    this.onEdit.emit(element);
  }

  deleteRow(element): void {
    this.onDelete.emit(element);
  }

  addUser(): void {
    this.onAddUser.emit();
  }

  openWorkshop(element) {
    if (this.allowReroute && this.tableName !== 'User List') {
      sessionStorage.setItem('listView', this.router.url);
      this.router.navigate(['/admin/request', element.id], {
        state: { workshop: element },
      });
    }
  }

  exportTable() {
    const exportData = this.allData.map((row) => {
      const filteredRow = {};
      this.colNamesKeys.forEach((key) => (filteredRow[key] = row[key]));
      return filteredRow;
    });
    this.exportService.exportTable(
      exportData,
      this.tableName,
      this.allEngagementColNames
    );
  }

  exportTotalRecoveryTable() {
    const totalRecoveryReportData = this.totalRecoveryReport.map((row) => {
      const filteredRow = {};
      this.totalRecoverycolNamesKeys.forEach(
        (key) => (filteredRow[key] = row[key])
      );
      return filteredRow;
    });
    this.exportService.exportTable(
      totalRecoveryReportData,
      this.tableName,
      this.totalRecoveryReportColNames
    );
  }
}
