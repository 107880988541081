<div [ngClass]="{'margin-top-7':tooltipObj}"
  fxLayout="{{direction}}"
  class="dropdown"
  [formGroup]="parentForm">
  <label
    fxFlex
    attr.for={{dropdownId}}
    class="dropdown__label"
    *ngIf="label"
    [class.dropdown__label__alignment]="direction === 'row'"
    >
      <span *ngIf="required === true" class="dropdown__asterisk">*</span>
        {{label}}<ioh-tooltip [detail]="tooltipObj"></ioh-tooltip></label>
    <mat-form-field appearance="outline" class="dropdown__field">
      <mat-select
        [formControlName]="field"
        multiple="{{isMultiple}}"
        placeholder="{{placeholder}}"
        id="{{dropdownId}}"
        [attr.aria-label]="field"
        [compareWith]="compareFn"
        [disabled]="isDisabled"
        >
        <mat-option title="{{value.tooltip}}"
          *ngFor="let value of values"
          [value]="value"
          >
          {{value.value}}
       
        </mat-option>
      </mat-select>
    </mat-form-field>
    <ioh-error
    class="dropdown__error"
    *ngIf="(parentForm.touched || parentForm.dirty) &&
      (parentForm.hasError('unique'))"
    errorMsg="This range was already selected!">
  </ioh-error>
    <ioh-error
      class="dropdown__error"
      *ngIf="(parentForm.get(field).touched || parentForm.get(field).dirty) && parentForm.get(field).hasError('required')"
      errorMsg="{{label || 'Period'}} is required"></ioh-error>
</div>
