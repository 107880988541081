export const programDropdown = {
  direction: 'column',
  dropdownId: 'programId',
  isMultiple: false,
  label: 'Program',
  name: 'Program ID',
  placeholder: 'Select a program',
  required: true,
};

export const accessLevelSetting = {
  direction: 'column',
  dropdownId: 'accessLevelId',
  isMultiple: false,
  label: 'Access Level',
  name: 'Access Level',
  placeholder: 'Select a Access Level',
  required: true,
};

export const accessLevelList = [
  {
    id: '001',
    value: 'Normal User',
  },
  {
    id: '002',
    value: 'Admin',
  },
];
