<mat-accordion multi="true">
  <div *ngIf="!showStatus">
    <div *ngFor="let section of sections">
      <mat-expansion-panel class="mat-elevation-z0 panel" [expanded]="true">
        <mat-expansion-panel-header
          class="panel__header"
          [collapsedHeight]="'6rem'"
          [expandedHeight]="'6rem'"
          fxLayoutAlign="start end"
        >
          <ioh-accordian-header>
            {{section.title}}
          </ioh-accordian-header>
        </mat-expansion-panel-header>
        <ioh-accordian-body>
          <ng-container *ngComponentOutlet="section.component"></ng-container>
        </ioh-accordian-body>
      </mat-expansion-panel>
    </div>
  </div>
</mat-accordion>