import { AdminFacade } from 'libs/core-data/src/lib/state/back-office/admin/admin.facade';
import { AppLoadingService } from 'libs/ui-library/src/lib/app-loading/app-loading.service';
import { Component } from '@angular/core';
import { ConsultationFacade, GeneralFacade } from '@ioh/core-data';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'ioh-back-office',
  styleUrls: ['./back-office.component.scss'],
  templateUrl: './back-office.component.html',
})
export class BackOfficeComponent {
  queryParam: string = '';
  userId = this.authService.getAccount().userName.split('@')[0];
  isAdmin = false;
  constructor(
    private readonly adminFacade: AdminFacade,
    private readonly authService: MsalService,
    public readonly appLoadingService: AppLoadingService,
    private readonly generalTabFacade: GeneralFacade,
    private readonly consultationFacade: ConsultationFacade,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.adminFacade.getWorkshopStatus();
    this.adminFacade.getCancellationReasons();
    this.generalTabFacade.getEngagementTypeResults('');
    this.generalTabFacade.getEngagementGroupResults('');
    this.generalTabFacade.getEngagementGroupEcosystemResults('');
    this.generalTabFacade.getOrganizationTypes();
    this.generalTabFacade.getAccentureEntities();
    this.generalTabFacade.getExternalOrganizationCategoriesResults();
    this.consultationFacade.loadInternalPurposeResults('');
    this.adminFacade.getAdminProgramList$.subscribe((adminProgramList) => {
      this.isAdmin = adminProgramList.length > 0;
    });

    if (sessionStorage.getItem('path')) {
      // if user isn't admin and want to navaigate to admin/user-access, system will redirect to admin/home
      if (
        sessionStorage.getItem('path') == 'admin/user-access' &&
        !this.isAdmin
      ) {
        const path = 'admin/home';
        this.router.navigateByUrl(path);
      }
      // for building the login url in case of logout
      sessionStorage.setItem('loginPath', sessionStorage.getItem('path'));
      sessionStorage.removeItem('path');
    }
    this.adminFacade.saveUserId(this.userId);
    sessionStorage.setItem('userId', this.userId);
    this.adminFacade.loadAllWorkshopsByUserId(this.userId);
  }
}
