import { ActivatedRoute, Router } from '@angular/router';
import {
  AdditionalNotesFacade,
  AdditionalNotesState,
  AttendeeDetailsFacade,
  AttendeeDetailsState,
  ContactDetailsFacade,
  ContactDetailsState,
  EngagementDetailsFacade,
  EngagementDetailsState,
  FormFacade,
  VisitDetailsFacade,
  VisitDetailsState,
} from '@ioh/core-data';
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormService, ModalService } from '@services';
import { ITermsConditions } from '@ioh/types';
import { MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'ioh-form',
  styleUrls: ['./form.component.scss'],
  templateUrl: './form.component.html',
})
export class FormComponent implements OnInit, OnDestroy {
  contactDetails: ContactDetailsState;
  engagementDetails: EngagementDetailsState;
  visitDetails: VisitDetailsState;
  attendeeDetails: AttendeeDetailsState;
  additionalNotes: AdditionalNotesState;
  queryParam: string = '';
  contents: ITermsConditions;
  width = 'fit-content';
  baseForm: FormGroup;
  response = false;
  showStatus = false;
  formSubmitted: boolean = false;
  programId: string;
  errorMessage: string;

  userId = this.authService.getAccount().userName.split('@')[0];
  isSavingWorkshop = false;
  private readonly subscriptions: Subscription = new Subscription();
  @ViewChild('TermAndConditionTemplate') modalTemplate: TemplateRef<any>;
  @ViewChild('FailedSubmissionTemplate')
  failedSubmissionTemplate: TemplateRef<any>;

  @HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
    event.returnValue = false;
  }

  constructor(
    private readonly modalService: ModalService,
    private readonly formService: FormService,
    private readonly contactDetailsFacade: ContactDetailsFacade,
    private readonly engagementDetailsFacade: EngagementDetailsFacade,
    private readonly visitDetailsFacade: VisitDetailsFacade,
    private readonly attendeeDetailsFacade: AttendeeDetailsFacade,
    private readonly additionalNotesFacade: AdditionalNotesFacade,
    private readonly formFacade: FormFacade,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly authService: MsalService
  ) {}

  ngOnInit(): void {
    this.checkForPath();

    this.baseForm = this.formBuilder.group({
      checkbox: this.formBuilder.control(false, [Validators.requiredTrue]),
    });

    this.subscriptions.add(
      this.contactDetailsFacade.allContactDetails$.subscribe(
        (res) => (this.contactDetails = res)
      )
    );

    this.subscriptions.add(
      this.engagementDetailsFacade.allEngagementDetails$.subscribe(
        (res) => (this.engagementDetails = res)
      )
    );

    this.subscriptions.add(
      this.visitDetailsFacade.allVisitDetails$.subscribe(
        (res) => (this.visitDetails = res)
      )
    );

    this.subscriptions.add(
      this.attendeeDetailsFacade.allAttendeeDetails$.subscribe(
        (res) => (this.attendeeDetails = res)
      )
    );

    this.subscriptions.add(
      this.additionalNotesFacade.allAdditionalNotes$.subscribe(
        (res) => (this.additionalNotes = res)
      )
    );

    this.subscriptions.add(
      this.formFacade.termsAndConditions$.subscribe((res: ITermsConditions) => {
        this.contents = res;
      })
    );

    this.subscriptions.add(
      this.formFacade.formSubmitted$.subscribe((res: boolean) => {
        this.formSubmitted = res;
      })
    );

    this.subscriptions.add(
      this.formFacade.requestSuccessful$
        .pipe(filter((x) => !!x))
        .subscribe((requestSuccessful: boolean) => {
          if (
            this.formSubmitted &&
            this.router.url.includes('/request-form') &&
            requestSuccessful
          ) {
            this.router.navigate(['submit-complete'], {
              state: { response: requestSuccessful },
            });
            this.formFacade.clearSuccessfulRequest();
            this.contactDetailsFacade.clearStateObject();
            this.engagementDetailsFacade.clearStateObject();
            this.visitDetailsFacade.clearStateObject();
            this.attendeeDetailsFacade.clearStateObject();
            this.additionalNotesFacade.clearStateObject();
            this.isSavingWorkshop = false;
          }
        })
    );

    this.subscriptions.add(
      this.formFacade.error$
        .pipe(
          filter((error: any) =>
            error?.url.includes('orchestrator/frontoffice')
          )
        )
        .subscribe(() => {
          this.errorMessage =
            "Don't worry, all your request details are still stored in the form, just try clicking the submit button again in a few moments.";
          this.openFailedRequestDialog();
          this.isSavingWorkshop = false;
        })
    );

    this.subscriptions.add(
      this.formFacade.getProgramId$.subscribe((res) => {
        this.programId = res;
        this.formFacade.getTermsAndConditions(res);
      })
    );
  }

  openFailedRequestDialog() {
    this.modalService.openDialog(
      { template: this.failedSubmissionTemplate },
      this.width
    );
  }

  closeFailedSubmissionDialog() {
    this.modalService.closeDialog();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  checkForPath() {
    if (sessionStorage.getItem('path')) {
      // for building the login url in case of logout
      sessionStorage.setItem('loginPath', sessionStorage.getItem('path'));
      sessionStorage.removeItem('path');
    }
    this.route.queryParams.subscribe((params) => {
      if (params['program']) {
        this.queryParam = params['program'];
        sessionStorage.setItem('programId', this.queryParam);
      }
    });
    const programId = sessionStorage.getItem('programId');
    this.formFacade.saveProgramId(programId);
  }

  get termsAndConditionsAccepted() {
    return this.baseForm.get('checkbox').value;
  }

  async createRequest() {
    try {
      const request = await this.formService.formatRequestBody({
        programId: this.programId,
        contactDetails: this.contactDetails,
        engagementDetails: this.engagementDetails,
        visitDetails: this.visitDetails,
        attendeeDetails: this.attendeeDetails,
        additionalNotes: this.additionalNotes,
      });
      request.modifyBy = this.userId;
      request.createBy = this.userId;
      this.isSavingWorkshop = true;
      this.formFacade.createRequest(request);
    } catch (error) {
      const errorMsg: string = String(error).split('Error: ')[1];
      this.errorMessage = errorMsg;
      this.openFailedRequestDialog();
    }
  }

  openDialog(event): void {
    event.preventDefault();
    const dataModal = { template: this.modalTemplate };
    this.modalService.openDialog(dataModal, this.width);
  }

  onAccept(): void {
    this.baseForm.patchValue({ checkbox: true });
    this.modalService.closeDialog();
  }

  onDownload(): void {
    window.print();
  }
}
