import { AdminFacade } from '@ioh/core-data';
import { AdminService } from './admin.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { LookUpValidatorsService } from '@utils/validators';
import { ModalService } from 'apps/ioh-app/src/app/services/modal.service';
import { SnackbarService } from 'apps/ioh-app/src/app/services/snackbar.service';
import { TableService } from '../../../services/table.service';
import {
  accessLevelList,
  accessLevelSetting,
  programDropdown,
} from '@assets/data/admin-details';
import { adminColNames } from '@assets/data/tables/table-columns';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  tap,
} from 'rxjs/operators';
import { isNonNull } from '@utils/filters';

import isEmpty from 'lodash/isEmpty';

@Component({
  selector: 'ioh-admin',
  styleUrls: ['./admin.component.scss'],
  templateUrl: './admin.component.html',
})
export class AdminComponent {
  addUserButtonToggle$ = new BehaviorSubject<boolean>(false);
  updatedAdminSuccess$ = this.adminFacade.selectedAdminUpdatedSuccess$;
  saveAdminError$ = this.adminFacade.saveAdminError$;
  isDuplicateUser: boolean = false;
  defaultSortColumn = 'userId';
  adminFacadeStateObject: any = {};
  userInfo = [];
  programList = [];
  adminProgramList = [];
  accessLevelList = accessLevelList;

  data$ = new BehaviorSubject(null);
  colNames = adminColNames;
  selectHub = '';
  selectedAccess: any;
  widthDeleteWarningModal = 'fit-content';
  programSetting = programDropdown;
  accessLevelSetting = accessLevelSetting;
  userNameLimit = 1;
  widthWarningModal = '400px';
  allUserNameResultsLoading$ = new BehaviorSubject(false);

  allUserNameResults$ = this.adminFacade.allUserNameSearchResults$.pipe(
    tap(() => this.allUserNameResultsLoading$.next(false)),
    filter((x) => !!x),
    map((res) => res.map((item) => item.enterpriseID))
  );

  @ViewChild('UserTemplate') modalTemplate: TemplateRef<any>;
  adminGroup: FormGroup;
  private readonly subscriptions: Subscription = new Subscription();
  saveButtonToggle$ = this.adminService._saveButtonToggle$;
  removeAccessSuccess$ = this.adminService.removeSuccess$;
  @ViewChild('deleteAccessTemplate') removeModalTemplate: TemplateRef<any>;

  constructor(
    private readonly fb: FormBuilder,
    private readonly adminFacade: AdminFacade,
    private readonly adminService: AdminService,
    private readonly lookUpValidator: LookUpValidatorsService,
    private readonly modalService: ModalService,
    private readonly tableService: TableService,
    public snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.initAdminForm();
    this.handleSubscriptions();
  }

  handleSubscriptions() {
    this.getProgramList();
    this.getUserList();
    this.fetchUserNameSearchResults();
    this.updateUserName();
    this.fetchUserNameSearchResults();
    this.closeRemovePopup();
    this.updateProgram();
    this.updateAccessLevel();
    this.getAdminState();
    this.getUserSaveError();
    this.getUserSaveSuccess();
    this.getSeletedHub();
  }

  setAdminProgramList(): void {
    if (this.programList.length === 1) {
      this.adminGroup.get('program').setValue({
        id: this.programList[0].id,
        value: this.programList[0].value,
      });
    }
  }

  getUserSaveSuccess(): void {
    this.subscriptions.add(
      this.updatedAdminSuccess$.pipe(filter(isNonNull)).subscribe((success) => {
        this.addUserButtonToggle$.next(false);
        this.openConfirmationOfSaveModal();
      })
    );
  }

  getUserSaveError() {
    this.subscriptions.add(
      this.saveAdminError$.pipe(filter(isNonNull)).subscribe(() => {
        this.addUserButtonToggle$.next(false);
        this.openFailureToast();
      })
    );
  }

  getSeletedHub() {
    this.subscriptions.add(
      this.adminFacade.selectedAdminHub$.subscribe((selectedHub) => {
        this.selectHub = selectedHub;
        this.updateAllData();
      })
    );
  }

  openFailureToast(): void {
    this.snackbarService.openSnackBar({
      message: 'Oops! Something went wrong while saving.',
    });
  }

  openConfirmationOfSaveModal() {
    this.snackbarService.openSnackBar({
      message: 'User successfully updated!',
    });
    this.adminFacade.updateAdminSuccess(null);
    this.adminFacade.loadAccessUserListByUserId(
      sessionStorage.getItem('userId')
    );
    this.cancel();
  }

  getAdminState(): void {
    this.subscriptions.add(
      this.adminFacade.allAdmin$.subscribe((admin) => {
        this.adminFacadeStateObject = admin;
      })
    );
  }

  get getLookUpInput(): FormControl {
    return (this.adminGroup.get('userName') as FormGroup).get(
      'userNameInput'
    ) as FormControl;
  }

  checkAddDuplicateUser() {
    this.isDuplicateUser = false;
    this.subscriptions.add(
      this.adminGroup.valueChanges
        .pipe(filter((u) => u))
        .subscribe((userAccess) => {
          const userName = userAccess.userName.userNameResult;
          const programId = userAccess.program.id;
          if (
            !!userName &&
            userName.length == 1 &&
            !!userName[0] &&
            !!userName[0].chip.trim() &&
            !!programId
          ) {
            const matchUser = this.userInfo.filter((user) => {
              return (
                user.userId.toLowerCase() == userName[0].chip.toLowerCase() &&
                user.programId == programId
              );
            });
            if (matchUser.length > 0) {
              this.isDuplicateUser = true;
            } else {
              this.isDuplicateUser = false;
            }
          } else {
            this.isDuplicateUser = false;
          }
        })
    );
  }

  updateProgram() {
    this.subscriptions.add(
      this.adminGroup.get('program').valueChanges.subscribe((program) => {
        this.adminFacade.updateAdminProgram(program);
      })
    );
  }

  updateAccessLevel() {
    this.subscriptions.add(
      this.adminGroup
        .get('accessLevel')
        .valueChanges.subscribe((accessLevel) => {
          this.adminFacade.updateAccessLevel(accessLevel);
        })
    );
  }

  updateUserName() {
    this.subscriptions.add(
      this.adminGroup
        .get('userName')
        .get('userNameResult')
        .valueChanges.subscribe((res) => {
          this.allUserNameResultsLoading$.next(true);
          this.adminFacade.updateUserName(res);
        })
    );
  }

  fetchUserNameSearchResults(): void {
    this.subscriptions.add(
      this.adminGroup
        .get('userName')
        .get('userNameInput')
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((res) => {
          this.allUserNameResultsLoading$.next(true);
          this.adminFacade.loadUserNameSearchResults({
            searchtxt: res,
          });
        })
    );
  }

  initAdminForm(): void {
    this.adminGroup = this.fb.group({
      program: this.fb.control('', Validators.required),
      userName: this.fb.group({
        userNameInput: this.fb.control(''),
        userNameResult: this.fb.array(
          [],
          [
            this.lookUpValidator.validateRequired,
            this.lookUpValidator.validateLimit(this.userNameLimit),
          ]
        ),
      }),
      accessLevel: this.fb.control('', Validators.required),
    });
  }

  getUserList(): void {
    this.subscriptions.add(
      this.adminFacade.getUserInfoList$.subscribe((userInfo) => {
        if (!isEmpty(userInfo)) {
          this.formatUserInfo(userInfo);
        }
      })
    );
  }

  addCodeForUserInfo(user, program) {
    user.forEach((element) => {
      program.forEach((item) => {
        if (element.programId === item.id) {
          element.programCode = item.value;
        }
      });
    });
  }

  getProgramList(): void {
    this.subscriptions.add(
      this.adminFacade.getAdminProgramList$.subscribe((program) => {
        this.programList = this.formatProgramList(program);
      })
    );
  }

  public formatProgramList(programeList) {
    let programList = programeList.map((element) => {
      return {
        value: element.code,
        id: element.id,
      };
    });
    programList = programList.sort(this.sortName);
    return programList;
  }

  sortName(a, b) {
    var max = a.value.toUpperCase();
    var min = b.value.toUpperCase();
    if (max < min) {
      return -1;
    }
    if (max > min) {
      return 1;
    }
    return 0;
  }

  formatUserInfo(data: any[]) {
    this.userInfo = this.flattenAndFormatUserList(data);
    this.updateAllData();
  }

  flattenAndFormatUserList(dataList: any[]): any[] {
    const list = dataList.map((element, index: number) => ({
      id: element.id,
      programId: element.programId,
      userId: element.userId,
      action: 'delete',
      accessLevel: element.accessLevel,
      createdAt: this.tableService.formatDate(element.createdAt),
      createdBy: element.createdBy,
      index: index,
    }));
    this.addCodeForUserInfo(list, this.programList);
    const sortedList = this.tableService.sortTableDataByAlphabeticalOrder(
      list,
      this.defaultSortColumn
    );
    return sortedList;
  }

  updateAllData() {
    this.data$.next(this.userInfo);
    if (!this.selectHub) {
      return true;
    }
    this.data$.next(
      this.userInfo.filter((data) => this.selectHub.includes(data.programCode))
    );
  }

  deleteUser(event): void {
    this.selectedAccess = event;
    this.openRemoveModal();
  }

  openRemoveModal(): void {
    const dataModal = { template: this.removeModalTemplate };
    this.modalService.openDialog(dataModal, this.widthDeleteWarningModal);
  }

  closeModal() {
    this.modalService.closeDialog();
  }

  closeRemovePopup() {
    this.subscriptions.add(
      this.removeAccessSuccess$.pipe(filter(isNonNull)).subscribe((success) => {
        this.closeModal();
      })
    );
  }

  deleteSelected() {
    this.adminService.disableSaveButton(true);
    this.adminService.removeUserAccess(this.selectedAccess);
  }

  openModal(): void {
    const dataModal = { template: this.modalTemplate };
    this.modalService.openDialog(dataModal, this.widthWarningModal);
    this.initAdminForm();
    this.setAdminProgramList();
    this.checkAddDuplicateUser();
    this.updateAccessLevel();
    this.updateUserName();
    this.updateProgram();
    this.fetchUserNameSearchResults();
    this.getUserSaveSuccess();
  }

  addUser(): void {
    const obj = this.formatStateToObj(this.adminFacadeStateObject);
    this.adminFacade.updateAdminById(obj);
    this.addUserButtonToggle$.next(true);
    this.openStartSavingModal();
  }

  openStartSavingModal() {
    this.snackbarService.openSnackBar({
      message: 'Adding User...',
      duration: 5000,
    });
  }

  formatStateToObj(obj) {
    return {
      program: obj.adminProgram.value,
      userId: obj.userName[0].chip,
      accessLevel: obj.accessLevel.value,
      programId: obj.adminProgram.id,
      createdBy: obj.userId,
    };
  }

  cancel(): void {
    this.modalService.closeDialog();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
