import * as AttendeeDetailsActions from './attendee-details.actions';
import * as AttendeeDetailsSelectors from './attendee-details.selectors';
import * as fromAttendeeDetails from './attendee-details.reducer';
import { Action, Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';

@Injectable()
export class AttendeeDetailsFacade {
  loaded$ = this.store.pipe(
    select(AttendeeDetailsSelectors.getAttendeeDetailsLoaded)
  );

  allAttendeeDetails$ = this.store.pipe(
    select(AttendeeDetailsSelectors.getAttendeeDetailsState)
  );

  allOrganizationCategories$ = this.store.pipe(
    select(AttendeeDetailsSelectors.getAllOrganizationCategories)
  );

  allOperatingGroupResults$ = this.store.pipe(
    select(AttendeeDetailsSelectors.getAllOperatingGroupResults)
  );

  allAccentureEntitiesResults$ = this.store.pipe(
    select(AttendeeDetailsSelectors.getAllAccentureEntities)
  );

  allOrganizationTypesResults$ = this.store.pipe(
    select(AttendeeDetailsSelectors.getAllOrganizationTypes)
  );

  allClientSearchResults$ = this.store.pipe(
    select(AttendeeDetailsSelectors.getAllClientOrganizationSearchResults)
  );

  constructor(
    private readonly store: Store<
      fromAttendeeDetails.AttendeeDetailsPartialState
    >
  ) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }

  getOrganizationCategoriesResults() {
    this.dispatch(AttendeeDetailsActions.getOrganizationCategoriesResults());
  }

  getOrganizationTypesResults(): void {
    this.dispatch(AttendeeDetailsActions.getOrganizationTypes());
  }

  getOperatingGroupResults(): void {
    this.dispatch(AttendeeDetailsActions.getOperatingGroupResults());
  }

  getAccentureEntities(): void {
    this.dispatch(AttendeeDetailsActions.getAccentureEntities());
  }

  updateOrganizations(value) {
    this.dispatch(
      AttendeeDetailsActions.updateOrganizations({ organizations: value })
    );
  }

  getClientOrganizationSearchResults(searchQuery: string) {
    this.dispatch(
      AttendeeDetailsActions.getClientOrganizationSearchResults({
        searchQuery,
      })
    );
  }

  updateNumberOfAttendees(attendees: any) {
    this.updateAccentureAttendees(attendees.accentureAttendees);
    this.updateClientCSuiteAttendees(attendees.clientCSuiteAttendees);
    this.updateClientOtherAttendees(attendees.clientOtherAttendees);
    this.updateOtherAttendees(attendees.otherAttendees);
    this.updateTotalAttendees(attendees.totalAttendees);
  }

  updateAccentureAttendees(value) {
    this.dispatch(
      AttendeeDetailsActions.updateAccentureAttendees({
        accentureAttendees: value,
      })
    );
  }

  updateClientCSuiteAttendees(value) {
    this.dispatch(
      AttendeeDetailsActions.updateClientCSuiteAttendees({
        clientCSuiteAttendees: value,
      })
    );
  }

  updateClientOtherAttendees(value) {
    this.dispatch(
      AttendeeDetailsActions.updateClientOtherAttendees({
        clientOtherAttendees: value,
      })
    );
  }

  updateOtherAttendees(value) {
    this.dispatch(
      AttendeeDetailsActions.updateOtherAttendees({
        otherAttendees: value,
      })
    );
  }

  updateTotalAttendees(value) {
    this.dispatch(
      AttendeeDetailsActions.updateTotalAttendees({
        totalAttendees: value,
      })
    );
  }

  updateSelectedLeadershipLevel(value) {
    this.dispatch(
      AttendeeDetailsActions.updateSelectedLeadershipLevel({
        leadershipLevel: value,
      })
    );
  }

  updateSelectedAudienceType(value) {
    this.dispatch(
      AttendeeDetailsActions.updateSelectedAudienceType({
        audienceType: value,
      })
    );
  }

  updateSelectedTraveling(value) {
    this.dispatch(
      AttendeeDetailsActions.updateSelectedTraveling({
        traveling: value,
      })
    );
  }

  clearStateObject() {
    this.dispatch(AttendeeDetailsActions.clearStateObject());
  }
}
