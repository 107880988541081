import { AdminFacade } from '@ioh/core-data';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarService } from './snackbar.service';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WorkshopService implements OnDestroy {
  private readonly subscriptions: Subscription = new Subscription();
  programCode$ = this.adminFacade.getProgramCode$;
  programId$ = this.adminFacade.getProgramId$;
  selectedWorkshop$ = this.adminFacade.selectedWorkshop$;
  flattenedSelectedWorkshop$ = this.adminFacade.selectedWorkshop$.pipe(
    filter((x) => !!x),
    map((workshop) => {
      return {
        ...workshop.engagement,
        ...workshop.consultation,
        ...workshop.recovery,
      };
    })
  );

  selectedWorkshopId$ = this.adminFacade.selectedWorkshopId$;
  selectedWorkshopGeneral$ = this.adminFacade.selectedWorkshopGeneral$;
  selectedWorkshopConsultation$ = this.adminFacade
    .selectedWorkshopConsultation$;

  selectedWorkshopRecovery$ = this.adminFacade.selectedWorkshopRecovery$;
  getWorkshops$ = this.adminFacade.getWorkshops$.pipe(filter((v) => !!v));
  allWorkshopEntities$ = this.adminFacade.allWorkshopEntities$;

  updatedSuccess$ = this.adminFacade.selectedWorkshopUpdatedSuccess$;
  saveWorkshopError$ = this.adminFacade.saveWorkshopError$;
  workshopStatus$ = this.adminFacade.workshopStatus$;
  cancellationReasons$ = this.adminFacade.cancellationReasons$;

  constructor(
    private readonly adminFacade: AdminFacade,
    private readonly router: Router,
    public snackbarService: SnackbarService
  ) {
    this.adminFacade.getProgramId$
      .pipe(filter((x) => !!x))
      .subscribe((programId) => {
        this.adminFacade.getProgramById(programId);
      });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  openConfirmationOfSaveModal() {
    this.snackbarService.openSnackBar({
      message: 'Workshop successfully updated!',
      action: 'Go back to table view',
    });
    this.registerSnackbarSubscription();
  }

  openStartSavingModal() {
    this.snackbarService.openSnackBar({
      message: 'Saving workshop...',
      duration: 5000,
    });
  }

  openFailureToast(): void {
    this.snackbarService.openSnackBar({
      message: 'Oops! Something went wrong while saving.',
    });
  }

  registerSnackbarSubscription() {
    this.subscriptions.add(
      this.snackbarService.snackbarReference.afterDismissed().subscribe(() => {
        this.adminFacade.updateSuccess(null);
        this.adminFacade.loadAllWorkshopsByUserId();
        const previousList = sessionStorage.getItem('listView');
        this.router.navigateByUrl(previousList);
      })
    );
  }

  loadWorkshopStatus() {
    this.adminFacade.getWorkshopStatus();
  }

  loadCancellationReasons() {
    this.adminFacade.getCancellationReasons();
  }

  loadAllWorkshopsByUserId() {
    this.adminFacade.loadAllWorkshopsByUserId();
  }

  setSelectedWorkshopID(id: string) {
    this.adminFacade.setSelectedWorkshopId(id);
  }

  updateWorkshopById(workshop: any) {
    this.openStartSavingModal();
    this.adminFacade.updateWorkshopById(workshop);
  }
}
