const isIE =
  window.navigator.userAgent.includes('MSIE ') ||
  window.navigator.userAgent.includes('Trident/');

export const environment = {
  config: {
    auth: {
      clientId: '024f6d1b-8ab4-45f3-ba8e-cbeed5f486bf',
      authority:
        'https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22/',
      redirectUri: 'https://ioh-preprod.ciostage.accenture.com',
      postLogoutRedirectUri:
        'https://ioh-preprod.ciostage.accenture.com/logout',
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    timeOut: 3600000,
  },
  options: {
    consentScopes: ['024f6d1b-8ab4-45f3-ba8e-cbeed5f486bf/.default'],
  },
  endpoints: {
    // base: 'https://1sz1jr9ua7.execute-api.us-east-1.amazonaws.com/preprod/',
    // base: 'https://htu001vw30.execute-api.us-east-1.amazonaws.com/preprod/',
    base: 'https://ioh-preprod.ciostage.accenture.com/api/',
    report: {
      getFullReport: 'report?exportProgramList=',
      getFullReportPowerBI: 'powerBI?user=',
    },
    orchestrator: {
      createRequest: 'orchestrator/frontoffice',
      getAllWorkshopsByUserId: 'orchestrator/backoffice',
      updateWorkshopById: 'orchestrator/backoffice',
    },
    program: {
      getById: 'program',
    },
    reference: {
      getAccentureEntities: 'reference/accEntities',
      getClients: 'reference/customer',
      getEngagementGroup: 'reference/engagementgroup',
      getEngagementGroupEcosystem: 'reference/engagementgroupEcosystem',
      getEngagementType: 'reference/engagementtype',
      getOperatingGroup: 'reference/operatingGroup',
      getOrganizationCategories: 'reference/orgCategory',
      getOrganizationTypes: 'reference/orgtype',
      getPeopleProfile: 'reference/people-profile',
      getProfilePicture: 'reference/profile-picture',
      getTermAndCondition: 'reference/termsconditions?program=',
      internalPurpose: 'reference/internalPurpose?program=',
      opportunityId: 'reference/opportunity/',
      tags: 'reference/tags?partialName=',
      wbs: 'reference/wbs/',
      getWorkshopStatus: 'reference/workshopstatus',
      getCancellationReason: 'reference/cancellationreason',
      getTeamRoles: 'reference/role?program=',
      getDatesAndLocationsRooms: 'reference/locationroom?program=',
      getExchangeRate: 'reference/exchange',
    },
    appstate: {
      state: 'appstate',
    },
    user: {
      access: 'user/access',
      removeAccess: 'user/removeAccess',
    },
  },
  production: false,
};
