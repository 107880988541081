import { AdminFacade } from '@ioh/core-data';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IEngagement } from '@utils/interfaces';
import { MsalService } from '@azure/msal-angular';
import { TableService, WorkshopService } from '@services';
import { checkStatusIs, transformDates } from '@utils/tablesUtils';
import { homeColNames } from '@assets/data/tables/table-columns';
import {
  isAfter,
  isBefore,
  isFuture,
  isPast,
  isToday,
  startOfToday,
  subMonths,
} from 'date-fns';
import isEmpty from 'lodash/isEmpty';

@Component({
  selector: 'ioh-home',
  styleUrls: ['./home.component.scss'],
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, OnDestroy {
  data = [];
  colNames = homeColNames;
  selectHub = '';
  pastDueHomeData$: BehaviorSubject<any> = new BehaviorSubject(null);
  upcomingHomeData$: BehaviorSubject<any> = new BehaviorSubject(null);
  completedHomeData$: BehaviorSubject<any> = new BehaviorSubject(null);
  myConsultationsHomeData$: BehaviorSubject<any> = new BehaviorSubject(null);
  eid = this.authService.getAccount().userName.split('@')[0];
  today: Date = startOfToday();
  withinLastMonth: Date = subMonths(this.today, 1);
  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly tableService: TableService,
    private readonly workshopService: WorkshopService,
    private readonly adminFacade: AdminFacade,
    private readonly authService: MsalService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.workshopService.getWorkshops$.subscribe((workshops) => {
        if (!isEmpty(workshops)) {
          this.handleFormatting(workshops);
        }
      })
    );
    this.subscriptions.add(
      this.adminFacade.selectedHub$.subscribe((selectedHub) => {
        this.selectHub = selectedHub;
        this.updateAllData();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleFormatting(workshops: any[]): void {
    if (typeof Worker != 'undefined') {
      const worker: Worker = new Worker(
        '../../../services/data-formatter.worker',
        {
          type: 'module',
        }
      );
      worker.onmessage = ({ data }) => {
        this.data = this.tableService.sortTableDataByDate(data);
        this.updateAllData();
      };
      worker.postMessage(workshops);
    } else {
      const unorderedData = this.tableService.flattenAndFormatWorkshops(
        workshops
      );
      this.data = this.tableService.sortTableDataByDate(unorderedData);
      this.updateAllData();
    }
  }

  updateAllData() {
    if (this.data.length == 0) {
      return true;
    }
    this.pastDueHomeData$.next(
      this.data
        .filter(this.pastDueDataFilter.bind(this))
        .map(transformDates.bind(this))
    );

    this.upcomingHomeData$.next(
      this.data
        .filter(this.upcomingDataFilter.bind(this))
        .map(transformDates.bind(this))
    );

    this.completedHomeData$.next(
      this.data
        .filter(this.completedDataFilter.bind(this))
        .map(transformDates.bind(this))
    );

    this.myConsultationsHomeData$.next(
      this.data
        .filter(this.myConsultationsDataFilter.bind(this))
        .map(transformDates.bind(this))
    );
  }

  pastDueDataFilter(value): boolean {
    const start = new Date(value.start);
    const startInPast = isPast(start);
    const checkStatus = checkStatusIs(value, 'Booked');
    const isInTeam = value.team && value.team.includes(this.eid);
    return (
      checkStatus &&
      startInPast &&
      (this.eid == value.lead || isInTeam) &&
      (!this.selectHub || this.selectHub.includes(value.programCode))
    );
  }

  upcomingDataFilter(value: IEngagement): boolean {
    const start = new Date(value.start);
    const startInFuture = isFuture(start);
    const startIsToday = isToday(start);
    return (
      (startInFuture || startIsToday) &&
      (this.eid == value.lead || value.team.includes(this.eid)) &&
      (!this.selectHub || this.selectHub.includes(value.programCode))
    );
  }

  completedDataFilter(value: IEngagement): boolean {
    const start = new Date(value.start);
    return (
      !isBefore(start, this.withinLastMonth) &&
      !isAfter(start, this.today) &&
      checkStatusIs(value, 'Completed') &&
      (this.eid == value.lead || value.team.includes(this.eid)) &&
      (!this.selectHub || this.selectHub.includes(value.programCode))
    );
  }

  myConsultationsDataFilter(value: IEngagement): boolean {
    return (
      checkStatusIs(value, 'Requested') &&
      this.eid == value.owner &&
      (!this.selectHub || this.selectHub.includes(value.programCode))
    );
  }
}
