import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Component } from '@angular/core';
import { EngagementDetailsFacade, FormFacade } from '@ioh/core-data';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookUpValidatorsService } from '@utils/validators';
import { LookupService } from '../../../services/lookup.service';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  tap,
} from 'rxjs/operators';
import { textAreas } from '@assets/data/engagement-details';

@Component({
  selector: 'ioh-engagement-details',
  styleUrls: ['./engagement-details.component.scss'],
  templateUrl: './engagement-details.component.html',
})
export class EngagementDetailsComponent {
  engagementDetailsGroup: FormGroup;

  private readonly subscriptions: Subscription = new Subscription();
  opportunityIdResultsLoading$ = new BehaviorSubject(false);

  constructor(
    private readonly fb: FormBuilder,
    private readonly lookUpValidator: LookUpValidatorsService,
    private readonly engagementDetailsFacade: EngagementDetailsFacade,
    private readonly formFacade: FormFacade,
    private readonly lookupService: LookupService
  ) {}

  textareas = textAreas;
  opportunityLimit = 10;
  internalPurposes$: Observable<any> = this.engagementDetailsFacade
    .allInternalPurposes$;

  allOpporunityIdsSearchResults$ = this.engagementDetailsFacade.allOpportunityIds$.pipe(
    tap(() => this.opportunityIdResultsLoading$.next(false)),
    map((res) =>
      res.map((item) => `${item.OpportunityId}-${item.OpportunityDescription}`)
    )
  );

  ngOnInit() {
    this.engagementDetailsGroup = this.fb.group({
      backgroundText: this.fb.control('', Validators.required),
      challengesText: this.fb.control('', Validators.required),
      objectivesText: this.fb.control('', Validators.required),
      opportunityInput: this.fb.group({
        input: this.fb.control(''),
        oppResult: this.fb.array(
          [],
          [this.lookUpValidator.validateLimit(this.opportunityLimit)]
        ),
      }),
      purposeOptions: this.fb.control('', Validators.required),
      successCriteria: this.fb.control('', Validators.required),
    });
    this.subscriptions.add(
      this.formFacade.getProgramId$.subscribe((res) =>
        this.engagementDetailsFacade.loadInternalPurposeResults(res)
      )
    );

    this.updateSelectedInternalPurpose();
    this.updateObjectives();
    this.updateBackground();
    this.updateChallenges();
    this.updateSuccessCriteria();
    this.updateOpportuniyId();
    this.fetchOpportunityIdSearchResults();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  updateBackground() {
    this.subscriptions.add(
      this.engagementDetailsGroup
        .get('backgroundText')
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((res) => {
          this.engagementDetailsFacade.updateBackgroundText(res);
        })
    );
  }

  updateObjectives() {
    this.subscriptions.add(
      this.engagementDetailsGroup
        .get('objectivesText')
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((res) => {
          this.engagementDetailsFacade.updateobjectivesAndExpectations(res);
        })
    );
  }

  updateSuccessCriteria() {
    this.subscriptions.add(
      this.engagementDetailsGroup
        .get('successCriteria')
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((res) => {
          this.engagementDetailsFacade.updateSuccessCriteria(res);
        })
    );
  }

  updateChallenges() {
    this.subscriptions.add(
      this.engagementDetailsGroup
        .get('challengesText')
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((res) => {
          this.engagementDetailsFacade.updateChallenges(res);
        })
    );
  }

  updateSelectedInternalPurpose() {
    this.subscriptions.add(
      this.engagementDetailsGroup
        .get('purposeOptions')
        .valueChanges.subscribe((change) => {
          this.engagementDetailsFacade.selectInternalPurpose(change);
        })
    );
  }

  updateOpportuniyId() {
    this.subscriptions.add(
      this.engagementDetailsGroup
        .get('opportunityInput')
        .get('oppResult')
        .valueChanges.subscribe((res) => {
          this.opportunityIdResultsLoading$.next(true);
          const formatted = this.lookupService.formatSelectedOpportunityIds(
            res
          );
          this.engagementDetailsFacade.updateSelectedOpportunityIds(formatted);
        })
    );
  }

  fetchOpportunityIdSearchResults() {
    this.subscriptions.add(
      this.engagementDetailsGroup
        .get('opportunityInput')
        .get('input')
        .valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          filter((res) => res)
        )
        .subscribe((res) => {
          this.opportunityIdResultsLoading$.next(true);
          this.engagementDetailsFacade.loadOpportunityIdResults(res);
        })
    );
  }
}
