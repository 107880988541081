import * as RecoveryActions from './recovery.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { IRecoveryEntry, IWbsCodeResults } from './recovery.models';

export const RECOVERY_FEATURE_KEY = 'recovery';

export interface RecoveryState extends EntityState<any> {
  selectedId?: string | number; // which Recovery record has been selected
  loaded: boolean; // has the Recovery list been loaded
  prepopulated: boolean;
  error?: string | null; // last none error (if any)
  exchangeRate: any[] | null;
  chargeDate: string | null;
  recoveryComments: string | null;
  recoveryEntries: IRecoveryEntry[] | null;
  // wbsCodes: IWbsCode[] | null;
  totalDiscounts: number | null;
  passThroughEstimate: number | null;
  recoveryTotal: number | null;
  totalCosts: number | null;
  wbsCodeResults: IWbsCodeResults | null;
}

export interface RecoveryPartialState {
  readonly [RECOVERY_FEATURE_KEY]: RecoveryState;
}

export const recoveryAdapter: EntityAdapter<any> = createEntityAdapter<any>();

export const initialState: RecoveryState = recoveryAdapter.getInitialState({
  chargeDate: null,
  // set initial required properties
  loaded: false,
  prepopulated: false,
  exchangeRate: [],
  recoveryComments: '',
  recoveryEntries: [],
  totalDiscounts: null,
  passThroughEstimate: null,
  recoveryTotal: null,
  totalCosts: null,
  wbsCodeResults: null,
});

const recoveryReducer = createReducer(
  initialState,
  on(RecoveryActions.updatePrepopulated, (state, { value }) => {
    return Object.assign({}, state, { prepopulated: value });
  }),
  on(RecoveryActions.updateWbsCodes, (state, { wbsCodes }) => {
    return Object.assign({}, state, { wbsCodes });
  }),
  on(RecoveryActions.loadWBSLookupResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(RecoveryActions.loadWBSLookupResultsSuccess, (state, { wbsCodeResults }) =>
    recoveryAdapter.addOne(wbsCodeResults, {
      ...state,
      loaded: true,
      wbsCodeResults,
    })
  ),
  on(RecoveryActions.loadWBSLookupResultsFailure, (state, { error }) => ({
    ...state,
    error,
    wbsCodeResults: null,
  })),
  on(RecoveryActions.updateTotalDiscounts, (state, { totalDiscounts }) => {
    return Object.assign({}, state, { totalDiscounts });
  }),
  on(RecoveryActions.updatePassThrough, (state, { passThroughEstimate }) => {
    return Object.assign({}, state, { passThroughEstimate });
  }),
  on(RecoveryActions.updateRecoveryTotal, (state, { recoveryTotal }) => {
    return Object.assign({}, state, { recoveryTotal });
  }),
  on(RecoveryActions.updateTotalCosts, (state, { totalCosts }) => {
    return Object.assign({}, state, { totalCosts });
  }),
  on(RecoveryActions.updateChargeDate, (state, { chargeDate }) => {
    return Object.assign({}, state, { chargeDate });
  }),
  on(RecoveryActions.updateRecoveryComments, (state, { recoveryComments }) => {
    return Object.assign({}, state, { recoveryComments });
  }),
  on(RecoveryActions.updateRecoveryEntries, (state, { recoveryEntries }) => {
    return Object.assign({}, state, { recoveryEntries });
  }),
  on(RecoveryActions.getExchangeRate, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(RecoveryActions.getExchangeRateSuccess, (state, { exchangeRate }) => {
    return Object.assign({}, state, { exchangeRate });
  }),
  on(RecoveryActions.getExchangeRateFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(RecoveryActions.clearStateObject, () => {
    return Object.assign({}, initialState);
  })
);

export function reducer(state: RecoveryState | undefined, action: Action) {
  return recoveryReducer(state, action);
}
