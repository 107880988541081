<div *ngIf="hubList.length >1">
  <div class="material-icons menu__item__icon icon-filter" style="cursor: pointer;" (click)="togglePrograms()">
    <div class="filterIcon">
      <div class="box"></div>
    </div>
  </div>
  <div *ngIf ="!isAdministration">
    <span class ="hubTitle">
      {{selectedHub}}
    </span>
    <span class ="hubTitle" *ngIf ="selectedHub ===''" >
      All Programs
    </span>
  </div>
  <div *ngIf ="isAdministration">
    <span class ="hubTitle">
      {{selectedAdminHub}}
    </span>
    <span class ="hubTitle" *ngIf ="selectedAdminHub ===''" >
      All Programs
    </span>
  </div>
  <div [ngClass]="{'openFlag':Menu,'closeFlag':!Menu}">
  </div>
<div [ngClass]="{'dropdownlist__pannel':Menu,'closeFlag':!Menu}">
   <p> Filter Programs View </p>
   <div  class="btn" style="cursor: pointer;" (click)= 'batchCheck(true)'>
    <span class="material-icons" style="vertical-align: middle;">
      done_all
      </span>
    Select All
  </div>
  <div *ngFor="let option of hubList;let i=index">
    <input type="checkbox" value="{{option.value}}"
    [checked]="option.checked"
    (click)="updateAnswer($event.target.checked, $event.target.value)">    
    {{option.value}}
  </div>
  <div class="btn" style="cursor: pointer;" (click)= 'batchCheck(false)'>
    <span class="material-icons" style="vertical-align: middle;">
      clear_all
      </span>
    Clear All
  </div>
  <br/>
  <button class="btn btn-primary btn-cancel" style="cursor: pointer;" value="" (click)= 'cancel()'>Cancel</button>
  <button class="btn btn-primary btn-save" style="cursor: pointer;" value="" (click)= 'setSelectedHub()'> Save </button>  
  </div>
</div>



        
        