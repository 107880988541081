import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  of,
} from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CustomDateValidators,
  LookUpValidatorsService,
} from '@utils/validators';
import {
  DATES_LOCATIONS,
  ENGAGEMENT_GROUP,
  GROUP_ECOSYSTEM,
  GROUP_PARTICIPATION,
} from '@utils/enums';
import { DateFormatService, WorkshopService } from '@services';
import {
  DatesAndLocationsEntryService,
  TeamMemberService,
} from '@ioh/ui-library';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { GeneralFacade, IGeneralModelDb } from '@ioh/core-data';
import { GeneralTabService } from './general-tab.service';
import { ILookUp } from '@utils/interfaces';
import { LookupService } from 'apps/ioh-app/src/app/services/lookup.service';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ModalService } from 'apps/ioh-app/src/app/services/modal.service';
import { OrganizationService } from 'apps/ioh-app/src/app/services/organization.service';
import { Router } from '@angular/router';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  tap,
} from 'rxjs/operators';
import {
  engagementGroupDropdown,
  engagementTeamRoleDropdown,
  engagementTypeDropdown,
  generalTableColumns,
  generalTextAreas,
  groupEcosystemDropdown,
  groupParticipationDropdown,
  imageTooltipTxt,
} from '@assets/data/general-details';
import { tooltipConst } from '@assets/data/tooltip-details';

import isEmpty from 'lodash/isEmpty';
import startOfToday from 'date-fns/startOfToday';
@Component({
  selector: 'ioh-general-tab',
  styleUrls: ['./general-tab.component.scss'],
  templateUrl: './general-tab.component.html',
})
export class GeneralTabComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription = new Subscription();
  dateLocationTooltipObj: any = {};
  engagementTypeTooltipObj: any = {};
  generalGroup: FormGroup;
  daleLocationFlag = false;
  dateFlag = false;
  isTopFlag = false;
  dateTooltip = '';
  today: Date = startOfToday();
  engagementTypeDropdownSettings = engagementTypeDropdown;
  imageTooltipTxt = imageTooltipTxt;
  generalTextareas = generalTextAreas;
  // TODO get dropdown values from backend
  columns = generalTableColumns;

  allEngagementGroupResults$: Observable<any> = this.generalTabFacade
    .engagementGroupResults$;

  allGroupEcosystem$: Observable<any> = this.generalTabFacade
    .engagementGroupEcosystemResults$;

  engagementTypeResults$: Observable<any> = this.generalTabFacade
    .engagementTypeResults$;

  datesAndLocationsRoomsValues$: Observable<any> = this.generalTabFacade
    .datesAndLocationsRooms$;

  engagementTeamsRoles$: Observable<any> = this.generalTabFacade
    .engagementTeamsRoles$;

  lookUps: ILookUp[];
  allEngagementSponsorResultsLoading$ = new BehaviorSubject(false);
  allPrimaryContactResultsLoading$ = new BehaviorSubject(false);
  allAdditionalContactResultsLoading$ = new BehaviorSubject(false);
  allEngagementLeadResultsLoading$ = new BehaviorSubject(false);
  allEngagementTeamMemberResultsLoading$ = new BehaviorSubject(false);
  allTagsLoading$ = new BehaviorSubject(false);
  engagementGroupDropdownSettings = engagementGroupDropdown;

  // TODO: get dropdown values from backend
  groupParticipationDropdownSettings = groupParticipationDropdown;
  groupEcosystemDropdownSettings = groupEcosystemDropdown;
  engagementTeamRoleSettings = engagementTeamRoleDropdown;
  engagementSponsorLimit = 1;
  primaryContactLimit = 1;
  additionalContactsLimit = 25;
  engagementLeadLimit = 1;
  teamMembersLimit = 20;
  tagsLimit = 50;
  smallScreen = false;
  prepopulated: boolean | null;
  widthWarningModal = 'fit-content';
  teamIndex: number;

  constructor(
    private readonly fb: FormBuilder,
    private readonly lookUpValidator: LookUpValidatorsService,
    private readonly router: Router,
    private readonly customValidator: CustomDateValidators,
    private readonly generalTabFacade: GeneralFacade,
    public generalTabService: GeneralTabService,
    private readonly modalService: ModalService,
    private readonly lookUpService: LookupService,
    private readonly organizationService: OrganizationService,
    private readonly workshopService: WorkshopService,
    public teamMemberService: TeamMemberService,
    public datesAndLocationsEntryService: DatesAndLocationsEntryService,
    private readonly dateFormatService: DateFormatService,
    public mediaObserver: MediaObserver
  ) {}

  allEngagementSponsorResults$ = this.generalTabFacade.allEngagementSponsorResults$.pipe(
    tap(() => this.allEngagementSponsorResultsLoading$.next(false)),
    map((res) => res.map((item) => item.enterpriseID))
  );

  allPrimaryContactResults$ = this.generalTabFacade.allPrimaryContactResults$.pipe(
    tap(() => this.allPrimaryContactResultsLoading$.next(false)),
    map((res) => res.map((item) => item.enterpriseID))
  );

  allAdditionalContactResults$ = this.generalTabFacade.allAdditionalContactResults$.pipe(
    tap(() => this.allAdditionalContactResultsLoading$.next(false)),
    map((res) => res.map((item) => item.enterpriseID))
  );

  allEngagementLeadResults$ = this.generalTabFacade.allEngagementLeadResults$.pipe(
    tap(() => this.allEngagementLeadResultsLoading$.next(false)),
    map((res) => res.map((item) => item.enterpriseID))
  );

  allTagsResults$ = this.generalTabFacade.allTagsResults$.pipe(
    tap(() => this.allTagsLoading$.next(false)),
    map((res) => this.formatTag(res))
  );

  externalOrganizationCategories$ = this.generalTabFacade
    .allExternalOrganizationCategories$;

  accentureEntitiesResults$ = this.generalTabFacade
    .allAccentureEntitiesResults$;

  organizationTypesResults$ = this.generalTabFacade.allOrganizationTypes$;

  isANewWorkshop$: Observable<boolean> = of(
    this.router.url.includes('/add-booking')
  );

  allGroupParticipations$ = this.generalTabFacade.engagementGroupResults$;

  selectedWorkshopGeneral$;
  generalFacadeStateObject$;

  ngOnInit() {
    this.generalGroup = this.fb.group(
      {
        additionalContactsInput: this.fb.group({
          additionalContactsResult: this.fb.array(
            [],
            [
              this.lookUpValidator.validateRequired,
              this.lookUpValidator.validateLimit(this.additionalContactsLimit),
            ]
          ),
          input: this.fb.control(''),
        }),
        adminComments: this.fb.control(''),
        organisations: this.fb.array([]),
        datesAndLocation: this.fb.group({
          dates: this.fb.array([]),
          totalHours: this.fb.control(0),
        }),
        engagementGroup: this.fb.control(''),
        engagementLeadInput: this.fb.group({
          engagementLeadResult: this.fb.array(
            [],
            [
              this.lookUpValidator.validateRequired,
              this.lookUpValidator.validateLimit(this.engagementLeadLimit),
            ]
          ),
          input: this.fb.control(''),
        }),
        engagementSponsorInput: this.fb.group({
          engagementSponsorResult: this.fb.array(
            [],
            [
              this.lookUpValidator.validateRequired,
              this.lookUpValidator.validateLimit(this.engagementSponsorLimit),
            ]
          ),
          input: this.fb.control(''),
        }),
        engagementTeams: this.fb.array([]),
        engagementTitle: this.fb.control('', Validators.required),
        engagementType: this.fb.control('', Validators.required),
        engagementStartDate: this.fb.control('', Validators.required),
        engagementEndDate: this.fb.control('', Validators.required),
        groupParticipation: this.fb.control(''),
        groupEcosystem: this.fb.control(''),
        primaryContactInput: this.fb.group({
          input: this.fb.control(''),
          primaryContactResult: this.fb.array(
            [],
            [
              this.lookUpValidator.validateRequired,
              this.lookUpValidator.validateLimit(this.primaryContactLimit),
            ]
          ),
        }),
        summary: this.fb.control(''),
        tags: this.fb.group({
          tagsInput: this.fb.control(''),
          tagsResult: this.fb.array(
            [],
            [this.lookUpValidator.validateLimit(this.tagsLimit)]
          ),
        }),
      },
      {
        validators: [
          this.customValidator.checkUniqueGroup.bind(this.customValidator),
          this.customValidator.checkDateTimeline,
        ],
      }
    );

    this.lookUps = [
      {
        isLoading: this.allEngagementSponsorResultsLoading$,
        label: 'Engagement Sponsor',
        limit: this.engagementSponsorLimit,
        lookUpId: 'sponsor',
        lookupArrayFieldName: 'engagementSponsorResult',
        lookupGroupFieldName: 'engagementSponsorInput',
        lookupInputFieldName: 'input',
        placeholder: 'ACN Sponsor and/or key decision maker',
        required: true,
        searchResults: this.allEngagementSponsorResults$,
      },
      {
        isLoading: this.allPrimaryContactResultsLoading$,
        label: 'Primary Point of Contact',
        limit: this.primaryContactLimit,
        lookUpId: 'primaryContact',
        lookupArrayFieldName: 'primaryContactResult',
        lookupGroupFieldName: 'primaryContactInput',
        lookupInputFieldName: 'input',
        placeholder:
          'ACN single point of accountability that the team should work with',
        required: true,
        searchResults: this.allPrimaryContactResults$,
      },
      {
        isLoading: this.allAdditionalContactResultsLoading$,
        label: 'Additional Contacts',
        limit: this.additionalContactsLimit,
        lookUpId: 'AdditionalContact',
        lookupArrayFieldName: 'additionalContactsResult',
        lookupGroupFieldName: 'additionalContactsInput',
        lookupInputFieldName: 'input',
        placeholder: 'Additional ACN contacts to keep informed',
        required: false,
        searchResults: this.allAdditionalContactResults$,
      },
    ];
    this.registerSubscriptions();
    this.generalTabFacade.getOrganizationTypes();
    this.generalTabFacade.getAccentureEntities();
    this.generalTabFacade.getExternalOrganizationCategoriesResults();
    this.handleResize();
    this.dateLocationTooltipObj = tooltipConst.dateLocationTooltipObj;
    this.engagementTypeTooltipObj = tooltipConst.engagementTypeTooltipObj;
  }

  handleResize() {
    this.subscriptions.add(
      this.mediaObserver.media$.subscribe((change: MediaChange) => {
        this.smallScreen = !!(
          change.mqAlias === 'md' ||
          change.mqAlias === 'sm' ||
          change.mqAlias === 'xs'
        );
      })
    );
  }

  formatTag(data) {
    if (data.length > 0) {
      const newArr = [];
      const obj = {};
      for (var i = 0; i < data.length; i++) {
        if (!obj[data[i].name]) {
          newArr.push(data[i]);
          obj[data[i].name] = true;
        }
      }
      const result = newArr.map((item) => item.name);
      return result;
    } else {
      return [];
    }
  }

  registerSubscriptions(): void {
    this.checkIfPrepopulated();

    this.updateTitle();
    this.updateEngagementType();
    this.updateSummary();
    this.updateAdminComments();
    this.getClientSearchResults();
    this.updateOrganizationSearchResults();
    this.updateEngagementSponsorResults();
    this.updatePrimaryContactResults();
    this.updateAdditionalContactResults();
    this.updateOrganizations();
    this.updateDatesAndlocations();
    this.updateEngagementGroup();
    this.updateEngagementLead();
    this.updateEngagementTeam();
    this.updateGroupParticipation();
    this.updateGroupEcosystem();
    this.updateTagsResults();
    this.updateEngagementStartDate();
    this.updateEngagementEndDate();

    this.fetchEngagementType();
    this.fetchEngagementGroup();
    this.fetchEngagementGroupEcosystem();
    this.fetchEngagementSponsorSearchResults();
    this.fetchPrimaryContactSearchResults();
    this.fetchAdditionalContactSearchResults();
    this.fetchEngagementLeadResults();
    this.fetchTagsSearchResults();
    this.mapEngagementTeamMemberSearchResults();
    this.getGeneralState();
    this.updateSelectedWorkshopGeneral();
    this.checkLocationRoom();
    this.checkTeamRole();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getGeneralState(): void {
    this.subscriptions.add(
      this.generalTabFacade.allGeneralTab$.subscribe((generalTabState) => {
        this.generalFacadeStateObject$ = generalTabState;
      })
    );
  }

  checkIfPrepopulated() {
    this.subscriptions.add(
      this.generalTabFacade.prepopulated$.subscribe((prepopulated) => {
        this.prepopulated = prepopulated;
      })
    );
  }

  /**
   * If the general tab state object is empty
   * then prepopulate from the database
   * if the general tab state object is NOT empty
   * this is because the user has made changes
   * and as such, the store is the most up to date
   * source of truth and so the form should be prepopulated
   * with the store object
   */
  updateSelectedWorkshopGeneral(): void {
    this.subscriptions.add(
      combineLatest([
        this.isANewWorkshop$,
        this.workshopService.selectedWorkshopGeneral$,
      ]).subscribe(([isANewWorkshop, workshop]) => {
        if (isANewWorkshop) {
          this.handleNewWorkshopMapping();
        } else if (!isANewWorkshop && workshop) {
          this.handleExistingWorkshopMapping(workshop);
        } else if (!isANewWorkshop && workshop === undefined) {
          this.handleNewWorkshopMapping();
        }
      })
    );
  }

  handleNewWorkshopMapping(): void {
    if (this.prepopulated) {
      this.generalTabService.prepopulateGeneralTabWithStateObject(
        this.generalFacadeStateObject$,
        this.generalGroup
      );
    } else {
      this.generalTabFacade.updatePrepopulated(true);
    }
    this.createDefaultEntries();
  }

  handleExistingWorkshopMapping(workshop: IGeneralModelDb): void {
    if (!this.prepopulated && workshop) {
      this.generalTabFacade.updatePrepopulated(true);
      this.generalTabService.prepopulateGeneralTabWithDBResponse(
        workshop,
        this.generalGroup
      );
    } else {
      this.generalTabService.prepopulateGeneralTabWithStateObject(
        this.generalFacadeStateObject$,
        this.generalGroup
      );
    }
    this.createDefaultEntries();
  }

  createDefaultEntries(): void {
    if (isEmpty(this.dates.value)) {
      this.addDate();
    }
    if (isEmpty(this.engagementTeams.value)) {
      this.addTeamMember();
    }
  }

  get datesAndLocation(): FormGroup {
    return this.generalGroup.get(DATES_LOCATIONS) as FormGroup;
  }

  get dates(): FormArray {
    return this.datesAndLocation.controls.dates as FormArray;
  }

  get totalHours() {
    return this.datesAndLocation.controls.totalHours as FormControl;
  }

  get organizations(): FormArray {
    return this.generalGroup.get('organisations') as FormArray;
  }

  get engagementTeams(): FormArray {
    return this.generalGroup.get('engagementTeams') as FormArray;
  }

  get engagementSponsors(): FormArray {
    return this.generalGroup
      .get('engagementSponsorInput')
      .get('engagementSponsorResult') as FormArray;
  }

  get primaryContacts(): FormArray {
    return this.generalGroup
      .get('primaryContactInput')
      .get('primaryContactResult') as FormArray;
  }

  get additionalContacts(): FormArray {
    return this.generalGroup
      .get('additionalContactsInput')
      .get('additionalContactsResult') as FormArray;
  }

  updateTitle(): void {
    this.subscriptions.add(
      this.generalGroup
        .get('engagementTitle')
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((engagementTitle) => {
          this.generalTabFacade.updateTitle(engagementTitle);
        })
    );
  }

  updateEngagementType(): void {
    this.subscriptions.add(
      this.generalGroup
        .get('engagementType')
        .valueChanges.subscribe((engagementType) => {
          this.generalTabFacade.updateEngagementType(engagementType);
        })
    );
  }

  updateSummary(): void {
    this.subscriptions.add(
      this.generalGroup
        .get('summary')
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((summary) => {
          this.generalTabFacade.updateSummary(summary);
        })
    );
  }

  updateAdminComments(): void {
    this.subscriptions.add(
      this.generalGroup
        .get('adminComments')
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((adminComments) => {
          this.generalTabFacade.updateAdminComments(adminComments);
        })
    );
  }

  updateOrganizationSearchResults(): void {
    this.subscriptions.add(
      this.generalTabFacade.allClientOrganizationSearchResults$.subscribe(
        (res) => {
          this.organizationService.updateSearchResults(res);
        }
      )
    );
  }

  getClientSearchResults(): void {
    this.subscriptions.add(
      this.organizationService.clientSearchQuery
        .pipe(filter((res: string) => !!res))
        .subscribe((currentSearchQuery: string) => {
          this.generalTabFacade.getClientOrganizationSearchResults(
            currentSearchQuery
          );
          this.organizationService.clientSearchResultsLoading.next(true);
        })
    );
  }

  fetchEngagementType(): void {
    this.subscriptions.add(
      this.workshopService.programId$.subscribe((programId) => {
        this.generalTabFacade.getEngagementTypeResults(programId);
      })
    );
  }

  fetchEngagementGroup(): void {
    this.subscriptions.add(
      this.workshopService.programId$.subscribe((programId) => {
        this.generalTabFacade.getEngagementGroupResults(programId);
      })
    );
  }

  fetchEngagementGroupEcosystem(): void {
    this.subscriptions.add(
      this.workshopService.programId$.subscribe((programId) => {
        this.generalTabFacade.getEngagementGroupEcosystemResults(programId);
      })
    );
  }

  fetchEngagementSponsorSearchResults(): void {
    this.subscriptions.add(
      this.generalGroup
        .get('engagementSponsorInput')
        .get('input')
        .valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          filter((res) => res),
          tap(() => this.allEngagementSponsorResultsLoading$.next(true))
        )
        .subscribe((res) => {
          this.generalTabFacade.loadEngagementSponsorSearchResults({
            searchText: res,
          });
        })
    );
  }

  fetchPrimaryContactSearchResults(): void {
    this.subscriptions.add(
      this.generalGroup
        .get('primaryContactInput')
        .get('input')
        .valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          filter((res) => res),
          tap(() => this.allPrimaryContactResultsLoading$.next(true))
        )
        .subscribe((primaryContact) => {
          this.generalTabFacade.loadPrimaryContactSearchResults({
            searchText: primaryContact,
          });
        })
    );
  }

  fetchAdditionalContactSearchResults(): void {
    this.subscriptions.add(
      this.generalGroup
        .get('additionalContactsInput')
        .get('input')
        .valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          filter((res) => res),
          tap(() => this.allAdditionalContactResultsLoading$.next(true))
        )
        .subscribe((additionalContacts) => {
          this.generalTabFacade.loadAdditionalContactSearchResults({
            searchText: additionalContacts,
          });
        })
    );
  }

  updateEngagementSponsorResults(): void {
    this.subscriptions.add(
      this.engagementSponsors.valueChanges.subscribe((res) => {
        this.allEngagementSponsorResultsLoading$.next(true);
        this.generalTabFacade.updateEngagementSponsors(res);
      })
    );
  }

  updatePrimaryContactResults(): void {
    this.subscriptions.add(
      this.primaryContacts.valueChanges.subscribe((res) => {
        this.allPrimaryContactResultsLoading$.next(true);
        this.generalTabFacade.updatePrimaryContacts(res);
      })
    );
  }

  updateAdditionalContactResults(): void {
    this.subscriptions.add(
      this.additionalContacts.valueChanges.subscribe(
        (additionalContactsResults) => {
          this.allAdditionalContactResultsLoading$.next(true);
          this.generalTabFacade.updateAdditionalContacts(
            additionalContactsResults
          );
        }
      )
    );
  }

  updateDatesAndlocations(): void {
    this.subscriptions.add(
      this.generalGroup
        .get(DATES_LOCATIONS)
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(() => {
          const rawValue = this.dates.getRawValue();
          const transRawValue = rawValue.map((elem) => {
            const newDate =
              elem.dates === ''
                ? ''
                : this.dateFormatService.getDateToSave(elem.dates);
            return { ...elem, dates: newDate };
          });
          const datesAndLocation = {
            dates: transRawValue,
            totalHours: this.totalHours.value,
          };
          this.generalTabFacade.updateDatesAndlocations(datesAndLocation);
        })
    );
  }

  updateOrganizations(): void {
    this.subscriptions.add(
      this.organizations.valueChanges.subscribe((organizations) => {
        this.generalTabFacade.updateOrganizations(organizations);
      })
    );
  }

  updateEngagementGroup(): void {
    this.subscriptions.add(
      this.generalGroup
        .get(ENGAGEMENT_GROUP)
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((engagementGroup) => {
          this.generalTabFacade.updateEngagementGroup(engagementGroup);
        })
    );
  }

  fetchEngagementLeadResults(): void {
    this.subscriptions.add(
      this.generalGroup
        .get('engagementLeadInput')
        .get('input')
        .valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          filter((res) => res)
        )
        .subscribe((engagementLead) => {
          this.allEngagementLeadResultsLoading$.next(true);
          this.generalTabFacade.loadEngagementLeadResults({
            searchText: engagementLead,
          });
        })
    );
  }

  updateEngagementLead(): void {
    this.subscriptions.add(
      this.generalGroup
        .get('engagementLeadInput')
        .get('engagementLeadResult')
        .valueChanges.subscribe((engagementLead) => {
          this.allEngagementSponsorResultsLoading$.next(false);
          this.generalTabFacade.updateEngagementLead(engagementLead);
        })
    );
  }

  updateEngagementTeam(): void {
    this.subscriptions.add(
      this.engagementTeams.valueChanges.subscribe((engagementTeams) => {
        // total days is a disabled/readonly value and so we need the rawValue of the array
        this.generalTabFacade.updateEngagementTeam(
          this.engagementTeams.getRawValue()
        );
      })
    );
  }

  /**
   * All team member components subscribe to the same public observable,
   * so we need to map the response back to the correctly indexed member
   * in the array.
   */
  mapEngagementTeamMemberSearchResults(): void {
    let teamMemberIndex;
    this.subscriptions.add(
      this.generalTabFacade.allEngagementTeamMemberResults$
        .pipe(
          filter((x) => !!x && x.length > 0),
          tap((x) => (teamMemberIndex = x[0].EngagementTeamMemberIndex)),
          map((res) => res.map((item) => item.enterpriseID))
        )
        .subscribe((searchResults) => {
          if (this.engagementTeams.at(teamMemberIndex)) {
            this.engagementTeams
              .at(teamMemberIndex)
              .get('nameInput')
              .get('searchResults')
              .setValue(searchResults);
            this.engagementTeams
              .at(teamMemberIndex)
              .get('nameInput')
              .get('nameResultsIsLoading')
              .setValue(false);
          }
        })
    );
  }

  fetchSearchResults(searchQuery: string): void {
    const index = +this.lookUpService.getDescription(searchQuery);
    const formattedSearchQuery = {
      searchText: this.lookUpService.getID(searchQuery),
    };
    this.generalTabFacade.loadEngagementTeamMemberResults(
      formattedSearchQuery,
      index
    );
  }

  updateGroupParticipation(): void {
    this.subscriptions.add(
      this.generalGroup
        .get(GROUP_PARTICIPATION)
        .valueChanges.subscribe((groupParticipation) => {
          this.generalTabFacade.updateGroupParticipation(groupParticipation);
        })
    );
  }

  updateGroupEcosystem(): void {
    this.subscriptions.add(
      this.generalGroup
        .get(GROUP_ECOSYSTEM)
        .valueChanges.subscribe((groupEcosystem) => {
          this.generalTabFacade.updateGroupEcosystem(groupEcosystem);
        })
    );
  }

  updateTagsResults(): void {
    this.subscriptions.add(
      this.generalGroup
        .get('tags')
        .get('tagsResult')
        .valueChanges.subscribe((res) => {
          this.allTagsLoading$.next(true);
          this.generalTabFacade.updateTags(res);
        })
    );
  }

  updateEngagementStartDate(): void {
    this.subscriptions.add(
      this.generalGroup
        .get('engagementStartDate')
        .valueChanges.pipe(distinctUntilChanged())
        .subscribe((res) => {
          const newRes = this.dateFormatService.getDateToSave(res);
          this.generalTabFacade.updateEngagementStartDate(newRes);
        })
    );
  }

  updateEngagementEndDate(): void {
    this.subscriptions.add(
      this.generalGroup
        .get('engagementEndDate')
        .valueChanges.pipe(distinctUntilChanged())
        .subscribe((res) => {
          const newRes = this.dateFormatService.getDateToSave(res);
          this.generalTabFacade.updateEngagementEndDate(newRes);
        })
    );
  }

  fetchTagsSearchResults(): void {
    this.subscriptions.add(
      this.generalGroup
        .get('tags')
        .get('tagsInput')
        .valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          filter((res) => res)
        )
        .subscribe((res) => {
          this.allTagsLoading$.next(true);
          this.generalTabFacade.loadTagsResults(res);
        })
    );
  }

  addTeamMember() {
    this.engagementTeams.push(this.teamMemberService.createTeamMember());
  }

  removeTeamMember(index: number): void {
    this.engagementTeams.removeAt(index);
    this.modalService.closeDialog();
  }

  addDate() {
    this.dates.push(this.datesAndLocationsEntryService.createNewDateEntry());
  }

  public checkLocationRoom() {
    this.subscriptions.add(
      this.datesAndLocationsRoomsValues$.subscribe((roomList) => {
        if (roomList.length > 0) {
          const currentDates = this.generalGroup
            .get('datesAndLocation')
            .get('dates') as FormArray;
          if (currentDates.length > 0) {
            for (var i = 0; i < currentDates.length; i++) {
              const currentLocations = currentDates.controls[i].get(
                'locations'
              ) as FormArray;
              for (var y = 0; y < currentLocations.length; y++) {
                const currentRoom = currentLocations.controls[y].get('room')
                  .value;
                if (!!currentRoom && currentRoom.value != '') {
                  const matchRoom = roomList.filter((room) => {
                    return room.value == currentRoom.value;
                  });
                  switch (matchRoom.length) {
                    case 0:
                      currentLocations.controls[y].get('room').setValue('');
                      break;
                    case 1:
                      currentLocations.controls[y]
                        .get('room')
                        .setValue(matchRoom[0]);
                      break;
                  }
                }
              }
            }
          }
        }
      })
    );
  }

  public checkTeamRole() {
    this.subscriptions.add(
      this.engagementTeamsRoles$.subscribe((roleList) => {
        if (roleList.length > 0) {
          const teamMembers = this.generalGroup.get(
            'engagementTeams'
          ) as FormArray;
          if (teamMembers.length > 0) {
            for (var i = 0; i < teamMembers.length; i++) {
              const currentRole = teamMembers.controls[i].get('role').value;
              if (!!currentRole && currentRole.value != '') {
                const matchRole = roleList.filter((role) => {
                  return role.value == currentRole.value;
                });
                switch (matchRole.length) {
                  case 0:
                    teamMembers.controls[i].get('role').setValue('');

                    break;
                  case 1:
                    teamMembers.controls[i].get('role').setValue(matchRole[0]);
                    break;
                }
              }
            }
          }
        }
      })
    );
  }

  dateLocationIn(id: string, boxId: string) {
    this.daleLocationFlag = true;
    this.isTopFlag = this.isOffsetTop(id);
    const elementBox = document.getElementById(boxId);
    this.setPosition(elementBox);
  }

  setPosition(elementBox) {
    elementBox.style.top = this.isTopFlag ? '85px' : '-400px';
  }

  setDatePosition(elementBox) {
    elementBox.style.top = this.isTopFlag ? '30px' : '-450px';
  }

  dateIn(id: string, boxId: string) {
    this.dateFlag = true;
    this.isTopFlag = this.isOffsetTop(id);
    const elementBox = document.getElementById(boxId);
    this.setDatePosition(elementBox);
    this.dateTooltip =
      id === 'icon-startDate'
        ? imageTooltipTxt.startDate
        : imageTooltipTxt.endDate;
  }

  dateLocationOut() {
    this.isTopFlag = false;
    this.daleLocationFlag = false;
  }

  dateOut() {
    this.dateFlag = false;
  }

  isOffsetTop(id) {
    const windowHeigt = window.innerHeight;
    const offsetTop = document.getElementById(id).getBoundingClientRect().top;
    return offsetTop < windowHeigt / 2;
  }
}
