import { AdminFacade } from 'libs/core-data/src/lib/state/back-office/admin/admin.facade';
import { HttpService } from '@ioh/core-data';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from '../shared/shared.service';
import { environment } from '@env/environment';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root',
})
export class AccessService {
  constructor(
    private readonly sharedService: SharedService,
    private readonly http: HttpService,
    private readonly adminFacade: AdminFacade
  ) {}

  checkAccess(): Observable<any> {
    const url = `${environment.endpoints.base}${environment.endpoints.user.access}`;
    return this.http.get(url).pipe(
      map((res: any) => {
        this.sharedService.canAccess.next(res.data.userInfo.hasAccess);
        if (res.data.userInfo.program.length > 0) {
          this.adminFacade.saveProgramList(res.data.userInfo.program);
          this.adminFacade.saveAdminProgramList(
            res.data.userInfo.adminHubNameList
          );
          this.adminFacade.saveUserAccessList(res.data.userInfo.accessInfo);
        }
      })
    );
  }
}
