<div class="dates" fxLayout="column" [formGroup]="parentForm">
  <ng-container [ngSwitch]="dateType">
    <div *ngSwitchCase="'Half Day or Less'"
      fxLayout="row"
      fxLayout.md="column"
      fxLayout.sm="column"
      fxLayout.xs="column"
      fxLayoutAlign="space-between start"
      fxLayoutAlign.md="space-around stretch"
      fxLayoutAlign.sm="space-around stretch"
      fxLayoutAlign.xs="space-around stretch"
      class="dates__selected mb"
    >
      <div
      class="dates__title"
      ngClass.md="dates__title__smaller-screens"
      ngClass.sm="dates__title__smaller-screens"
      ngClass.xs="dates__title__smaller-screens"
      > <span class="dates__asterisk">*</span> Option {{ field + 1 }}</div>
      <div class="date">
        <ioh-date-picker
            [formGroup]="parentForm"
            icon="today"
            value="date"
            [minDate]="minDate"
            ariaLabel="date picker"
            [warning]="warning"
            placeholder="Choose a date"
          ></ioh-date-picker>
      </div>
      <ioh-dropdown
        class="dates__dropdown"
        ngClass.md="dates__dropdown__smaller-screens"
        ngClass.sm="dates__dropdown__smaller-screens"
        ngClass.xs="dates__dropdown__smaller-screens"
        [formGroup]="parentForm"
        dropdownId="{{ dayPeriod.dropdownId }}"
        isMultiple="{{ dayPeriod.isMultiple }}"
        [values]="dayPeriod.values"
        field="period"
        required="{{ dayPeriod.required }}"
        placeholder="{{ dayPeriod.placeholder }}"
        name="{{ dayPeriod.name }}"
        direction = "{{dayPeriod.direction}}"></ioh-dropdown>
      <div
        fxLayout="column"
        class="dates__checkbox"
        ngClass.md="dates__checkbox__smaller-screens"
        ngClass.sm="dates__checkbox__smaller-screens"
        ngClass.xs="dates__checkbox__smaller-screens"
        fxLayoutAlign="center center"
        fxLayoutAlign.md="space-around stretch"
        fxLayoutAlign.sm="space-around stretch"
        fxLayoutAlign.xs="space-around stretch"
        *ngIf="waitlist">
          <div class="dates__checkbox__label">Waitlist</div>
          <ioh-checkbox
            checkId="{{checkId}}"
            value="waitlistCheckbox"
            [formGroup]="parentForm"
            required=false>
            <label fxHide="true">Waitlist</label>
          </ioh-checkbox>
      </div>
      <button
        mat-mini-fab
        class="dates__button__delete"
        ngClass.md="dates__button__delete__smaller-screens"
        ngClass.sm="dates__button__delete__smaller-screens"
        ngClass.xs="dates__button__delete__smaller-screens"
        color="accent"
        (click)="deleteDate(field)"
      >
        <mat-icon class="material-icons">clear</mat-icon>
      </button>
    </div>
    <div *ngSwitchCase="'Full Day'"
      fxLayout="row"
      fxLayoutAlign="space-between start"
      class="dates__selected mb"
    >
      <div class="dates__title">Option {{ field + 1 }}</div>
      <div class="date">
        <ioh-date-picker
          [formGroup]="parentForm"
          icon="today"
          value="date"
          ariaLabel="date picker"
          [minDate]="minDate"
          [warning]="warning"
          placeholder="Choose a date"
        ></ioh-date-picker>
      </div>
      <div fxLayout="column"
        class="dates__checkbox"
        fxLayoutAlign="center center"
         *ngIf="waitlist">
          <div class="dates__checkbox__label">Waitlist</div>
          <ioh-checkbox
            checkId="{{checkId}}"
            value="waitlistCheckbox"
            [formGroup]="parentForm"
            required=false></ioh-checkbox>
      </div>
      <button
        mat-mini-fab
        class="dates__button__delete"
        color="accent"
        (click)="deleteDate(field)"
      >
        <mat-icon class="material-icons">clear</mat-icon>
      </button>
    </div>
    <div *ngSwitchCase="'Multi-Day'"
      fxLayout="row"
      fxLayout.md="column"
      fxLayout.sm="column"
      fxLayout.xs="column"
      fxLayoutAlign="space-between start"
      fxLayoutAlign.md="space-around stretch"
      fxLayoutAlign.sm="space-around stretch"
      fxLayoutAlign.xs="space-around stretch"
      class="dates__selected mb"
    >
      <div
        class="dates__title"
        ngClass.md="dates__title__smaller-screens"
        ngClass.sm="dates__title__smaller-screens"
        ngClass.xs="dates__title__smaller-screens"
        >Option {{ field + 1 }}</div>
      <div
        class="date">
        <ioh-date-picker
          [formGroup]="parentForm"
          icon="today"
          value="startDate"
          ariaLabel="date picker"
          [minDate]="minDate"
          [isEndDate]="false"
          [warning]="warning"
          placeholder="Choose Start date"
        ></ioh-date-picker>
      </div>
      <div
        class="date date__end"
        ngClass.md="date__end__smaller-screens"
        ngClass.sm="date__end__smaller-screens"
        ngClass.xs="date__end__smaller-screens"
      >
        <ioh-date-picker
          [formGroup]="parentForm"
          icon="today"
          value="endDate"
          ariaLabel="date picker"
          [minDate]="minDate"
          [isEndDate]="true"
          placeholder="Choose End date"
        ></ioh-date-picker>
      </div>
      <div fxLayout="column"
        class="dates__checkbox"
        ngClass.md="dates__checkbox__smaller-screens"
        ngClass.sm="dates__checkbox__smaller-screens"
        ngClass.xs="dates__checkbox__smaller-screens"
        fxLayoutAlign="center center"
        fxLayoutAlign.md="space-around stretch"
        fxLayoutAlign.sm="space-around stretch"
        fxLayoutAlign.xs="space-around stretch"
        *ngIf="waitlist"
      >
        <div class="dates__checkbox__label">Waitlist</div>
        <ioh-checkbox
          checkId="{{checkId}}"
          value="waitlistCheckbox"  
          [formGroup]="parentForm"
          required=false></ioh-checkbox>
      </div>
      <button
        mat-mini-fab
        class="dates__button__delete"
        ngClass.md="dates__button__delete__smaller-screens"
        ngClass.sm="dates__button__delete__smaller-screens"
        ngClass.xs="dates__button__delete__smaller-screens"
        color="accent"
        (click)="deleteDate(field)"
        >
        <mat-icon class="material-icons">clear</mat-icon>
      </button>
    </div>
  </ng-container>
</div>
