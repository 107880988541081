<div fxLayoutAlign="end center">
  <button class="table__button__delete" mat-flat-button
    color="secondary"
    (click)="openModal()"
    [disabled]="selection.selected.length === 0">
    <span class="material-icons">
      delete
    </span>
    Delete
  </button>
</div>
<div fxLayout="row" [formGroup]="parentForm" class="table__container">
  <mat-table #table class="table" [dataSource]="dataSource" formArrayName="dates">
    <ng-container *ngFor="let col of cols; let index= index;" matColumnDef="{{col.name}}">
      <ng-container *ngIf="col.name === 'selected'; else notSelected">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggleCheckboxes() : null"
            id="selectAll"
            color="primary"
            class="table__checkbox"
            [checked]="selection.hasValue() && areAllRowsSelected()"
            [indeterminate]="selection.hasValue() && !areAllRowsSelected()">
            <label fxHide="true">select all</label>
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell
          ngClass.md="table__first__cell--smaller__screen"
          ngClass.sm="table__first__cell--smaller__screen"
          ngClass.xs="table__first__cell--smaller__screen"
          *matCellDef="let row">
          <label class="table__label table__label__select" fxHide.xl="true" fxHide.lg="true">{{col.text}}</label>
          <mat-checkbox
              (click)="$event.stopPropagation()"
              class="table__checkbox"
              color="primary"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
          >
              <label fxHide="true">Select Date</label>
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-template #notSelected>
        <mat-header-cell [class.table__header__first]="index === 1 || index === 7" *matHeaderCellDef> {{col.text}} </mat-header-cell>
          <ng-container *ngIf="col.type === 'input'">
            <mat-cell *matCellDef="let element; let index = index;" [formGroupName]="index">
                <label class="table__label" fxHide.xl="true" fxHide.lg="true">{{col.text}}</label>
                <ioh-input
                  class="table__input"
                  inputId="{{col.name}}-{{index}}"
                  [formGroup]="dates.at(index)"
                  name="{{col.name}}"
                  placeholder="{{col.placeholder}}"
                  value="{{col.name}}"
                  required="false"
                ></ioh-input>
            </mat-cell>
          </ng-container>
          <ng-container *ngIf="col.type === 'dropdown'">
            <mat-cell *matCellDef="let element; let index = index;" [formGroupName]="index">
              <label class="table__label" fxHide.xl="true" fxHide.lg="true">{{col.text}}</label>
              <ioh-dropdown
                fxFlex="100%"
                dropdownId="{{col.name}}-{{index}}"
                [formGroup]="dates.at(index)"
                isMultiple = "false"
                [values]="col.values"
                field = "{{col.name}}"
                required = "false"
                placeholder = "{{col.placeholder}}"
                name = "{{col.name}}"
                direction="column"></ioh-dropdown>
            </mat-cell>
          </ng-container>
          <ng-container *ngIf="col.type === 'datepicker'">
            <mat-cell *matCellDef="let element; let index = index;" [formGroupName]="index">
              <label class="table__label" fxHide.xl="true" fxHide.lg="true">{{col.text}}</label>
              <ioh-date-picker
                class="table__date"
                placeholder="{{col.placeholder}}"
                value="{{col.name}}"
                ariaLabel="{{col.text}}"
                [formGroup]="dates.at(index)">
              </ioh-date-picker>
            </mat-cell>
          </ng-container>
          <ng-container *ngIf="col.type === 'timepicker'">
            <mat-cell *matCellDef="let element; let index = index;" [formGroupName]="index">
              <label class="table__label" fxHide.xl="true" fxHide.lg="true">{{col.text}}</label>
              <!-- TO DO: double check if we need a new timepicker-input comp -->
              <ioh-input
                class="table__input"
                inputId="{{col.name}}-{{index}}"
                [formGroup]="dates.at(index)"
                name="{{col.name}}"
                placeholder="{{col.placeholder}}"
                value="{{col.name}}"
                required="false"
              ></ioh-input>
            </mat-cell>
          </ng-container>
          <ng-container *ngIf="col.type === 'locationInput'">
            <mat-cell *matCellDef="let element; let datesIndex = index;" [formGroupName]="datesIndex">
              <div
                fxLayout.xl="column"
                fxLayout.lg="column"
                fxLayout.md="row"
                fxLayout.sm="row"
                fxLayout.xs="row"
                fxFlex="100%"
                fxLayoutGap.xl="0rem"
                fxLayoutGap.lg="0rem"
                fxLayoutGap.md="0.3rem"
                fxLayoutGap.sm="0.3rem"
                fxLayoutGap.xs="0.3rem"
                >
                <label class="table__label table__label__location" fxHide.xl="true" fxHide.lg="true">{{col.text}}</label>
                <ng-container *ngFor="let locations of dates.at(datesIndex).get('locations').controls; let locationIndex = index">
                  <ioh-input
                    class="table__input"
                    inputId="{{col.name}}-{{datesIndex}}-{{locationIndex}}"
                    [formGroup]="locations"
                    name="{{col.name}}"
                    placeholder="{{col.placeholder}}"
                    value="{{col.name}}"
                    required="false"
                  ></ioh-input>
                </ng-container>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container *ngIf="col.type === 'locationDropdown'">
            <mat-cell *matCellDef="let element; let datesIndex = index;" [formGroupName]="datesIndex">
              <div
                fxLayout.xl="column"
                fxLayout.lg="column"
                fxLayout.md="row"
                fxLayout.sm="row"
                fxLayout.xs="row"
                fxLayoutGap.xl="0rem"
                fxLayoutGap.lg="0rem"
                fxLayoutGap.md="0.3rem"
                fxLayoutGap.sm="0.3rem"
                fxLayoutGap.xs="0.3rem"
                fxFlex="100%">
                <label class="table__label table__label__location" fxHide.xl="true" fxHide.lg="true">{{col.text}}</label>
                <ng-container *ngFor="let locations of dates.at(datesIndex).get('locations').controls; let locationIndex = index">
                  <ioh-dropdown
                    dropdownId="{{col.name}}-{{datesIndex}}-{{locationIndex}}"
                    [formGroup]="locations"
                    isMultiple = "false"
                    [values]="roomsValues$ | async"
                    field = "{{col.name}}"
                    required = "false"
                    placeholder = "{{col.placeholder}}"
                    name = "{{col.name}}"
                    direction="column">
                  </ioh-dropdown>
                </ng-container>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container *ngIf="col.type === 'locationTimepicker'">
            <mat-cell *matCellDef="let element; let datesIndex = index;" [formGroupName]="datesIndex">
              <div
                fxLayout.xl="column"
                fxLayout.lg="column"
                fxLayout.md="row"
                fxLayout.sm="row"
                fxLayout.xs="row"
                fxLayoutGap.xl="0rem"
                fxLayoutGap.lg="0rem"
                fxLayoutGap.md="0.3rem"
                fxLayoutGap.sm="0.3rem"
                fxLayoutGap.xs="0.3rem"
                fxFlex="100%">
                <label class="table__label table__label__location" fxHide.xl="true" fxHide.lg="true">{{col.text}}</label>
                <ng-container *ngFor="let locations of dates.at(datesIndex).get('locations').controls; let locationIndex = index">
                  <!-- TO DO: double check if we need a new timepicker-input comp -->
                  <ioh-input
                    class="table__input"
                    inputId="{{col.name}}-{{datesIndex}}-{{locationIndex}}"
                    [formGroup]="locations"
                    name="{{col.name}}"
                    placeholder="{{col.placeholder}}"
                    value="{{col.name}}"
                    required="false"
                ></ioh-input>
                </ng-container>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container *ngIf="col.type === 'locationActions'">
            <mat-cell *matCellDef="let element; let datesIndex = index;" [formGroupName]="datesIndex">
              <div
                fxLayout.xl="column"
                fxLayout.lg="column"
                fxLayout.md="row"
                fxLayout.sm="row"
                fxLayout.xs="row"
                fxFlex="100%"
                fxLayoutGap.xl="0rem"
                fxLayoutGap.lg="0rem"
                fxLayoutGap.md="9.3rem"
                fxLayoutGap.sm="9.3rem"
                fxLayoutGap.xs="9.3rem"
                ngClass.md="table__buttons__container--smaller__screen"
                ngClass.sm="table__buttons__container--smaller__screen"
                ngClass.xs="table__buttons__container--smaller__screen">
                <ng-container *ngIf="dates.at(datesIndex).get('locations').length === 0">
                  <div
                    fxLayout.xl="column"
                    fxLayoutGap="0.2rem"
                    class="table__buttons__container"
                    >
                    <button
                        mat-flat-button
                        class="table__action__button"
                        color="secondary"
                        (click)="addNewLocation(dates.at(datesIndex).get('locations'))"
                        >
                          <span class="material-icons">
                            add
                          </span>
                      </button>
                    </div>
                </ng-container>
                <ng-container *ngFor="let locations of dates.at(datesIndex).get('locations').controls; let locationIndex = index">
                  <div fxLayout="row" fxLayoutGap="0.2rem" class="table__buttons__container">
                    <button
                      mat-flat-button
                      class="table__action__button table__action__button__add"
                      color="secondary"
                      (click)="addNewLocation(dates.at(datesIndex).get('locations'))"
                      >
                        <span class="material-icons">
                          add
                        </span>
                    </button>
                    <button
                      mat-flat-button
                      class="table__action__button"
                      color="secondary"
                      (click)="removeLocation(dates.at(datesIndex).get('locations'), locationIndex)"
                    >
                      <span class="material-icons">
                        remove
                      </span>
                    </button>
                  </div>
                </ng-container>
              </div>
            </mat-cell>
          </ng-container>
      </ng-template>

    </ng-container>

    <mat-header-row
      fxHide.md="true"
      fxHide.sm="true"
      fxHide.xs="true"
      fxLayoutGap="0.3rem"
      *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      fxLayout.md="column"
      fxLayout.sm="column"
      fxLayout.xs="column"
      fxLayoutGap="0.3rem"
      class="table__row"
      ngClass.md="table__md__screen"
      ngClass.sm="table__sm__screen"
      ngClass.xs="table__xs__screen"
      *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
<div fxLayoutAlign="space-between center" class="table__mt">
  <div fxLayoutAlign="start center">
    <button
    mat-flat-button
    color="primary"
    class="table__add__button"
    (click)="addNewDate()"
  >
    <span class="material-icons">
      add
    </span>
  </button>
  <span class="table__add__button__text">Add Dates</span>
</div>
  <p class="table__total__hours">Total Hours: {{totalHours}}</p>
</div>

<ng-template #warningTemplate>
  <ioh-warning-modal
    elementName="location"
    (delete)="deleteSelected()"
    (close)="closeModal()"
  ></ioh-warning-modal>
</ng-template>
