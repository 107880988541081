import { HttpService } from '../http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private readonly http: HttpService) {}

  getExcelReport(exportProgramList: string): Observable<any> {
    const url = `${environment.endpoints.report.getFullReport}${exportProgramList}`;
    return this.http.get(url);
  }
}
