import { IDataModal } from '@utils/interfaces';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../shared/modal/modal.component';
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public dialogInstance: MatDialogRef<ModalComponent>;

  constructor(public dialog: MatDialog) {}

  openDialog(dialogData: IDataModal, width: string) {
    this.dialogInstance = this.dialog.open<ModalComponent, IDataModal>(
      ModalComponent,
      {
        autoFocus: true,
        data: dialogData,
        width: width,
      }
    );
  }

  closeDialog() {
    this.dialogInstance.close();
  }
}
