import { AbstractControl, ControlContainer } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ioh-radio-button',
  styleUrls: ['./radio-button.component.scss'],
  templateUrl: './radio-button.component.html',
})
export class RadioButtonComponent implements OnInit {
  @Input() radioId: string;
  @Input() label: string;
  @Input() field: any;
  @Input() values: any[];
  @Input() font: string;
  @Input() required: boolean;
  @Output() selected = new EventEmitter<string>();
  parentForm: AbstractControl;

  constructor(public controlContainer: ControlContainer) {}

  ngOnInit() {
    this.parentForm = this.controlContainer.control;
  }

  selectedRadio(radio) {
    const selectedValue = radio.value;
    this.selected.emit(selectedValue);
  }
}
