import { AbstractControl, ControlContainer } from '@angular/forms';
import { AdminFacade, GeneralFacade, RecoveryFacade } from '@ioh/core-data';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ModalService } from 'apps/ioh-app/src/app/services/modal.service';
import { NumberUtilsService } from '@utils/validators/number-validators';
import { Observable, Subscription, of } from 'rxjs';
import { Router } from '@angular/router';

import {
  chargeStatusDropdown,
  currencyDropdownSettings,
  inputsRecoveryEntry,
  itemDropdown,
} from '@assets/data/recovery-details';
import { recoveryCurrency } from '@assets/data/currency-details';

@Component({
  selector: 'ioh-recovery-entry',
  styleUrls: ['./recovery-entry.component.scss'],
  templateUrl: './recovery-entry.component.html',
})
export class RecoveryEntryComponent implements OnInit, OnDestroy {
  @Input() index: number;
  @Input() recoveryItemsValues$: Observable<any[]>;
  @Output() deleteRecoveryEntry = new EventEmitter();
  parentForm: AbstractControl;
  inputsRecoveryEntry = inputsRecoveryEntry;
  itemDropdownSettings = itemDropdown;
  currency = recoveryCurrency;
  statusDropdownSettings = chargeStatusDropdown;
  currencyDropdownSettings = currencyDropdownSettings;
  @ViewChild('warningTemplate') modalTemplate: TemplateRef<any>;
  widthWarningModal = 'fit-content';
  first: boolean;
  smallScreen = false;
  quantity: string;
  workshopId: any;
  recovery2P: number;
  manualEdited: boolean;
  cost: string;
  discount: string;
  subTotal: number;
  programCode: string;
  engTitle: string;
  chargeDate: any;
  chargeStatus: any;
  passThrough: any;
  chargeStatusFlag: boolean;
  wbsDetails = true;
  isANewWorkshop$: Observable<boolean> = of(
    this.router.url.includes('/add-booking')
  );

  private readonly subscriptions: Subscription = new Subscription();
  discountGTCostError: boolean;

  constructor(
    private readonly router: Router,
    private readonly adminFacade: AdminFacade,
    private readonly controlContainer: ControlContainer,
    private readonly modalService: ModalService,
    public mediaObserver: MediaObserver,
    private readonly recoveryFacade: RecoveryFacade,
    private readonly generalFacade: GeneralFacade,
    private readonly numberUtilsService: NumberUtilsService
  ) {
    this.getProgramCode();
    this.getGeneralTitle();
    this.getWorkshopId();
  }

  ngOnInit() {
    this.parentForm = this.controlContainer.control;
    this.quantity = this.parentForm.get('quantity').value;
    this.recovery2P = this.parentForm.get('recovery2P').value;
    this.manualEdited = this.parentForm.get('manualEdited').value;
    this.cost = this.parentForm.get('cost').value;
    this.discount = this.parentForm.get('discount').value;
    this.subTotal = this.parentForm.get('subTotal').value;
    const totalCost =
      // this.numberUtilsService.normalizeToNumbersOnly(this.quantity) *
      this.numberUtilsService.normalizeToNumbersOnly(this.cost);
    const discount = this.numberUtilsService.normalizeToNumbersOnly(
      this.discount
    );
    this.discountGTCostError = totalCost < discount;
    // handle prepopulation
    this.first = this.index == 0;
    this.chargeDate = this.parentForm.get('chargeDate').value;
    this.chargeStatus = this.parentForm.get('chargeStatus').value;
    this.calculateSubTotal();
    this.setDescription();
    this.setCurrency();
    this.setChargeStatus();
    this.setChargeDateChange();
    this.registerSubscriptions();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  setChargeStatus() {
    if (this.parentForm.get('chargeStatus').value.value == '')
      this.parentForm.get('chargeStatus').setValue({
        chargeStautsId: 1,
        value: 'Draft',
      });
  }

  setDescription() {
    setTimeout(() => {
      const descriptionVal = this.parentForm.get('description').value;
      const workshopId = this.workshopId ? ' - ' + String(this.workshopId) : '';
      if (this.programCode && !descriptionVal) {
        const descriptionTxt = `${this.programCode}${workshopId} | ${this.engTitle}`;
        this.parentForm.get('description').setValue(descriptionTxt);
      }
    }, 100);
  }

  setCurrency() {
    setTimeout(() => {
      const currencyVal = this.parentForm.get('currency').value;
      if (this.programCode && !currencyVal.value) {
        const currency = this.currency[this.programCode]['currency'];
        const currencyId = this.currency[this.programCode]['id'];
        this.parentForm.get('currency').setValue({
          id: currencyId,
          value: currency,
        });
      }
    }, 100);
  }

  getProgramCode() {
    this.subscriptions.add(
      this.adminFacade.getProgramCode$.subscribe((programCode) => {
        this.programCode = programCode;
        this.setDescription();
        this.setCurrency();
      })
    );
  }

  getWorkshopId() {
    this.subscriptions.add(
      this.adminFacade.selectedWorkshopId$.subscribe((workshopId) => {
        this.workshopId = workshopId;
      })
    );
  }

  getGeneralTitle() {
    this.subscriptions.add(
      this.generalFacade.engagementTitleResults$.subscribe((title) => {
        this.engTitle = title;
      })
    );
  }

  registerSubscriptions() {
    this.handleResize();
    this.handleValueChanges();
  }

  roundToTwoDecimalPoints(input: string): void {
    const formControl: AbstractControl = this.parentForm.get(input);
    const { value } = formControl;
    const roundedValue = this.numberUtilsService.roundToNDecimalPoints({
      decimalPlace: 2,
      value,
    });
    formControl.setValue(roundedValue);
  }

  setManualEditedFlag(input: string): void {
    if (input == 'recovery2P') {
      this.parentForm.get('manualEdited').setValue(true);
    }
  }

  handleResize() {
    this.subscriptions.add(
      this.mediaObserver.media$.subscribe((change: MediaChange) => {
        this.smallScreen = !!(
          change.mqAlias === 'md' ||
          change.mqAlias === 'sm' ||
          change.mqAlias === 'xs'
        );
      })
    );
  }

  autoCalculateRecovery2P() {
    this.recovery2P =
      !this.manualEdited &&
      !!this.parentForm.get('wbsType').value &&
      this.parentForm.get('wbsType').value.toUpperCase() ==
        'BUSINESS DEVELOPMENT'
        ? Number((this.subTotal * 0.02).toFixed(2))
        : this.recovery2P;
    this.parentForm.get('recovery2P').setValue(this.recovery2P);
  }

  handleValueChanges() {
    this.subscriptions.add(
      this.parentForm.get('recovery2P').valueChanges.subscribe((recovery2P) => {
        this.recovery2P = recovery2P;
        this.validationRecovery2p();
      })
    );
    this.subscriptions.add(
      this.parentForm
        .get('manualEdited')
        .valueChanges.subscribe((manualEdited) => {
          this.manualEdited = manualEdited;
        })
    );
    this.subscriptions.add(
      this.parentForm.get('cost').valueChanges.subscribe((cost) => {
        this.cost = cost;
        this.calculateSubTotal();
      })
    );
    this.subscriptions.add(
      this.parentForm.get('discount').valueChanges.subscribe((discount) => {
        this.discount = discount;
        this.calculateSubTotal();
      })
    );
    this.subscriptions.add(
      this.parentForm.get('subTotal').valueChanges.subscribe((subTotal) => {
        this.subTotal = subTotal;
        this.validationRecovery2p();
        this.autoCalculateRecovery2P();
        this.validateAndUpdateDiscountValueErrors(subTotal);
      })
    );
    this.subscriptions.add(
      this.parentForm.get('wbsType').valueChanges.subscribe((wbsType) => {
        this.autoCalculateRecovery2P();
      })
    );

    this.subscriptions.add(
      this.parentForm.get('code').valueChanges.subscribe((wbscode) => {
        if (!wbscode.trim()) {
          this.parentForm.get('wbsDescription').setValue('');
          this.parentForm.get('wbsType').setValue('');
          this.parentForm.get('isValid').setValue(null);
          this.setResponseLoadedVal();
        }
      })
    );

    this.subscriptions.add(
      this.parentForm
        .get('chargeStatus')
        .valueChanges.subscribe((chargeStatus) => {
          const todayStr = new Date().toLocaleDateString();
          const today = new Date(todayStr);
          const chargeDateValue = this.parentForm.get('chargeDate').value;
          const chargeDate = new Date(chargeDateValue);
          if (
            chargeDateValue !== null &&
            chargeDate < today &&
            (chargeStatus.value === 'Draft' ||
              chargeStatus.value === 'Ready for Submission')
          ) {
            this.chargeStatusFlag = true;
          } else {
            this.chargeStatusFlag = false;
          }
          this.parentForm
            .get('passThrough')
            .setValue(chargeStatus.value === 'Direct Charge');
        })
    );
  }

  setResponseLoadedVal() {
    setTimeout(() => {
      this.parentForm.get('responseLoaded').setValue(false);
    }, 2000);
  }

  validateAndUpdateDiscountValueErrors(subTotal: number): void {
    if (this.subtotalNegativeValue(subTotal)) {
      this.parentForm.get('discount').setErrors({
        invalidDiscount: true,
      });
    } else {
      this.parentForm.get('discount').setErrors({
        invalidDiscount: null,
      });
      this.parentForm
        .get('discount')
        .updateValueAndValidity({ emitEvent: false, onlySelf: true });
    }
  }

  setChargeDateChange() {
    this.subscriptions.add(
      this.parentForm.get('chargeDate').valueChanges.subscribe((chargeDate) => {
        const chargeStatus = this.parentForm.get('chargeStatus').value;
        const todayStr = new Date().toLocaleDateString();
        const today = new Date(todayStr);
        const chargeDateValue = new Date(chargeDate);
        if (
          chargeDate !== null &&
          chargeDateValue < today &&
          (chargeStatus.value === 'Draft' ||
            chargeStatus.value === 'Ready for Submission')
        ) {
          this.chargeStatusFlag = true;
        } else {
          this.chargeStatusFlag = false;
        }
      })
    );
  }

  validationRecovery2p() {
    const subTotal = Number(this.parentForm.get('subTotal').value);
    const recovery2P = Number(this.parentForm.get('recovery2P').value);
    if (
      !this.parentForm.get('subTotal').hasError('twoDecimalRegex') &&
      !this.parentForm.get('recovery2P').hasError('twoDecimalRegex')
    ) {
      if (
        subTotal < recovery2P &&
        this.parentForm.get('recovery2P').value !== ''
      ) {
        this.parentForm.get('recovery2P').setErrors({
          invalidRecovery2P: true,
        });
      } else {
        this.parentForm.get('recovery2P').setErrors({
          invalidRecovery2P: null,
        });
        this.parentForm
          .get('recovery2P')
          .updateValueAndValidity({ emitEvent: false, onlySelf: true });
      }
    }
  }

  subtotalNegativeValue(subTotal: number): boolean {
    return Math.sign(subTotal) == -1;
  }

  calculateSubTotal(): void {
    const totalCost =
      // this.numberUtilsService.normalizeToNumbersOnly(this.quantity) *
      this.numberUtilsService.normalizeToNumbersOnly(this.cost);
    const discount = this.numberUtilsService.normalizeToNumbersOnly(
      this.discount
    );
    if (this.cost || this.discount) {
      this.subTotal = (totalCost * 100 - discount * 100) / 100;
      this.parentForm.get('subTotal').setValue(this.subTotal.toFixed(2));
    } else {
      this.parentForm.get('subTotal').setValue('');
    }
    // handle floating point arithmetic
    this.discountGTCostError = this.subTotal < 0;
  }

  openModal(): void {
    const dataModal = { template: this.modalTemplate };
    this.modalService.openDialog(dataModal, this.widthWarningModal);
  }

  closeModal(): void {
    this.modalService.closeDialog();
  }

  removeEntry(): void {
    this.deleteRecoveryEntry.emit(this.index);
  }

  getWbs(event) {
    this.recoveryFacade.getWBS(event.wbs, this.index);
  }
}
