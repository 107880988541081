import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { Observable } from 'rxjs';
import { tooltipConst } from '@assets/data/tooltip-details';

@Component({
  selector: 'ioh-details-engagement',
  styleUrls: ['./details-engagement.component.scss'],
  templateUrl: './details-engagement.component.html',
})
export class DetailsEngagementComponent implements OnInit {
  @Input() textareas: any;
  @Input() lookupGroupFieldName: string;
  @Input() lookupArrayFieldName: string;
  @Input() lookupInputFieldName: string;
  @Input() dropdownOptionsPurpose: any;
  @Input() opportunityLimit: number;
  @Input() opportunityResults: Observable<any>;
  @Input() opportunityLoading: Observable<any>;
  parentForm;
  mmsTooltipObj: any = {};
  engagementPurposeTooltipObj: any = {};
  constructor(private readonly parentControl: ControlContainer) {}

  ngOnInit() {
    this.parentForm = this.parentControl.control;
    this.mmsTooltipObj = tooltipConst.mmsOppTooltipObj;
    this.engagementPurposeTooltipObj = tooltipConst.engagementPurposeTooltipObj;
  }
}
