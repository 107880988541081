import { AdminFacade, RecoveryFacade, RecoveryState } from '@ioh/core-data';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DateFormatService, WorkshopService } from '@services';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ModalService } from 'apps/ioh-app/src/app/services/modal.service';
import { NumberUtilsService } from '@utils/validators/number-validators';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { RecoveryEntryService, WbsEntryService } from '@ioh/ui-library';
import { RecoveryTabService } from './recovery-tab.service';
import { Router } from '@angular/router';
import { WBSValidators } from '@utils/validators';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import {
  inputsRecoveryEntry,
  itemDropdown,
  summaryDetails,
} from '@assets/data/recovery-details';
import { recoveryCurrency } from '@assets/data/currency-details';
import { tooltipConst } from '@assets/data/tooltip-details';
import _isEmpty from 'lodash/isEmpty';
@Component({
  selector: 'ioh-recovery-tab',
  styleUrls: ['./recovery-tab.component.scss'],
  templateUrl: './recovery-tab.component.html',
})
export class RecoveryTabComponent implements OnInit, OnDestroy {
  chargeDateTooltipObj: any = {};
  chargeStatusTooltipObj: any = {};
  recoveryGroup: FormGroup;
  wbsDetails = true;
  itemDropdownSettings = itemDropdown;
  inputsRecoveryEntry = inputsRecoveryEntry;
  summarySectionDetails = summaryDetails;
  itemIndex: number;
  smallScreen = false;
  recoveryFacadeStateObject$: RecoveryState;
  prepopulated: boolean | null;
  isANewWorkshop$: Observable<boolean> = of(
    this.router.url.includes('/add-booking')
  );

  mainCurrency: string = 'USD';
  private readonly subscriptions: Subscription = new Subscription();
  exchangeRate: any[];
  exchangeAmount: number;
  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly wbsValidators: WBSValidators,
    private readonly modalService: ModalService,
    private readonly adminFacade: AdminFacade,
    private readonly recoveryFacade: RecoveryFacade,
    private readonly numberUtilsService: NumberUtilsService,
    private readonly workshopService: WorkshopService,
    public mediaObserver: MediaObserver,
    public recoveryEntryService: RecoveryEntryService,
    public wbsEntryService: WbsEntryService,
    public recoveryTabService: RecoveryTabService,
    private readonly changeDetect: ChangeDetectorRef,
    public readonly dateFormatService: DateFormatService
  ) {}

  ngOnInit() {
    this.exchangeRate = [];
    this.exchangeAmount = 0;
    this.recoveryGroup = this.fb.group({
      recoveryTotal: this.fb.control(0),
      totalCosts: this.fb.control(0),
      passThroughEstimate: this.fb.control(0),
      totalDiscounts: this.fb.control(0),
      totalCost: this.fb.control(0),
      chargeDate: this.fb.control(''),

      recoveryComments: this.fb.control(''),
      recoveryEntries: this.fb.array([]),
      wbsFormArray: this.fb.array([]),
    });
    this.registerSubscriptions();
    this.handleResize();
    this.chargeDateTooltipObj = tooltipConst.chargeDateTooltipObj;
    this.chargeStatusTooltipObj = tooltipConst.chargeStatusTooltipObj;
  }

  ngAfterViewInit() {
    this.changeDetect.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  handleResize() {
    this.subscriptions.add(
      this.mediaObserver.media$.subscribe((change: MediaChange) => {
        this.smallScreen = !!(
          change.mqAlias === 'md' ||
          change.mqAlias === 'sm' ||
          change.mqAlias === 'xs'
        );
      })
    );
  }

  /**
   * Validator needs to be set after the formGroup has successfully created
   */
  setFormGroupValidators() {
    this.wbsFormArray.setValidators(
      this.wbsValidators.percentageControlsValidator.bind(this)
    );

    // register validator on the formGroup only once we know we have at least one entry in the wbsFormArray
    this.recoveryGroup.setValidators(
      this.wbsValidators.validateBreakDownValueAgainstRecoveryTotal.bind(
        this.wbsValidators
      )
    );
  }

  registerSubscriptions(): void {
    this.getMainCurrency();
    this.updateRecoveryTotal();
    this.updateTotalCosts();
    this.updatePassThroughEstimate();
    this.updateTotalDiscounts();
    this.updateChargeDate();
    this.updateRecoveryComments();
    this.updateRecoveryEntries();
    this.updateAllSummaryValues();
    this.mapWBSLookupSearchResults();
    this.checkIfPrepopulated();
    this.getRecoveryStateObject();
    this.updateSelectedWorkshopRecovery();
    this.updateExchangeRate();
  }

  get recoveryTotal() {
    return this.recoveryGroup.get('recoveryTotal');
  }

  get totalCosts() {
    return this.recoveryGroup.get('totalCosts');
  }

  get totalDiscounts() {
    return this.recoveryGroup.get('totalDiscounts');
  }

  get passThroughEstimate() {
    return this.recoveryGroup.get('passThroughEstimate');
  }

  get recoveryEntries(): FormArray {
    return this.recoveryGroup.get('recoveryEntries') as FormArray;
  }

  get wbsFormArray(): FormArray {
    return this.recoveryGroup.get('wbsFormArray') as FormArray;
  }

  checkIfPrepopulated() {
    this.subscriptions.add(
      this.recoveryFacade.prepopulated$.subscribe((prepopulated) => {
        this.prepopulated = prepopulated;
      })
    );
  }

  getRecoveryStateObject() {
    this.subscriptions.add(
      this.recoveryFacade.allRecoveryTab$.subscribe((recoveryState) => {
        this.recoveryFacadeStateObject$ = recoveryState;
      })
    );
  }

  updateSelectedWorkshopRecovery(): void {
    this.subscriptions.add(
      combineLatest(
        this.isANewWorkshop$,
        this.workshopService.selectedWorkshopRecovery$
      ).subscribe(([isANewWorkshop, workshop]) => {
        if (isANewWorkshop) {
          this.handleNewWorkshopMapping();
        } else if (!isANewWorkshop && workshop) {
          this.handleExistingWorkshopMapping(workshop);
        } else if (!isANewWorkshop && workshop === undefined) {
          this.handleNewWorkshopMapping();
        }
      })
    );
  }

  handleNewWorkshopMapping() {
    if (this.prepopulated) {
      this.recoveryTabService.prepopulateTabWithStateObject(
        this.recoveryFacadeStateObject$,
        this.recoveryGroup
      );
    } else {
      this.recoveryFacade.updatePrepopulated(true);
    }
    this.createDefaultEntries();
  }

  handleExistingWorkshopMapping(workshop) {
    if (!this.prepopulated && workshop) {
      this.recoveryTabService.prepopulateTabWithDBResponse(
        workshop,
        this.recoveryGroup
      );
      this.recoveryFacade.updatePrepopulated(true);
    } else {
      this.recoveryTabService.prepopulateTabWithStateObject(
        this.recoveryFacadeStateObject$,
        this.recoveryGroup
      );
    }
    this.createDefaultEntries();
  }

  /**
   * Checks to see if the recoveryEntries and wbsFormArrays
   * are empty.
   * If they are, then it should create a default empty entry
   * If they aren't empty, then do nothing
   */
  createDefaultEntries() {
    if (_isEmpty(this.recoveryEntries.value)) {
      this.recoveryEntries.push(
        this.recoveryEntryService.createRecoveryEntry()
      );
    }
    if (_isEmpty(this.wbsFormArray.value)) {
      this.wbsFormArray.push(this.wbsEntryService.createPercentageWbs());
    }
    this.setFormGroupValidators();
  }

  updateExchangeRate(): void {
    this.subscriptions.add(
      this.recoveryFacade.exchangeRate$.subscribe((exchangeRate) => {
        this.exchangeRate = exchangeRate;
        this.calculateTotalEstimate();
        this.calculatePassThrough();
        this.calculateTotalDiscount();
      })
    );
  }

  updateAllSummaryValues(): void {
    this.subscriptions.add(
      this.recoveryEntries.valueChanges.subscribe(() => {
        this.calculateTotalEstimate();
        this.calculatePassThrough();
        this.calculateTotalDiscount();
      })
    );
  }

  updateChargeDate(): void {
    this.subscriptions.add(
      this.recoveryGroup
        .get('chargeDate')
        .valueChanges.subscribe((chargeDate) => {
          const newRes = this.dateFormatService.getDateToSave(chargeDate);
          this.recoveryFacade.updateChargeDate(newRes);
        })
    );
  }

  updateRecoveryComments(): void {
    this.subscriptions.add(
      this.recoveryGroup
        .get('recoveryComments')
        .valueChanges.pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((recoveryComments) => {
          this.recoveryFacade.updateRecoveryComments(recoveryComments);
        })
    );
  }

  updateRecoveryEntries(): void {
    this.subscriptions.add(
      this.recoveryGroup
        .get('recoveryEntries')
        .valueChanges.pipe(debounceTime(800), distinctUntilChanged())
        .subscribe(() => {
          const rawValue = this.recoveryEntries.getRawValue();
          const transRawValue = rawValue.map((elem) => {
            const newDate =
              elem.chargeDate === ''
                ? ''
                : this.dateFormatService.getDateToSave(elem.chargeDate);
            return { ...elem, chargeDate: newDate };
          });
          this.recoveryFacade.updateRecoveryEntries(transRawValue);
        })
    );
  }

  mapWBSLookupSearchResults() {
    this.subscriptions.add(
      this.recoveryFacade.wbsLookupSearchResults$
        .pipe(filter((x) => !_isEmpty(x)))
        .subscribe((searchResults) => {
          if (this.recoveryEntries.at(searchResults.WbsComponentIndex)) {
            this.recoveryEntries
              .at(searchResults.WbsComponentIndex)
              .get('isValid')
              .setValue(searchResults.IsValid);
            this.recoveryEntries
              .at(searchResults.WbsComponentIndex)
              .get('responseLoaded')
              .setValue(true);
            this.recoveryEntries
              .at(searchResults.WbsComponentIndex)
              .get('wbsType')
              .setValue(searchResults.BusinessActivityLevel1Description);
            this.recoveryEntries
              .at(searchResults.WbsComponentIndex)
              .get('wbsDescription')
              .setValue(searchResults.WbsDescription);
            this.recoveryEntries
              .at(searchResults.WbsComponentIndex)
              .get('expiration')
              .setValue(searchResults.BasicFinishDate);
          }
        })
    );
  }

  updateTotalDiscounts() {
    this.subscriptions.add(
      this.recoveryGroup
        .get('totalDiscounts')
        .valueChanges.subscribe((totalDiscounts) => {
          this.recoveryFacade.updateTotalDiscounts(totalDiscounts);
        })
    );
  }

  updateRecoveryTotal() {
    this.subscriptions.add(
      this.recoveryGroup
        .get('recoveryTotal')
        .valueChanges.subscribe((recoveryTotal) => {
          this.recoveryFacade.updateRecoveryTotal(recoveryTotal);
        })
    );
  }

  updateTotalCosts() {
    this.subscriptions.add(
      this.recoveryGroup
        .get('totalCosts')
        .valueChanges.subscribe((totalCosts) => {
          this.recoveryFacade.updateTotalCosts(totalCosts);
        })
    );
  }

  updatePassThroughEstimate() {
    this.subscriptions.add(
      this.recoveryGroup
        .get('passThroughEstimate')
        .valueChanges.subscribe((passThroughEstimate) => {
          this.recoveryFacade.updatePassThroughEstimate(passThroughEstimate);
        })
    );
  }

  getMainCurrency() {
    this.subscriptions.add(
      this.adminFacade.getProgramCode$.subscribe((programCode) => {
        this.mainCurrency =
          programCode && recoveryCurrency[programCode]
            ? recoveryCurrency[programCode]['currency']
            : 'USD';
      })
    );
  }

  calculateByExchangeRate(entry, amount) {
    this.exchangeAmount = 0;
    if (
      this.exchangeRate &&
      this.exchangeRate.length != 0 &&
      this.exchangeRate
        .map((option) => option.ToCurrencyCode)
        .includes(entry.currency.value)
    ) {
      this.exchangeRate.forEach((rate) => {
        if (entry.currency.value == rate.ToCurrencyCode) {
          this.exchangeAmount =
            this.numberUtilsService.normalizeToNumbersOnly(amount) *
            rate.ExchangeRates;
        }
      });
    } else {
      this.exchangeAmount = this.numberUtilsService.normalizeToNumbersOnly(
        amount
      );
    }
    return this.exchangeAmount;
  }

  calculateTotalEstimate(): void {
    const totalCost = this.recoveryEntries
      .getRawValue()
      .filter((option) => !option.passThrough)
      .reduce(
        (runningTotal: number, entry) =>
          runningTotal + this.calculateByExchangeRate(entry, entry.cost),
        0
      );
    this.totalCosts.setValue(totalCost);
  }

  calculateTotalDiscount(): void {
    const totalDiscounts = this.recoveryEntries
      .getRawValue()
      .filter((option) => !option.passThrough)
      .reduce(
        (runningTotal: number, entry) =>
          runningTotal + this.calculateByExchangeRate(entry, entry.discount),
        0
      );
    this.totalDiscounts.setValue(totalDiscounts);
  }

  calculatePassThrough(): void {
    const passThrough = this.recoveryEntries
      .getRawValue()
      .filter((value) => value.passThrough)
      .reduce(
        (runningTotal: number, entry) =>
          runningTotal + this.calculateByExchangeRate(entry, entry.subTotal),
        0
      );
    this.passThroughEstimate.setValue(passThrough);
    const recoveryTotal = this.recoveryEntries
      .getRawValue()
      .filter((value) => !value.passThrough)
      .reduce(
        (runningTotal: number, entry) =>
          runningTotal + this.calculateByExchangeRate(entry, entry.subTotal),
        0
      );
    this.recoveryTotal.setValue(recoveryTotal.toFixed(2));
  }

  removeRecoveryEntry(index: number): void {
    this.recoveryEntries.removeAt(index);
    this.modalService.closeDialog();
  }

  addRecoveryEntry(): void {
    this.recoveryEntries.push(this.recoveryEntryService.createRecoveryEntry());
  }

  getWbs(event) {
    this.recoveryFacade.getWBS(event.wbs, event.index);
  }
}
