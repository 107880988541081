import { HttpService } from '../http';
import { IOpportunityResponse } from '@utils/interfaces';
import { IOrgType, IOrganizationCategory, PeopleRequest } from '@ioh/types';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ReferenceDataService {
  constructor(private readonly http: HttpService) {}

  getInternalPurpose(programId: string): Observable<any> {
    const url = `${environment.endpoints.reference.internalPurpose}${programId}`;
    return this.http.get(url);
  }

  public getPeopleProfile({
    requestId = 1,
    exactMatch = 0,
    searchText,
  }: PeopleRequest) {
    const url = environment.endpoints.reference.getPeopleProfile;
    return this.http.post(url, {
      exactmatch: exactMatch,
      requestid: requestId,
      searchtxt: searchText,
    });
  }

  /**
   * Subscribe to this and it will return an object with a data property that contains
   * the already formated base64 string, ready to use as an image src
   * @param param0
   */
  public getProfilePicture({ eid }: { eid: string }): Observable<any> {
    const url = `${environment.endpoints.reference.getProfilePicture}/?eid=${eid}`;
    return this.http.get(url);
  }

  getWBS(code: string): Observable<any> {
    const url = `${environment.endpoints.reference.wbs}` + `${code}`;
    return this.http.get(url);
  }

  getOpportunityId(value): Observable<IOpportunityResponse> {
    const url: string = `${environment.endpoints.reference.opportunityId}${value}`;
    return this.http.get(url);
  }

  getTermAndCondition(programId: string): Observable<any> {
    const url: string = `${environment.endpoints.reference.getTermAndCondition}${programId}`;
    return this.http.get(url);
  }

  getOrganizationsTypes(): Observable<{ data: IOrgType[] }> {
    const url = environment.endpoints.reference.getOrganizationTypes;
    return this.http.get(url);
  }

  getOrganizationCategories(): Observable<{ data: IOrganizationCategory[] }> {
    const url: string =
      environment.endpoints.reference.getOrganizationCategories;
    return this.http.get(url);
  }

  getOperatingGroup(): Observable<any> {
    const url: string = environment.endpoints.reference.getOperatingGroup;
    return this.http.get(url);
  }

  getAccentureEntities(): Observable<any> {
    const url: string = environment.endpoints.reference.getAccentureEntities;
    return this.http.get(url);
  }

  getEngagementTypes(): Observable<any> {
    const url: string = environment.endpoints.reference.getEngagementType;
    return this.http.get(url);
  }

  getEngagementGroup(): Observable<any> {
    const url: string = environment.endpoints.reference.getEngagementGroup;
    return this.http.get(url);
  }

  getEngagementGroupEcosystem(): Observable<any> {
    const url: string =
      environment.endpoints.reference.getEngagementGroupEcosystem;
    return this.http.get(url);
  }

  getClients(searchQuery: string): Observable<any> {
    const url = `${environment.endpoints.reference.getClients}?customerName=${searchQuery}`;
    return this.http.get(url);
  }

  getTags(partialName: string): Observable<any> {
    const url: string = `${environment.endpoints.reference.tags}${partialName}`;
    return this.http.get(url);
  }

  /**
   * Returns the values used for the workshop status dropdown
   */
  getWorkshopStatus(): Observable<any> {
    const url = `${environment.endpoints.reference.getWorkshopStatus}`;
    return this.http.get(url);
  }

  getCancellationReason(): Observable<any> {
    const url = `${environment.endpoints.reference.getCancellationReason}`;
    return this.http.get(url);
  }

  getEngagementTeamRoles(programId: string): Observable<any> {
    const url = `${environment.endpoints.reference.getTeamRoles}${programId}`;
    return this.http.get(url);
  }

  getDatesAndLocationsRooms(programId: string): Observable<any> {
    const url = `${environment.endpoints.reference.getDatesAndLocationsRooms}${programId}`;
    return this.http.get(url);
  }

  getExchangeRate(searchQuery: {
    fromCurr: string;
    toCurr: string;
  }): Observable<any> {
    const url = `${environment.endpoints.reference.getExchangeRate}?fromCurr=${searchQuery.fromCurr}&toCurr=${searchQuery.toCurr}`;
    return this.http.get(url);
  }
}
