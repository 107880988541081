<div class="table" [hidden]="!(data$ | async)">
  <div
    class="table__header"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="0.5rem"
  >
    <h3
      [ngClass]="{'urgent' : urgentTableName}"
      fxFlex="65%"
    >{{tableName}}</h3>
    <mat-form-field fxFlex="30%" *ngIf="dataSource?.data?.length > 0">
      <mat-label>Filter</mat-label>
      <input matInput placeholder="Filter" (keyup)="applyFilter($event)">
    </mat-form-field>
    <button
      class="table__button__export"
      mat-flat-button color="primary"
      fxFlex="2%"
      *ngIf="tableName === 'All Engagements'"
      matTooltip="Download Table"
      matTooltipPosition="below"
      (click)="exportTable()"
    >
      <mat-icon> save_alt </mat-icon>
    </button>
    <button
      class="table__button__export"
      mat-flat-button color="primary"
      fxFlex="2%"
      *ngIf="tableName === 'User List'"
      matTooltip="Add User"
      matTooltipPosition="below"
      (click)="addUser()"
    >
      <span class="material-icons">
        add
      </span>
    </button>
    <button
      class="table__button__export"
      mat-flat-button color="primary"
      fxFlex="2%"
      *ngIf="tableName === 'Total Recovery' && dataSource?.data?.length > 0"
      matTooltip="Download Table"
      matTooltipPosition="below"
      (click)="exportTotalRecoveryTable()"
    >
      <mat-icon> save_alt </mat-icon>
    </button>
  </div>
  <div [hidden]="dataSource?.data?.length === 0">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="mat-elevation-z8"
      ngClass.sm="table__small__device"
      ngClass.xs="table__small__device"
    #table>
      <ng-container *ngFor="let disCol of colNamesKeys; let i = index" matColumnDef="{{disCol}}">
        <th class="table__header" mat-header-cell *matHeaderCellDef mat-sort-header> {{colNames[disCol]}} </th>
        <td 
          mat-cell 
          *matCellDef="let element" 
          tabindex="0" 
          (click)="openWorkshop(element)" 
          (keydown.enter)="openWorkshop(element)"
          matTooltip="{{element[disCol]}}" 
          [matTooltipShowDelay]="500"
          [matTooltipHideDelay]="500" 
          matTooltipPosition="below"
        >
          <div *ngIf="element[disCol]==='edit delete'; else text">
            <button
              mat-flat-button
              color="primary"
              class="square"
              (click)="editRow(element)"
            >
              <span class="material-icons">
                edit
              </span>
            </button>
            <button
              mat-flat-button
              color="primary"
              class="square"
              (click)="deleteRow(element)"
            >
              <span class="material-icons">
                delete
              </span>
            </button>
          </div>
          <div *ngIf="element[disCol]==='delete' && tableName === 'User List'; else text2">
            <button
              mat-flat-button
              color="primary"
              class="square"
              [disabled] = "!!element.userId && element.userId == currentUser"
              (click)="deleteRow(element)"
            >
              <span class="material-icons">
                delete
              </span>
            </button>
          </div>
          <ng-template #text>
            {{element[disCol] !== 'delete' ? element[disCol]:''}}
          </ng-template>
          <ng-template #text2 *ngIf="tableName === 'User List'">
            {{element[disCol]}}
          </ng-template>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="colNamesKeys"></tr>
      <tr mat-row *matRowDef="let row; columns: colNamesKeys;"></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[10, 20, 50]"
      [pageSize]="pageLength"
      showFirstLastButtons
      [style.background-color]="colour"
    ></mat-paginator>
  </div>
  <div class="no-data" [hidden]="dataSource?.data?.length > 0">
    No data to display for this table.
  </div>
</div>
<div [hidden]="(data$ | async)">
  <h3 [ngClass]="{'urgent' : urgentTableName}">{{tableName}}</h3>
  <mat-spinner class="no-data" color="accent" diameter="20"></mat-spinner>
</div>
