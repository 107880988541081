export const closedColNames = {
  listViewId: 'id',
  status: 'engagement status',
  poc: 'poc',
  type: 'type',
  title: 'title',
  org: 'org',
  start: 'start',
  end: 'end',
  lead: 'lead',
  wbs: 'wbs',
  total: 'total',
};

export const adminColNames = {
  programCode: 'program',
  userId: 'user name',
  accessLevel: 'access level',
  createdAt: 'added date',
  createdBy: 'added by',
  action: 'action',
};

export const homeColNames = {
  listViewId: 'id',
  status: 'engagement status',
  start: 'start',
  end: 'end',
  title: 'title',
  org: 'org',
  poc: 'poc',
  type: 'type',
  attendees: 'attendees',
  lead: 'lead',
  team: 'team',
  total: 'total',
};

export const directChargeAndSubmissioncolNames = {
  chargedate: 'CHG DATE',
  listViewId: 'BOOKING ID',
  title: 'TITLE',
  org: 'ORG',
  start: 'START DATE',
  end: 'END DATE',
  status: 'ENG STATUS',
  lead: 'ENG LEAD',
  code: 'WBS',
  subTotal: 'RECOVERY AMOUNT',
};

export const inProgressColNames = {
  listViewId: 'id',
  start: 'start',
  end: 'end',
  title: 'title',
  org: 'org',
  type: 'type',
  poc: 'poc',
  attendees: 'attendees',
  lead: 'lead',
  team: 'team',
  totalCosts: 'total cost',
};

export const pendingColNames = {
  chargedate: 'CHG DATE',
  chargeStatus: 'CHG STATUS',
  listViewId: 'BOOKING ID',
  title: 'TITLE',
  org: 'ORG',
  start: 'START DATE',
  end: 'END DATE',
  status: 'ENG STATUS',
  lead: 'ENG LEAD',
  code: 'WBS',
  total: 'RECOVERY AMOUNT',
};

export const totalRecoveryReportColNames = {
  chargedate: 'Charge Date',
  chargeStatus: 'Charge Status',
  status: 'Engagement Status',
  wbsType: 'WBS Type',
  isValid: 'WBS Status',
  sendWbs: 'Sender WBS',
  networkSender: 'Network Sender',
  costElement: 'Cost Element',
  amount: 'Amount',
  currency: 'Currency',
  code: 'WBS',
  networkReceiver: 'Network Receiver',
  engagementTitle: 'Engagement Title',
  recovery2P: '2% Recovery',
  totalRecoveryOrg: 'Organization',
  groupOwner: 'Group Owner',
  bookingId: 'Booking ID',
};

export const allEngagementColNames = {
  listViewId: 'ID',
  status: 'Engagement Status',
  poc: 'Poc',
  type: 'Engagement Type',
  title: 'Engagement Title',
  org: 'Organization',
  start: 'Start Date',
  end: 'End Date',
  lead: 'Engagement Lead',
  wbs: 'WBS',
  total: 'Total Recovery',
};

export const requestedColNames = {
  listViewId: 'id',
  poc: 'poc',
  type: 'type',
  org: 'org',
  start: 'start',
  end: 'end',
  waitlist: 'waitlist',
  expiration: 'softlock',
  owner: 'cons. owner',
  complete: 'consultation',
};

export const orgColNames = {
  type: 'type',
  org: 'organization name',
  group: 'group/category',
  action: 'action',
};
