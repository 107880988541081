<div
    fxLayout="row"
    fxLayout.md="column"
    fxLayout.sm="column"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
    fxLayoutAlign.md="space-between stretch"
    fxLayoutAlign.sm="space-between stretch"
    fxLayoutAlign.xs="space-between stretch"
    fxLayoutGap="1rem"
    class="general__mt"
    [formGroup]="parentForm"
  >
    <ioh-wbs-input
      fxFlex="20%"
      name="wbs-code"
      inputId="wbs-code-{{index}}"
      name="wbs-code"
      placeholder="WBS Code"
      value="code"
      [label]="(index === 0 || smallScreen) ? 'WBS Code' : ''"
      [formGroup]="parentForm"
      [wbsDetails]="wbsDetails"
      [success]="isValid"
      [expired]="isExpired"
      [response]="responseLoaded"
      [loading$]="wbsResultsLoading$"
    ></ioh-wbs-input>
    <!-- <ioh-wbs-input
      fxFlex="20%"
      inputId="wbs-breakdown-{{index}}"
      name="wbs-breakdown"
      [label]="(index === 0 || smallScreen) ? wbsBreakdown : ''"
      placeholder="WBS Breakdown"
      [wbsDetails]="wbsDetails"
      [breakdownSymbol]="breakdownType"
      [formGroup]="parentForm"
      value="breakdownValue"
      breakdown="true"
      (onBreakdownTypeChange)="onChangeBreakdownType($event)"
    >
    </ioh-wbs-input> -->
    <ng-container *ngIf="wbsDetails">
      <div fxLayout="column" fxFlex>
        <label *ngIf="(index === 0 || smallScreen)" class="wbs__details__label" fxFlex="50%">WBS Type</label>
        <p fxFlex="50%" class="wbs__details__text">{{wbsType || '-'}}</p>
      </div>
      <div fxLayout="column" fxFlex>
        <label *ngIf="(index === 0 || smallScreen)" class="wbs__details__label" fxFlex="50%">WBS Description</label>
        <p fxFlex="50%" class="wbs__details__text">{{wbsDescription || '-'}}</p>
      </div>
      <div fxLayout="column"v fxFlex="15%">
        <label *ngIf="(index === 0 || smallScreen)" class="wbs__details__label" fxFlex="50%">Expiration</label>
        <p fxFlex="50%" [ngStyle]="{'color':(isExpired?'#c2255c':'rgba(0, 0, 0, 0.87)')}" class="wbs__details__text">{{(wbsExpiration | date:"MM/dd/yy") || '-' }}</p>
      </div>
    </ng-container>
    <!-- <div fxFlex="4%">
      <button
        (click)="onRemoveWbs(index)"
        mat-mini-fab
        class="wbs__button__delete"
        [ngClass]="{'wbs__button__delete--first': (index === 0 && !smallScreen), 'wbs__button__delete__visit--first': (index === 0 && !wbsDetails) }"
        [ngClass.md]="'wbs__button__delete--medium'"
        [ngClass.sm]="'wbs__button__delete--small'"
        [ngClass.xs]="'wbs__button__delete--xsmall'"
        color="accent"
        aria-label="Remove wbs button"
      >
        <mat-icon class="material-icons" aria-label="Remove wbs button"
          >clear</mat-icon
        >
      </button>
    </div> -->
</div>
<ioh-error
  class="wbs__error"
  *ngIf="
    (parentForm.get('breakdownValue').touched ||
    parentForm.get('breakdownValue').dirty) &&
    (parentForm.get('breakdownValue').value == '')
  "
  errorMsg="Don't forget to add a breakdown to your WBS!"
  >
</ioh-error>

<ng-template #warningTemplate>
  <ioh-warning-modal
    elementName="wbs"
    [elementIndex]="index"
    (delete)="removeWbs($event)"
    (close)="closeModal()"
  ></ioh-warning-modal>
</ng-template>
