import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormControlUtils } from '@utils/formControlUtils';
import { Injectable } from '@angular/core';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
@Injectable({
  providedIn: 'root',
})
export class RecoveryEntryService {
  constructor(
    private readonly fb: FormBuilder,
    private readonly formControlUtils: FormControlUtils
  ) {}

  twoDecimalRegex = /^\d+(\.\d{0,2})?$/;

  createRecoveryEntry(entry?): FormGroup {
    return this.fb.group({
      id: this.fb.control(
        this.formControlUtils.mapProperty({ entry, property: 'id' })
      ),
      cost: this.fb.control(
        this.formControlUtils.mapProperty({ entry, property: 'cost' }),
        [
          Validators.min(0),
          RxwebValidators.pattern({
            expression: { twoDecimalPoints: this.twoDecimalRegex },
          }),
        ]
      ),
      description: this.fb.control(
        this.formControlUtils.mapProperty({ entry, property: 'description' })
      ),
      discount: this.fb.control(
        this.formControlUtils.mapProperty({ entry, property: 'discount' }),
        [
          Validators.min(0),
          RxwebValidators.pattern({
            expression: { twoDecimalPoints: this.twoDecimalRegex },
          }),
        ]
      ),
      chargeDate: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'chargeDate',
        })
      ),
      chargeStatus: this.fb.control({
        value:
          // eslint-disable-next-line prettier/prettier
          entry && typeof entry.chargeStatus === 'string'
            ? this.formControlUtils.mapProperty({
                entry,
                property: 'chargeStatus',
              })
            : this.formControlUtils.mapProperty({
                entry,
                property: 'chargeStatus.value',
              }),
      }),
      currency: this.fb.control({
        value:
          // eslint-disable-next-line prettier/prettier
          entry && typeof entry.currency === 'string'
            ? this.formControlUtils.mapProperty({
                entry,
                property: 'currency',
              })
            : this.formControlUtils.mapProperty({
                entry,
                property: 'currency.value',
              }),
      }),
      code: this.fb.control(this.mapWbsCode(entry)),
      wbsDescription: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'wbsDescription',
        }),
        Validators.required
      ),
      wbsType: this.fb.control(this.mapType(entry), Validators.required),
      expiration: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'expiration',
        }),
        Validators.required
      ),
      isValid: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'isValid',
          defaultValue: null,
        })
      ),
      responseLoaded: this.fb.control(false),
      passThrough: this.fb.control(
        this.formControlUtils.mapProperty({
          entry,
          property: 'passThrough',
          defaultValue: false,
        })
      ),
      quantity: this.fb.control(
        this.formControlUtils.mapProperty({ entry, property: 'quantity' }),
        [
          Validators.min(0),
          RxwebValidators.pattern({
            expression: { twoDecimalPoints: this.twoDecimalRegex },
          }),
        ]
      ),
      recovery2P: this.fb.control(
        this.formControlUtils.mapProperty({ entry, property: 'recovery2P' }),
        [
          Validators.min(0),
          RxwebValidators.pattern({
            expression: { twoDecimalPoints: this.twoDecimalRegex },
          }),
        ]
      ),
      manualEdited: this.fb.control(
        this.formControlUtils.mapProperty({ entry, property: 'manualEdited' })
      ),
      subTotal: this.fb.control({
        disabled: true,
        value: this.formControlUtils.mapProperty({
          entry,
          property: 'subTotal',
        }),
      }),
    });
  }

  private mapWbsCode(entry: any): string {
    return (
      this.formControlUtils.mapProperty({ entry, property: 'wbsCode' }) ||
      this.formControlUtils.mapProperty({ entry, property: 'code' })
    );
  }

  private mapType(entry: any): string {
    return this.formControlUtils.mapProperty({
      entry,
      property: 'wbsType',
      defaultValue: '-',
    });
  }
}
