import * as EngagementDetailsActions from './engagement-details.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

export const ENGAGEMENTDETAILS_FEATURE_KEY = 'engagementDetails';

export interface EngagementDetailsState extends EntityState<any> {
  loaded: boolean;
  error: string;
  backgroundText: string | null;
  objectivesAndExpectations: string | null;
  successCriteria: string | null;
  challenges: string | null;
  internalPurposeSearchResults: any; // should be an array of internal purpose objs
  opportunityIdsSearchResults: any; // should be an array of opp id obs;
  selectedOpportunityIds: any[]; // an array
  selectedInternalPurpose: any; // obj of key value/ id value of interal purpose;
}

export interface EngagementDetailsPartialState {
  readonly [ENGAGEMENTDETAILS_FEATURE_KEY]: EngagementDetailsState;
}

export const engagementDetailsAdapter: EntityAdapter<any> = createEntityAdapter<
  any
>({
  selectId: (engagementDetails) =>
    engagementDetails.id
      ? engagementDetails.id
      : engagementDetails.OpportunityId,
});

export const initialState: EngagementDetailsState = engagementDetailsAdapter.getInitialState(
  {
    backgroundText: '',
    challenges: null,
    error: null,
    internalPurposeSearchResults: [],
    loaded: null,
    objectivesAndExpectations: '',
    opportunityIdsSearchResults: [],
    selectedInternalPurpose: [],
    selectedOpportunityIds: [],
    successCriteria: '',
  }
);

const engagementDetailsReducer = createReducer(
  initialState,
  on(EngagementDetailsActions.loadInternalPurposeResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    EngagementDetailsActions.loadInternalPurposeResultsSuccess,
    (state, { internalPurposeSearchResults }) =>
      engagementDetailsAdapter.addMany(internalPurposeSearchResults, {
        ...state,
        internalPurposeSearchResults,
        loaded: true,
      })
  ),
  on(
    EngagementDetailsActions.loadInternalPurposeResultsFailure,
    (state, { error }) => ({ ...state, error })
  ),
  on(
    EngagementDetailsActions.selectInternalPurpose,
    (state, { selectedInternalPurpose }) => {
      return Object.assign({}, state, { selectedInternalPurpose });
    }
  ),

  on(
    EngagementDetailsActions.updateObjectivesAndExpectations,
    (state, { objectivesAndExpectations }) => {
      return Object.assign({}, state, { objectivesAndExpectations });
    }
  ),
  on(
    EngagementDetailsActions.updateBackgroundText,
    (state, { backgroundText }) => {
      return Object.assign({}, state, { backgroundText });
    }
  ),
  on(
    EngagementDetailsActions.updateSuccessCriteria,
    (state, { successCriteria }) => {
      return Object.assign({}, state, { successCriteria });
    }
  ),
  on(EngagementDetailsActions.updateChallenges, (state, { challenges }) => {
    return Object.assign({}, state, { challenges });
  }),
  on(
    EngagementDetailsActions.updateSelectedOpportunityIds,
    (state, { selectedOpportunityIds }) => {
      return Object.assign({}, state, { selectedOpportunityIds });
    }
  ),
  on(EngagementDetailsActions.loadOpportunityIdsSearchResults, (state) => ({
    ...state,
    error: null,
    loaded: false,
  })),
  on(
    EngagementDetailsActions.loadOpportunityIdsSearchResultsSuccess,
    (state, { opportunityIdsSearchResults }) =>
      engagementDetailsAdapter.addMany(opportunityIdsSearchResults, {
        ...state,
        loaded: true,
        opportunityIdsSearchResults,
      })
  ),
  on(
    EngagementDetailsActions.loadOpportunityIdsSearchResultsFailure,
    (state, { error }) => ({ ...state, error })
  ),
  on(EngagementDetailsActions.clearStateObject, () => {
    return Object.assign({});
  })
);

export function reducer(
  state: EngagementDetailsState | undefined,
  action: Action
) {
  return engagementDetailsReducer(state, action);
}
