export const engagementTypeDropdown = {
  direction: 'column',
  dropdownId: 'engagementType',
  isMultiple: false,
  label: 'Engagement Type',
  name: 'type of service being provided',
  placeholder: 'Type of service that is being provided',
  required: true,
};

export const generalTextAreas = [
  {
    description: 'Summary of problem/challenge statement or engagement purpose',
    height: '4rem',
    id: 'summary',
    label: 'Summary',
    limit: 300,
    name: 'Summary',
    placeholder:
      'Provide short problem/challenge statement or summary of engagement purpose that will guide design or execution.',
    required: false,
    value: 'summary',
  },
  {
    description:
      'Administrative notes for tracking updates and internal information',
    height: '4rem',
    id: 'adminComments',
    label: 'Admin Comments',
    limit: 1000,
    name: 'Admin Comments',
    placeholder:
      'Administrative notes for tracking updates and internal information',
    required: false,
    value: 'adminComments',
  },
];

export const engagementGroupDropdown = {
  direction: 'column',
  dropdownId: 'engagementGroup',
  isMultiple: false,
  label: 'Group Owner',
  name: 'Accenture group driving the engagement',
  placeholder: 'Accenture group driving the engagement',
  required: false,
  values: [
    { value: 'CIS' },
    { value: 'Accenture Office' },
    { value: 'Account Team' },
    { value: 'Applied Intelligence' },
    { value: 'Avanade' },
    { value: 'Digital Products' },
    { value: 'Fjord' },
    { value: 'Industry X.0' },
    { value: 'Labs' },
    { value: 'Liquid Studio' },
    { value: 'Marketing' },
    { value: 'POD' },
    { value: 'Research' },
    { value: 'Ventures' },
    { value: 'Other' },
  ],
};

export const engagementTeamRoleDropdown = {
  direction: 'column',
  isMultiple: false,
  label: 'Role',
  name: 'engagement role dropdown',
  placeholder: 'Choose one',
  required: false,
};

export const inputEngagementTeam = [
  {
    id: 'prepDay',
    label: 'Prep Days',
    maxLength: '7',
    name: 'Prep Day',
    placeholder: '-',
    required: false,
    value: 'prepDay',
  },
  {
    id: 'execDay',
    label: 'Execution Days',
    maxLength: '7',
    name: 'Exec Day',
    placeholder: '-',
    required: false,
    value: 'execDay',
  },
  {
    id: 'closeOutDay',
    label: 'Close Out Days',
    maxLength: '7',
    name: 'Close out Day',
    placeholder: '-',
    required: false,
    value: 'closeOutDay',
  },
  {
    id: 'totalDay',
    label: 'Total Days',
    name: 'Total Day',
    placeholder: '-',
    required: false,
    value: 'totalDay',
  },
];

export const groupParticipationDropdown = {
  direction: 'column',
  dropdownId: 'groupParticipation',
  isMultiple: true,
  label: 'Innovation (Internal)',
  name: 'group partecipation',
  placeholder: 'Select groups',
  required: false,
  values: [
    { id: 1, value: 'CIS' },
    { id: 14, value: 'Accenture Office' },
    { id: 12, value: 'Account Team' },
    { id: 8, value: 'Applied Intelligence' },
    { id: 3, value: 'Avanade' },
    { id: 9, value: 'Digital Products' },
    { id: 11, value: 'Fjord' },
    { id: 10, value: 'Industry X.0' },
    { id: 5, value: 'Labs' },
    { id: 2, value: 'Liquid Studio' },
    { id: 13, value: 'Marketing' },
    { id: 7, value: 'POD' },
    { id: 4, value: 'Research' },
    { id: 6, value: 'Ventures' },
    { id: 15, value: 'Other' },
  ],
};

export const groupEcosystemDropdown = {
  direction: 'column',
  dropdownId: 'groupEcosystem',
  isMultiple: true,
  label: 'Ecosystem (External)',
  name: 'group ecosystem',
  placeholder: 'Select groups',
  required: false,
  values: [
    { id: 1, value: 'Salesforce' },
    { id: 2, value: 'Microsoft' },
    { id: 3, value: 'Amazon' },
    { id: 4, value: 'Google' },
    { id: 5, value: 'SAP' },
    { id: 6, value: 'Other Ecosystem Partner​' },
  ],
};

export const groupEcosystemMockDropdown = {
  direction: 'column',
  dropdownId: 'groupEcosystemMock',
  isMultiple: true,
  label: 'Group Participation - Ecosystem External',
  name: 'group ecosystem',
  placeholder: 'Select groups',
  required: false,
  values: [
    { value: 'Salesforce' },
    { value: 'Microsoft' },
    { value: 'Amazon' },
    { value: 'Google' },
    { value: 'SAP' },
    { value: 'Other Ecosystem Partner​' },
  ],
};

export const imageTooltipTxt = {
  startDate:
    'Indicates when meaningful work began for an engagement.  This field is auto-populated based on when the engagement is moved from “Requested” to “Booked” status.  Users may manually overwrite the auto-populated date as needed. Please see the illustrative timeline:',
  endDate:
    'Indicates when meaningful work has completed for an engagement.  This field is auto-populated based on when the engagement is moved from “Booked” to “Completed” status.  Users may manually overwrite the auto-populated date as needed.  Please see the illustrative timeline:',
  dateLoction:
    'Please identify when there are specific event dates planned for this engagement.  For example, if there is a 2-day workshop planned with the client team, please add one row for each event date (two rows total).  If your engagement does not have specific event dates, then do not populate any rows.  Internal or planning meetings should NOT be included.  Please see illustrative timeline:',
};

export const generalTableColumns = [
  { name: 'selected', text: 'Select Date', type: 'checkbox', placeholder: '' },
  {
    name: 'dates',
    text: 'Dates',
    type: 'datepicker',
    placeholder: 'mm/dd/yyyy',
  },
  {
    name: 'type',
    text: 'Type',
    type: 'dropdown',
    placeholder: 'Select',
    // TODO: this needs to integrate with
    values: [{ value: 'Dry Run' }, { value: 'Execution' }],
  },
  {
    name: 'clientArrival',
    text: 'Client Arrival',
    type: 'timepicker',
    placeholder: 'hh:mm',
  },
  {
    name: 'startTime',
    text: 'Start Time',
    type: 'timepicker',
    placeholder: 'hh:mm',
  },
  {
    name: 'endTime',
    text: 'End Time',
    type: 'timepicker',
    placeholder: 'hh:mm',
  },
  { name: 'hours', text: 'Hours', type: 'input', placeholder: '-' },
  {
    name: 'room',
    text: 'Locations',
    type: 'locationDropdown',
    placeholder: 'Select',
  },
  {
    name: 'locationStartTime',
    text: 'Start Time',
    type: 'locationTimepicker',
    placeholder: 'hh:mm',
  },
  {
    name: 'locationEndTime',
    text: 'End Time',
    type: 'locationTimepicker',
    placeholder: 'hh:mm',
  },
  { name: 'ems', text: 'EMS #', type: 'locationInput', placeholder: '-' },
  { name: 'actions', text: '', type: 'locationActions', placeholder: '' },
];
