import {
  FORM_FEATURE_KEY,
  FormPartialState,
  FormState,
  formAdapter,
} from './form.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

// Lookup the 'Form' feature state managed by NgRx
export const getFormState = createFeatureSelector<FormPartialState, FormState>(
  FORM_FEATURE_KEY
);

const { selectAll, selectEntities } = formAdapter.getSelectors();

export const getFormLoaded = createSelector(
  getFormState,
  (state: FormState) => state.loaded
);

export const getRequestStatus = createSelector(
  getFormState,
  (state: FormState) => state.requestSuccessful
);

export const getSubmissionStatus = createSelector(
  getFormState,
  (state: FormState) => state.submitted
);

export const getFormError = createSelector(
  getFormState,
  (state: FormState) => state.error
);

export const getTermsAndConditions = createSelector(
  getFormState,
  (state: FormState) => state.termsAndConditions
);

export const getAllForm = createSelector(getFormState, (state: FormState) =>
  selectAll(state)
);

export const getFormEntities = createSelector(
  getFormState,
  (state: FormState) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getFormState,
  (state: FormState) => state.selectedId
);

export const getSelected = createSelector(
  getFormEntities,
  getSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

export const getProgramId = createSelector(
  getFormState,
  (state: FormState) => state.programId
);
