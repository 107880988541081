import { BehaviorSubject, Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContactDetailsFacade } from '@ioh/core-data';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ILookUp } from '@utils/interfaces';
import { LookUpValidatorsService } from '@utils/validators';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  tap,
} from 'rxjs/operators';
@Component({
  selector: 'ioh-contact-details',
  styleUrls: ['./contact-details.component.scss'],
  templateUrl: './contact-details.component.html',
})
export class ContactDetailsComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription = new Subscription();
  myGroup: FormGroup;
  lookUps: ILookUp[];
  allEngagementSponsorResultsLoading$ = new BehaviorSubject(false);
  allPrimaryContactResultsLoading$ = new BehaviorSubject(false);
  allAdditionalContactResultsLoading$ = new BehaviorSubject(false);

  engagementSponsorLimit = 1;
  primaryContactLimit = 1;
  additionalContactsLimit = 25;

  constructor(
    private readonly fb: FormBuilder,
    private readonly lookUpValidator: LookUpValidatorsService,
    private readonly contactDetailsFacade: ContactDetailsFacade
  ) {}

  allEngagementSponsorResults$ = this.contactDetailsFacade.allEngagementSponsorResults$.pipe(
    tap(() => this.allEngagementSponsorResultsLoading$.next(false)),
    map((res) => this.formatData(res))
  );

  allPrimaryContactResults$ = this.contactDetailsFacade.allPrimaryContactResults$.pipe(
    tap(() => this.allPrimaryContactResultsLoading$.next(false)),
    map((res) => this.formatData(res))
  );

  allAdditionalContactResults$ = this.contactDetailsFacade.allAdditionalContactResults$.pipe(
    tap(() => this.allAdditionalContactResultsLoading$.next(false)),
    map((res) => this.formatData(res))
  );

  ngOnInit() {
    this.myGroup = this.fb.group({
      additionalContactsInput: this.fb.group({
        additionalContactsResult: this.fb.array(
          [],
          [this.lookUpValidator.validateLimit(this.additionalContactsLimit)]
        ),
        input: this.fb.control(''),
      }),
      engagementSponsorInput: this.fb.group({
        engagementSponsorResult: this.fb.array(
          [],
          [
            this.lookUpValidator.validateRequired,
            this.lookUpValidator.validateLimit(this.engagementSponsorLimit),
          ]
        ),
        input: this.fb.control(''),
      }),
      primaryContactInput: this.fb.group({
        input: this.fb.control(''),
        primaryContactResult: this.fb.array(
          [],
          [
            this.lookUpValidator.validateRequired,
            this.lookUpValidator.validateLimit(this.primaryContactLimit),
          ]
        ),
      }),
    });

    this.lookUps = [
      {
        isLoading: this.allEngagementSponsorResultsLoading$,
        label: 'Engagement Sponsor',
        limit: this.engagementSponsorLimit,
        lookUpId: 'sponsor',
        lookupArrayFieldName: 'engagementSponsorResult',
        lookupGroupFieldName: 'engagementSponsorInput',
        lookupInputFieldName: 'input',
        placeholder: 'ACN Sponsor and/or key decision maker',
        required: true,
        searchResults: this.allEngagementSponsorResults$,
      },
      {
        isLoading: this.allPrimaryContactResultsLoading$,
        label: 'Primary Point of Contact',
        limit: this.primaryContactLimit,
        lookUpId: 'primaryContact',
        lookupArrayFieldName: 'primaryContactResult',
        lookupGroupFieldName: 'primaryContactInput',
        lookupInputFieldName: 'input',
        placeholder:
          'ACN single point of accountability that the team should work with',
        required: true,
        searchResults: this.allPrimaryContactResults$,
      },
      {
        isLoading: this.allAdditionalContactResultsLoading$,
        label: 'Additional Contacts',
        limit: this.additionalContactsLimit,
        lookUpId: 'AdditionalContact',
        lookupArrayFieldName: 'additionalContactsResult',
        lookupGroupFieldName: 'additionalContactsInput',
        lookupInputFieldName: 'input',
        placeholder: 'Additional ACN contacts to keep informed',
        required: false,
        searchResults: this.allAdditionalContactResults$,
      },
    ];

    this.updateEngagementSponsorResults();
    this.updatePrimaryContactResults();
    this.updateAdditionalContactResults();

    this.fetchEngagementSponsorSearchResults();
    this.fetchPrimaryContactSearchResults();
    this.fetchAdditionalContactSearchResults();
  }

  formatData(data) {
    data = data || [];
    if (data.length > 0) {
      return data.map((item) => item.enterpriseID);
    } else {
      return [];
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  fetchEngagementSponsorSearchResults() {
    this.subscriptions.add(
      this.myGroup
        .get('engagementSponsorInput')
        .get('input')
        .valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          filter((res) => res)
        )
        .subscribe((res) => {
          this.allEngagementSponsorResultsLoading$.next(true);
          this.contactDetailsFacade.loadEngagementSponsorSearchResults({
            searchtxt: res,
          });
        })
    );
  }

  fetchPrimaryContactSearchResults() {
    this.subscriptions.add(
      this.myGroup
        .get('primaryContactInput')
        .get('input')
        .valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          filter((res) => res)
        )
        .subscribe((res) => {
          this.allPrimaryContactResultsLoading$.next(true);
          this.contactDetailsFacade.loadPrimaryContactSearchResults({
            searchtxt: res,
          });
        })
    );
  }

  fetchAdditionalContactSearchResults() {
    this.subscriptions.add(
      this.myGroup
        .get('additionalContactsInput')
        .get('input')
        .valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          filter((res) => res)
        )
        .subscribe((res) => {
          this.allAdditionalContactResultsLoading$.next(true);
          this.contactDetailsFacade.loadAdditionalContactSearchResults({
            searchtxt: res,
          });
        })
    );
  }

  updateEngagementSponsorResults() {
    this.subscriptions.add(
      this.myGroup
        .get('engagementSponsorInput')
        .get('engagementSponsorResult')
        .valueChanges.subscribe((res) => {
          this.allEngagementSponsorResultsLoading$.next(true);
          this.contactDetailsFacade.updateEngagementSponsors(res);
        })
    );
  }

  updatePrimaryContactResults() {
    this.subscriptions.add(
      this.myGroup
        .get('primaryContactInput')
        .get('primaryContactResult')
        .valueChanges.subscribe((res) => {
          this.allPrimaryContactResultsLoading$.next(true);
          this.contactDetailsFacade.updatePrimaryContact(res);
        })
    );
  }

  updateAdditionalContactResults() {
    this.subscriptions.add(
      this.myGroup
        .get('additionalContactsInput')
        .get('additionalContactsResult')
        .valueChanges.subscribe((res) => {
          this.allAdditionalContactResultsLoading$.next(true);
          this.contactDetailsFacade.updateAdditionalContact(res);
        })
    );
  }
}
