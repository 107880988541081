import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'ioh-text-area',
  styleUrls: ['./text-area.component.scss'],
  templateUrl: './text-area.component.html',
})
export class TextAreaComponent implements OnInit {
  @Input() label: string;
  @Input() textareaId: string;
  @Input() placeholder: string;
  @Input() value: any;
  @Input() name: any;
  @Input() required: boolean;
  @Input() maxCharLimit: number;
  @Input() description: string;
  @Input() height: string;
  parentForm;

  constructor(private readonly controlContainer: ControlContainer) {}

  ngOnInit() {
    this.parentForm = this.controlContainer.control;
  }
}
